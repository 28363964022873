import {ReactElement, ReactNode} from "react";
import {Link} from "react-router-dom";

export interface SobrConditionalLinkProps {
    to: string | null | undefined
    children: ReactNode
}

export function SobrConditionalLink(props: SobrConditionalLinkProps): ReactElement {
    return !props.to ? <>{props.children}</> : <Link to={props.to}>{props.children}</Link>;
}
