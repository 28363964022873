import f from "lib/util";
import v from "lib/validation";
import PropTypes from 'prop-types';
import {assoc, assocPath, filter, identity, ifElse, map, omit, path, pick, pipe, prop, propSatisfies} from "ramda";

export const SERVICE_PARTNER_DATA_PROPTYPE = PropTypes.shape({
    importedByAbacus: PropTypes.bool.isRequired,
    name: PropTypes.string,
    companyName: PropTypes.string,
    nameAffix: PropTypes.string,
    uid: PropTypes.string,
    type: PropTypes.shape({id: PropTypes.string.isRequired}),
    addressStreet: PropTypes.string,
    addressZip: PropTypes.string,
    addressCity: PropTypes.string,

    primaryContactFirstName: PropTypes.string,
    primaryContactLastName: PropTypes.string,
    primaryContactEmail: PropTypes.string,
    primaryContactPhone: PropTypes.string,
    primaryContactMobile: PropTypes.string,

    bankAccountHolder: PropTypes.string,
    bankIban: PropTypes.string,
    bankBic: PropTypes.string,
    bankName: PropTypes.string,

    partnerId: PropTypes.string,
    pmsProviderId: PropTypes.string,
    pmsIntegrationActive: PropTypes.bool,
    pmsNo: PropTypes.string,
    sendToCrm: PropTypes.bool
});

export const VALIDATION_DATA_PROPTYPE = PropTypes.shape({
    name: v.VALIDATION_RESULT_PROPTYPE,
    companyName: v.VALIDATION_RESULT_PROPTYPE,
    nameAffix: v.VALIDATION_RESULT_PROPTYPE,
    uid: v.VALIDATION_RESULT_PROPTYPE,
    type: v.VALIDATION_RESULT_PROPTYPE,
    addressStreet: v.VALIDATION_RESULT_PROPTYPE,
    addressZip: v.VALIDATION_RESULT_PROPTYPE,
    addressCity: v.VALIDATION_RESULT_PROPTYPE,

    primaryContactFirstName: v.VALIDATION_RESULT_PROPTYPE,
    primaryContactLastName: v.VALIDATION_RESULT_PROPTYPE,
    primaryContactEmail: v.VALIDATION_RESULT_PROPTYPE,
    primaryContactPhone: v.VALIDATION_RESULT_PROPTYPE,
    primaryContactMobile: v.VALIDATION_RESULT_PROPTYPE,

    bankAccountHolder: v.VALIDATION_RESULT_PROPTYPE,
    bankIban: v.VALIDATION_RESULT_PROPTYPE,
    bankBic: v.VALIDATION_RESULT_PROPTYPE,
    bankName: v.VALIDATION_RESULT_PROPTYPE,

    partnerId: v.VALIDATION_RESULT_PROPTYPE,
    pmsProviderId: v.VALIDATION_RESULT_PROPTYPE,
    pmsIntegrationActive: v.VALIDATION_RESULT_PROPTYPE,
    pmsNo: v.VALIDATION_RESULT_PROPTYPE,
    sendToCrm: PropTypes.bool
});

export const BASE_DATA_PROPTYPE = PropTypes.shape({
    partnerGroups: PropTypes.array.isRequired,
    partnerTypes: PropTypes.array.isRequired,
    pmsProviders: PropTypes.array.isRequired
});

export function servicePartnerToFormData(servicePartner) {
    return pipe(
        omit([
            'partnerGroup',
            'type',
            'address',
            'primaryContact',
            'bankAccount',
            'pmsIntegrations'
        ]),
        assoc('partnerGroupId', path(['partnerGroup', 'id'], servicePartner)),
        assoc('typeId', path(['type', 'value'], servicePartner)),

        assoc('addressStreet', path(['address', 'street'], servicePartner)),
        assoc('addressZip', path(['address', 'zip'], servicePartner)),
        assoc('addressCity', path(['address', 'city'], servicePartner)),

        assoc('primaryContactEmail', path(['primaryContact', 'email'], servicePartner)),
        assoc('primaryContactPhone', path(['primaryContact', 'telephone'], servicePartner)),
        assoc('primaryContactMobile', path(['primaryContact', 'mobile'], servicePartner)),

        assoc('bankAccountHolder', path(['bankAccount', 'accountHolder'], servicePartner)),
        assoc('bankIban', path(['bankAccount', 'iban'], servicePartner)),
        assoc('bankBic', path(['bankAccount', 'bic'], servicePartner)),
        assoc('bankName', path(['bankAccount', 'bankName'], servicePartner)),
        assoc('pmsId', path(['pmsIntegrations', 0, 'id'], servicePartner)),
        assoc('pmsIntegrationActive', path(['pmsIntegrations', 0, 'active'], servicePartner)),
        assoc('pmsProviderId', path(['pmsIntegrations', 0, 'bookingProvider', 'value'], servicePartner)),
        assoc('pmsNo', path(['pmsIntegrations', 0, 'objectId'], servicePartner)),

    )(servicePartner);
}

export function formDataToServicePartnerRequestData(spFormData) {
    return pipe(
        pick([
            'name',
            'companyName',
            'nameAffix',
            'uid',
            'partnerId',
            'sendToCrm'
        ]),
        ifElse(
            propSatisfies(f.isNotNilOrEmpty, 'partnerGroupId'),
            assocPath(['partnerGroup', 'id'], spFormData.partnerGroupId),
            identity
        ),
        assocPath(['type', 'id'], spFormData.typeId),
        assoc('address', {
            street: spFormData.addressStreet,
            zip: spFormData.addressZip,
            city: spFormData.addressCity,
        }),
        assoc('primaryContact', {
            firstName: spFormData.primaryContactFirstName,
            lastName: spFormData.primaryContactLastName,
            email: spFormData.primaryContactEmail,
            telephone: spFormData.primaryContactPhone,
            mobile: spFormData.primaryContactMobile
        }),
        assoc('bankAccount', {
            accountHolder: spFormData.bankAccountHolder,
            iban: spFormData.bankIban,
            bic: spFormData.bankBic,
            bankName: spFormData.bankName,
        }),
        filter(f.isNotNilOrEmpty)
    )(spFormData);
}

export function formDataToPmsIntegrationRequestData(formData) {
    return {
        id: formData.pmsId,
        ...(formData.importedByAbacus ? {} : {providerId: formData.pmsProviderId}),
        objectId: formData.pmsNo,
        active: formData.pmsIntegrationActive
    };
}

export function emptyServicePartnerFormData() {
    return {
        name: '',
        companyName: '',
        nameAffix: '',
        uid: '',
        type: null,
        addressStreet: '',
        addressZip: '',
        addressCity: '',

        primaryContactFirstName: '',
        primaryContactLastName: '',
        primaryContactEmail: '',
        primaryContactPhone: '',
        primaryContactMobile: '',

        bankAccountHolder: '',
        bankIban: '',
        bankBic: '',
        bankName: '',

        partnerId: '',
        pmsProviderId: null,
        pmsIntegrationActive: false,
        pmsNo: '',
        sendToCrm: false
    }
}

export function validateServicePartnerFormData(spData, baseData) {
    const validPartnerTypeIds = map(prop('value'), baseData.partnerTypes);
    const validPartnerGroupIds = map(prop('value'), baseData.partnerGroups);
    const validProviderIds = map(prop('value'), baseData.pmsProviders);

    const validationRules = (spData.importedByAbacus ? pick('pmsNo') : identity)({
        name: v.requiredStandardTextField,
        companyName: v.optionalStandardTextField,
        nameAffix: v.optionalStandardTextField,
        typeId: v.withMessage("Kein gültiger Partnertyp", f.includedIn(validPartnerTypeIds)),
        uid: v.optionalStandardTextField,
        addressStreet: v.optional(v.lengthRange(1, 120)),
        addressZip: v.optional(v.lengthRange(1, 10)),
        addressCity: v.optional(v.lengthRange(1, 120)),

        partnerGroupId: (value, dataObject) => {
            return f.isNotNilOrEmpty(dataObject.partnerId) ?
                v.withMessage("Partner ID und Partnergruppe dürfen nicht beide gesetzt sein", f.isNilOrEmpty)(value, dataObject) :
                v.optional(v.withMessage("Keine gültige Partnergruppe", f.includedIn(validPartnerGroupIds)))(value, dataObject);
        },
        primaryContactFirstName: v.optional(v.lengthRange(1, 120)),
        primaryContactLastName: v.optional(v.lengthRange(1, 120)),
        primaryContactEmail: v.optional(v.isEmail),
        primaryContactPhone: v.optional(v.isPhoneNumber),
        primaryContactMobile: v.optional(v.isPhoneNumber),

        bankAccountHolder: v.optionalStandardTextField,
        bankIban: v.required(v.isIban),
        bankBic: v.optional(v.isBic),
        bankName: v.optional(v.lengthRange(1, 120)),

        partnerId: (value, dataObject) => {
            return f.isNotNilOrEmpty(dataObject.partnerGroupId) ?
                v.withMessage("Partner ID und Partnergruppe dürfen nicht beide gesetzt sein", f.isNilOrEmpty)(value, dataObject) :
                true;
        },
        pmsProviderId: v.withMessage("Kein gültiges PMS", f.includedIn(validProviderIds)),
        pmsNo: v.optional(v.lengthRange(1, 120))
    });

    return v.createValidator(validationRules)(spData);
}

export default {
    SERVICE_PARTNER_DATA_PROPTYPE,
    VALIDATION_DATA_PROPTYPE,
    BASE_DATA_PROPTYPE,
    servicePartnerToFormData,
    formDataToServicePartnerRequestData,
    formDataToPmsIntegrationRequestData,
    validateServicePartnerFormData
}
