/* eslint-disable comma-spacing,no-plusplus */
/*
 * SelfServicePage
 *
 * List all the features
 */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Moment from 'moment';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {getCustomPlaceHolderLanguages} from "utils/selectTexts";
import Select from 'react-select';
import {Actions} from "./redux";
import $ from 'jquery';
import {SobrDatePickerSelfService} from "components/SobrDatePickerSelfService/SobrDatePicker";


const salutationOptions = {
  "de": [
      {
          value: 'Frau',
          label: 'Frau'
      },
      {
          value: 'Herr',
          label: 'Herr'
      }
  ],
  "fr":
        [
            {
                value: 'Madame',
                label: 'Madame'
            },
            {
                value: 'Monsieur',
                label: 'Monsieur'
            }
        ],
    "en": [
        {
            value: 'Miss',
            label: 'Miss'
        },
        {
            value: 'Mister',
            label: 'Mister'
        }
    ]
}

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        maxHeight: '200px'
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: '200px'
    })
};

export class SelfServiceStep2Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        language: PropTypes.string.isRequired,
        nationalityData: PropTypes.any.isRequired,
        setCustomerData: PropTypes.func,
        customerData: PropTypes.any,
        languageData: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            birthday: null,
            nationality: '',
            firstName: '',
            lastName: '',
            language: '',
            salutation: '',
        };

        this.disabled = true;


        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
        this.onChangeNationality = this.onChangeNationality.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.onChangeSalutation = this.onChangeSalutation.bind(this);
        this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
    }

    componentDidMount() {
        if(this.props.registrationForm) {
            let primaryGuest = this.props.registrationForm.guests.find(guest => guest.primary === true);
            let customerData = this.props.customerData;
            let natList = this.props.nationalityData;
            let langList = this.props.languageData;

            let lang = customerData ? customerData.languageCode ? customerData.languageCode : null : primaryGuest.guest.languageCode ? primaryGuest.guest.languageCode : null;
            let bDay = customerData && customerData.birthday ? customerData.birthday : primaryGuest.guest.birthday ? primaryGuest.guest.birthday : null;
            let nat = customerData && customerData.nationality ? customerData.nationality : primaryGuest.guest.nationality ? primaryGuest.guest.nationality : '';

            this.setState({
                firstName: customerData && customerData.firstName ? customerData.firstName : primaryGuest.guest.firstName,
                lastName: customerData && customerData.lastName ? customerData.lastName : primaryGuest.guest.lastName,
                birthday: bDay ? Moment.isMoment(bDay) ? bDay : Moment.utc(bDay) : null,
                nationality: nat.length > 0 && natList.find((nation) => nation.value === nat.toUpperCase()) ? natList.find((nation) => nation.value === nat.toUpperCase()).value : natList.find((nation) => nation.label === nat.toUpperCase()) ? natList.find((nation) => nation.label === nat.toUpperCase()).value : '',
                language: lang ? lang.length > 0 && langList.find((language) => language.value === lang) ? langList.find((language) => language.value === lang).value : langList.find((language) => language.label === lang) ? langList.find((language) => language.label === lang).value : '' : '',
                salutation: customerData && customerData.salutation && (customerData.salutation === 'Herr' || customerData.salutation === 'Frau') ? customerData.salutation : (primaryGuest.guest.salutation === 'Frau' || primaryGuest.guest.salutation === 'Herr') ? primaryGuest.guest.salutation : '',
            });
        }
    }

    handleDropdownMenu(open) {
        const $contentBoxText = $('.content-box .text');
        let scrollTop = $contentBoxText.scrollTop();
        open ? $contentBoxText.addClass('menu-open').css('margin-top', '-' + scrollTop + 'px') : $contentBoxText.removeClass('menu-open').css('margin-top', '0');
    }

    onChangeNationality(e) {
        this.setState({
            nationality: e.value });
    }

    onChangeLanguage(e) {
        this.setState({
            language: e.value
        })
    }

    onChangeSalutation(e){
        this.setState({
            salutation: e.value
        })
    }

    handleBirthdayChange(e) {
        this.setState({ birthday: e });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit() {
        if(this.disabled)
            return;

        let customerBefore = this.props.customerData ? this.props.customerData : this.props.registrationForm.guests.find(guest => guest.primary === true);

        const customer = customerBefore.guest ? Object.assign({}, customerBefore.guest) : customerBefore;
        customer.guestId = customerBefore.id;
        customer.primary = customerBefore.primary;
        customer.checkinDate = customerBefore.checkinDate;
        customer.checkoutDate = customerBefore.checkoutDate;
        customer.birthday = this.state.birthday.format("YYYY-MM-DD");
        customer.nationality = this.state.nationality;
        customer.firstName = this.state.firstName;
        customer.lastName = this.state.lastName;
        customer.languageCode = this.state.language;
        customer.salutation = this.state.salutation;

        this.props.setCustomerData(customer);

        this.props.history.push(`/self-service/${this.props.registrationForm.id}/step3`);
    }
    handleButtonClick = () => {
        window.scrollTo(0, 0);
        this.handleSubmit();
      };

    render() {
        const id = this.props.registrationForm.id;
        this.disabled = !(this.state.firstName && this.state.lastName && this.state.birthday && this.state.nationality && this.state.language && this.state.salutation);

        const salutationOptionsLanguageAdjusted = localStorage.getItem('language') == null ? salutationOptions['en'] : salutationOptions[localStorage.getItem('language')]

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep2Page.title', defaultMessage: 'Pre-Check-In'}} class="step2">

                <SelfServiceStepLayout id={id}/>

                <div className="text">
                    <div className="wrapper self-service">

                        <label htmlFor="step2SalutationSelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.salutation'
                                defaultMessage='Anrede'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <Select className='b-select-input-service'
                                classNamePrefix="react-select_service"
                                id="salutation"
                                placeholder={getCustomPlaceHolderLanguages(this.props.language)}
                                ref={(ref) => {
                                    this.selectSalutation = ref;
                                }}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                simpleValue
                                clearable={false}
                                onChange={this.onChangeSalutation}
                                name="selected-state"
                                searchable={true}
                                options={salutationOptionsLanguageAdjusted}
                                valueKey="code"
                                labelKey="name"
                                value={this.state.salutation  ? salutationOptionsLanguageAdjusted.find((salutation) => salutation.value === this.state.salutation) : ''}
                                styles={customStyles}
                        />

                        <label htmlFor="step2FirstNameInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.firstName'
                                defaultMessage='Vorname'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            onChange={this.handleChange}
                            type="text"
                            className="b-input-self-service"
                            id="step2FirstNameInput"
                            name="firstName"
                            value={this.state.firstName}
                        />

                        <label htmlFor="step2LastNameInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.lastName'
                                defaultMessage='Nachname'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            onChange={this.handleChange}
                            type="text"
                            className="b-input-self-service"
                            id="step2LastNameInput"
                            name="lastName"
                            value={this.state.lastName}
                        />
                        <label htmlFor="step2BirthDateSelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.birthdate'
                                defaultMessage='Geburtsdatum'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <div className="input-group inputGroupCustomRegForm">
                            <SobrDatePickerSelfService
                                moment={this.state.birthday}
                                onSetMoment={this.handleBirthdayChange}
                                showMonthPicker={true}
                                disabled={false}
                                language={this.props.language}
                            />
                        </div>
                        <label htmlFor="step2CountrySelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.country'
                                defaultMessage='Nationalität'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <Select className='b-select-input-service'
                                classNamePrefix="react-select_service"
                                id="nationality"
                                placeholder={getCustomPlaceHolderLanguages(this.props.language)}
                                ref={(ref) => {
                                    this.selectNat = ref;
                                }}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                simpleValue
                                clearable={false}
                                onChange={this.onChangeNationality}
                                name="selected-state"
                                searchable={true}
                                options={this.props.nationalityData}
                                valueKey="code"
                                labelKey="name"
                                onMenuOpen={() => this.handleDropdownMenu(true)}
                                onMenuClose={() => this.handleDropdownMenu(false)}
                                value={this.state.nationality  ? this.props.nationalityData.find((nationality) => nationality.value === this.state.nationality) : ''}
                                styles={customStyles}
                        />

                        <label htmlFor="step2LanguageSelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.language'
                                defaultMessage='Sprache'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <Select className='b-select-input-service'
                                classNamePrefix="react-select_service"
                                id="language"
                                placeholder={getCustomPlaceHolderLanguages(this.props.language)}
                                ref={(ref) => {
                                    this.selectLang = ref;
                                }}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                simpleValue
                                clearable={false}
                                onChange={this.onChangeLanguage}
                                name="selected-state"
                                searchable={true}
                                options={this.props.languageData}
                                valueKey="code"
                                labelKey="name"
                                onMenuOpen={() => this.handleDropdownMenu(true)}
                                onMenuClose={() => this.handleDropdownMenu(false)}
                                value={this.state.language  ? this.props.languageData.find((language) => language.value === this.state.language) : ''}
                                styles={customStyles}
                        />

                    </div>
                </div>

                <div className="bottom">
                    <div onClick={this.handleButtonClick}>
                        <button className="b-button-pre-checkin" disabled={this.disabled} id="self-service-step2-confirm-next-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </div>
                </div>

            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep2 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        language: state.get('language')['currentLanguage'],
        nationalityData: state.getIn(['selfService', 'nationalityData']),
        customerData: state.getIn(['selfService', 'data', 'customerData']),
        languageData: state.getIn(['selfService', 'languageData']),
    }),
    (dispatch) => ({
        setCustomerData: customerData => dispatch(Actions.setCustomerData(customerData))
    })
)(SelfServiceStep2Component);

