import React, {ReactElement, useEffect, useState} from 'react';
import 'react-table/react-table.css';
import Moment from 'moment';
import {history} from 'utils/history';
import {
    RegistrationFormListActions,
    RegistrationFormListSelectors
} from "./registrationFormListSlice";
import {useDispatch, useSelector} from "react-redux";
import {Actions as SearchFilterActions} from "../RegistrationFormSearchFilter/RegistrationFormSearchFilterSlice";
import {SobrDataTable} from "components/SobrDataTable/SobrDataTable";
import SobrPagination from "components/SobrPagination/SobrPagination";
import {SobrInfoButton} from "components/SobrInfoButton/SobrInfoButton";
import {AppDispatch, RootState} from "App";
import {Column} from "react-table";
import {RegistrationForm} from "../RegistrationFormModel";
import {UserTypeEnum} from "../../../../services/users/UserService";
import {SessionSelectors} from "../../Session/SessionSlice";
import {FormattedMessage} from "react-intl";

export function RegistrationFormList(): ReactElement {

    const userType = useSelector(SessionSelectors.userType);
    const isPolice = userType === UserTypeEnum.POLICE;
    const pages = useSelector(RegistrationFormListSelectors.pages);
    const loading = useSelector(RegistrationFormListSelectors.loading);
    const forms = useSelector(RegistrationFormListSelectors.registrationForms);
    const currentPage = useSelector((state: RootState) => state.getIn(['zterminal', 'registrationForms', 'searchFilter', 'currentPage']));
    const highlightedRegistrationFormId = useSelector(RegistrationFormListSelectors.highlightedRegistrationFormId);

    const dispatch: AppDispatch = useDispatch();

    const setSorting = (field: string, direction: 'asc' | 'desc') => {
        dispatch(SearchFilterActions.setSorting(field, direction));
        dispatch(RegistrationFormListActions.fetchListIfNecessary());
    };
    const goToPage = (pageNum: number) => {
        dispatch(SearchFilterActions.setCurrentPage(pageNum));
        dispatch(RegistrationFormListActions.fetchListIfNecessary());
    }

    const [isInitialMount, setIsInitialMount] = useState(true);
    useEffect(() => {
        if (isInitialMount) {
            setIsInitialMount(false);
            dispatch(RegistrationFormListActions.fetchListIfNecessary());
        }
    }, [isInitialMount, setIsInitialMount, dispatch]);


        const statusInfoPopover = (
            <SobrInfoButton>
                    <div className={'status-info'}>
                        <span><FormattedMessage
                            id="bonfire.containers.administration.RegistrationForm.header.status.initial"
                            defaultMessage='bonfire.containers.administration.RegistrationForm.header.status.initial'
                        /></span><br/>
                        <span><FormattedMessage
                            id="bonfire.containers.administration.RegistrationForm.header.status.checked_in"
                            defaultMessage='bonfire.containers.administration.RegistrationForm.header.status.checked_in'/></span><br/>
                        <span><FormattedMessage
                            id="bonfire.containers.administration.RegistrationForm.header.status.checked_out"
                            defaultMessage='bonfire.containers.administration.RegistrationForm.header.status.checked_out'/></span>
                    </div>
            </SobrInfoButton>
        );

    return <div className="flex-growing d-flex flex-column">
        <div className="flex-growing scrollable px-4 pt-3">
            <SobrDataTable
                additionalClassNames="h-100 mh-100 w-100"
                columns={TABLE_COLUMNS}
                data={forms}
                loading={loading}
                onSelectRow={row => {
                    if (isPolice) {
                        history.push(`/zterminal/registrationform/read/${row.id}`);
                    } else {
                        history.push(`/zterminal/registrationform/edit/${row.id}`);
                    }
                }}
                onChangeSorting={setSorting}
                infoPopper={{column: 'status', infoPopper: statusInfoPopover}}
                highlight={row => highlightedRegistrationFormId != null && row.id === highlightedRegistrationFormId}
            />
        </div>
        <div className="flex-fixed px-4 py-2">
            <SobrPagination
                numPages={pages}
                currentPage={currentPage}
                onSelectPage={goToPage}
                className="pagination flex-wrap"
            />
        </div>
    </div>

}

const TABLE_COLUMNS: Column[] = [
    {
        Header: 'bonfire.registrationform.list.tableheaders.name',
        accessor: 'name'
    },
    {
        id: 'countGuests', // Required because our accessor is not a string
        Header: 'bonfire.registrationform.list.tableheaders.numberOfGuests',
        accessor: (d: RegistrationForm) => d.guests.length,
        sortable: false
    },
    {
        id: 'checkinDate', // Required because our accessor is not a string
        Header: 'bonfire.registrationform.list.tableheaders.checkinDate',
        accessor: (d: RegistrationForm) => Moment.utc(d.checkinDate).format('DD.MM.YYYY')
    },
    {
        id: 'checkoutDate', // Required because our accessor is not a string
        Header: 'bonfire.registrationform.list.tableheaders.checkoutDate',
        accessor: (d: RegistrationForm) => Moment.utc(d.checkoutDate).format('DD.MM.YYYY')
    },
    {
        Header: 'bonfire.registrationform.list.tableheaders.bookingSource',
        accessor: 'bookingSource'
    },
    {
        Header: 'bonfire.registrationform.list.tableheaders.status',
        accessor: 'status'
    },
    {
        id: 'tax',
        Header: 'bonfire.registrationform.list.tableheaders.tax',
        accessor: (d: RegistrationForm) => d.totalTax + ' CHF',
        sortable: false
    }
];
