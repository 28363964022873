import React from 'react';
import PropTypes from 'prop-types';
import f from "lib/util";
import {faSearch} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import { injectIntl } from "react-intl";

class SobrSearchHTML extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: ''
        };

        this.onChange = this.onChange.bind(this);
        this.onClick = this.onClick.bind(this);
    }

    static propTypes = {
        placeholder: PropTypes.string,
        onChange: PropTypes.func,
        value: PropTypes.string,
        disabled: PropTypes.bool,
        onFocus: PropTypes.func,
        inputRef: PropTypes.any,
        onClick: PropTypes.func,
        class: PropTypes.string,
        onKeyUp: PropTypes.func,
        size: PropTypes.number
    };

    static defaultProps = {
        placeholder: '',
        onChange: () => {return;},
        disabled: false,
        input: null,
        onFocus: () => {return;},
        inputRef: React.createRef(),
        onClick: () => {return;},
        class: 'form-control sobr-search-text-field',
        onKeyUp: () => {return;},
        size: 20
    };

    onChange(e) {
        this.setState({
            value: e.target.value
        }, this.props.onChange(e.target.value));
    }

    onClick() {
        f.callIfExists(this.props.onClick, this.state.value);
    }

    render() {
        const {formatMessage} = this.props.intl;
        return (
            <div className="input-group sobr-search-field">
                <input type="text"
                       className={this.props.class}
                       placeholder={this.props.placeholder ? formatMessage({ id: this.props.placeholder  }) : ""}
                       onChange={this.onChange}
                       value={this.props.value}
                       disabled={this.props.disabled}
                       ref={this.props.inputRef}
                       onFocus={this.props.onFocus}
                       onKeyUp={this.props.onKeyUp}
                />
                <span className="search" onClick={this.onClick}>
                    <FontAwesomeIcon icon={faSearch} size="lg" />
                </span>
            </div>
        );
    }
}

export default injectIntl(SobrSearchHTML);

