import ApiService from "../api/ApiService";
import {ApiEndpoint} from "../api/ApiEndpoints";
import * as R from "ramda";

const getServicePartnersRequest = ApiService.createApiEndpoint('admin/servicepartners');
export function getAllServicePartners() {
    return getServicePartnersRequest({
        sort: 'name',
        order: 'asc'
    }).then(R.prop('data'));
}

export function listServicePartners(max, offset, sort, order, q=undefined, showInactive = false) {
    return getServicePartnersRequest({
        max,
        offset,
        sort,
        order,
        q,
        inactive: showInactive
    });
}

export function getServicePartner(id) {
    const getServicePartnerRequest = new ApiEndpoint('admin/servicepartners/:id', 'GET', true);
    return getServicePartnerRequest.callWithPathVariables({id});
}

const reportChangeRequest = ApiService.createApiEndpoint('servicepartners/reportchange', 'POST');
export function reportChange(servicePartnerId, text) {
    return reportChangeRequest({servicePartnerId, text});
}

const generateTaxStatementsRequest = ApiService.createApiEndpoint('admin/servicepartners/:spId/generatetaxstatements');
export function generateTaxStatements(servicePartnerId) {
    return generateTaxStatementsRequest({}, {spId: servicePartnerId});
}

export const createServicePartner = ApiService.createApiEndpoint('admin/servicepartners', 'POST', true);

const createPmsIntegrationEndpoint = new ApiEndpoint('admin/servicepartners/:spId/pms', 'POST', true);
export function createPmsIntegration(spId, pmsData) {
    return createPmsIntegrationEndpoint.call(null, pmsData, {spId});
}

const updateServicePartnerDataEndpoint = new ApiEndpoint('admin/servicepartners/:spId', 'POST', true);
export function updateServicePartner(spId, spData) {
    return updateServicePartnerDataEndpoint.call(null, spData, {spId});
}

const updatePmsIntegrationEndpoint = new ApiEndpoint('admin/servicepartners/:spId/pms/:pmsId', 'PUT', true);
export function updatePmsIntegration(spId, pmsId, pmsData) {
    return updatePmsIntegrationEndpoint.call(null, pmsData, {spId, pmsId});
}

export default {
    getAllServicePartners,
    listServicePartners,
    getServicePartner,
    reportChange,
    generateTaxStatements,
    createServicePartner,
    createPmsIntegration,
    updateServicePartner,
    updatePmsIntegration
}
