import React, {ReactElement, useEffect} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {breadcrumbActions, BreadcrumbPath, breadcrumbSelectors} from "./breadcrumbSlice";
import {equals} from 'ramda';

export function WithBreadcrumb(props: WithBreadcrumbProps): ReactElement {
    const newIcon = props.icon;
    const newPath = props.path;
    const currentIcon = useSelector(breadcrumbSelectors.icon);
    const currentPath = useSelector(breadcrumbSelectors.path);
    const dispatch = useDispatch();

    useEffect(() => {
        if (newIcon !== currentIcon) {
            dispatch(breadcrumbActions.setIcon(newIcon));
        }
    }, [newIcon, currentIcon, dispatch]);

    useEffect(() => {
        if (!equals(newPath, currentPath)) {
            dispatch(breadcrumbActions.setPath(newPath));
        }
    }, [newPath, currentPath, dispatch]);

    return <></>;
}

export interface WithBreadcrumbProps {
    icon: string
    path: BreadcrumbPath
}
