
import './IconCheckbox.scss';
import { ReactComponent as CheckboxIconUnselected } from "../../images/icons/icon_checkbox.svg";
import { ReactComponent as CheckboxIconSelected } from "../../images/icons/icon_checkbox_checked.svg";

export interface IconCheckboxProps {
  label: string;
  key?: string;
  checked: boolean;
  name: string;
  value: string;
  disabled: boolean;
  onClick?: () => void;
  onChange: (checked: boolean) => void;
}

export const IconCheckbox = (props: IconCheckboxProps) => {
  const checkboxId = props.key || props.label;

  return (
    <div className='checkbox-container'>
    <div className={`sobr-checkbox ${props.checked ? 'checked ' : ''}${props.disabled ? 'disabled ' : ''}`}
         onClick={() => props.onChange(!props.checked)}>
      {props.checked && !props.disabled ? (
        <CheckboxIconSelected className="sobr-checkbox-icon" />
      ) : (
        <CheckboxIconUnselected className="sobr-checkbox-icon" />
      )}
      <label className="sobr-checkbox-label" htmlFor={checkboxId}>{props.label}</label>
    </div>
    </div>
  );
};