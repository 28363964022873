import React from 'react';
import moment from "moment";
import {formatNumber} from "../../../helpers";

interface props {
    title: string
    isPercent: boolean,
    isCompare?: boolean,
    graphData: any,
    graphDataCompare?: any,
    hasPrediction: boolean
    tooltip: {
        top: number,
        left: number,
        opacity: number,
        dataIndex: number,
        value: any,
        compareValue: any
    }
}


export const GraphTooltip = ({title, isPercent, graphData, graphDataCompare, tooltip, hasPrediction}: props) => {
    const date = (moment(graphData?.labels?.[tooltip.dataIndex]).isValid() && graphData?.labels?.[tooltip.dataIndex] !== undefined) ? moment(graphData?.labels?.[tooltip.dataIndex]).format("DD.MM.yyyy") : "---";
    const today = new Date();

    let value = 0;
    if(hasPrediction) {
        if (moment(graphData?.labels?.[tooltip.dataIndex]).isValid()) {
            if (moment(graphData?.labels?.[tooltip.dataIndex]).isAfter(today)) {
                value = graphData?.datasets?.[2]?.data[tooltip.dataIndex];
            } else {
                value = graphData?.datasets?.[0]?.data[tooltip.dataIndex];
            }
        }
    }
    else {
        value = graphData?.datasets?.[0]?.data[tooltip.dataIndex];
    }


    let compareDate = "---";
    let compareValue = "---";
    //const value = graphData?.datasets?.[0]?.data[tooltip.dataIndex];
    let change = '---';

    if (graphDataCompare && graphDataCompare?.labels) {
        compareDate = (moment(graphDataCompare?.labels?.[tooltip.dataIndex]).isValid() && graphDataCompare?.labels?.[tooltip.dataIndex] !== undefined) ? moment(graphDataCompare?.labels?.[tooltip.dataIndex]).format("DD.MM.yyyy") : "---";
        compareValue = graphDataCompare?.datasets?.[0]?.data[tooltip.dataIndex];
        if (tooltip.compareValue && compareValue) {
            //@ts-ignore
            if (value === 0 || value === "0") {
                change = '---';
            } else {

                let percentChange = 0;
                if(isPercent) {
                    //@ts-ignore
                    percentChange = value - compareValue;
                }
                else {
                    //@ts-ignore
                    percentChange = ((compareValue - parseInt(value)) / parseInt(value) *100)
                }

                // @ts-ignore
                change = (value < compareValue ? (percentChange * -1) : percentChange).toFixed(1);
            }
        }
    }


    // @ts-ignore
    const isPositive = compareValue < value;

    return (
        <React.Fragment>
            <div className='tooltip'
                 style={{top: tooltip.top, left: tooltip.left, opacity: tooltip.opacity, pointerEvents: 'none'}}>
                <div className="tooltip-container">
                    <div className="date-container">
                        <div className="date1">
                            {date}
                        </div>
                        <div className="date2">
                            {compareDate}
                        </div>
                    </div>
                    <div className="workload-container">
                        <div className="label">
                            {title}
                        </div>
                        <div className="value-container">
                            <div className="value">
                                {formatNumber(value ?? 0)} {isPercent ? " %" : ""}
                            </div>
                            {graphDataCompare &&
                                <div className="percentage-container">
                                    <div className="label1">(</div>
                                    {isPositive ? <div className="triangle-positive"/> :
                                        <div className="triangle-negative"/>}
                                    <div className="label2">{formatNumber(change)} %)</div>
                                </div>
                            }
                        </div>
                    </div>
                    {graphDataCompare &&
                        <div className="workload-container">
                            <div className="label">
                                Vergleichsperiode
                            </div>

                            <div className="value-container">
                                <div className="value">
                                    {formatNumber(compareValue)} {isPercent ? " %" : ""}
                                </div>
                            </div>
                        </div>
                    }

                </div>
            </div>
        </React.Fragment>
    );
};
