import React, {ChangeEventHandler, ReactElement} from "react";
import {omit} from "ramda";

export interface SobrTextareaProps extends React.HTMLProps<HTMLTextAreaElement> {
    value: string
    onValueChange?: (value: string) => void
}

export function SobrTextarea(props: SobrTextareaProps): ReactElement {
    const onChange: ChangeEventHandler<HTMLTextAreaElement> = event => {
        // support both onChange and onValueChange hooks because legacy stuff
        if (props.onChange) {
            props.onChange(event);
        }
        if (props.onValueChange) {
            props.onValueChange(event.target.value);
        }
    }
    const attributes = omit(['value', 'onValueChange', 'onChange'], props);
    return <textarea
        className={"form-control"}
        value={props.value}
        onChange={onChange}
        {...attributes}
    />
}
