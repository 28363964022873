import React, {ReactElement, ReactNode} from 'react';
import {BonfireIcon} from "components/zterminal/BonfireIcon/BonfireIcon";
import {useSelector} from "react-redux";
import './breadcrumb.scss';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faAngleRight} from "@fortawesome/free-solid-svg-icons"
import {
    BreadcrumbPathElement,
    breadcrumbSelectors,
    isTranslatableBreadcrumbPathElement
} from "bonfire/ZTerminal/breadcrumb/breadcrumbSlice";

export function Breadcrumb(): ReactElement | null {
    const icon = useSelector(breadcrumbSelectors.icon);
    const path = useSelector(breadcrumbSelectors.path);

    if (!icon && !path) {
        return null;
    } else {
        return (
            <div id="zterminal-breadcrumb" className="row">
                {icon && <span id="zterminal-breadcrumb-icon">
                    <BonfireIcon icon={icon}/>
                </span>}
                {path && <span id="zterminal-breadcrumb-path" className="d-inline-block ml-3">
                    {path.length > 0 ? path.map(renderPathElement) : Object.keys(path).map(renderPathElement)}
                </span>}
            </div>
        );
    }

}

function renderPathElement(pathElt: BreadcrumbPathElement, idx: number): ReactNode {
    function getPathElementText(pathElement: BreadcrumbPathElement): ReactNode {
        if (!pathElement) {
            return '';
        } else if (isTranslatableBreadcrumbPathElement(pathElement)) {
            return <FormattedMessage {...pathElement} />
        } else {
            return pathElement;
        }
    }

    const text = getPathElementText(pathElt);
    const key = isTranslatableBreadcrumbPathElement(pathElt) ? pathElt?.id : pathElt;
    const label = (isTranslatableBreadcrumbPathElement(pathElt) && pathElt?.url) ?
        (<Link to={pathElt?.url}>{text}</Link>) :
        (<span>{text}</span>);

    return (
        <span key={key} className="zterminal-breadcrumb-path-elt">
                {idx >= 1 && <span className="zterminal-breadcrumb-path-elt-icon">
                    <FontAwesomeIcon icon={faAngleRight} size={"xs"} />
                </span>}
            {label}
            </span>
    );
}
