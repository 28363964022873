/* eslint-disable comma-spacing,no-plusplus,no-unused-vars,react/no-did-mount-set-state */
/*
 * Login
 *
 * List all the features
 */
import React from 'react';
import {Helmet} from 'react-helmet';
import PropTypes from 'prop-types';
import {createStructuredSelector} from 'reselect';
import {connect} from 'react-redux';
import {compose} from 'redux';
import {FormattedMessage} from 'react-intl';

import injectReducer from '../../utils/injectReducer'
import messages from './messages';
import {title,} from './style';
import {makeSelectError, makeSelectLoading} from '../App/selectors';
import reducer from './reducer';
import {passwordActions} from './actions';
import {makeSelectLoggedId} from './selectors';
import {Link, withRouter} from "react-router-dom";
import zermattLogoBig from 'images/logos/zermattLogoBig.png';

export class ForgotPasswordPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
        };

        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    handleChange(event) {
        if (event.target.name === 'username') {
            this.setState({ username: event.target.value });
        }
    }

    handleSubmit() {
        this.props.resetPassword({ username: this.state.username });
    }


    render() {
        const { loading, error } = this.props;
        const dataListProps = {
            loading,
            error,
        };

        return (
            <div className="forgot-password">
                <Helmet>
                    <title>Passwort zurücksetzen</title>
                    <meta name="description" content="Feature page of React.js Boilerplate application" />
                </Helmet>
                <div className="wrapper-main">
                    <header className="header-none"></header>
                    <div className="wrapper-content">
                        <div className="content-box special">
                            <div className="login forgot-pw">
                                <div className="head">
                                    <img className="logo" src={zermattLogoBig} alt="logo"/>
                                </div>
                                <div className="text">
                                    <div className="wrapper">

                                        <div className="sab-big">
                                            <FormattedMessage {...messages.title}></FormattedMessage>
                                        </div>

                                        <div className="pw-reset-text sar-big">
                                            <FormattedMessage {...messages.passwordResetText}></FormattedMessage>
                                        </div>

                                        <div className="field-wrap email-wrap">
                                            <label>
                                                <FormattedMessage {...messages.email}></FormattedMessage>
                                            </label>
                                            <input
                                                type="text"
                                                className="form-control"
                                                name="username"
                                                onBlur={this.handleChange}
                                                autoComplete="on"
                                            ></input>
                                        </div>

                                    </div>
                                </div>
                                <div className="bottom">
                                    <div onClick={this.handleSubmit}>
                                        <button className="btn slb main">
                                            <FormattedMessage {...messages.loginButton}></FormattedMessage>
                                        </button>
                                    </div>
                                    <Link to="/login">
                                        <button className="btn slb ">
                                            <FormattedMessage {...messages.backButton}></FormattedMessage>
                                        </button>
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

ForgotPasswordPage.propTypes = {
    loading: PropTypes.bool,
    error: PropTypes.oneOfType([
        PropTypes.object,
        PropTypes.bool,
    ]),
    resetPassword: PropTypes.func,
    checkUser: PropTypes.func,
    loggedIn: PropTypes.any,
};

export function mapDispatchToProps(dispatch) {
    return {
        resetPassword: (data) => {
            dispatch(passwordActions.forgotPassword(data));
        },
    };
}

const mapStateToProps = createStructuredSelector({
    loading: makeSelectLoading(),
    error: makeSelectError(),
    loggedIn: makeSelectLoggedId(),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);

const withReducer = injectReducer({ key: 'login', reducer });

export default compose(
    withReducer,
    withConnect,
)(ForgotPasswordPage);