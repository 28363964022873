import {Action, createSlice, PayloadAction} from "@reduxjs/toolkit";
import {AppDispatch, RootState} from "../../../App";
import moment from "moment";
import AccomodationCategoriesService from "services/categories/AccomodationCategoriesService";
import { AccomodationCategoryType } from "./components/DropdownAccomodation/AccomodationCategories";
import { ThunkAction } from "@reduxjs/toolkit";


type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export interface DropdownValues {
    selectedKeys: string[];
    selectedRadio: string;
  }

export interface SliceState {
    startDateTimeFrame: Date,
    endDateTimeFrame: Date,
    startDateCompareFrame: Date,
    endDateCompareFrame: Date,
    formattedStartDateTimeFrame: string,
    formattedEndDateTimeFrame: string,
    formattedStartDateCompareFrame: string,
    formattedEndDateCompareFrame: string
    trigger: number,
    timeFramePreset: number,

    dropdownValues: DropdownValues,
    compareDropdownValues: DropdownValues,
    accomodationCategoryTypes: AccomodationCategoryType[]
}

const initialState: SliceState = {
    startDateTimeFrame: new Date(new Date().getFullYear(), 0, 1),
    endDateTimeFrame: new Date(new Date().getFullYear(), 11, 31),
    startDateCompareFrame: new Date(new Date().getFullYear() - 1, 0, 1),
    endDateCompareFrame: new Date(new Date().getFullYear() -1, 11, 31),
    formattedStartDateTimeFrame: moment(new Date(new Date().getFullYear(), 0, 1)).format("DD.MM.YY"),
    formattedEndDateTimeFrame: moment(new Date(new Date().getFullYear(), 11, 31)).format("DD.MM.YY"),
    formattedStartDateCompareFrame: moment(new Date(new Date().getFullYear() - 1, 0, 1)).format("DD.MM.YY"),
    formattedEndDateCompareFrame: moment(new Date(new Date().getFullYear() -1, 11, 31)).format("DD.MM.YY"),
    trigger: 0,
    timeFramePreset: 0,

    accomodationCategoryTypes: [],

    dropdownValues: {
        selectedKeys: [],
        selectedRadio: "All",
    },
    compareDropdownValues: {
        selectedKeys: [],
        selectedRadio: "All",
    },

};

export const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        setStartDateTimeFrame: (state, action: PayloadAction<Date>) => {
            state.startDateTimeFrame = action.payload;
            state.formattedStartDateTimeFrame = moment(action.payload).isValid() ? moment(action.payload).format("DD.MM.YY") : "";
            state.timeFramePreset = 0;
        },
     
        setEndDateTimeFrame: (state, action: PayloadAction<Date>) => {
            state.endDateTimeFrame = action.payload;
            state.formattedEndDateTimeFrame = moment(action.payload).isValid() ? moment(action.payload).format("DD.MM.YY") : "";
        },
        setStartDateCompareFrame: (state, action: PayloadAction<Date>) => {
            state.startDateCompareFrame = action.payload;
            state.formattedStartDateCompareFrame = moment(action.payload).isValid() ? moment(action.payload).format("DD.MM.YY") : "";
        },
        setEndDateCompareFrame: (state, action: PayloadAction<Date>) => {
            state.endDateCompareFrame = action.payload;
            state.formattedEndDateCompareFrame = moment(action.payload).isValid() ? moment(action.payload).format("DD.MM.YY") : "";
        },
        triggerFetch: (state) => {
            state.trigger = state.trigger + 1;
        },
        setTimeFramePreset: (state, action: PayloadAction<number>) => {
            const currentDate = new Date();
            switch(action.payload) {
                case 1:
                    const startDate1 = new Date(currentDate.getFullYear(), 0, 1);
                    state.startDateTimeFrame = startDate1;
                    state.endDateTimeFrame = currentDate;
                    state.formattedStartDateTimeFrame = moment(startDate1).format("DD.MM.YY");
                    state.formattedEndDateTimeFrame = moment(currentDate).format("DD.MM.YY");
                    state.timeFramePreset = 1;
                    break;
                case 2:
                    const startDate2 = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                    state.startDateTimeFrame = startDate2;
                    state.endDateTimeFrame = currentDate;
                    state.formattedStartDateTimeFrame = moment(startDate2).format("DD.MM.YY");
                    state.formattedEndDateTimeFrame = moment(currentDate).format("DD.MM.YY");
                    state.timeFramePreset = 2;
                    break;
                case 3:
                    const startDate3 = new Date(currentDate.getFullYear() - 1, 0, 1);
                    const endDate3 = new Date(currentDate.getFullYear() - 1, 11, 31);
                    state.startDateTimeFrame = startDate3;
                    state.endDateTimeFrame = endDate3;
                    state.formattedStartDateTimeFrame = moment(startDate3).format("DD.MM.YY");
                    state.formattedEndDateTimeFrame = moment(endDate3).format("DD.MM.YY");
                    state.timeFramePreset = 3;
                    break;
                case 4:
                    const startDate4 = new Date(currentDate.getFullYear() - 3, 0, 1);
                    const endDate4 = new Date(currentDate.getFullYear() +1, 11, 31);
                    state.startDateTimeFrame = startDate4;
                    state.endDateTimeFrame = endDate4;
                    state.formattedStartDateTimeFrame = moment(startDate4).format("DD.MM.YY");
                    state.formattedEndDateTimeFrame = moment(endDate4).format("DD.MM.YY");
                    state.timeFramePreset = 4;
                    break;
            }
        },
        setAccomodationCategoryTypes: (state, action) => {
            state.accomodationCategoryTypes = action.payload.data;
                },
                
        setSelectedDropdownValues: (state, action: PayloadAction<DropdownValues>) => {
              // Update regular dropdown values
          state.dropdownValues.selectedKeys = action.payload.selectedKeys;
          state.dropdownValues.selectedRadio = action.payload.selectedRadio;
           },
          
        setSelectedCompareDropdownValues: (state, action: PayloadAction<DropdownValues>) => {
            // Update regular dropdown values
        state.compareDropdownValues.selectedKeys = action.payload.selectedKeys;
        state.compareDropdownValues.selectedRadio = action.payload.selectedRadio;
         }
        }   
});

function getAnalyticsState(state: RootState): SliceState {
    return state.getIn(['zterminal', 'analytics']) as SliceState;
}

export function getAccomodationCategories() {
    return (dispatch: AppDispatch) => {
        return AccomodationCategoriesService.getAccomodationCategories()
            // @ts-ignore
            .then((accomodationCategoryType) => {
                dispatch(analyticsSlice.actions.setAccomodationCategoryTypes(accomodationCategoryType));
                return accomodationCategoryType;
                
            })
            .catch(error => {
                if (error.status === 403) {
                    dispatch(analyticsSlice.actions.setAccomodationCategoryTypes([]));
                    return;
                    
                } else {
                  
                    console.log("404")
                    return Promise.reject(error);
                }
            });
    }
}


export const setSelectedDropdownValues = (values: DropdownValues): AppThunk => (dispatch: AppDispatch) => {
    dispatch(analyticsSlice.actions.setSelectedDropdownValues(values)); 
};

export const setSelectedDropdownCompareValues = (values: DropdownValues): AppThunk => (dispatch: AppDispatch) => {
    dispatch(analyticsSlice.actions.setSelectedCompareDropdownValues(values)); 
};

export const getPartnerTypeAndCategoryId = (selectedKeys: any) => {
    const partnerType = selectedKeys?.filter((pt: any) => ['apartement', 'mountain-cabin'].includes(pt));
    const categoryId = selectedKeys?.filter((pt: any) => !partnerType.includes(pt));
    return { partnerType, categoryId }
}


export const AnalyticsSelectors = {
    startDateTimeFrame: (state: RootState) => getAnalyticsState(state).startDateTimeFrame,
    endDateTimeFrame: (state: RootState) => getAnalyticsState(state).endDateTimeFrame,
    startDateCompareFrame: (state: RootState) => getAnalyticsState(state).startDateCompareFrame,
    endDateCompareFrame: (state: RootState) => getAnalyticsState(state).endDateCompareFrame,
    formattedStartDateTimeFrame: (state: RootState) => getAnalyticsState(state).formattedStartDateTimeFrame,
    formattedEndDateTimeFrame: (state: RootState) => getAnalyticsState(state).formattedEndDateTimeFrame,
    formattedStartDateCompareFrame: (state: RootState) => getAnalyticsState(state).formattedStartDateCompareFrame,
    formattedEndDateCompareFrame: (state: RootState) => getAnalyticsState(state).formattedEndDateCompareFrame,
    trigger: (state: RootState) => getAnalyticsState(state).trigger,
    timeFramePreset: (state: RootState) => getAnalyticsState(state).timeFramePreset,

    accomodationCategoryTypes: (state: RootState) => getAnalyticsState(state).accomodationCategoryTypes,

    dropdownValues: (state: RootState) => getAnalyticsState(state).dropdownValues,
    compareDropdownValues: (state: RootState) => getAnalyticsState(state).compareDropdownValues,

};

export const AnalyticsActions = {
    setStartDateTimeFrame: analyticsSlice.actions.setStartDateTimeFrame,
    setEndDateTimeFrame: analyticsSlice.actions.setEndDateTimeFrame,
    setStartDateCompareFrame: analyticsSlice.actions.setStartDateCompareFrame,
    setEndDateCompareFrame: analyticsSlice.actions.setEndDateCompareFrame,
    setTrigger: analyticsSlice.actions.triggerFetch,
    setTimeFramePreset: analyticsSlice.actions.setTimeFramePreset,
    
    setAccomodationCategoryTypes: analyticsSlice.actions.setAccomodationCategoryTypes,
}