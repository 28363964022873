import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Actions} from "./redux";
import {fromJS} from "immutable";

export class SelfServiceStep9Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        interestsData: PropTypes.any.isRequired,
        customerData: PropTypes.any,
        setCustomerData: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            interests: [],
            interestsList: []
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.checkedChange = this.checkedChange.bind(this);
    }

    componentDidMount() {
        this.updateInterestsList(this.props.interestsData);
    }

    componentDidUpdate(prevProps) {
        if (this.props.interestsData !== prevProps.interestsData) {
            this.updateInterestsList(this.props.interestsData);
        }
    }

    updateInterestsList(interestsData) {
        if(this.props.registrationForm) {
            let primaryGuest = this.props.registrationForm.guests.find(guest => guest.primary === true);
            let customerData = this.props.customerData;
            let interests = customerData ? customerData.interests : primaryGuest.guest.interests;

        interestsData.sort((a, b) => {
            return a.sort - b.sort;
        });

            this.setState({
                interests: interests,
            interestsList: interestsData
        });
    }
}

    checkedChange(event) {
        let interests = fromJS(this.state.interests);
        let val = event.target.value;

        if(event.target.checked) {
            interests = interests.push({'value': val, 'label': this.props.interestsData.find((i) => i.value === val).label})
        } else {
            interests = interests.delete(this.state.interests.findIndex(int => int.value === val));
        }

        this.setState({
           interests: interests.toJS()
        });

    }

    handleSubmit() {
        let customerBefore = this.props.customerData ? this.props.customerData : this.props.registrationForm.guests.find(guest => guest.primary === true);

        /*let interestsObject = this.state.interests.reduce(
            (obj, item) => Object.assign(obj, {[item.value]: item.label}) ,{});*/

        const customer = Object.assign({}, this.props.customerData ? customerBefore : customerBefore.guest);
        customer.guestId = customerBefore.id;
        customer.primary = customerBefore.primary;
        customer.checkinDate = customerBefore.checkinDate;
        customer.checkoutDate = customerBefore.checkoutDate;
        customer.interests = this.state.interests;

        this.props.setCustomerData(customer);

        this.props.history.push(`/self-service/${this.props.registrationForm.id}/step10`);
    }

    render() {
        const id = this.props.registrationForm.id;

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep9Page.title', defaultMessage: 'Pre-Check-In'}} class="step8-1">

                <SelfServiceStepLayout id={id} title={{id: 'bonfire.containers.SelfServiceStep8_1Page.stepTitle', defaultMessage: 'Newsletter-Service'}}/>


                <div className="text">
                    <div className="wrapper self-service">


                        <div className="heading-sc sab-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep8_1Page.heading'
                                defaultMessage='Was sind Ihre Interessen?'
                            />
                        </div>

                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                {this.state.interestsList &&
                                this.state.interestsList.map(interest =>
                                    (
                                        <tr key={interest.value}>
                                            <td className="label">
                                                <span>{interest.label}</span>
                                            </td>
                                            <td className="value">

                                                <div className="checkbox">
                                                    <label className="checkbox-inline checkbox-bootstrap checkbox-lg">
                                                        <input type="checkbox"
                                                               value={interest.value}
                                                               defaultChecked={this.state.interests ? this.state.interests.some((i) => i.value === interest.value) : false}
                                                               onChange={this.checkedChange}
                                                        />
                                                        <span className="checkbox-placeholder" />
                                                    </label>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{height: '20px'}} />
                <div className="bottom">
                    <div onClick={this.handleSubmit}>
                        <button className="b-button-pre-checkin">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </div>
                </div>
            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep9 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        interestsData: state.getIn(['selfService', 'interestsData']),
        customerData: state.getIn(['selfService', 'data', 'customerData']),
        currentLanguage: state.get('language')['currentLanguage'],
    }),
    (dispatch) => ({
        setCustomerData: customerData => dispatch(Actions.setCustomerData(customerData))
    })
)(SelfServiceStep9Component);

