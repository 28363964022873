import React, { useState } from 'react';
import './SobrRadioButton.scss';
import { ReactComponent as RadioUnselected } from "../../images/icons/icon_radio.svg";
import { ReactComponent as RadioSelected } from "../../images/icons/icon_radio_checked.svg";

export interface RadioButtonProps {
  label: string;
  key?: string;
  selected: boolean;
  name: string;
  value: string;
  onItemClick?: () => void;
  onClick?: () => void;
}

export const SobrRadioButton = (props: RadioButtonProps) => {
  const radioButtonId = props.key || props.label;

  return (
    <div className="sobr-radio-button" onClick={props.onClick}>
      {props.selected
        ? <RadioSelected className="sobr-radio-icon" />
        : <RadioUnselected className="sobr-radio-icon" />}
      <label className='sobr-radio-label' htmlFor={radioButtonId}>{props.label}</label>
    </div>
  );
};


export interface RadioGroupProps {
  name: string;
  selectedValue: string;
  onChange: (value: string) => void;
  children: React.ReactNode;
}

export const RadioGroup = (props: RadioGroupProps) => {
  const [selectedValue, setSelectedValue] = useState(props.selectedValue);

  const handleRadioChange = (value: string) => {
    setSelectedValue(value);
    props.onChange(value);
  };

  return (
    <div className="radio-group">
      {React.Children.map(props.children, (child) => {
        const selected = (child as React.ReactElement).props.value === selectedValue;
        return React.cloneElement(child as React.ReactElement, {
          name: props.name,
          selected,
          onClick: () => handleRadioChange((child as React.ReactElement).props.value),
        });
      })}
    </div>
  );
};
