import React, {ChangeEventHandler} from 'react';
import {omit} from "ramda";
import {toInputValue} from "lib/forms";

export interface SobrTextInputProps extends React.HTMLProps<HTMLInputElement> {
    value: string
    onValueChange?: (val: string) => void
}

export function SobrTextInput(props: SobrTextInputProps) {
    const onChangeHandler: ChangeEventHandler<HTMLInputElement> = event => {
        // support both onChange and onValueChange hooks because legacy stuff
        if (props.onChange) {
            props.onChange(event);
        }
        if (props.onValueChange) {
            props.onValueChange(event.target.value);
        }
    }
    const attributes = omit(['value', 'onValueChange', 'onChange'], props);
    return <input
        className="form-control"
        type="text"
        value={toInputValue(props.value)}
        onChange={onChangeHandler}
        autoComplete="off"
        {...attributes}
    />
}

export default SobrTextInput;
