import React, {ReactElement} from 'react';
import {useSelector} from "react-redux";
import TouristtaxListPage from "bonfire/ZTerminal/Touristtax/TouristtaxList/TouristtaxListPage";
import {isServicePartnerUser, isZermattTourismusUser} from "services/users/UserService";
import {TouristTaxDetailPage} from "./TouristtaxDetail/TouristTaxDetailPage";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";

function TouristtaxPage(): ReactElement {
    const roles = useSelector(SessionSelectors.roles);
    const currentServicePartner = useSelector(SessionSelectors.currentServicePartner);
    if (isServicePartnerUser(roles)) {
        if (currentServicePartner) {
            return <TouristTaxDetailPage servicePartnerId={currentServicePartner.id} />;
        }
    }

    if (isZermattTourismusUser(roles)) {
        return <TouristtaxListPage/>
    }

    throw new Error("Touristtax Page Case not implemented yet");
}

export default TouristtaxPage;
