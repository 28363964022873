import React, {ReactElement} from 'react';
import Select from 'react-select';

export interface SobrSelectOption<T> {
    value: T
    label: string
}

export type SobrSelectOnChangeHandler<T> = (value: T) => void

export interface SobrSelectProps<V, O> {
    readOnly?: boolean
    isClearable?: boolean
    options: Array<SobrSelectOption<O>>
    value: V
    onChange: SobrSelectOnChangeHandler<V>
}

export function SobrSingleSelect(props: SobrSelectProps<string | null, string>): ReactElement {
    const currentValue = props.options.find(o => o.value === props.value);
    return <Select
        options={props.options}
        value={currentValue}
        onChange={val => props.onChange(val ? val.value : null)}
        isClearable={props.isClearable ?? false}
        isDisabled={props.readOnly ?? false}
        closeMenuOnSelect={true}
        isSearchable={true}
        className="react-select-container"
        classNamePrefix="react-select"
    />
}

export function SobrMultiSelect(props: SobrSelectProps<string[], string>) {
    const values = props.options.filter(o => props.value.includes(o.value));
    return <Select
        isMulti
        options={props.options}
        value={values}
        onChange={val => props.onChange(val.map(v => v.value))}
        isClearable={props.isClearable ?? false}
        isDisabled={props.readOnly ?? false}
        closeMenuOnSelect={false}
        isSearchable={true}
        className="react-select-container"
        classNamePrefix="react-select"
    />
}
