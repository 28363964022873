import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import 'moment/locale/de';
import {injectIntl} from "react-intl";
import SobrSearchField from "components/SobrSearchField/SobrSearchField";
import {connect} from "react-redux";
import {Actions} from "./RegistrationFormSearchFilterSlice";
import {RegistrationFormListActions} from '../RegistrationFormList/registrationFormListSlice';
import {Actions as TaxOverviewActions} from '../TaxOverview/redux';
import {SobrDatePicker} from "components/SobrDatePicker/SobrDatePicker";
import {ExcelUploadButton} from "./ExcelUpload.js";

function callIfExists(func, arg) {
    if (func && typeof func === 'function') {
        func(arg);
    }
}

const DATE_TODAY = Moment.utc().startOf('day');

class RegistrationFormSearchFilterComponent extends React.Component {

    static propTypes = {
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        searchTerm: PropTypes.string,
        disabled: PropTypes.bool,
        onSetStartDate: PropTypes.func,
        onSetEndDate: PropTypes.func,
        onSetSearchTerm: PropTypes.func,
        onSearch: PropTypes.func,
        setCurrentPage: PropTypes.func
    };

    constructor(props) {
        super(props);

        this.state = {
            importSuccess: false,
            generalImportError: false,
            hasErrors: false,
            errors: [],
        };

        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.setSearchTerm = this.setSearchTerm.bind(this);
        this.setDateRangeToday = this.setDateRangeToday.bind(this);
        this.setImportErrors = this.setImportErrors.bind(this);
        this.clearImportErrors = this.clearImportErrors.bind(this);
        this.setGeneralImportError = this.setGeneralImportError.bind(this);
    }

    setGeneralImportError() {
        this.setState({
            generalImportError: true,
            importSuccess: false
        });
    }

    clearImportErrors() {
        this.setState({
            generalImportError: false,
            hasErrors: false,
            errors: [],
            importSuccess: true
        });
    }

    setImportErrors(errors) {
        this.setState({
            generalImportError: false,
            hasErrors: true,
            errors: errors,
            importSuccess: false
        });
    };

    setDateRangeToday() {
        callIfExists(this.props.onSetStartDate, DATE_TODAY);
        callIfExists(this.props.onSetEndDate, DATE_TODAY);
        callIfExists(this.props.setCurrentPage, 0);
        callIfExists(this.props.onSearch);
    }

    setStartDate(startDate) {
        callIfExists(this.props.setCurrentPage, 0);
        callIfExists(this.props.onSetStartDate, startDate);
        callIfExists(this.props.onSearch);
    }

    setEndDate(endDate) {
        callIfExists(this.props.setCurrentPage, 0);
        callIfExists(this.props.onSetEndDate, endDate.clone().endOf('day'));
        callIfExists(this.props.onSearch);
    }

    setSearchTerm(searchTerm) {
        callIfExists(this.props.setCurrentPage, 0);
        callIfExists(this.props.onSetSearchTerm, searchTerm);
        callIfExists(this.props.onSearch);
    }

    get disabled() {
        return this.props.disabled;
    }

    render() {
        const {formatMessage} = this.props.intl;
        return <div id="registration-form-search-filter" className="search-bar">
            <div className="row">
                <div className="col-md-5 col-lg-3 search-bar-col">
                    <SobrDatePicker
                        moment={this.props.startDate}
                        showMonthPicker={true}
                        onSetMoment={this.setStartDate}
                        placeholder={formatMessage({ id: 'bonfire.containers.placeholder.start'})}
                        disabled={this.disabled}
                        todayEnabled={true}
                    />
                </div>
                <div className="col-md-5 col-lg-3 search-bar-col">
                    <SobrDatePicker
                        moment={this.props.endDate}
                        showMonthPicker={true}
                        onSetMoment={this.setEndDate}
                        placeholder={formatMessage({ id: 'bonfire.containers.placeholder.end'})}
                        disabled={this.disabled}
                        todayEnabled={true}
                    />
                </div>
                <div className="col-md-2 col-lg-3 search-bar-col">
                    <div className="d-flex flex-row justify-between align-items-center">
                    <ExcelUploadButton
                        setErrors={this.setImportErrors}
                        clearErrors={this.clearImportErrors}
                        setGeneralError={this.setGeneralImportError}
                    /></div>

                </div>
                <div className="col-md-12 col-lg-3 search-bar-col">
                    <SobrSearchField
                        value={this.props.searchTerm}
                        onChangeTerm={this.setSearchTerm}
                        disabled={this.disabled}
                    />
                </div>
            </div>
            {this.state.importSuccess &&
                <div className="row">
                    <div className="col-lg-12 pt-5">
                        <div className="alert alert-success" role="alert">
                            <h4>Der Excel Import wurde erfolgreich verarbeitet und die Meldescheine erstellt.</h4>
                        </div>
                    </div>
                </div>
            }
            {this.state.generalImportError &&
                <div className="row">
                    <div className="col-lg-12 pt-5">
                        <div className="alert alert-danger" role="alert">
                            <h4>Server Fehler, bitte kontaktieren Sie einen Administrator</h4>
                        </div>
                    </div>
                </div>
            }
            {this.state.hasErrors &&
                <div className="row">
                    <div className="col-lg-12 pt-5">
                        <div className="alert alert-danger" role="alert">
                            <h4>Import Fehler:</h4>
                            {this.state.errors.map((error) => {
                                return (
                                    <p>
                                        Excel Zeile {error.primaryRowNum}: {error.error}
                                    </p>
                                );
                            })}
                            <p>Es wurden keine Meldescheine erstellt.</p>
                        </div>
                    </div>
                </div>
            }
        </div>
    }

}

export default connect(
    state => {
        const filter = state.getIn(['zterminal', 'registrationForms', 'searchFilter']);
        const disabled = state.getIn(['zterminal', 'registrationForms', 'registrationFormList', 'loading']);
        return {
            startDate: filter.getIn(['dateRange', 'start']),
            endDate: filter.getIn(['dateRange', 'end']),
            searchTerm: filter.get('term'),
            disabled: disabled
        };
    },
    dispatch => ({
        onSetStartDate: startDate => {
            dispatch(Actions.setDateRangeStart(startDate));
            dispatch(RegistrationFormListActions.invalidate());
            dispatch(TaxOverviewActions.invalidateOverview());
        },
        onSetEndDate: endDate => {
            dispatch(Actions.setDateRangeEnd(endDate));
            dispatch(RegistrationFormListActions.invalidate());
            dispatch(TaxOverviewActions.invalidateOverview());
        },
        onSetSearchTerm: searchTerm => {
            dispatch(Actions.setSearchTerm(searchTerm));
            dispatch(RegistrationFormListActions.invalidate());
            dispatch(TaxOverviewActions.invalidateOverview());
        },
        setCurrentPage: currentPage => {
            dispatch(Actions.setCurrentPage(currentPage));
            dispatch(RegistrationFormListActions.invalidate());
            dispatch(TaxOverviewActions.invalidateOverview());
        }
    })
)(injectIntl(RegistrationFormSearchFilterComponent));