import React from 'react';
import PropTypes from 'prop-types';
import f from "lib/util";

export default class SobrOnClickOutside extends React.Component {

    static propTypes = {
        onClickOutside: PropTypes.func,
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
        this.setContentRef = element => {
            this.content = element;
        };
        this.handleClickOutsideEvents = this.handleClickOutsideEvents.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutsideEvents);
        document.addEventListener('keydown', this.handleClickOutsideEvents);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutsideEvents);
        document.removeEventListener('keydown', this.handleClickOutsideEvents);
    }

    handleClickOutsideEvents(event) {
        const isEscapeKey = event.keyCode === 27;
        const isClickOutsideDayPicker = !this.content.contains(event.target);
        if (isEscapeKey || isClickOutsideDayPicker) {
            f.callIfExists(this.props.onClickOutside);
        }
    }

    render() {
        return <div ref={this.setContentRef}>
            {this.props.children}
        </div>
    }

}
