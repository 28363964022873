import React, {HTMLProps, ReactElement} from 'react';

import './TouristTaxDetailHeader.scss';
import {useSelector} from "react-redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {SobrErrorMessage} from "components/SobrErrorMessage/SobrErrorMessage";
import {
    TouristTaxDetailsHeaderSelectors,
    TouristTaxSummary
} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDetailHeaderSlice";
import {useIntl} from "react-intl";

export interface StatementDetailHeaderProps {
}

export function TouristTaxDetailHeader(props: StatementDetailHeaderProps): ReactElement {
    const intl = useIntl();
    const loading = useSelector(TouristTaxDetailsHeaderSelectors.getLoading);
    const data = useSelector(TouristTaxDetailsHeaderSelectors.getData);
    const error = useSelector(TouristTaxDetailsHeaderSelectors.getError);

    const totalTax = getTotalTax(data);
    return <div id="statement-detail-header">
        {error && <SobrErrorMessage title="Konnte Kurtaxen-Details nicht laden" message={error}/>}
        {!error && (
            <LoadingOverlay loading={loading}>
                <DetailHeaderPill className="mr-3" label={intl.formatMessage({id: 'bonfire.touristTax.header.totalKurtaxe'})} value={totalTax}/>
                <DetailHeaderPill className="mr-3" label={intl.formatMessage({id: 'bonfire.touristTax.header.totalOvernightStays'})} value={data.totalNightCount}/>
                <DetailHeaderPill className="mr-3" label={intl.formatMessage({id: 'bonfire.touristTax.header.adults'})} value={data.adultNightCount}/>
                <DetailHeaderPill className="mr-3" label={intl.formatMessage({id: 'bonfire.touristTax.header.children'})} value={data.childrenNightCount}/>
                <DetailHeaderPill className="mr-3" label={intl.formatMessage({id: 'bonfire.touristTax.header.infants'})} value={data.smallChildrenNightCount}/>
                <DetailHeaderPill className="mr-3" label={intl.formatMessage({id: 'bonfire.touristTax.header.exempt'})} value={data.taxFreeNightCount}/>
            </LoadingOverlay>
        )}
    </div>
}

const getTotalTax = (data: TouristTaxSummary) => `${(data.totalTax || 0.0).toFixed(2)} CHF`;

interface DetailHeaderPillProps extends HTMLProps<HTMLDivElement> {
    label: string
    value: string | number | undefined
    className: string
}

function DetailHeaderPill(props: DetailHeaderPillProps): ReactElement {
    const {label, value, className, ...rest} = props;
    return <div className={`statement-detail-header-pill ${className}`} {...rest}>
        <div className="statement-detail-header-pill-label">
            {label}
        </div>
        <div className="statement-detail-header-pill-value">
            {value || "—"}
        </div>
    </div>
}
