import {Map} from 'immutable';
import RegistrationFormService from "services/registrationforms/RegistrationFormService";

export const InitialState = Map({
    registrationForm: null,
    nationalityData: null,
    interestsData: null,
    languageData: null,
    data: Map({customerData: null, comment: '', fellowTraveler: null, newsletter: null, signature: null})
});

const ACTION_SET_REGISTRATION_FORM = 'selfService/setRegistrationForm';
const ACTION_SET_NATIONALITY_DATA = 'selfService/setNationalityData';
const ACTION_SET_DATA_CUSTOMER = 'selfService/setCustomerData';
const ACTION_SET_DATA_COMMENT = 'selfService/setDataComment';
const ACTION_SET_FELLOWTRAVELER = 'selfService/setFellowTraveler';
const ACTION_SET_NEWSLETTER_DATA = 'selfService/setNewsletterData';
const ACTION_SET_INTERESTS_DATA = 'selfService/setInterestsData';
const ACTION_SET_SIGNATURE_DATA = 'selfService/setSignatureData';
const ACTION_SET_LANGUAGE_DATE = 'selfService/setLanguageData';
const ACTION_CLEAR_STATE = 'selfService/clearState';

function clearReduxStateSelfService() {
    return {
        type: ACTION_CLEAR_STATE
    }
}

function fetchRegistrationForm(id) {
    return dispatch => {
        return RegistrationFormService.getRegistrationForm(id)
            .then(registrationForm => {
                return dispatch(setRegistrationForm(registrationForm));
            });
    }
}

function fetchNationalityData() {
    return dispatch => {
        return RegistrationFormService.getNationalityData()
            .then(nationalityData => {
                return dispatch(setNationalityData(nationalityData.sort((a, b) => a.label.localeCompare(b.label))));
            });
    }
}

function fetchInterestsData() {
    return dispatch => {
        return RegistrationFormService.getInterestsData()
            .then(interestsData => {
                return dispatch(setInterestsData(interestsData));
            });
    }
}

function fetchLanguageData() {
    return dispatch => {
        return RegistrationFormService.getLanguageData()
            .then(languageData => {
                return dispatch(setLanguageData(languageData));
            });
    }
}

function updateGuest(id, data) {
    return () => {
        return RegistrationFormService.updateGuest(id, data);
    }
}

function setRegistrationForm(registrationForm) {
    return {
        type: ACTION_SET_REGISTRATION_FORM,
        registrationForm: registrationForm
    }
}

function setNationalityData(nationalityData) {
    return {
        type: ACTION_SET_NATIONALITY_DATA,
        nationalityData: nationalityData
    }
}

function setDataComment(comment) {
    return {
        type: ACTION_SET_DATA_COMMENT,
        comment: comment
    }
}

function setCustomerData(customerData) {
    return {
        type: ACTION_SET_DATA_CUSTOMER,
        customerData: customerData
    }
}

function setFellowTravelerData(fellowTraveler) {
    return {
        type: ACTION_SET_FELLOWTRAVELER,
        fellowTraveler: fellowTraveler
    }
}

function setNewsletterData(newsletter) {
    return {
        type: ACTION_SET_NEWSLETTER_DATA,
        newsletter: newsletter
    }
}

function setInterestsData(interestsData) {
    return {
        type: ACTION_SET_INTERESTS_DATA,
        interestsData: interestsData
    }
}

function setSignatureData(signature) {
    return {
        type: ACTION_SET_SIGNATURE_DATA,
        signature: signature
    }
}

function setLanguageData(languageData) {
    languageData = languageData.map((lang) => {
        return {
            value: lang.value.toLowerCase(),
            label: lang.label
        };
    });
    return {
        type: ACTION_SET_LANGUAGE_DATE,
        languageData: languageData
    }
}

export const Actions = {
    fetchRegistrationForm,
    fetchNationalityData,
    setCustomerData,
    setDataComment,
    setFellowTravelerData,
    setNewsletterData,
    fetchInterestsData,
    setSignatureData,
    updateGuest,
    fetchLanguageData,
    clearReduxStateSelfService
};


export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_REGISTRATION_FORM:
            return state.set('registrationForm', action.registrationForm);
        case ACTION_SET_NATIONALITY_DATA:
            return state.set('nationalityData', action.nationalityData);
        case ACTION_SET_DATA_CUSTOMER:
            return state.setIn(['data', 'customerData'], action.customerData);
        case ACTION_SET_DATA_COMMENT:
            return state.setIn(['data', 'comment'], action.comment);
        case ACTION_SET_FELLOWTRAVELER:
            return state.setIn(['data', 'fellowTraveler'], action.fellowTraveler);
        case ACTION_SET_NEWSLETTER_DATA:
            return state.setIn(['data', 'newsletter'], action.newsletter);
        case ACTION_SET_INTERESTS_DATA:
            return state.set('interestsData', action.interestsData);
        case ACTION_SET_SIGNATURE_DATA:
            return state.setIn(['data', 'signature'], action.signature);
        case ACTION_SET_LANGUAGE_DATE:
            return state.set('languageData', action.languageData);
        case ACTION_CLEAR_STATE:
            return (state) => {
                state.set('registrationForm', null)
                state.set('nationalityData', null)
                state.set('interestsData', null)
                state.set('languageData', null)
                state.set('data', Map({customerData: null, comment: '', fellowTraveler: null, newsletter: null, signature: null}))
            }
        default:
            return state;
    }
}


export default {
    Actions, InitialState, Reducer
}
