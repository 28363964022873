import React, {ReactElement, ReactNode} from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";

import './loading-overlay.scss';

export interface LoadingOverlayProps extends React.HTMLProps<HTMLDivElement> {
    loading: boolean
    children: ReactNode
}

export function LoadingOverlay(props: LoadingOverlayProps): ReactElement {
    const {loading, children, ...rest} = props;
    if (loading) {
        return <div className="loading-overlay" {...rest}>
            <div>
                <ScaleLoader color='#003463'/>
            </div>
        </div>
    } else {
        return <>{props.children}</>;
    }
}
