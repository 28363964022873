import React from 'react';
import {Route, Switch} from "react-router-dom";
import {ServicePartnerPage} from "bonfire/ZTerminal/ServicePartner/ServicePartnerPage";
import ServicePartnerCreatePage from "bonfire/ZTerminal/ServicePartner/ServicePartnerCreate/ServicePartnerCreatePage";
import ServicePartnerEditPage from "bonfire/ZTerminal/ServicePartner/ServicePartnerEdit/ServicePartnerEditPage";

export default function ServicePartnerSection() {
    return <Switch>
        <Route exact={true} path="/zterminal/servicepartner" component={ServicePartnerPage} />
        <Route exact={true} path="/zterminal/servicepartner/create" component={ServicePartnerCreatePage} />
        <Route exact={true} path="/zterminal/servicepartner/edit/:id" component={ServicePartnerEditPage} />
    </Switch>
}
