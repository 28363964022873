import download from 'downloadjs';
import RestService from "./RestService";

export function getBlob(url, params, headers = {}) {
    const finalUrl = RestService.createGetRequestUrl(url, params);
    return fetch(finalUrl, {
        method: 'GET',
        headers: headers
    }).then(handleBlobResponse);
}

export function downloadBlob(filename, mimetype, url, params, headers = {}) {
    return getBlob(url, params, headers)
        .then(blob => {
            download(blob, filename, mimetype);
        });
}

function handleBlobResponse(response) {
    if (response.ok) {
        return response.blob();
    } else {
        return Promise.reject(response);
    }
}

export default {
    getBlob, downloadBlob
}
