import React, {ReactElement} from 'react';

import {Provider} from 'react-redux';
import configureStore from "./configureStore";
import {HashRouter, Redirect, Route, Switch} from "react-router-dom";
import Bonfire from "bonfire/Bonfire";
import {TobiCodeLoader} from "./TobiCodeLoader";
import LanguageProvider from "bonfire/Language/LanguageProvider";

export const BonfireStore = configureStore({});
export type RootState = ReturnType<typeof BonfireStore.getState>;
export type AppGetState = typeof BonfireStore.getState;
export type AppDispatch = typeof BonfireStore.dispatch;

export default function App(): ReactElement {
    return <Provider store={BonfireStore}>
        {/* One day when there are no Translations in ZTerminal any more, LanguageProvider can be pushed to SelfService */}
        <LanguageProvider>
            <HashRouter>
                {/* TODO in a bright future where there is no TobiCode, we don't need a switch here -> just render <Bonfire />  */}
                <Switch>
                    {/* for zterminal and self-service (newly refactored components) we include the working code */}
                    <Route exact path="/" render={() => <Redirect to="/zterminal"/>}/>
                    <Route path="/zterminal" component={Bonfire}/>
                    <Route path="/self-service" component={Bonfire}/>

                    <Route path="/forgot_password" component={Bonfire}/>
                    <Route path="/challenge_password/:id" component={Bonfire}/>

                    <Route path="/welcome" component={Bonfire}/>

                    <Route path="/start" component={Bonfire}/>
                    <Route path="/welcome/challenge_password" component={Bonfire}/>
                    <Route path="/welcome/challenge_contact_informations" component={Bonfire}/>
                    {/* all the rest will load unholy tobi code */}
                    <Route component={TobiCodeLoader}/>



                </Switch>
            </HashRouter>
        </LanguageProvider>
    </Provider>
}
