import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";


const SobrLightLoadingIndicator = () => (
    <div className={'loading-wrapper'}>
        <ScaleLoader color={'#003463'}/>
    </div>
);

export default SobrLightLoadingIndicator;
