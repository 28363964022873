import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {Actions} from "./redux";
import {SelfServiceHome} from "./SelfServiceHome";
import {SelfServiceStep1} from "./SelfServiceStep1";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {compose} from "redux";
import {SelfServiceStep2} from "./SelfServiceStep2";
import {SelfServiceStep3} from "./SelfServiceStep3";
import {SelfServiceStep4} from "./SelfServiceStep4";
import {SelfServiceStep5} from "./SelfServiceStep5";
import {SelfServiceStep6} from "./SelfServiceStep6";
import {SelfServiceStep7} from "./SelfServiceStep7";
import {SelfServiceStep8} from "./SelfServiceStep8";
import {SelfServiceStep9} from "./SelfServiceStep9";
import {SelfServiceStep10} from "./SelfServiceStep10";
import {SelfServiceStep11} from "./SelfServiceStep11";
import {SelfServiceStep12} from "./SelfServiceStep12";
import {SelfServiceErrorComponent} from "./SelfServiceError";

export class SelfServiceComponent extends React.Component {

    static propTypes = {
        registrationFormId: PropTypes.string,
        registrationForm: PropTypes.object,
        fetchRegistrationForm: PropTypes.func,
        nationalityData: PropTypes.any,
        fetchNationalityData: PropTypes.func,
        fetchInterestsData: PropTypes.func,
        interestsData: PropTypes.any,
        fetchLanguageData: PropTypes.func,
        languageData: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            loaded: false,
            errors: false
        };
    }

    UNSAFE_componentWillUpdate({ location, history }) {
        const gtag = window.gtag;

        if (location.pathname === this.props.location.pathname) {
            return;
        }

        if (history.action === 'PUSH' && typeof(gtag) === 'function') {
            let host = window.location.hostname;

            if(host !== "localhost" && host.indexOf('test') === -1 ){
                gtag('config', 'UA-130973172-2', {
                    'page_location': window.location.href,
                    'page_path': location.pathname,
                })
            } else {
                gtag('config', 'UA-136719568-1', {
                    'page_location': window.location.href,
                    'page_path': location.pathname,
                })
            }

        }
    }

    componentDidMount() {
        let promises = [];

        if (!this.props.registrationForm) {
            promises.push(this.props.fetchRegistrationForm(this.props.registrationFormId));
        }

        if(!this.props.nationalityData) {
            promises.push(this.props.fetchNationalityData());
        }

        if(!this.props.interestsData) {
            promises.push(this.props.fetchInterestsData());
        }

        if(!this.props.languageData) {
            promises.push(this.props.fetchLanguageData());
        }

        Promise.all(promises).then(() => {
           this.setState({
               loaded: true
           });
        }).catch((error) => {
            console.log('Data loading Error', error);
            this.setState({
                errors: true
            }, () => {
                this.props.history.push('/self-service/error');
            });
        });
    }

    render() {
        if(this.state.loaded){
            return (
                <Switch>
                    <Route exact path="/self-service/:id/" component={SelfServiceHome}/>
                    <Route path="/self-service/:id/step1/" component={SelfServiceStep1}/>
                    <Route path="/self-service/:id/step2/" component={SelfServiceStep2}/>
                    <Route path="/self-service/:id/step3/" component={SelfServiceStep3}/>
                    <Route path="/self-service/:id/step4/" component={SelfServiceStep4}/>
                    <Route path="/self-service/:id/step5/" component={SelfServiceStep5}/>
                    <Route path="/self-service/:id/step6/" component={SelfServiceStep6}/>
                    <Route path="/self-service/:id/step7/:guestId/" component={SelfServiceStep7}/>
                    <Route path="/self-service/:id/step8/" component={SelfServiceStep8}/>
                    <Route path="/self-service/:id/step9/" component={SelfServiceStep9}/>
                    <Route path="/self-service/:id/step10/" component={SelfServiceStep10}/>
                    <Route path="/self-service/:id/step11/" component={SelfServiceStep11}/>
                    <Route path="/self-service/:id/step12/" component={SelfServiceStep12}/>
                </Switch>
            )
        } else if(this.state.errors) {
            return <Route path="/self-service/error" component={SelfServiceErrorComponent}/>
        } else {
            return <LoadingOverlay loading={true} />
        }
    }
}

export const SelfService = compose(
    withRouter,
    connect(
        state => ({
            registrationForm: state.getIn(['selfService', 'registrationForm']),
            nationalityData: state.getIn(['selfService', 'nationalityData']),
            interestData: state.getIn(['selfService', 'interestsData']),
            languageData: state.getIn(['selfService', 'languageData']),
        }),

        (dispatch) => ({
            fetchRegistrationForm: id => dispatch(Actions.fetchRegistrationForm(id)),
            fetchNationalityData: () => dispatch(Actions.fetchNationalityData()),
            fetchInterestsData: () => dispatch(Actions.fetchInterestsData()),
            fetchLanguageData: () => dispatch(Actions.fetchLanguageData()),
        })
    )
)(SelfServiceComponent);





