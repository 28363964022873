import React from 'react';
import {isServicePartnerUser, isZermattTourismusUser} from "services/users/UserService";
import {useSelector} from "react-redux";
import {ViewServicePartnerPage} from "./ViewServicePartner/ViewServicePartnerPage";
import ServicePartnerListPage from "bonfire/ZTerminal/ServicePartner/ServicePartnerList/ServicePartnerListPage";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";

export function ServicePartnerPage() {
    const roles = useSelector(SessionSelectors.roles);
    const currentServicePartner = useSelector(SessionSelectors.currentServicePartner);
    if (isServicePartnerUser(roles)) {
        if (!currentServicePartner) {
            throw new Error("service partner not set");
        }
        return <ViewServicePartnerPage servicePartner={currentServicePartner}/>;
    }

    if (isZermattTourismusUser(roles)) {
        return <ServicePartnerListPage />
    }

    throw new Error("ServicePartnerPage Case not implemented yet");
}
