import {ReactElement} from "react";
import {SobrDatePicker} from "components/SobrDatePicker/SobrDatePicker";
import {useDispatch, useSelector} from "react-redux";
import {TouristTaxSearchParamSelectors} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxSearchParamsSlice";
import {TouristTaxDetailActions} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailSlice";
import {useIntl} from "react-intl";

export function TouristTaxDateRange(): ReactElement {
    const intl = useIntl();
    const startDate = useSelector(TouristTaxSearchParamSelectors.startDate);
    const endDate = useSelector(TouristTaxSearchParamSelectors.endDate);
    const dispatch = useDispatch();
    return <div>
        <div className="d-inline-block w-50 pr-3">
            <SobrDatePicker
                moment={startDate}
                onSetMoment={(d) => dispatch(TouristTaxDetailActions.setStartDate(d))}
                showMonthPicker={true}
                placeholder={intl.formatMessage({id: 'bonfire.containers.placeholder.start'})}
                todayEnabled={true}
            />
        </div>
        <div className="d-inline-block w-50">
            <SobrDatePicker
                moment={endDate}
                onSetMoment={(d) => dispatch(TouristTaxDetailActions.setEndDate(d))}
                showMonthPicker={true}
                placeholder={intl.formatMessage({id: 'bonfire.containers.placeholder.end'})}
                todayEnabled={true}
            />
        </div>
    </div>
}
