import React from 'react';
import {Route, Switch} from "react-router-dom";
import {RegistrationFormListPage} from "./RegistrationFormList/RegistrationFormListPage";
import {TaxOverviewPage} from "./TaxOverview/TaxOverviewPage";
import {RegistrationFormLoader} from "./RegistrationForm/RegistrationFormLoaderComponent";

export default function RegistrationForms() {
    return <Switch>
        <Route exact={true} path="/zterminal/registrationform/" component={RegistrationFormListPage}/>
        <Route exact={true} path="/zterminal/registrationform/read/:id"
               render={({match}) => <RegistrationFormLoader read={true} registrationFormId={match.params.id}/>}/>
        <Route exact={true} path="/zterminal/registrationform/edit/:id"
               render={({match}) => <RegistrationFormLoader edit={true} registrationFormId={match.params.id}/>}/>
        <Route exact={true} path="/zterminal/registrationform/create"
               render={() => <RegistrationFormLoader create={true}/>}/>
        <Route exact={true} path="/zterminal/registrationform/tax-overview" component={TaxOverviewPage}/>
    </Switch>
}
