import React, {ReactElement} from 'react';
import {SobrFormGroup} from "components/SobrForm/SobrFormGroup";
import {FormattedMessage} from "react-intl";
import {SobrText} from "components/SobrForm/Forms/sobrText";
import {SobrZipCity} from "components/SobrForm/Forms/sobrZipCity";

import icon_placeholder from 'images/icons/icon_placeholder.svg';
import {ServicePartner} from "bonfire/ZTerminal/ServicePartner/ServicePartner";


export interface ViewServicePartnerComponentProps {
    servicePartner: ServicePartner
}

export function ViewServicePartnerComponent(props: ViewServicePartnerComponentProps): ReactElement {
    const servicePartner = props.servicePartner;

    // prevent errors in case those sub objects aren't definde
    const address = servicePartner.address || {};
    const primaryContact = servicePartner.primaryContact || {};
    const type = servicePartner.type || {};
    const bankAccount = servicePartner.bankAccount || {};
    const pmsIntegration = (servicePartner.pmsIntegrations && servicePartner.pmsIntegrations[0]) || {};
    const partnerGroup = servicePartner.partnerGroup || {};
    const partnerId = servicePartner.partnerGroup ? servicePartner.partnerGroup.partnerId : servicePartner.partnerId;

    return <div id="servicepartner-view-component">
        <div className="row">
            <div className="col-12 col-sm-6 col-lg-4">
                <div className="heading">Logo</div>
                {servicePartner.logoUrl ?
                    <img className="img-fluid"
                         alt="Service Partner Logo"
                         src={servicePartner.logoUrl}
                    /> :
                    <div className="logoStyle"
                         style={{
                             height: '300px',
                             width: '100%',
                             backgroundColor: '#F4F2F0'
                         }}
                    >
                        <img src={icon_placeholder}
                             className="iconPlacerHolderStyle align-middle"
                             alt="Service Partner Logo"
                        />
                    </div>
                }
            </div>

            {/* Leistungspartner */}
            <div className="col-12 col-sm-6 col-lg-4">
                <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineLP">
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.name"
                                                 defaultMessage="Name"/>}
                        value={servicePartner.name}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.companyName"
                                                 defaultMessage="Firmenname"/>}
                        value={servicePartner.companyName}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.nameAffix"
                                                 defaultMessage="Namenszusatz"/>}
                        value={servicePartner.nameAffix}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.housingID"
                                                 defaultMessage="Mwst Nr."/>}
                        value={servicePartner.uid}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.type"
                                                 defaultMessage="Typ"/>}
                        value={type.label}
                        readOnly={true}
                    />

                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.postfach"
                                                 defaultMessage="Postfach"/>}
                        value={''}
                        readOnly={true}
                    />

                    <SobrText
                        label={<FormattedMessage
                            id="bonfire.containers.administration.ServicePartner.edit.addressStreet"
                            defaultMessage="Strasse/Hausnr."/>}
                        value={address.street}
                        readOnly={true}
                    />
                    <SobrZipCity
                        label={<FormattedMessage
                            id="bonfire.containers.administration.ServicePartner.edit.addressPlcCity"
                            defaultMessage="PLZ/Ort"/>}
                        idZip="addressZip"
                        idCity="addressCity"
                        valueZip={address.zip}
                        valueCity={address.city}
                        readOnly={true}
                    />
                </SobrFormGroup>
            </div>

            {/* Kontaktperson */}
            <div className="col-12 col-sm-6 col-lg-4">
                <SobrFormGroup title={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.headlineContact"
                                                 defaultMessage="Kontaktperson"/>}>
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.partnerGroup"
                                                 defaultMessage="Partnergruppe"/>}
                        value={partnerGroup.name}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.email"
                                                 defaultMessage="Email"/>}
                        value={primaryContact.email}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.phoneNumber"
                                                 defaultMessage="Telefon"/>}
                        value={primaryContact.telephone}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.mobileNumber"
                                                 defaultMessage="Mobil"/>}
                        value={primaryContact.mobile}
                        readOnly={true}
                    />
                </SobrFormGroup>
            </div>

            {/* Bankdaten */}
            <div className="col-12 col-sm-6 col-lg-4">
                <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineBank">
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.bankName"
                                                 defaultMessage="Bankname"/>}
                        value={bankAccount.bankName}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.bankAccountHolder"
                                                 defaultMessage="Inhaber"/>}
                        value={bankAccount.accountHolder}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.bankIban"
                                                 defaultMessage="IBAN"/>}
                        value={bankAccount.iban}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.bankBic"
                                                 defaultMessage="BIC"/>}
                        value={bankAccount.bic}
                        readOnly={true}
                    />
                </SobrFormGroup>
            </div>

            {/* Schnittstellen */}
            <div className="col-12 col-sm-6 col-lg-4">
                <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineInterfaces">
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.partnerId"
                                                 defaultMessage="Abacus ID"/>}
                        value={partnerId}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.pms"
                                                 defaultMessage="PMS"/>}
                        value={(pmsIntegration.bookingProvider || {}).label}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.pmsNr"
                                                 defaultMessage="PMS Nr."/>}
                        value={pmsIntegration.objectId}
                        readOnly={true}
                    />
                </SobrFormGroup>
            </div>

            {/* Kurverwaltung */}
            <div className="col-12 col-sm-6 col-lg-4">
                <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineTaxAdministration">
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.taxAdministration"
                                                 defaultMessage="Zuständige Kurverwaltung"/>}
                        value={servicePartner.touristAdministration.dropdown.label}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.taxRate"
                                                 defaultMessage="Pauschale"/>}
                        value={(servicePartner.taxMode || {}).label}
                        readOnly={true}
                    />
                    <SobrText
                        label={<FormattedMessage id="bonfire.containers.administration.ServicePartner.edit.taxLocation"
                                                 defaultMessage="Ort"/>}
                        value={(servicePartner.taxLocation || {}).label}
                        readOnly={true}
                    />
                </SobrFormGroup>
            </div>

        </div>
    </div>
}
