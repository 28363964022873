import {Map} from 'immutable';
import PartnerGroupService from "services/servicepartners/PartnerGroupService";
import ServicePartnerBasedataService from "services/servicepartners/ServicePartnerBasedataService";

const InitialState = Map({
    loading: false,
    baseData: Map({
        partnerGroups: [],
        partnerTypes: [],
        pmsProviders: []
    })
});

export const Accessors = {
    getServicePartnerForm,
    getServicePartnerFormBaseData
};

function getServicePartnerForm(state) {
    return state.getIn(['zterminal', 'servicePartner', 'form']).toJS();
}

function getServicePartnerFormBaseData(state) {
    return state.getIn(['zterminal', 'servicePartner', 'form', 'baseData']).toJS();
}

const ACTION_SET_LOADING = 'zterminal/servicepartner/form/setLoading';
const ACTION_BASEDATA_SET_PARTNERGROUPS = 'zterminal/servicepartner/form/basedata/setPartnerGroups';
const ACTION_BASEDATA_SET_PARTNERTYPES = 'zterminal/servicepartner/form/basedata/setPartnerTypes';
const ACTION_BASEDATA_SET_PMS_PROVIDERS = 'zterminal/servicepartner/form/basedata/setPmsProviders';

export const Actions = {
    fetchBaseData
};

function fetchBaseData() {
    return dispatch => {
        dispatch(setLoading(true));
        return Promise.all([
            PartnerGroupService.getPartnerGroups()
                .then(response => {
                    dispatch(setBaseDataPartnerGroups(response.data));
                }),
            ServicePartnerBasedataService.getPartnerTypes()
                .then(response => {
                    dispatch(setBaseDataPartnerTypes(response.data));
                }),
            ServicePartnerBasedataService.getBookingProviders()
                .then(response => {
                    dispatch(setBaseDataPmsProviders(response));
                })
        ]).then(() => {
            dispatch(setLoading(false));
        })
    };
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

function setBaseDataPartnerGroups(partnerGroups) {
    return {
        type: ACTION_BASEDATA_SET_PARTNERGROUPS,
        partnerGroups
    };
}

function setBaseDataPartnerTypes(partnerTypes) {
    return {
        type: ACTION_BASEDATA_SET_PARTNERTYPES,
        partnerTypes
    };
}

function setBaseDataPmsProviders(pmsProviders) {
    return {
        type: ACTION_BASEDATA_SET_PMS_PROVIDERS,
        pmsProviders
    };
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_BASEDATA_SET_PARTNERGROUPS:
            return state.setIn(['baseData', 'partnerGroups'], action.partnerGroups);
        case ACTION_BASEDATA_SET_PARTNERTYPES:
            return state.setIn(['baseData', 'partnerTypes'], action.partnerTypes);
        case ACTION_BASEDATA_SET_PMS_PROVIDERS:
            return state.setIn(['baseData', 'pmsProviders'], action.pmsProviders);
        default:
            return state;
    }
}

export default {
    InitialState,
    Accessors,
    Actions,
    Reducer
}
