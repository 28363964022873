/**
 * Create the store with dynamic reducers
 */

import {applyMiddleware, compose, createStore} from 'redux';
import {fromJS} from 'immutable';
import thunkMiddleware from 'redux-thunk';
import createReducer from './reducers';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const MIDDLEWARES = [
    thunkMiddleware
];

export default function configureStore(initialState = {}) {
    const middlewareEnhancer = applyMiddleware(...MIDDLEWARES);

    const enhancers = [middlewareEnhancer];
    const composedEnhancers = composeEnhancers(...enhancers);

    const store = createStore(createReducer(), fromJS(initialState), composedEnhancers);
    store.injectedReducers = {}; // Reducer registry
    store.injectReducer = (key, reducer) => {
        store.injectedReducers[key] = reducer;
        store.replaceReducer(createReducer(store.injectedReducers));
    }
    return store;
}
