import React, {ReactElement} from 'react';
import {useDispatch, useSelector} from "react-redux";

import logo from 'images/logos/zermattLogoBig.png';
import {FormattedMessage} from "react-intl";
import {SessionActions, SessionSelectors} from "../Session/SessionSlice";
import {Link} from "react-router-dom";
import {ServicePartnerChooser} from "./ServicePartnerChooser";

import './zterminalHeader.scss';
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {AppDispatch} from "App";
import LanguagePicker from "../../Language/LanguagePicker";

export function Header(): ReactElement {
    const userName = useSelector(SessionSelectors.user)?.username;

    const dispatch: AppDispatch = useDispatch();

    const onLogout = () => {
        dispatch(SessionActions.logout())
            .then(() => {
                // TODO implement this properly, once tobi code is completely removed. E.g:
                // this.props.history.push('/')
                window.location.href = "/";
                sessionStorage.clear();
                localStorage.clear();
            })
    }

    return <div id="zterminal-header" className="flex-fixed">
        <Link to={'/zterminal'}>
            <div id="zterminal-header-logo">
                <img className="img-fluid" src={logo} alt="Logo"/>
            </div>
        </Link>

        <div className="container-fluid">
            <div className="row">
                <div className="col-4 text-left" id="zterminal-header-left">
                    <div id="zterminal-header-title">
                        <ServicePartnerChooser/>
                    </div>
                </div>


                <div className="col-8 text-right" id="zterminal-header-user">
                    <div className="d-inline-block align-middle mx-3">
                        <span id="zterminal-header-usericon">
                            <FontAwesomeIcon icon={faUser} size={"sm"}/>
                        </span>
                        <span id="zterminal-header-username">
                            {userName}
                        </span>
                    </div>

                    <div className="d-inline-block align-middle mx-3">
                        <LanguagePicker/>
                    </div>

                    <div id="zterminal-header-logout-button" className="d-inline-block ml-2 of pl-2">
                        <button className='btn'
                                onClick={onLogout}
                        >
                            <FormattedMessage id="bonfire.components.Header.logoutButton"
                                              defaultMessage="Abmelden"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

}
