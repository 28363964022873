
import React, {useState, useEffect, useRef, useMemo} from 'react';
import moment from "moment";
import { GraphHeader } from "./components/GraphHeader";
import { GraphTooltip } from "./components/Tooltip";
import {Line} from "react-chartjs-2";
import 'chartjs-adapter-moment';
import { useDispatch, useSelector } from 'react-redux';
import {
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    LineElement,
    PointElement,
    Title,
    Tooltip,
    TimeScale,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import {useCustomTooltip} from "./hooks/useCustomTooltip";
import {AnalyticsSelectors, getPartnerTypeAndCategoryId} from "../../AnalyticsSlice";
import { backgroundChartArea } from "./graphplugins/chartBackground";
import {formatNumber} from "../../helpers";
import { getOccupancyForecastData, getOverNightStaysChartAccumulatedForecastData} from '../../AnalyticsChartSlice';
import { SessionSelectors } from 'bonfire/ZTerminal/Session/SessionSlice';
import { graphDataSelectors } from './graphDataSelectors';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    ChartDataLabels,
    TimeScale,
    backgroundChartArea
);

interface props {
    tooltipTitle: string,
    isPercent: boolean,
    isCompareGraph: boolean
    title: string
    graphDatasetId: string
    hasPrediction: boolean
}

const formatter = (value: number, context: any, isCompareGraph: boolean = false, isPercent: boolean = false) => {
    if(isCompareGraph) {
        return (context.datasetIndex === 1) ? "" : `${value} ${isPercent ? "%" : ""}`;
    }
    else {
        return `${value} ${isPercent ? "%" : ""}`
    }
}

export const Graph = ({ tooltipTitle, isPercent, isCompareGraph, title, graphDatasetId, hasPrediction}: props) => {

    const chartRef = useRef(null);
    const dispatch = useDispatch();
    const [showDataLabels, setShowDataLabels] = useState<boolean>(false);
    const { customTooltip, tooltip } = useCustomTooltip(chartRef);

    const [data, setData] = useState<{ labels: any[], datasets: any[] }>();
  
    const [compareData, setCompareData] = useState<{ labels: any[], datasets: any[] }>();
  

    let showDataLabelSwitch = false;
    if (data && data.datasets.length > 0) {
        showDataLabelSwitch = data?.datasets?.[0]?.data.length < 32;
    }
    if (compareData && compareData.datasets.length > 0) {
        showDataLabelSwitch = compareData?.datasets?.[0]?.data.length < 32;
    }
   
    const startTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const startCompareFrame = useSelector(AnalyticsSelectors.startDateCompareFrame);
    const endCompareFrame = useSelector(AnalyticsSelectors.endDateCompareFrame);
    const startDate = moment(startTimeFrame).format("YYYY-MM-DD");
    const endDate = moment(endTimeFrame).format("YYYY-MM-DD");
    const compareStartDate = moment(startCompareFrame).format("YYYY-MM-DD");
    const compareEndDate = moment(endCompareFrame).format("YYYY-MM-DD");
  
  
    const dropdownValue = useSelector(AnalyticsSelectors.dropdownValues);
    const dropdownCompareValue = useSelector(AnalyticsSelectors.compareDropdownValues);
    const graphData = graphDataSelectors[graphDatasetId];
    
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id;
    const trigger = useSelector(AnalyticsSelectors.trigger);

    const dropdownEqualAndAllSelected = 
    dropdownValue.selectedRadio === "All" &&
    dropdownCompareValue.selectedRadio === "All" &&
    (dropdownValue.selectedKeys.length === 6 || dropdownValue.selectedKeys.length === 0) &&
    (dropdownCompareValue.selectedKeys.length === 6 || dropdownCompareValue.selectedKeys.length === 0);
  
    const dropdownEqualAndOwnSelected = dropdownValue.selectedRadio === "Own" && dropdownCompareValue.selectedRadio === "Own";
    
    const dropdownEqualAndNotAllOrOwnSelected =
        dropdownValue.selectedRadio !== "Own" &&
        dropdownCompareValue.selectedRadio !== "Own" &&
        dropdownValue.selectedKeys.length > 0 && dropdownValue.selectedKeys.length < 6 &&
        dropdownCompareValue.selectedKeys.length > 0 && dropdownCompareValue.selectedKeys.length < 6 &&
        JSON.stringify(dropdownValue.selectedKeys) === JSON.stringify(dropdownCompareValue.selectedKeys);
;

    const handleDropdownChange = async ( 
        startDate: string | undefined, endDate: string | undefined,  servicePartnerId: string | undefined, selectedKeys: string[], selectedRadio: string | undefined, compareStartDate?: string | undefined, compareEndDate?: string | undefined, isCompare?: boolean | null
    ) => {
        const { partnerType, categoryId } = getPartnerTypeAndCategoryId(selectedKeys);
        const isOwnSelected = selectedRadio === "Own";
        const isAllSelected = selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0);
        const isElseSelected = selectedRadio !== "Own" && selectedKeys.length < 6 && selectedKeys.length > 0;
            
        let actionCreatorParams;
        if (isAllSelected ) {
            actionCreatorParams = { startDate, endDate, compareStartDate, compareEndDate, isCompare }
        }
        else if (isOwnSelected) {
            actionCreatorParams = { startDate, endDate, servicePartnerId, isCompare };
            }
        else if (isElseSelected) {
            actionCreatorParams = { startDate, endDate, undefined, categoryId, partnerType, isCompare }
            }
        try {
            // @ts-ignore
            const chartData = await dispatch(graphData.actionCreator(actionCreatorParams)).unwrap();
            return chartData;
        }
        catch (error) {
            console.error(error);
        }
    };


    const handleDropdownCompareChange = async ( 
        startDate: string | undefined, endDate: string | undefined,  servicePartnerId: string | undefined, selectedKeys: string[], selectedRadio: string | undefined, compareStartDate?: string | undefined, compareEndDate?: string | undefined, isCompare?: boolean | null
    ) => {
        const { partnerType, categoryId } = getPartnerTypeAndCategoryId(selectedKeys);
    
        let actionCreatorParams;
        if (selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0)) {
            actionCreatorParams = { startDate, endDate, compareStartDate, compareEndDate, isCompare }
        }
        if (selectedRadio === "Own") {
   
            {
                actionCreatorParams = { startDate, endDate, servicePartnerId, isCompare }; //
            }
        }
        if  (dropdownCompareValue.selectedKeys.length < 6 && dropdownCompareValue.selectedKeys.length > 0 && dropdownCompareValue.selectedRadio !== "Own") {
            {
                actionCreatorParams = { startDate, endDate, undefined, categoryId, partnerType, isCompare }
             
            }
        
        }
        try {
            // @ts-ignore
            const chartData = await dispatch(graphData.actionCreator(actionCreatorParams)).unwrap();
            return chartData;
        }
        catch (error) {
            console.error(error);
        }
    };
 
      
    const getForecastData = async (servicePartnerId: string | undefined, selectedKeys: string[], selectedRadio: string) => {
       
        const { partnerType: partnerType, categoryId: categoryId } = getPartnerTypeAndCategoryId(dropdownCompareValue.selectedKeys);
        const startDate = moment(startCompareFrame).format("YYYY-MM-DD");
        const endDate = moment(endCompareFrame).format("YYYY-MM-DD");
        const isOwnSelected = selectedRadio === "Own";
        const isAllSelected = selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0);
        const isElseSelected = selectedRadio !== "Own" && selectedKeys.length < 6 && selectedKeys.length > 0;
        let data;
        let params: any = { startDate, endDate };
        if (isAllSelected) {
            params = { ...params };
        }
        else if (isOwnSelected ) {
            params = { ...params, servicePartnerId };
        }
        else if (isElseSelected) {
            params = { ...params, categoryId, partnerType};
        }
        if (graphDatasetId === "overnightStaysChartData") {
            //@ts-ignore
          data = await dispatch(getOverNightStaysChartAccumulatedForecastData(params)).unwrap();
        }
        else {
            //@ts-ignore
          data = await dispatch(getOccupancyForecastData(params)).unwrap();
        }
        return data;
      };
      
    
    
      const getChartChangesData = async () => {
        const { partnerType, categoryId } = getPartnerTypeAndCategoryId(dropdownValue.selectedKeys);
        const { partnerType: partnerTypeCompare, categoryId: categoryIdCompare } = getPartnerTypeAndCategoryId(dropdownCompareValue.selectedKeys);
        let actionCreatorParams = {};
      
        if (dropdownEqualAndAllSelected) {
          actionCreatorParams = { startDate, endDate, compareEndDate, compareStartDate };
        } else if (dropdownEqualAndOwnSelected) {
          actionCreatorParams = { startDate, endDate, servicePartnerId, compareStartDate, compareEndDate };
        } else if (dropdownEqualAndNotAllOrOwnSelected) {
          actionCreatorParams = { startDate, endDate, categoryId, partnerType, compareStartDate, compareEndDate, partnerTypeCompare, categoryIdCompare };
        } else  {
          if (dropdownValue.selectedRadio === "Own" || dropdownCompareValue.selectedRadio === "Own") {
            actionCreatorParams = { startDate, endDate, servicePartnerId, categoryId, partnerType, compareStartDate, compareEndDate, partnerTypeCompare, categoryIdCompare };
          } else {
            actionCreatorParams = { startDate, endDate, categoryId, partnerType, compareStartDate, compareEndDate, partnerTypeCompare, categoryIdCompare };
          }
        }
        try {
            //@ts-ignore
          const chartData = await dispatch(graphData.actionCreator(actionCreatorParams)).unwrap();
          return chartData;
        } catch (error) {
          console.error(error);
        }
      }
      
    

    useEffect(() => {
        const fetchData = async () => {
            const today = moment().format("YYYY-MM-DD");
            const yesterday = moment().subtract(1, 'days');
            const tomorrow = moment().add(1, 'days');
            let periodData = {};
            let compareData = {};
            let forecastData = {};
            let periodDataAfterToday = {};
            try {
                if (graphDatasetId === "occupancyRateChartCompareData" || graphDatasetId === "avgStaysChartCompareData" || graphDatasetId === "overnightStaysChartCompareData") {
                    const dataFromChartCompare = await getChartChangesData()
                    periodData = dataFromChartCompare.chart;
                    compareData = dataFromChartCompare.chartCompare;
                } 
                if (graphDatasetId === "avgStaysChartData") {
                  
                    periodData = await handleDropdownChange(startDate, endDate, servicePartnerId, dropdownValue.selectedKeys, dropdownValue.selectedRadio, undefined, undefined, false);
                    compareData = await handleDropdownCompareChange(compareStartDate, compareEndDate, servicePartnerId, dropdownCompareValue.selectedKeys, dropdownCompareValue.selectedRadio, undefined, undefined, true);
                }
      
                if (graphDatasetId === "overnightStaysChartData" || graphDatasetId === "occupancyRateChartData") {
                    
                    forecastData = await getForecastData(servicePartnerId, dropdownCompareValue.selectedKeys, dropdownCompareValue.selectedRadio)

                    compareData = await handleDropdownCompareChange(compareStartDate, compareEndDate, servicePartnerId, dropdownCompareValue.selectedKeys, dropdownCompareValue.selectedRadio, undefined, undefined, true);
                  
                    const mainData = await handleDropdownChange(startDate, endDate, servicePartnerId, dropdownValue.selectedKeys, dropdownValue.selectedRadio, undefined, undefined, false);
                    periodData = Object.fromEntries(Object.entries(mainData ?? {}).filter(([key]) => key <= today));
                    periodDataAfterToday = Object.fromEntries(Object.entries(mainData ?? {}).filter(([key]) => key >= today));
               }
        

          
                const customLabelsCompare:string[] = [];
                const labels: string[] = [];
            
                const mainDataSetValues = [];
                const compareDataSetValues  = [];
                
                const mainDataSetValuesForAfterToday = []
                const customMainDataAfterTodayLabel = [];
                
                const customLabelsForecast = [];
                const forecastDataSetValues = [];
                
        
                const todaysDate = moment();
                let selectedStartDate = moment(startDate);
                const selectedEndDate = moment(endDate)

                while (selectedStartDate < yesterday) { 
                    customMainDataAfterTodayLabel.push(selectedStartDate.format('YYYY-MM-DD'));
                    mainDataSetValuesForAfterToday.push(null)
                    selectedStartDate.add(1, 'days'); 
                }
             
                let forecastStartDate = moment(startDate); 
                
                while  (forecastStartDate < yesterday) { 
                  customLabelsForecast.push(forecastStartDate.format('YYYY-MM-DD'));
                  forecastDataSetValues.push(null); 
                  forecastStartDate.add(1, 'days');
                }
       
                for (const [key, value] of Object.entries(forecastData ?? {})) {
                  customLabelsForecast.push(key);
                  forecastDataSetValues.push(value);
                }
               
                for (const [key, value] of Object.entries(periodData ?? {})) {
                  labels.push(key);
                  mainDataSetValues.push(value);
                }
         
                for (const [key, value] of Object.entries(periodDataAfterToday ?? {})) {
                    customMainDataAfterTodayLabel.push(key);
                    mainDataSetValuesForAfterToday.push(value);
                  }
                  
                for (const [key, value] of Object.entries(compareData ?? {})) {
                  customLabelsCompare.push(key);
                  compareDataSetValues.push(value);
                }      
                const mainDataSet = {
                        label: "Auswahl",
                        data: Object.values(mainDataSetValues),
                        customLabel: labels,
                        borderColor: "#F7380D",
                        backgroundColor: "#F7380D",
                        pointRadius: 0,
                       
                    };
         
              const compareDataSet: any = {
                  label: "Vergleich",
                  data: Object.values(compareDataSetValues),
                  borderColor: "#0E9CFF",
                  backgroundColor: "#0E9CFF",
                  pointRadius: 0,
                  isCompareGraph: true,
                  customLabel: customLabelsCompare,
              };
              const mainDataSetAfterToday = {
                label:"",
                data: Object.values(mainDataSetValuesForAfterToday),
                borderColor:  "#F7380D",
                backgroundColor: "#0E9CFF",
                pointRadius: 0,
                customLabel: customMainDataAfterTodayLabel,
                borderDash: [3],
                pointStyle: "line"

             };
                const forecastDataSet = {
                     label: "Forecast Data",
                     data: forecastDataSetValues,
                     customLabel: customLabelsForecast,
                     borderColor: "#0E9CFF",
                     backgroundColor: "#0E9CFF",
                     pointRadius: 0,
                     borderDash: [3],
                     pointStyle: "line",
                  };
                if (graphDatasetId === "overnightStaysChartData" || graphDatasetId === "occupancyRateChartData") {
                      
                    while (tomorrow <= selectedEndDate) {
                        labels.push(tomorrow.format('YYYY-MM-DD'));
                        mainDataSetValues.push(null);
                        tomorrow.add(1, 'days');
                    }
             
    
                    
                }
                const datasets = [mainDataSet, forecastDataSet, mainDataSetAfterToday, compareDataSet];
                const compareDatasets = [compareDataSet]
                
                setData({
                    labels: labels,
                    datasets: datasets,
                });
                setCompareData({
                    labels: customLabelsCompare,
                    datasets: compareDatasets,
                });
          

            }
            catch(error) {
                console.log(error);
            }
        };
        fetchData();
    }, [graphDatasetId, dropdownCompareValue, dropdownValue, trigger, isPercent]);

            
                const options = useMemo(() => {
                    return {
                        responsive: true,
                        interaction: {
                            intersect: false,
                            mode: 'index' as const,
                        },
                        scales: {
                            y: {
                                grid: {
                                    drawBorder: false,
                                    borderDash: [3],
                                    color: '#cccccc'
                                },
                                ticks: {
                                    beginAtZero: true,
                                    stepSize: 25,
                                    callback: (value: any, index: any, ticks: any) => `${formatNumber(value)} ${isPercent ? "%" : ""}`
                                },
                            },
                            x: {
                                grid: {
                                    drawBorder: false,
                                    borderDash: [3],
                                    color: '#cccccc'
                                },
                                ticks: {
                                    callback: function (value: any): any {
                                           // @ts-ignore
                                        return moment(this.getLabelForValue(value)).format("MMM DD");
     
                                      }
                       
                                }
                            }
                        },
                        plugins: {
                            backgroundChartArea,
                            legend: {
                                position: 'bottom' as const,
                                labels: {
                                    usePointStyle: true
                                },
                            },
                            datalabels: {
                                display: showDataLabels,
                                anchor: 'end' as const,
                                align: 'top' as const,
                                formatter: (value: number, context: any) => formatter(value, context, isCompareGraph, isPercent)
                            },
                            tooltip: {
                                enabled: false,
                                external: customTooltip,
                            },
                        }
                    }
                }, [data, showDataLabels]);
            
    return (
        <React.Fragment>
        <GraphHeader
           title={title}
           showDataLabelSwitch={showDataLabelSwitch}
           showDataLabels={showDataLabels}
           setShowDataLabels={setShowDataLabels}
         />
            <Line options={options} ref={chartRef} data={data || { labels: [], datasets: [] } }  />
        <GraphTooltip
                title={tooltipTitle}
                isPercent={isPercent}
                graphDataCompare={compareData}
                graphData={data}
                tooltip={tooltip}
                hasPrediction={hasPrediction}
               />
    
        </React.Fragment>
        );
}
        

