import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';

import './SobrPagination.scss';

export default function SobrPagination(props) {
    const divProps = R.omit(['numPages', 'currentPage', 'onSelectPage'], props);
    const pageButtons = R.map(
        page => <div
            key={page}
            className={`pagination-button ${page === props.currentPage ? "active" : ""}`}
            onClick={() => props.onSelectPage(page)}
        >
            <span className="num">{page}</span>
        </div>
    )(R.range(1, props.numPages + 1));

    return <div {...divProps}>
        {pageButtons}
    </div>
}

SobrPagination.propTypes = {
    numPages: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onSelectPage: PropTypes.func
};

SobrPagination.defaultProps = {
    onSelectPage: R.F,
    className: 'pagination'
};
