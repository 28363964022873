import React from 'react';

export const AnalyticsNavigation  = () => {

    return (
        <React.Fragment>
            <div className="analytics-navigation">
                <button className="navigation-button active">
                    Auslastung
                </button>
                {/* <button className="navigation-button ">
                    Buchungsverhalten & Gäste
                </button> */}
            </div>
        </React.Fragment>
    );
}