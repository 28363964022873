/**
 * Combine all reducers in this file and export the combined reducers.
 */

import Language from "bonfire/Language/redux";

import globalReducer from 'containers/App/reducer';
import {combineReducers} from 'redux-immutable';
import SelfService from "./bonfire/SelfService/redux";

import ZTerminal from './bonfire/ZTerminal/redux'
import overviewReducer from './containers/Overview/reducer';
import rootReducer from './containers/RootPage/reducer';
import welcomeReducer from './containers/Welcome/reducer';

/*
 * routeReducer
 *
 * The reducer merges route location changes into our immutable state.
 * The change is necessitated by moving to react-router-redux@5
 *
 */

/**
 * Creates the main reducer with the dynamically injected ones
 */
export default function createReducer(injectedReducers) {
    return combineReducers({
        zterminal: ZTerminal.Reducer,
        selfService: SelfService.Reducer,
        language: Language.Reducer,
        global: globalReducer,
        overview: overviewReducer,
        welcome: welcomeReducer,
        root: rootReducer,
        ...injectedReducers,
    });
}
