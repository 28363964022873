import {Map} from 'immutable';
import {Accessors as ServicePartnerFormAccessors} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/redux";
import ServicePartnerFormLogic, {emptyServicePartnerFormData} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerFormLogic";
import v from "lib/validation";
import ServicePartnerService from "services/servicepartners/ServicePartnerService";
import * as R from "ramda";

export const InitialState = Map({
    servicePartnerData: null,
    validationData: null,
    loading: false
});

const ACTION_SET_SERVICEPARTNER_DATA = 'zterminal/servicePartner/create/setServicePartnerData';
const ACTION_SET_VALIDATION_DATA = 'zterminal/servicePartner/create/setValidationData';
const ACTION_SET_LOADING = 'zterminal/servicePartner/create/setLoading';

export const Actions = {
    clearValidationData,
    setServicePartnerData,
    createServicePartner
};

function createServicePartner(servicePartnerData) {
    return (dispatch, getState) => {
        const spFormBaseData = ServicePartnerFormAccessors.getServicePartnerFormBaseData(getState());
        const validationResult = ServicePartnerFormLogic.validateServicePartnerFormData(servicePartnerData, spFormBaseData);
        dispatch(setValidationData(validationResult));
        if (!v.isValid(validationResult)) {
            return Promise.reject(validationResult);
        }

        dispatch(setLoading(true));
        const pmsRequestData = ServicePartnerFormLogic.formDataToPmsIntegrationRequestData(servicePartnerData);
        const spRequestData = R.pipe(
            ServicePartnerFormLogic.formDataToServicePartnerRequestData,
            R.assocPath(['touristAdministration', 'id'], 'zta'),
            R.assocPath(['taxMode', 'id'], 'zt-single-report'),
            R.assocPath(['taxLocation', 'id'], 'zermatt'),
        )(servicePartnerData);
        return ServicePartnerService.createServicePartner(spRequestData)
            .then(sp => ServicePartnerService.createPmsIntegration(sp.id, pmsRequestData))
            .then(() => {
                dispatch(setServicePartnerData(emptyServicePartnerFormData()));
                dispatch(setLoading(false));
            });
    };
}

function setServicePartnerData(servicePartnerData) {
    return {
        type: ACTION_SET_SERVICEPARTNER_DATA,
        servicePartnerData
    };
}

function clearValidationData() {
    return setValidationData(null);
}

function setValidationData(validationData) {
    return {
        type: ACTION_SET_VALIDATION_DATA,
        validationData
    };
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_SERVICEPARTNER_DATA:
            return state.set('servicePartnerData', action.servicePartnerData);
        case ACTION_SET_VALIDATION_DATA:
            return state.set('validationData', action.validationData);
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        default:
            return state;
    }
}

export default {
    InitialState, Actions, Reducer
}
