import React from 'react';
import PropTypes from 'prop-types';
import {toInputValue} from "lib/forms";

export class SobrText extends React.Component {

    static propTypes = {
        label: PropTypes.any.isRequired,
        class: PropTypes.string,
        inputClass: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func,
        //disabled: PropTypes.bool,
        required: PropTypes.bool,
        errorMessage: PropTypes.any,
        emailSynthError: PropTypes.bool,
        emailSynthErrorMessage: PropTypes.any,
        mobileNumberSynthError: PropTypes.bool,
        mobileNumberSynthErrorMessage: PropTypes.any,
        isError: PropTypes.bool,
        readOnly: PropTypes.bool,
        isPassword: PropTypes.bool
    };

    static defaultProps = {
        value: '',
        disabled: false,
        required: false,
        class: '',
        inputClass: '',
        name: '',
        emailSynthError: false,
        mobileNumberSynthError: false,
        isError: false,
        readOnly: false,
        isPassword: false
    };

    render() {

        const name = this.props.name ? this.props.name : this.props.id;
        return (
            <div className="form-group row">
                <span className={"col-sm-12 col-form-label " + this.props.class}>
                    {this.props.label}
                    {this.props.required && <span>*</span>}
                </span>
                <div className="col-sm-12">
                    <input
                        type={this.props.isPassword ? 'password' : 'text'}
                        className={"form-control " + this.props.inputClass}
                        id={this.props.id}
                        name={name}
                        value={toInputValue(this.props.value)}
                        onChange={this.props.onChange}
                        //disabled={this.props.disabled}
                        readOnly={this.props.readOnly}
                    />
                    {(this.props.required && this.props.errorMessage && this.props.isError && this.props.emailSynthError) && <span className="errorTextStyle">{this.props.emailSynthErrorMessage}</span>}
                    {(this.props.required && this.props.errorMessage && this.props.isError && this.props.mobileNumberSynthError) && <span className="errorTextStyle">{this.props.mobileNumberSynthErrorMessage}</span>}
                    {(this.props.required && this.props.errorMessage && this.props.isError && !this.props.emailSynthError && !this.props.mobileNumberSynthError) && <span className="errorTextStyle">{this.props.errorMessage}</span>}
                </div>
            </div>
        );
    }
}
