import * as R from 'ramda';

function flet (arg, fun) {
    if (arg) {
        return fun(arg);
    } else {
        return undefined;
    }
}

function noop() {}

function callIfExists(func, ...args) {
    if (R.is(Function, func)) {
        return func(...args);
    }
}

const includedIn = R.flip(R.includes);

function xor(b1, b2) {
    return b1 ? !b2 : b2;
}

const isNotNil = R.complement(R.isNil);
const isNilOrEmpty = R.either(R.isNil, R.isEmpty);
const isNotNilOrEmpty = R.complement(isNilOrEmpty);
const hasValue = isNotNilOrEmpty;

function removeWhitespace(str) { return str.replace(/\s+/g, ''); }

function onePasses(f1, f2) {
    return value => xor(f1(value), f2(value));
}

const listAnd = R.reduce(R.and, true);
const listOr = R.reduce(R.or, false);

let lastHtmlId = 0;
function getUniqueHtmlId(prefix = '') {
    lastHtmlId += 1;
    return `${prefix}${lastHtmlId}`;
}


export default {
    let: flet,
    callIfExists: callIfExists,
    noop,
    includedIn,
    xor,
    isNotNil,
    isNilOrEmpty,
    isNotNilOrEmpty,
    hasValue,
    removeWhitespace,
    onePasses,
    listAnd,
    listOr,
    getUniqueHtmlId
}
