import React from 'react';

import {ToastContainer} from 'react-toastify';

export default function CustomToastContainer() {
    return <ToastContainer
        position="bottom-center"
        autoClose={5000}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnVisibilityChange
        draggable
    />
}
