import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Moment from 'moment';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Link} from "react-router-dom";


export class SelfServiceStep10Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        fellowTraveler: PropTypes.any,
        customerData: PropTypes.any
    };

    render() {
        let customerData = this.props.customerData;
        const id = this.props.registrationForm.id;
        const primaryGuest = customerData ? customerData : this.props.registrationForm.guests.find(guest => guest.primary === true).guest;
        const fellow = this.props.fellowTraveler;
        const fellowTraveler = this.props.registrationForm.guests.filter((item) => item.primary === false);
        const servicePartner = this.props.registrationForm.servicePartner;

        return (
            <SelfServiceMainLayout
                title={{id: 'bonfire.containers.SelfServiceStep10Page.title', defaultMessage: 'Pre-Check-In'}}
                class="step8-1">

                <SelfServiceStepLayout id={id}
                                       title={{id: 'bonfire.containers.SelfServiceStep10Page.title.dataOverview'}}
                                       class="step8-1"/>

                <div className="text text2">
                    <div className="wrapper wrapper2 self-service">
                        <div className="table-responsive">
                            <table className="table table-striped">
                                <tbody>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.hotelName'
                                        defaultMessage='Unterkunft: '
                                    /></td>
                                    <td className="self-service-value">{servicePartner.name}</td>
                                </tr>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.checkinDate'
                                        defaultMessage='Anreise: '
                                    /></td>
                                    <td className="self-service-value">{Moment.utc(this.props.registrationForm.checkinDate).format('DD.MM.YYYY')}</td>
                                </tr>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.checkoutDate'
                                        defaultMessage='Abreise: '
                                    /></td>
                                    <td className="self-service-value">{Moment.utc(this.props.registrationForm.checkoutDate).format('DD.MM.YYYY')}</td>
                                </tr>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.name'
                                        defaultMessage='Name: '
                                    /></td>
                                    <td className="self-service-value">{primaryGuest.firstName} {primaryGuest.lastName}</td>
                                </tr>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.birthday'
                                        defaultMessage='Geburtsdatum: '
                                    /></td>
                                    <td className="self-service-value">{Moment.utc(primaryGuest.birthday).format('DD.MM.YYYY')}</td>
                                </tr>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.email'
                                        defaultMessage='E-Mail: '
                                    /></td>
                                    <td className="self-service-value">{primaryGuest.email}</td>
                                </tr>
                                <tr>
                                    <td className="self-service-label"><FormattedMessage
                                        id='bonfire.containers.SelfServiceStep9Page.countGuests'
                                        defaultMessage='Anzahl Mitreisende: '
                                    /></td>
                                    <td className="self-service-value">{fellowTraveler.length}</td>
                                </tr>
                                {
                                    /* Mitreisende sind jetzt auch direkt auf der ersten Zeile nicht erst auf der nächsten */
                                    false &&
                                    <tr>
                                        <td className="self-service-label"><FormattedMessage
                                            id='bonfire.containers.SelfServiceStep9Page.countGuests2'
                                            defaultMessage='Registrierte Mitreisende: '
                                        /></td>
                                        <td className="self-service-value"></td>
                                    </tr>
                                }

                                {fellowTraveler &&
                                    fellowTraveler.map((object, key) => (
                                            <tr key={key}>
                                                <td className="self-service-label">{key === 0 ?
                                                    <FormattedMessage
                                                        id='bonfire.containers.SelfServiceStep9Page.countGuests2'
                                                        defaultMessage='Registrierte Mitreisende: '/> : ''}</td>
                                                <td className="self-service-value">{fellow && fellow[object.guest.id] && (fellow[object.guest.id].firstName || fellow[object.guest.id].lastName) ? (fellow[object.guest.id].firstName ? fellow[object.guest.id].firstName + ' ' : '') + (fellow[object.guest.id].lastName ? fellow[object.guest.id].lastName : '') : object.guest && (object.guest.firstName || object.guest.lastName) ? (object.guest.firstName ? object.guest.firstName + ' ' : '') + (object.guest.lastName ? object.guest.lastName : '') : object.displayName ? object.displayName : ''}</td>
                                            </tr>
                                        )
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div style={{height: '20px'}} />
                <div className="bottom">
                    <Link to={`/self-service/${id}/step11`}>
                        <button className="b-button-pre-checkin" id="self-service-step10-confirm-next-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </Link>

                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep10 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        fellowTraveler: state.getIn(['selfService', 'data', 'fellowTraveler']),
        customerData: state.getIn(['selfService', 'data', 'customerData'])
    })
)(SelfServiceStep10Component);

