import React from 'react';
import PropTypes from 'prop-types';
import {useHistory} from 'react-router-dom';

const SobrDashboardCardGuest = (props) => {
    const history = useHistory();

    return (
        <div className={'dashboard-card-guest'}>
            {
                props.guestId ? (
                    <div className={'container main-container'}
                         onClick={() => history.push(`/zterminal/registrationform/edit/${props.guestId}`)}>
                        {
                            props.noGuest ? (
                                <span className={'no-guest'}>{props.noGuest}</span>
                            ) : (
                                <div className={'head-container'}>
                                    <span className={'head-title'}>{props.guestName}</span>
                                    <span
                                        className={'head-subtitle'}>{props.guestCountry ? props.guestCountry : '-'}</span>
                                </div>
                            )
                        }
                        <div className={'detail-container'}>
                            <span className={'detail-address'}>{props.guestAddress ? props.guestAddress : '-'}</span>
                            {
                                props.guestCheckedDate &&
                                <div className={'detail-date-container'}>
                                    <span className="icon icon-calendar-small"/>
                                    <span className={'date'}>{props.guestCheckedDate}</span>
                                </div>
                            }
                        </div>
                    </div>
                ) : (
                    <div className={'container main-container'}>
                        <div className={'head-container'}>
                            <span className={'icon-big'}>
                                <img src={props.icon} alt="Logo"/>
                            </span>
                        </div>
                        <div className={'detail-container'}>
                            <span className={'detail-title'}>{props.noGuest}</span>
                        </div>
                    </div>
                )
            }
        </div>
    );
};

SobrDashboardCardGuest.propTypes = {
    count: PropTypes.any,
    guestId: PropTypes.any,
    guestName: PropTypes.any,
    guestCountry: PropTypes.any,
    guestAddress: PropTypes.any,
    guestCheckedDate: PropTypes.any,
    noGuest: PropTypes.any,
    icon: PropTypes.any,
};

export default SobrDashboardCardGuest;