import React from 'react';
import {Redirect, Route, Switch} from "react-router-dom";

import {ZTerminal} from "./ZTerminal/ZTerminal";
import SelfServiceWrapper from "bonfire/SelfService/SelfServiceWrapper";
import ForgotPasswordPage from "../containers/ForgotPassword";
import ChallengePasswordPage from "../containers/ChallengePassword/Loadable";
import StartPage from "../containers/Welcome/StartComponent";
import WelcomeChallengePasswordPage from "../containers/Welcome/ChallengePassword/Loadable";
import WelcomeChallengeContactInformationsPage from "../containers/Welcome/ChallengeContactInformations/Loadable";

export default class Bonfire extends React.Component {

    render() {
        return <div id="bonfire" className="w-100 h-100">
            <Switch>
                <Route path="/zterminal" component={ZTerminal}/>
                <Route path="/self-service/" exact={false} component={SelfServiceWrapper}/>
                <Route path="/forgot_password" component={ForgotPasswordPage}/>
                <Route path="/challenge_password/:id" component={ChallengePasswordPage}/>
                <Route path="/start" component={StartPage}/>
                <Route path="/welcome/challenge_password" component={WelcomeChallengePasswordPage}/>
                <Route path="/welcome/challenge_contact_informations"
                       component={WelcomeChallengeContactInformationsPage}/>
                <Route path="/welcome" render={() => (<Redirect to="/welcome/challenge_password"/>)}/>

            </Switch>
        </div>
    }

}
