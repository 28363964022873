import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Moment from 'moment';
import {Link} from "react-router-dom";
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";


export class SelfServiceStep6Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        fellowTraveler: PropTypes.any,
        nationalityData: PropTypes.any.isRequired
    };

    get fellowTravelerGuests() {
        return this.props.registrationForm.guests.filter((item) => item.primary === false);
    }

    getFellow(guestId) {
        const fellows = this.props.fellowTravelers;
        return fellows && fellows[guestId];
    }

    createCard(guest, guestKey) {
        const fellow = this.getFellow(guest.guest.id);
        return Object.assign({ key: guestKey, guestId: guest.guest.id }, guest.guest, fellow);
    }

    cardIsEmpty(card) {
        return !(card.firstName || card.lastName || card.birthday);
    }

    formatDate(date) {
        if (!date) {
            return "";
        }
        return new Moment.utc(date).format('DD.MM.YYYY');
    }

    render() {
        const nationalityData = this.props.nationalityData;
        const registrationFormId = this.props.registrationForm.id;
        const totalGuests = this.props.registrationForm.additionalGuestCount ? this.props.registrationForm.additionalGuestCount : 0;

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep6Page.title', defaultMessage: 'Pre-Check-In'}} class="step6">

                <SelfServiceStepLayout id={registrationFormId} title={{id: 'bonfire.containers.SelfServiceStep4Page.title.mitreisende'}} class="step6"/>

                <div className="text">
                    <div className="wrapper self-service">
                        <div className="edit-text-wrap with-bold sar-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep5Page.countTraveler'
                                defaultMessage='Wählen Sie einen Mitreisenden aus, um ihn zu editieren.'
                                values={{
                                    count: totalGuests,
                                    b(fragments) {
                                        return <b>{fragments}</b>
                                    }
                                }}
                            />
                        </div>
                        <div className="edit-text-wrap with-bold sar-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep6Page.registerAll'
                                defaultMessage='Wir bitten Sie, alle Mitreisenden zu registrieren.'
                            />
                        </div>
                        {this.fellowTravelerGuests
                            .map((guest, key) => this.createCard(guest, key))
                            .map(card => <div key={card.key}>
                                    <Link to={`/self-service/${registrationFormId}/step7/${card.guestId}`}>
                                        <div className="card" id={card.key} onClick={() => window.scrollTo(0, 0)}>
                                            <div className="card-body position-relative">
                                                <div className="float-right edit-button" id="self-service-step6-edit-button">
                                                    <FormattedMessage
                                                        id="bonfire.containers.SelfServiceStep6Page.Edit"
                                                        defaultMessage="Editieren"
                                                    />
                                            </div>
                                            { this.cardIsEmpty(card) ? (
                                                <div className="unknown">
                                                    <FormattedMessage
                                                        id="bonfire.containers.SelfServiceStep6Page.Guest"
                                                        values={{guestNo: (card.key + 1)}}
                                                        defaultMessage="Client {guestNo}"
                                                    />
                                                </div>
                                            ) : (
                                                <div>
                                                    <div className="traveling-with sab-big">
                                                        {card.firstName}&nbsp;
                                                        {card.lastName}
                                                    </div>
                                                    <div className="birthday">
                                                        <FormattedMessage
                                                            id='bonfire.containers.SelfServiceStep6Page.birthday'
                                                            defaultMessage='Geburtstag: '
                                                        />
                                                        {this.formatDate(card.birthday)}
                                                    </div>
                                                    <div className="nationality">
                                                        <FormattedMessage
                                                            id='bonfire.containers.SelfServiceStep6Page.nationality'
                                                            defaultMessage='Nationalität: '
                                                        />
                                                        {nationalityData.find((nation) => nation.value === card?.nationality)?.label}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </Link>
                            </div>
                            )
                        }
                    </div>
                </div>
                <div style={{height: '20px'}} />
                <div className="bottom">
                    <Link to={`/self-service/${registrationFormId}/step8`}>
                        <button className="b-button-pre-checkin" onClick={() => window.scrollTo(0, 0)} id="self-service-step6-confirm-next-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </Link>

                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep6 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        fellowTravelers: state.getIn(['selfService', 'data', 'fellowTraveler']),
        nationalityData: state.getIn(['selfService', 'nationalityData'])
    })
)(SelfServiceStep6Component);

