/*
 * FeaturePage Messages
 *
 * This contains all the text for the FeaturePage component.
 */
import { defineMessages } from 'react-intl';

export default defineMessages({
  title: {
    id: 'bonfire.containers.ForgotPassword.title',
    defaultMessage: 'Passwort zurücksetzen',
  },
  email: {
    id: 'bonfire.containers.ForgotPassword.email',
    defaultMessage: 'E-Mail',
  },
  password: {
    id: 'bonfire.containers.ForgotPassword.password',
    defaultMessage: 'Passwort',
  },
  passwordResetText: {
    id: 'bonfire.containers.ForgotPassword.passwordResetText',
    defaultMessage: 'Bitte tragen sie die E-Mail Adresse ein, unter der sie im System registriert sind.',
  },
  backButton: {
    id: 'bonfire.containers.ForgotPassword.backButton',
    defaultMessage: 'Zurück',
  },
  loginButton: {
    id: 'bonfire.containers.ForgotPassword.loginButton',
    defaultMessage: 'Passwort zurücksetzen',
  },
});
