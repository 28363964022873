import {Map} from 'immutable';
import ServicePartnerService from "services/servicepartners/ServicePartnerService";

export const InitialState = Map({
    loading: false,
    error: undefined
});

const ACTION_SET_LOADING = 'zterminal/servicePartner/reportChange/setLoading';
const ACTION_SET_ERROR = 'zterminal/servicePartner/reportChange/setError';

export const Actions = {
    submit
};

function submit(servicePartnerId, text) {
    return dispatch => {
        dispatch(setLoading(true));
        return ServicePartnerService.reportChange(servicePartnerId, text)
            .then(() => {
                dispatch(setLoading(false));
                dispatch(setError(undefined));
            })
            .catch(error => {
                dispatch(setLoading(false));
                dispatch(setError(`Fehler: ${error.status}`));
                return Promise.reject(error.status);
            });
    }
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

function setError(error) {
    return {
        type: ACTION_SET_ERROR,
        error
    }
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_SET_ERROR:
            return state.set('error', action.error);
        default:
            return state;
    }
}

export default {
    Actions, InitialState, Reducer
}
