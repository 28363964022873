import {SobrSingleSelect} from "components/SobrForm/Inputs/SobrSelect";
import React from 'react';
import PropTypes from 'prop-types';
import * as R from "ramda";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {SobrFormGroup} from "components/SobrForm/SobrFormGroup";
import {connect} from "react-redux";
import {
    Accessors as ServicePartnerFormAccessors,
    Actions as ServicePartnerFormActions
} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/redux";
import {SobrFormRow} from "components/SobrForm/Forms/SobrFormRow";
import SobrTextInput from "components/SobrForm/Inputs/SobrTextInput";
import {
    BASE_DATA_PROPTYPE,
    SERVICE_PARTNER_DATA_PROPTYPE,
    VALIDATION_DATA_PROPTYPE
} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerFormLogic";
import {SobrCustomCheckbox} from "components/SobrForm/Inputs/SobrCustomCheckbox";

export class ServicePartnerFormComponent extends React.Component {

    static propTypes = {
        loading: PropTypes.bool,
        servicePartnerData: SERVICE_PARTNER_DATA_PROPTYPE,
        baseData: BASE_DATA_PROPTYPE,
        validationData: VALIDATION_DATA_PROPTYPE,
        fetchBaseData: PropTypes.func.isRequired,
        onDataChange: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props, context);

        this.fetchBaseDataIfNecessary = this.fetchBaseDataIfNecessary.bind(this);
        this.updateServicePartnerData = this.updateServicePartnerData.bind(this);
    }

    componentDidMount() {
        this.fetchBaseDataIfNecessary();
    }

    fetchBaseDataIfNecessary() {
        const baseData = this.props.baseData;
        const baseDataMissing = R.any(R.isEmpty)(R.values(baseData));
        if (baseDataMissing) {
            this.props.fetchBaseData();
        }
    }

    updateServicePartnerData(fun) {
        const newSp = fun(this.props.servicePartnerData);
        this.props.onDataChange(newSp);
    }

    render() {
        const inputHandler = fun => R.compose(this.updateServicePartnerData, fun);
        const spData = this.props.servicePartnerData;
        const getError = prop => R.path([prop, 'errorMsg'], this.props.validationData);
        return <div className="service-partner-form">
            <LoadingOverlay loading={this.props.loading}>
                <div>
                    {spData.importedByAbacus && <div className="row">
                        <div className="col-12">
                            <h4>Abacus-Verwaltet</h4>
                            Änderungen bitte in Abacus vornehmen
                        </div>
                    </div>}

                    <div className="row">

                        {/* Leistungspartner */}
                        <div className="col-12 col-sm-6">
                            <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineLP">
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.name"
                                             error={getError('name')}
                                >
                                    <SobrTextInput value={spData.name}
                                                   onValueChange={inputHandler(R.assoc('name'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.companyName"
                                             error={getError('companyName')}
                                >
                                    <SobrTextInput value={spData.companyName}
                                                   onValueChange={inputHandler(R.assoc('companyName'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.nameAffix"
                                             error={getError('nameAffix')}
                                >
                                    <SobrTextInput value={spData.nameAffix}
                                                   onValueChange={inputHandler(R.assoc('nameAffix'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.type"
                                             error={getError('typeId')}
                                >
                                    <SobrSingleSelect
                                        isClearable={true}
                                        options={this.props.baseData.partnerTypes}
                                        onChange={inputHandler(R.assoc('typeId'))}
                                        value={spData.typeId}
                                        readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.housingID"
                                             error={getError('uid')}
                                >
                                    <SobrTextInput value={spData.uid}
                                                   onValueChange={inputHandler(R.assoc('uid'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.addressStreet"
                                             error={getError('addressStreet')}
                                >
                                    <SobrTextInput value={spData.addressStreet}
                                                   onValueChange={inputHandler(R.assoc('addressStreet'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.addressZipCity"
                                             error={getError('addressZip') || getError('addressCity')}
                                >
                                    <div className="row">
                                        <div className="col-5">
                                            <SobrTextInput value={spData.addressZip}
                                                           onValueChange={inputHandler(R.assoc('addressZip'))}
                                                           readOnly={spData.importedByAbacus}
                                            />
                                        </div>
                                        <div className="col-7">
                                            <SobrTextInput value={spData.addressCity}
                                                           onValueChange={inputHandler(R.assoc('addressCity'))}
                                                           readOnly={spData.importedByAbacus}
                                            />
                                        </div>
                                    </div>
                                </SobrFormRow>
                            </SobrFormGroup>
                        </div>

                        {/* Kontaktperson */}
                        <div className="col-12 col-sm-6">
                            <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineContact">
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.group"
                                             error={getError('partnerGroupId')}
                                >
                                    <SobrSingleSelect
                                        isClearable={true}
                                        options={this.props.baseData.partnerGroups}
                                        onChange={inputHandler(R.assoc('partnerGroupId'))}
                                        value={spData.partnerGroupId}
                                        readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.email"
                                             error={getError('primaryContactEmail')}
                                >
                                    <SobrTextInput value={spData.primaryContactEmail}
                                                   onValueChange={inputHandler(R.assoc('primaryContactEmail'))}
                                    />
                                </SobrFormRow>
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.phoneNumber"
                                             error={getError('primaryContactPhone')}
                                >
                                    <SobrTextInput value={spData.primaryContactPhone}
                                                   onValueChange={inputHandler(R.assoc('primaryContactPhone'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.mobile"
                                             error={getError('primaryContactMobile')}
                                >
                                    <SobrTextInput value={spData.primaryContactMobile}
                                                   onValueChange={inputHandler(R.assoc('primaryContactMobile'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>
                            </SobrFormGroup>
                        </div>


                        {/* Bankdaten */}
                        <div className="col-12 col-sm-6">
                            <SobrFormGroup title="bonfire.containers.administration.ServicePartner.edit.headlineBank">
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.bankAccountHolder"
                                             error={getError('bankAccountHolder')}
                                >
                                    <SobrTextInput value={spData.bankAccountHolder}
                                                   onValueChange={inputHandler(R.assoc('bankAccountHolder'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.bankIban"
                                             error={getError('bankIban')}
                                >
                                    <SobrTextInput value={spData.bankIban}
                                                   onValueChange={inputHandler(R.assoc('bankIban'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.bankBic"
                                             error={getError('bankBic')}
                                >
                                    <SobrTextInput value={spData.bankBic}
                                                   onValueChange={inputHandler(R.assoc('bankBic'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>
                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.bankName"
                                             error={getError('bankName')}
                                >
                                    <SobrTextInput value={spData.bankName}
                                                   onValueChange={inputHandler(R.assoc('bankName'))}
                                                   readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>
                            </SobrFormGroup>
                        </div>

                    </div>

                    <div className="row">
                        <div className="col-12">
                            <h4>Schnittstellendaten</h4>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-12 col-sm-6">
                            <SobrFormGroup>

                                <SobrFormRow
                                    label="bonfire.containers.administration.ServicePartner.edit.partnerId"
                                    error={getError('partnerId')}
                                >
                                    <SobrTextInput value={spData.partnerId}
                                                   onValueChange={inputHandler(R.assoc('partnerId'))}
                                    />
                                </SobrFormRow>

                                <SobrFormRow
                                    label="bonfire.containers.administration.ServicePartner.edit.pmsIntegrationActive"
                                    error={getError('pmsIntegrationActive')}
                                >
                                    <SobrCustomCheckbox
                                        checked={spData.pmsIntegrationActive}
                                        onChange={inputHandler(R.assoc('pmsIntegrationActive'))}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.provider"
                                             error={getError('pmsProviderId')}
                                >
                                    <SobrSingleSelect
                                        isClearable={false}
                                        options={this.props.baseData.pmsProviders}
                                        onChange={inputHandler(R.assoc('pmsProviderId'))}
                                        value={spData.pmsProviderId}
                                        readOnly={spData.importedByAbacus}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.pmsNo"
                                             error={getError('pmsNo')}
                                >
                                    <SobrTextInput value={spData.pmsNo}
                                                   onValueChange={inputHandler(R.assoc('pmsNo'))}
                                    />
                                </SobrFormRow>

                                <SobrFormRow label="bonfire.containers.administration.ServicePartner.edit.sendToCrm"
                                             error={getError('sendToCrm')}
                                >
                                    <SobrCustomCheckbox
                                        checked={spData.sendToCrm}
                                        onChange={inputHandler(R.assoc('sendToCrm'))}
                                    />
                                </SobrFormRow>

                            </SobrFormGroup>
                        </div>
                    </div>
                </div>
            </LoadingOverlay>
        </div>
    }

}

export const ServicePartnerForm = connect(
    state => ({
        loading: state.getIn(['zterminal', 'servicePartner', 'form', 'loading']),
        baseData: ServicePartnerFormAccessors.getServicePartnerFormBaseData(state)
    }),
    dispatch => ({
        fetchBaseData: () => dispatch(ServicePartnerFormActions.fetchBaseData())
    })
)(ServicePartnerFormComponent);
