import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import * as R from "ramda";

export default class SobrDropdown extends React.Component {

    static propTypes = {
        label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
        items: PropTypes.arrayOf(PropTypes.shape({
            key: PropTypes.string.isRequired,
            label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired
        })).isRequired,
        onSelect: PropTypes.func,
        id: PropTypes.string,
        buttonClassName: PropTypes.string,
        buttonOnClick: PropTypes.func,
    };

    constructor(props) {
        super(props);
        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(key) {
        if (R.is(Function, this.props.onSelect)) {
            this.props.onSelect(key);
        }
      
    }

    get buttonClassNames() {
        const classNames = R.is(String, this.props.buttonClassName) ?
            this.props.buttonClassName :
            "btn btn-light";
        return classNames + " dropdown-toggle";
    }

    render() {
        const restProps = R.omit(['label', 'items', 'onSelect', 'id', 'buttonClassName', 'buttonOnClick'], this.props);
        return <div className="sobr-dropdown" {...restProps}>
            <div className="dropdown">
                <button
                    className={this.buttonClassNames}
                    type="button"
                    id={this.props.buttonId}
                    data-toggle="dropdown"
                    onClick={this.props.buttonOnClick}
                >
                    {this.props.label}
                </button>
                <div className="dropdown-menu">
                    {this.props.items.map(item =>
                        <button
                            key={item.key}
                            id={`${this.props.optionIdPrefix}${item.key}`}
                            className="dropdown-item"
                            onClick={() => this.onSelect(item.key)}
                        >
                            {item.label}
                        </button>
                    )}
                </div>
            </div>
        </div>
    }
}
