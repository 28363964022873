import React, {ReactElement, ReactNode, useState} from 'react';
import {usePopper} from 'react-popper';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfo} from "@fortawesome/free-solid-svg-icons";
import './SobrInfoButton.scss';

export interface SobrInfoButtonProps {
    children: ReactNode
}

export function SobrInfoButton(props: SobrInfoButtonProps): ReactElement {
    const [referenceElement, setReferenceElement] = useState<HTMLButtonElement | null>(null);
    const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(null);
    const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
    const {styles, attributes} = usePopper(referenceElement, popperElement, {
        strategy: 'fixed',
        modifiers: [{name: 'arrow', options: {element: arrowElement}}]
    });
    const [open, setOpen] = useState(false);

    return <div className="sobr-info-button">
        <button
            className="btn btn-default sobr-info-button-button"
            type="button"
            ref={setReferenceElement}
            onClick={(e) => {
                e.stopPropagation();
                setOpen(!open);
                return false;
            }}>
            <FontAwesomeIcon icon={faInfo}/>
        </button>
        {open && (
            <div className="sobr-info-button-popper" ref={setPopperElement} style={styles.popper} {...attributes.popper}>
                <div className="sobr-info-button-popper-content">
                    {props.children}
                </div>
                <div ref={setArrowElement} style={styles.arrow}/>
            </div>
        )}
    </div>
}
