import React from 'react';

export const GraphNavigation = (props: any) => {

    return (
        <React.Fragment>
            <div className="graph-navigation-container">
                <div className="graph-navigation-buttons-container">
                    <button className={`navigation-button ${props.currentView === 0 ? 'active' : ''}`} onClick={() => props.setCurrentView(0)}>Auslastung</button>
                    <button className={`navigation-button ${props.currentView === 1 ? 'active' : ''}`} onClick={() => props.setCurrentView(1)}>Logiernächte</button>
                    <button className={`navigation-button ${props.currentView === 2 ? 'active' : ''}`} onClick={() => props.setCurrentView(2)}>Aufenthaltsdauer</button>
                </div>

                {props.currentView === 0 &&
                    <div className="graph-navigation-graph-button-container aufenthaltsdauer">
                        <button className={`graph-navigation-buttons ${props.currentSubView === 0 ? 'active' : ''}`} onClick={() => props.setCurrentSubView(0)}>Zeitraum</button>
                        <button className={`graph-navigation-buttons ${props.currentSubView === 1 ? 'active' : ''}`} onClick={() => props.setCurrentSubView(1)}>% Veränderung</button>
                    </div>
                }

                {props.currentView === 1 &&
                    <div className="graph-navigation-graph-button-container">
                        <button className={`graph-navigation-buttons ${props.currentSubView === 0 ? 'active' : ''}`} onClick={() => props.setCurrentSubView(0)}>Zeitraum</button>
                        <button className={`graph-navigation-buttons ${props.currentSubView === 1 ? 'active' : ''}`} onClick={() => props.setCurrentSubView(1)}>Akkumuliert</button>
                    </div>
                }

                {props.currentView === 2 &&
                    <div className="graph-navigation-graph-button-container aufenthaltsdauer">
                        <button className={`graph-navigation-buttons ${props.currentSubView === 0 ? 'active' : ''}`} onClick={() => props.setCurrentSubView(0)}>Zeitraum</button>
                        <button className={`graph-navigation-buttons ${props.currentSubView === 1 ? 'active' : ''}`} onClick={() => props.setCurrentSubView(1)}>% Veränderung</button>
                    </div>
                }

            </div>


        </React.Fragment>
    )
}