import React, {useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

const SobrModal = (props) => {
    const modalSizes = useRef(['modal-sm', 'modal-lg', 'modal-xl']);
    const onClose = props.onClose;

    useEffect(() => {
        function handleClose(e) {
            const target = e.target;
            const classList = target.classList;

            if (classList.contains('sobr-modal') || classList.contains('close-modal') || classList.contains('btn-secondary')) {
                if (onClose) {
                    onClose();
                }
            }
        }

        const modal = document.getElementsByClassName('sobr-modal')[0];
        if (modal) {
            modal.addEventListener('click', handleClose);
        }

        return () => {
            if (modal) {
                modal.removeEventListener('click', handleClose);
            }
        }
    }, [onClose]);

    function getModalClass() {
        let modalClass = 'modal';
        if (props.modalSizeClass) {
            if (modalSizes.current.indexOf(props.modalSizeClass) >= 0) {
                modalClass = props.modalSizeClass;
            }
        }
        return modalClass;
    }

    return (
        <div className={"sobr-modal fade " + getModalClass()} tabIndex="-1" id={props.id}>
            <div className="modal-dialog">
                <div className="modal-content">
                    {
                        props.withHeader &&
                        <div className="modal-header">
                            <h5 className="modal-title">{props.modalTitle}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span className={'close-modal'} aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    }

                    <div className="modal-body">
                        { props.children }
                    </div>

                    {
                        props.withFooter &&
                        <div className="modal-footer">
                            {
                                props.withCloseButton &&
                                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            }
                            {
                                props.withPrimaryButton &&
                                <button type="button" className="btn btn-primary" onClick={props.primaryButtonFunc}>{props.primaryButtonText}</button>
                            }
                        </div>
                    }
                </div>
            </div>
        </div>

    );
};

export default SobrModal;

SobrModal.propTypes = {
    withHeader: PropTypes.bool,
    modalTitle: PropTypes.string,
    withFooter: PropTypes.bool,
    withPrimaryButton: PropTypes.bool,
    withCloseButton: PropTypes.bool,
    primaryButtonText: PropTypes.string,
    primaryButtonFunc: PropTypes.func,
    id: PropTypes.string,
    modalSizeClass: PropTypes.string,
    onClose: PropTypes.func
};

SobrModal.defaultProps = {
    withHeader: true,
    withFooter: true,
    withPrimaryButton: true,
    withCloseButton: true,
    id: 'sobr-modal'
};
