import React from 'react';
import PropTypes from 'prop-types';
import $ from "jquery";
import f from "lib/util";

export default class SobrPopup extends React.Component {

    static propTypes = {
        show: PropTypes.bool.isRequired,
        title: PropTypes.string,
        onClose: PropTypes.func,
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
        this.setRef = elt => {
            this.ref = elt;
        };
        this.handleClose = this.handleClose.bind(this);
    }

    componentDidMount() {
        const $modal = $(this.ref);
        $modal.modal({
            'show': this.props.show
        });
        $modal.on('hidden.bs.modal', e => {
            f.callIfExists(this.props.onClose);
        });
    }

    componentDidUpdate(prevProps) {
        // only do stuff on transitions
        if (this.props.show !== prevProps.show) {
            $(this.ref).modal(this.props.show ? 'show' : 'hide');
        }
    }

    handleClose() {
        f.callIfExists(this.props.onClose);
    }

    render() {
        return <div ref={this.setRef} className="modal fade">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">{this.props.title || ''}</h5>
                        <button type="button" className="close" data-dismiss="modal">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        {this.props.children}
                    </div>
                </div>
            </div>
        </div>
    }

}
