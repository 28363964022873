import React from 'react';
import {Route, Switch} from "react-router-dom";
import UserListPage from "bonfire/ZTerminal/Users/UserList/UserListPage";
import UserCreatePage from "bonfire/ZTerminal/Users/UserCreate/UserCreatePage";
import UserEditPage from "bonfire/ZTerminal/Users/UserEdit/UserEditPage";

export default function usersSection() {
    return <Switch>
        <Route exact={true} path="/zterminal/user" component={UserListPage} />
        <Route exact={true} path="/zterminal/user/create" component={UserCreatePage} />
        <Route exact={true} path="/zterminal/user/edit/:id" component={UserEditPage} />
    </Switch>
}
