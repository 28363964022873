import React, {ReactElement} from 'react';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {connect, useDispatch, useSelector} from "react-redux";
import Moment from 'moment';
import 'moment/locale/de';
import RegistrationFormSearchFilter from "../RegistrationFormSearchFilter/RegistrationFormSearchFilter";
import {RegistrationFormListActions} from "./registrationFormListSlice";
import {Actions as FilterActions} from "../RegistrationFormSearchFilter/RegistrationFormSearchFilterSlice";
import {WithBreadcrumb} from "../../breadcrumb/WithBreadcrumb";
import {OnServicePartnerChange} from "components/zterminal/OnServicePartnerChange/OnServicePartnerChange";
import {UserTypeEnum} from "services/users/UserService";
import PoliceRegistrationFormSearchFilter from "../RegistrationFormSearchFilter/PoliceRegistrationFormSearchFilter";
import {AppDispatch} from "App";
import {RegistrationFormList} from "bonfire/ZTerminal/RegistrationForms/RegistrationFormList/RegistrationFormList";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";

const ListRegistrationFormSearchFilter = connect(
    null,
    (dispatch: AppDispatch) => ({
        onSearch: () => {
            dispatch(RegistrationFormListActions.fetchListIfNecessary())
        }
    })
)(RegistrationFormSearchFilter);

const PoliceListRegistrationFormSearchFilter = connect(
    null,
    (dispatch: AppDispatch) => ({
        onSearch: () => {
            dispatch(RegistrationFormListActions.fetchListIfNecessary())
        }
    })
)(PoliceRegistrationFormSearchFilter);

export interface RegistrationFormListPageProps {
}

export function RegistrationFormListPage(props: RegistrationFormListPageProps): ReactElement {
    // @ts-ignore
    const userType = useSelector(SessionSelectors.userType);
    const dispatch = useDispatch();
    const onServicePartnerChange = () => {
        dispatch(RegistrationFormListActions.invalidate());
        dispatch(RegistrationFormListActions.fetchListIfNecessary());
    }

    const isPolice = userType === UserTypeEnum.POLICE;
    const isServicePartner = userType === UserTypeEnum.SERVICE_PARTNER;
    const breadcrumbPath = [{
        id: 'bonfire.components.AdministrationSidebar.registrationForm',
        defaultMessage: 'Meldescheine'
    }];

    return <div id="registration-form-content-list" className="zterminal-content-box">
        <WithBreadcrumb icon={'registrationForm'} path={breadcrumbPath}/>
        <OnServicePartnerChange onServicePartnerChange={onServicePartnerChange}/>
        <div className="zterminal-content-box-header">
            <div className="px-4 py-3 border-bottom border-light">
                {!isPolice ?
                    <ListRegistrationFormSearchFilter/> :
                    <PoliceListRegistrationFormSearchFilter/>
                }
            </div>
        </div>
        <div className="zterminal-content-box-body">
            <RegistrationFormList/>
        </div>

        {isServicePartner &&
        <div className="zterminal-content-box-footer">
            <div className="container-fluid px-4 py-2">
                <div className="row">
                    <div className="col-12">
                        <Link to="/zterminal/registrationform/tax-overview" className="btn btn-secondary btn-160 mr-3">
                            <FormattedMessage id="bonfire.containers.administration.RegistrationForm.overviewButton"
                                              defaultMessage="Übersicht"/>
                        </Link>
                        <Link to="/zterminal/registrationform/create" className="btn btn-primary btn-160">
                            <FormattedMessage id="bonfire.containers.administration.RegistrationForm.createButton"
                                              defaultMessage="Neu"
                            />
                        </Link>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>;
}
