import { style } from 'glamor';
import Logo from '../../images/zermatt_logo.png';

export const iconStyle = {
    color: '#524440',
    fontSize: '4em',
};

export const iconCompleteStyle = {
  ccolor: '#524440',
  fontSize: '4em',
};

export const containerStyle = {
  alignSelf: 'flex-start',
  marginTop: '1.0vh',
  marginRight: '1vw',
  display: 'flex',
  color: '#4f5861',
  alignItems: 'center',
};

export const iconImageStyle = {
  fontSize: '1.4em',
  color: 'white',
};

export const titleStyle = {
  color: '#524440'
};
export const titleCompleteStyle = {
    color: '#524440',
    fontWeight: 'inherit',
};

export const angleRightStyle = {
    color: '#524440'
};

export const modalContentStyle = {
    "borderRadius": "0px",
    "backgroundColor": "#ffffff",
    "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
    "width": "23vw",
    "height": "20vh",
    "minWidth": "350px",
    "minHeight": "260px",
    fontFamily: 'PFCentroSansPro-Regular',
    fontSize: '16px'
};

export const modalDialogLoadingStyle = {
    "width": "100%",
    "height": "90%"
};

export const modalContentLoadingStyle = {
    "borderRadius": "inherit",
    "backgroundColor": "transparent",
    "boxShadow": "none",
    "width": "100%",
    "height": "100%",
    "minWidth": "350px",
    "minHeight": "260px",
    "border": "none"
};

export const modalContentPMSStyle = {
    "borderRadius": "0px",
    "backgroundColor": "#ffffff",
    "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
    "width": "23vw",
    "height": "auto",
    "minWidth": "650px",
    "minHeight": "400px"
};

export const modalContentOnBoardingStyle = {
    "borderRadius": "0px",
    "backgroundColor": "#ffffff",
    "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
    width: "700px",
    height: "500px",
};

export const modalContentExcStyle = {
    "borderRadius": "0px",
    "backgroundColor": "rgb(255, 255, 255)",
    "boxShadow": "rgba(0, 0, 0, 0.5) 0px 2px 8px 0px",
    "width": "40vw",
    "height": "45vh",
    "minWidth": "650px",
    "minHeight": "350px"
};

export const modalContentGroupBookingStyle = {
    "borderRadius": "0px",
    "backgroundColor": "#ffffff",
    "boxShadow": "0 2px 8px 0 rgba(0, 0, 0, 0.5)",
    "width": "23vw",
    "height": "33vh",
    "minWidth": "400px",
    "minHeight": "300px"
};

export const modalBodyStyle = {
    "display": "flex",
    "flexDirection": "column",
    "justifyContent": "space-around",
    "alignItems": "center"

};

export const modalButtonAreaStyle = {
    "width": "100%",
    "display": "flex",
    "justifyContent": "space-between",
};

export const modalButtonAreaOnBoardingStyle = {
    "width": "90%",
    "display": "flex",
    "justifyContent": "center",
    marginTop: "25px",
};

export const modalButtonExcAreaStyle = {
    "width": "90%",
    "display": "flex",
    "justifyContent": "flex-start",
};

export const groupAddHeaderStyle = {
    "display": "flex",
    "flexDirection": "column",
    "alignSelf": "flex-start",
    "borderBottom": "1px solid #4da1ff",
    "width": "21.6vw",
};


export const groupElementContainerStyle = {
    "display": "flex",
    "width": "21vw",
    "borderBottom": "1px solid #4da1ff",
    "paddingBottom": "0.5vh",
    "justifyContent": "space-around"
};

export const groupElementInputStyle = {
    "height": "5vh",
    "width": "60px",
    "fontSize": "1.2em",
    "fontWeight": "inherit",
    textAlign: "center",
};

export const groupElementTitle = {
    "fontSize": "1.7em",
    minWidth: "145px",
};

export const buttonInputContainer = {
    "display": "flex",
    "justifyContent": "space-around",
    "width": "7vw",
    "minWidth": "160px",
    alignItems: "center",
};

export const tableContainer = {
    "maxHeight": "25vh",
    "height": "25vh",
    "width": "100%",
    "overflow": "scroll"
};

export const normalInputStyle = {
    width: '20%',
    marginRight: "1vw",
};

export const datePickerInputStyle = {
    width: '60%',
};

export const buttonContainer2 = {
    "minWidth": "245px",
        "display": "flex",
        "justifyContent": "space-between",
};


export const deleteButtonStyle = {
    button: style({
        "backgroundColor": "rgb(244, 38, 38)",
        "boxShadow": "rgba(0, 0, 0, 0.1) 0px 2px 10px 0px",
        "borderRadius": "0",
        "color": "white",
        "width": "6vw",
        "height": "4vh",
        "minWidth": "100px",
        ":hover": {
            "backgroundColor": "#ff5252",
        },
        ":active": {
            "backgroundColor": "#e62323",
        },
    })
};

export const completeButtonStyle = {
    button: style({
        "backgroundColor": "#4da1ff",
        "boxShadow": "rgba(0, 0, 0, 0.1) 0px 2px 10px 0px",
        "color": "white",
        "width": "6vw",
        "height": "4vh",
        "minWidth": "100px",
        ":hover": {
            "backgroundColor": "#4dc3ff",
        },
        ":active": {
            "backgroundColor": "#2788f4",
        },
    })
};
export const completeButtonOBStyle = {
    button: style({
        "backgroundColor": "#4da1ff",
        "boxShadow": "rgba(0, 0, 0, 0.1) 0px 2px 10px 0px",
        "color": "white",
        "width": "185px",
        "height": "5vh",
        "minWidth": "100px",
        ":hover": {
            "backgroundColor": "#4dc3ff",
        },
        ":active": {
            "backgroundColor": "#2788f4",
        },
    })
};
export const addDeleteButton = {
    button: style({
        "backgroundColor": "rgb(77, 161, 255)",
        "boxShadow": "rgba(0, 0, 0, 0.1) 0px 2px 10px 0px",
        "color": "white",
        "width": "40px",
        "height": "40px",
        "minWidth": "40px",
        "padding": "0",
        ":hover": {
            "backgroundColor": "#4dc3ff",
        },
        ":active": {
            "backgroundColor": "#2788f4",
        },

    })
};

export const cancelButtonStyle = {
    button: style({
        "boxShadow": "rgba(0, 0, 0, 0.1) 0px 2px 10px 0px",
        "color": "grey",
        "width": "6vw",
        "height": "4vh",
        "border": "solid 1px grey",
        "minWidth": "100px",
        ":hover": {
            backgroundColor: "#f5f5f5",
        },
        ":active": {
            backgroundColor: "#ffffff !important",
        },
    })
};

export const logo = {
    backgroundImage: `url(${Logo})`,
    "height": "135px",
    "marginTop": "-3vh",
    "backgroundPosition": "center center",
    "width": "185px",
    "backgroundSize": "contain",
};

export const onBoardingHeadline = {
    "color": "#4da1ff",
    "fontSize": "1.6em",
};

export const onBoardingDivider = {
    "width": "80%",
    "borderBottom": "1px solid #4da1ff",
};

export const onBoardingText = {
    "width": "65%",
    "textAlign": "center",
    "color": "#4F5861",
    "fontSize": "1.2em",
};

export const onBoardingContactText = {
    color: "#4da1ff",
};

export const onBoardingCircleActive = {
    "color": "#4da1ff",
    "textAlign": "center",
    "marginLeft": "1vw"
};

export const onBoardingCircle = {
    "textAlign": "center",
    "color": "#979797",
    "marginLeft": "1vw"
};

export const onBoardingCircleContainer = {
    "width": "10%",
    "display": "flex",
    marginTop: "25px",
};

export const onBoardingFontSize = {
    fontSize: '4em',
};

export const onBoardingAttentionStyle = {
    "width": "15px",
    "height": "15px",
    "textAlign": "center",
    "borderRadius": "100px",
    "background-color": "#f42626",
};

export const onBoardingAttentionContainer = {
    "display": "flex",
};