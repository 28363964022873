import React, {ReactElement, useState} from 'react';
import 'react-day-picker/lib/style.css';
import 'moment/locale/de';
import {BonfireIcon} from "components/zterminal/BonfireIcon/BonfireIcon";
import DayPicker from "react-day-picker";
import {MONTHS} from "utils/months";
import SobrOnClickOutside from "../SobrOnClickOutside/SobrOnClickOutside";
import SobrDateInput from "./SobrDateInput";
import {YearMonthForm} from "./YearMonthForm";
import moment, {Moment} from "moment";

const WEEKDAYS_SHORT = [
    'So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa',
];

const WEEKDAYS_LONG = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
];

const FIRST_DAY_OF_WEEK = 0;

const LABELS = {
    nextMonth: 'next month', previousMonth: 'previousMonth'
};
const DEFAULT_FORMAT = 'DD.MM.YYYY';

export interface SobrDatePickerProps {
    moment: Moment | null | undefined
    onSetMoment: (moment: Moment) => void
    showMonthPicker: boolean
    format?: string
    placeholder?: string
    disabled?: boolean
    language?: keyof typeof MONTHS;
}

export function SobrDatePickerSelfService(props: SobrDatePickerProps): ReactElement {
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [monthToDisplay, setMonthToDisplay] = useState<Date | null>(null);

    const toggleDatePicker = () => setDatePickerOpen(!datePickerOpen);
    const closeDatePicker = () => setDatePickerOpen(false);
    const setMoment = (moment: Moment) => {
        props.onSetMoment(moment);
        closeDatePicker();
    }
    const setDate = (date: Date) => setMoment(moment.utc(date));

    const dateFormat = props.format ?? DEFAULT_FORMAT;
    const date = props.moment?.toDate();
    const language: keyof typeof MONTHS = props.language ?? 'DE';
    const month = monthToDisplay ?? date;
    const disabled = props.disabled ?? false;

    return <div className="sobr-date-picker2">
        <div className="input-group">
            <SobrDateInput
                selfService={true}
                moment={props.moment}
                format={dateFormat}
                onSetMoment={setMoment}
                placeholder={props.placeholder}
                disabled={disabled}
            />
            <div className="input-group-append">
                <button className="btn btn-outline-secondary calendar-btn"
                        type="button"
                        onClick={toggleDatePicker}
                        disabled={disabled}
                >
                    <BonfireIcon icon={'calendar-small'}/>
                </button>
            </div>
        </div>
        {datePickerOpen && <SobrOnClickOutside onClickOutside={closeDatePicker}>
            <DayPicker
                locale="de"
                selectedDays={date}
                onDayClick={setDate}
                months={MONTHS[language]}
                month={month}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={WEEKDAYS_SHORT}
                firstDayOfWeek={FIRST_DAY_OF_WEEK}
                labels={LABELS}
                {...props.showMonthPicker ? {
                    captionElement: ({date}) => (
                        <YearMonthForm
                            date={date}
                            onChange={setMonthToDisplay}
                            locale={language}
                        />
                    )
                } : {}}
            />
        </SobrOnClickOutside>}
    </div>

}
