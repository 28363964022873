/* eslint-disable import/no-unresolved */
import Logo from '../../images/zermatt_logo.png';

export const containerStyle = {
  display: 'flex',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
};

export const loginPanel = {
    "borderRadius": "2px",
    "backgroundColor": "rgb(255, 255, 255)",
    "boxShadow": "rgba(0, 0, 0, 0.25) 0px 0px 10px 0px",
    "minHeight": "450px",
    "minWidth": "400px",
    "display": "flex",
    "justifyContent": "center",
    "width": "19vw",
    "height": "36vh"
};

export const logo = {
    backgroundImage: `url(${Logo})`,
    "height": "135px",
    "marginTop": "-180px",
    "backgroundPosition": "center center",
    "width": "185px",
    "backgroundSize": "contain",
};

export const loginInnerInnerPanel = {
    "width": "25vw",
    "display": "flex",
    "flexDirection": "column",
    "alignItems": "center",
    "justifyContent": "center"
};

export const loginInnerPanel = {
    "width": "100%",
    "display": "flex",
    "alignItems": "center",
    "justifyContent": "center",
    "flexDirection": "column",
};

export const labelStyle = {
  fontSize: '0.8em',
    width: "60%",
};

export const colSmStyle = {
  marginTop: '50px',
  marginRight: 'auto',
  marginLeft: 'auto',
};

export const cardStyle = {
  width: '18rem',
  marginLeft: 'auto',
  marginRight: 'auto',
  position: 'absolute',
  top: '35%',
  left: '0',
  right: '0',
  textAlign: 'center',
};

export const informationsInputStyle = {
    padding: '0',
    color: '#7E7979',
    fontSize: '0.9em',
    backgroundColor: '#ddeeff',
    border: 'solid 1px #d0e5f8',
    paddingLeft: '0.4rem',
    height: '32px',
    width: '289px',
};

export const title = {
  color: '#f44a49',
  fontSize: '2em',
};

export const outContainer = {
  /*backgroundImage: `url(${Background})`,
  backgroundRepeat: 'no-repeat',
  backgroundPosition: 'center center',*/
  minHeight: '450px',
  backgroundSize: 'cover',
};

export const loginInputContainerStyle = {
};

export const buttonContainer = {
    "display": "flex",
    "justifyContent": "space-between",
    "flexDirection": "column",
    "alignItems": "center",
    "height": "9vh"
};
