import AuthorizationService from "services/authorization/AuthorizationService";
import ServicePartnerService from "services/servicepartners/ServicePartnerService";
import UserService, {UserTypeEnum} from "services/users/UserService";
import {ServicePartner, ServicePartnerType} from "bonfire/ZTerminal/ServicePartner/ServicePartner";
import {User, UserRole, UserType} from "bonfire/ZTerminal/Users/UserModel";
import {AppDispatch, RootState} from "App";
import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import ServicePartnerBasedataService from "services/servicepartners/ServicePartnerBasedataService";
import { PartnerType } from "../Analytics/components/DropdownAccomodation/AccomodationCategories";


export interface SliceState {
    loggedIn: boolean
    triedRefresh: boolean
    user: User | null
    roles: UserRole[]
    userType: UserType | null
    partnerTypes: PartnerType[],
    servicePartners: ServicePartner[]
    currentServicePartner: ServicePartner | null
    loading: boolean
}

export const initialState: SliceState = {
    loggedIn: false,
    triedRefresh: false,
    user: null,
    roles: [],
    userType: null,
    partnerTypes: [],
    servicePartners: [],
    currentServicePartner: null,
    loading: false
};

export interface Credentials {
    username: string
    password: string
}


const {reducer, actions} = createSlice({
    name: 'session',
    initialState,
    reducers: {
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.loggedIn = action.payload;
        },
        setTriedRefresh: (state, action: PayloadAction<boolean>) => {
            state.triedRefresh = action.payload;
        },
        setUser: (state, action: PayloadAction<User | null>) => {
            state.user = action.payload;
        },
        setRoles: (state, action: PayloadAction<UserRole[]>) => {
            state.roles = action.payload;
        },
        setUserType: (state, action: PayloadAction<UserType>) => {
            state.userType = action.payload;
          
        },
        setServicePartners: (state, action: PayloadAction<ServicePartner[]>) => {
            state.servicePartners = action.payload;
        },
        
        setServicePartnerTypes: (state, action) => {
            state.partnerTypes = action.payload.data;
           
        },
        setCurrentServicePartner: (state, action: PayloadAction<ServicePartner | null>) => {
            state.currentServicePartner = action.payload;
        },
        setLoading: (state, action: PayloadAction<boolean>) => {
            state.loading = action.payload;
        }
    }
});

export const SessionReducer = reducer;

function login(credentials: Credentials) {
    return (dispatch: AppDispatch) => {
        dispatch(actions.setLoading(true));
        return AuthorizationService.login(credentials.username, credentials.password)
            .then(loginData => dispatch(fetchSessionData(loginData)))
            .then(() => {
                dispatch(actions.setLoggedIn(true));
                dispatch(actions.setTriedRefresh(false));
                dispatch(actions.setLoading(false));
            })
            .catch(error => {
                dispatch(clearSessionData());
                dispatch(actions.setLoading(false));
                return Promise.reject(error);
            });
    }
}

function tryRefreshSavedToken() {
    return (dispatch: AppDispatch) => {
        dispatch(actions.setLoading(true));
        return AuthorizationService.refreshToken()
            .then(loginData => dispatch(fetchSessionData(loginData)))
            .then(() => {
                dispatch(actions.setLoggedIn(true));
                dispatch(actions.setTriedRefresh(false));
                dispatch(actions.setLoading(false));
                return {success: true};
            })
            .catch(error => {
                dispatch(actions.setTriedRefresh(true));
                dispatch(clearSessionData());
                dispatch(actions.setLoading(false));
                return {success: false};
            });
    }
}

function logout() {
    return (dispatch: AppDispatch) => {
        return AuthorizationService.logout()
            .then(() => dispatch(clearSessionData()));
    }
}

function fetchSessionData(loginData: { roles: UserRole[] }) {
    return (dispatch: AppDispatch) => {
        dispatch(actions.setRoles(loginData.roles));
        const userType = UserService.determineUserType(loginData.roles) as UserType;
        dispatch(actions.setUserType(userType));

        const fetchServicePartnerData = () => {
            switch (userType) {
                case UserTypeEnum.SERVICE_PARTNER:
                    return dispatch(getServicePartners())
                        .then((servicePartners: ServicePartner[]) => {
                            if (servicePartners.length > 0) {
                                dispatch(actions.setCurrentServicePartner(servicePartners[0]));
                                return servicePartners[0];
                            } else {
                                dispatch(actions.setCurrentServicePartner(null));
                                return {};
                            }
                        });
                default:
                    return Promise.resolve();
            }
        };

        return Promise.all([
            dispatch(getCurrentUser()),
            fetchServicePartnerData()
        ]);

    }
}

function getCurrentUser() {
    return (dispatch: AppDispatch) => {
        return UserService.getCurrentUser()
            .then(user => dispatch(actions.setUser(user)))
            .catch(error => {
                clearSessionData();
                return Promise.reject(error);
            });
    }
}

function getServicePartners() {
    return (dispatch: AppDispatch) => {
        return ServicePartnerService.getAllServicePartners()
            // @ts-ignore
            .then((servicePartners: ServicePartner[]) => {
                dispatch(actions.setServicePartners(servicePartners));
                return servicePartners;
            })
            .catch(error => {
                if (error.status === 403) {
                    dispatch(actions.setServicePartners([]));
                    return [];
                } else {
                    clearSessionData();
                    return Promise.reject(error);
                }
            });
    }
}
export function getServicePartnerTypes() {
 
    return (dispatch: AppDispatch) => {
        return ServicePartnerBasedataService.getPartnerTypes()
            // @ts-ignore
            .then((partnerTypes) => {
                dispatch(actions.setServicePartnerTypes(partnerTypes));
                return partnerTypes;
             
            })
            .catch(error => {
                if (error.status === 403) {
                    dispatch(actions.setServicePartnerTypes([]));
                    console.log("err1")
                    return;
                    
                } else {
                    clearSessionData();
                    console.log("err2")
                    return Promise.reject(error);
                }
            });
    }
}

function clearSessionData() {
    return (dispatch: AppDispatch) => {
        dispatch(actions.setLoggedIn(false));
        dispatch(actions.setUser(null));
        dispatch(actions.setRoles([]));
        dispatch(actions.setServicePartners([]));
        dispatch(actions.setServicePartnerTypes([]));
        dispatch(actions.setCurrentServicePartner(null));
    };
}

export const SessionActions = {
    login,
    tryRefreshSavedToken,
    logout,
    getServicePartnerTypes,
    setCurrentServicePartner: actions.setCurrentServicePartner,
    setUser: actions.setUser
};


function getSessionSate(state: RootState): SliceState {
    return state.getIn(['zterminal', 'session']) as SliceState;
}

export const SessionSelectors = {
    loggedIn: (state: RootState) => getSessionSate(state).loggedIn,
    sessionLoading: (state: RootState) => getSessionSate(state).loading,
    roles: (state: RootState) => getSessionSate(state).roles,
    triedRefresh: (state: RootState) => getSessionSate(state).triedRefresh,
    user: (state: RootState) => getSessionSate(state).user,
    userType: (state: RootState) => getSessionSate(state).userType,
    servicePartners: (state: RootState) => getSessionSate(state).servicePartners,
    partnerTypes: (state: RootState) => getSessionSate(state).partnerTypes,
    currentServicePartner: (state: RootState) => getSessionSate(state).currentServicePartner
};

