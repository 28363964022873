import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {connect} from "react-redux";
import {Actions as LanguageActions} from "bonfire/Language/redux";
import TranslationService from "services/TranslationService/TranslationService";
import SobrDropdown from "components/zterminal/SobrDropdown/SobrDropdown";
import {FormattedMessage} from "react-intl";
import {Actions} from "bonfire/SelfService/redux";
import {Actions as RegistrationActions} from "bonfire/ZTerminal/RegistrationForms/RegistrationForm/redux";
import {Actions as UserFormActions} from "bonfire/ZTerminal/Users/UserForm/redux";

class LanguagePickerComponent extends React.Component {

    static propTypes = {
        currentLanguage: PropTypes.string,
        language: PropTypes.string,
        setLanguage: PropTypes.func,
        readOnly: PropTypes.bool,
        id: PropTypes.string,
    };

    static defaultProps = {
        readOnly: false,
        isSelfService: false
    };

    constructor(props) {
        super(props);
        this.setLanguage = this.setLanguage.bind(this);

        if (props.language && props.language !== props.currentLanguage) {
            this.setLanguage(props.language);
        }
    }

    componentDidUpdate(prevProps) {
        if(this.props.currentLanguage !== prevProps.currentLanguage) {
            this.props.fetchNationalityData();
            this.props.fetchLanguageData();
            this.props.fetchInterestsData(this.props.currentLanguage);
            this.props.fetchInterestsData2();
            this.props.fetchNationalityData2();
            this.props.fetchTaxCategories();
            this.props.fetchTaxExemptReasons();
            this.props.fetchRoleData();
        }
    }

    get currentLanguageTranslationKey() {
        return `bonfire.languages.name.${this.props.currentLanguage}`;
    }

    get availableLanguages() {
        return TranslationService.getLanguages()
            .map(item => ({
                key: item.key,
                label: <FormattedMessage id={item.translation.key} defaultMessage={item.translation.defaultMessage} />
            }));
    }

    setLanguage(languageKey) {
        if (R.is(Function, this.props.setLanguage)) {
            this.props.setLanguage(languageKey);
        }
    }

    render() {
        const {  languagePickerItemIdPrefix } = this.props;
        const restProps = R.omit([ 'currentLanguage', 'setLanguage','id', 'readOnly'], this.props);
      
        return <div className="language-picker">
            {
                this.props.readOnly ? (
                    <span style={{color: 'white'}}>{this.currentLanguageTranslationKey}</span>
                ) : (
                    <SobrDropdown {...restProps}
                    buttonClassName={this.props.isSelfService ? 'language-picker-btn-self-service' : "language-picker-btn"}
                    label={<FormattedMessage id={this.currentLanguageTranslationKey} />}
                    buttonId="language-picker-dropdown-extend"
                    optionIdPrefix="language-picker-option-"
                    items={this.availableLanguages}
                    onSelect={this.setLanguage}
                    itemKeyPrefix={languagePickerItemIdPrefix}
                    />
                )
            }
        </div>
    }

}


export default connect(
    state => ({
        currentLanguage: state.get('language')['currentLanguage'],
        nationalityData: state.getIn(['selfService', 'nationalityData']),
        languageData: state.getIn(['selfService', 'languageData']),
        interestsData: state.getIn(['selfService', 'interestsData']),
    }),

    dispatch => ({
        setLanguage: (language) => dispatch(LanguageActions.setLocale(language)),
        fetchNationalityData: () => dispatch(Actions.fetchNationalityData()),
        fetchInterestsData: () => dispatch(Actions.fetchInterestsData()),
        fetchLanguageData: () => dispatch(Actions.fetchLanguageData()),
        fetchInterestsData2: () => dispatch(RegistrationActions.fetchInterestsData()),
        fetchNationalityData2: () => dispatch(RegistrationActions.fetchNationalityData()),
        fetchTaxCategories: () => dispatch(RegistrationActions.fetchTaxCategorydData()),
        fetchTaxExemptReasons: () => dispatch(RegistrationActions.fetchTaxExemptReasonData()),
        fetchRoleData: () => dispatch(UserFormActions.fetchRoleData())
    })
)(LanguagePickerComponent);
