import React from 'react';
import {withRouter} from "react-router-dom";
import {WithBreadcrumb} from "../../breadcrumb/WithBreadcrumb";
import {RegistrationForm} from "./RegistrationFormComponent";


export class RegistrationFormCreateComponent extends React.Component {

    get breadcrumbPath() {
        return [
            {
                id: 'bonfire.components.AdministrationSidebar.registrationForm',
                defaultMessage: 'Meldescheine',
                url: '/zterminal/registrationform'
            },
            {
                id: 'bonfire.containers.administration.RegistrationForm.createButton',
                defaultMessage: 'Neu'
            }
        ]
    }

    render() {
        return <div id="registration-form-create">
            <WithBreadcrumb icon={'registrationForm'} path={this.breadcrumbPath}/>
            <RegistrationForm create={true}/>
        </div>;
    }
}

export const RegistrationFormCreate = withRouter(RegistrationFormCreateComponent);
