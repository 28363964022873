import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Actions} from "./redux";
import * as R from 'ramda';

export class SelfServiceStep8Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        setNewsletterData: PropTypes.func.isRequired,
        newsletter: PropTypes.any,
        customerData: PropTypes.any,
        setCustomerData: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            newsletterEmail: '',
            housing: false,
            zermatt: false
        };
        this.subscribePossible = true;

        this.checkedChange = this.checkedChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleCancel = this.handleCancel.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if(this.props.registrationForm) {
            let customerData = this.props.customerData;
            let primaryGuest = this.props.registrationForm.guests.find(guest => guest.primary === true);
            let newsletter = this.props.newsletter;

            this.setState({
                newsletterEmail: customerData ? customerData.email : primaryGuest.guest.email,
                housing: newsletter ? newsletter.housing : primaryGuest.guest.newsletterHousing ? primaryGuest.guest.newsletterHousing : false,
                zermatt: newsletter ? newsletter.zermatt : primaryGuest.guest.newsletter ? primaryGuest.guest.newsletter : false
            });
        }
    }

    checkedChange(event) {
        this.setState({
            [event.target.value]: event.target.checked
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit() {

        if(!this.subscribePossible)
            return;

        let newsletter = {housing: this.state.housing, zermatt: this.state.zermatt, email: this.state.newsletterEmail};
        this.props.setNewsletterData(newsletter);

        this.props.history.push(`/self-service/${this.props.registrationForm.id}/step9`);
    }

    handleCancel() {
        let newsletter = {housing: false, zermatt: false, email: ''};
        this.props.setNewsletterData(newsletter);
        let interestsBefore = R.clone(this.props.registrationForm.guests.find(guest => guest.primary === true).guest.interests);
        let customerData = this.props.customerData;

        if(customerData) {
            customerData.interests = interestsBefore;
            this.props.setCustomerData(customerData);
        }

        this.props.history.push(`/self-service/${this.props.registrationForm.id}/step10`);
    }

    render() {
        const id = this.props.registrationForm.id;
        this.subscribePossible = !!(this.state.newsletterEmail && (this.state.zermatt || this.state.housing));

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep1Page.title', defaultMessage: 'Pre-Check-in'}} class="step8">

                <SelfServiceStepLayout id={id} title={{id: 'bonfire.containers.SelfServiceStep8Page.stepTitle', defaultMessage: 'Newsletter-Service'}}/>


                <div className="text">
                    <div className="wrapper self-service">

                        <div className="newsletter-text sar-big">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep8Page.ourNewsletterService'
                                defaultMessage='Jetzt Newsletter abonnieren und informiert bleiben!'
                            />
                        </div>

                        <div className="checkboxes-wrapper">
                            <div className="checkbox">
                                <label className="checkbox-inline checkbox-bootstrap checkbox-lg">
                                    <input type="checkbox"
                                           value="zermatt"
                                           checked={this.state.zermatt}
                                           onChange={this.checkedChange}
                                    />
                                    <span className="checkbox-placeholder"/>
                                </label>
                                <span className="checkbox-label sar-big">
                                    <FormattedMessage
                                        id='bonfire.containers.SelfServiceStep8Page.checkbox.zermatt'
                                        defaultMessage='Newsletter Zermatt - Matterhorn'
                                    />
                                </span>
                            </div>

                            <div className="checkbox">
                                <label className="checkbox-inline checkbox-bootstrap checkbox-lg">
                                    <input type="checkbox"
                                           value="housing"
                                           checked={this.state.housing}
                                           onChange={this.checkedChange}
                                    />
                                    <span className="checkbox-placeholder"/>
                                </label>
                                <span className="checkbox-label sar-big">
                                    <FormattedMessage
                                        id='bonfire.containers.SelfServiceStep8Page.checkbox.housing'
                                        defaultMessage='Unterkunfts-Newsletter'
                                    />
                                </span>
                            </div>
                        </div>


                            <label htmlFor="step8EmailInput" className="b-input-label-self">
                                <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep8Page.emailAdressPlease'
                                    defaultMessage='Bitte geben Sie Ihre persönliche E-Mail-Adresse ein'
                                />
                            </label>
                            <input
                                type="text"
                                className="b-input-self-service"
                                id="step8EmailInput"
                                name="newsletterEmail"
                                value={this.state.newsletterEmail}
                                onChange={this.handleChange}
                            />
                    </div>
                </div>
                <div className="bottom">

                    <div onClick={this.handleSubmit}>
                        <button className="b-button-pre-checkin" disabled={!this.subscribePossible}  id="self-service-step8-confirm-next-yes-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep8Page.buttonYes'
                                defaultMessage='Abonnieren'
                            />
                        </button>
                    </div>

                    <div onClick={this.handleCancel}>
                        <button className="b-button-pre-checkin" id="self-service-step8-confirm-next-no-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep8Page.buttonNo'
                                defaultMessage='Nicht abonnieren'
                            />
                        </button>
                    </div>

                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep8 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        newsletter: state.getIn(['selfService', 'data', 'newsletter']),
        customerData: state.getIn(['selfService', 'data', 'customerData']),
    }),
    (dispatch) => ({
        setNewsletterData: newsletter => dispatch(Actions.setNewsletterData(newsletter)),
        setCustomerData: customerData => dispatch(Actions.setCustomerData(customerData))
    })
)(SelfServiceStep8Component);

