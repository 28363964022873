import React, {  useState } from 'react';
import {ReactComponent as CalendarIcon} from 'images/icons/Calendar.svg';
import {ReactComponent as AnalyticsIcon} from 'images/icons/analytics_header_icon.svg';
import { DateRangePicker } from "./DateRangePicker/DateRangePicker";
import {useSelector, useDispatch} from "react-redux";
import {AnalyticsSelectors, AnalyticsActions, setSelectedDropdownValues, setSelectedDropdownCompareValues, analyticsSlice} from "../AnalyticsSlice";
import moment from "moment";
import ClickAwayListener from 'react-click-away-listener';
import { AccomodationCategoriesDropdown } from './DropdownAccomodation/AccomodationCategoriesDropdown';

interface AnalyticsHeaderProps {
    onDropdownChange: (selectedKeys:any, selectedRadio:string) => void;
 
  }
  interface DropdownValues {
    selectedKeys: any;
    selectedRadio: string;
  }
export const AnalyticsHeader =  () => {
    const [timeFrameStartRemember, setTimeFrameStartRemember] = useState(new Date());
    const [timeFrameEndRemember, setTimeFrameEndRemember] = useState(new Date());
    const [compareFrameStartRemember, setCompareFrameStartRemember] = useState(new Date());
    const [compareFrameEndRemember, setCompareFrameEndRemember] = useState(new Date());
    const dispatch = useDispatch();
    const [compareDropdownValues, setCompareDropdownValues] = useState<DropdownValues>({ 
        selectedKeys: [],
        selectedRadio: "All"
      });
    const [dropdownValues, setDropdownValues] = useState<DropdownValues>({ 
        selectedKeys: [],
        selectedRadio: "All"
      });

    const [showTimeRangeFilter, setShowTimeRangeFilter] = useState<boolean>(false);
    const [showCompareFilter, setShowCompareFilter] = useState<boolean>(false);

    const startDateTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endDateTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const startDateCompareFrame = useSelector(AnalyticsSelectors.startDateCompareFrame);
    const endDateCompareFrame = useSelector(AnalyticsSelectors.endDateCompareFrame);

    const formattedStartDateTimeFrame = useSelector(AnalyticsSelectors.formattedStartDateTimeFrame);
    const formattedEndDateTimeFrame = useSelector(AnalyticsSelectors.formattedEndDateTimeFrame);
    const formattedStartDateCompareFrame = useSelector(AnalyticsSelectors.formattedStartDateCompareFrame);
    const formattedEndDateCompareFrame = useSelector(AnalyticsSelectors.formattedEndDateCompareFrame);

    const isTimeframeValid = moment(startDateTimeFrame).isValid() && moment(endDateTimeFrame).isValid();
    const isCompareFrameValid = moment(startDateCompareFrame).isValid() && moment(endDateCompareFrame).isValid();

 
    const handleClickAwayTimeFrame = () => {
        if(showTimeRangeFilter) {
            dispatch(AnalyticsActions.setStartDateTimeFrame(timeFrameStartRemember));
            dispatch(AnalyticsActions.setEndDateTimeFrame(timeFrameEndRemember));
        }
        setShowTimeRangeFilter(false);
    }

    const handleClickAwayCompare = () => {
        if(showCompareFilter) {
            dispatch(AnalyticsActions.setStartDateCompareFrame(compareFrameStartRemember));
            dispatch(AnalyticsActions.setEndDateCompareFrame(compareFrameEndRemember));
        }
        setShowCompareFilter(false);
    }

    const handleDropdownChange = (selectedKeys: any, selectedRadio: any) => {

       setDropdownValues({selectedKeys, selectedRadio});
       dispatch(setSelectedDropdownValues({ selectedKeys, selectedRadio }));
         };
     
    const handleDropdownCompareChange = (selectedKeys: any, selectedRadio: any) => {

        dispatch(setSelectedDropdownCompareValues({ selectedKeys, selectedRadio }));
        setCompareDropdownValues({selectedKeys, selectedRadio});
      };     
    return (
        <React.Fragment>
            <div className="analytics-header">
                <div className="title-container">
                    <div className="title-icon">
                        <AnalyticsIcon />
                    </div>
                    <div className="title">Analytics</div>
                </div>
                <div className='new-filter-container'>
          
                    
                    <div className='titles'>
                   <div className='title-auswahl'>
                    <div className='circle-red'></div>
                            <div className='titles-label'>Auswahl</div>
                        </div>
                        <div className='title-vergleich'>
                        <div className='circle-blue'></div>
                            <div className='titles-label'>Vergleichen mit</div>
                            </div>
                    </div>            
                <div className="filter-container">
                <div className='timespan-filter-container'>
                    <ClickAwayListener onClickAway={handleClickAwayTimeFrame}>                     
                        <div className="timespan-container">
                            <div className="timespan-label">
                                Zeitraum
                            </div>
                            <div className="analytics-filter-dropdown">
                                <div
                                    className={`timespan-input-container ${showTimeRangeFilter ? "bordered-active" : "bordered"}`}
                                    onClick={() => {
                                        setTimeFrameStartRemember(startDateTimeFrame);
                                        setTimeFrameEndRemember(endDateTimeFrame);
                                        setShowTimeRangeFilter(true)
                                    }}
                                >
                                    <div className="timespan-input">
                                        {`${formattedStartDateTimeFrame} - ${formattedEndDateTimeFrame}`}
                                    </div>
                                    <CalendarIcon className="calendar-icon"/>
                                </div>
                                <div className={`content ${showTimeRangeFilter ? 'show' : ''}`}>
                                    <DateRangePicker
                                        startDate={startDateTimeFrame}
                                        setStartDate={AnalyticsActions.setStartDateTimeFrame}
                                        endDate={endDateTimeFrame}
                                        setEndDate={AnalyticsActions.setEndDateTimeFrame}
                                        isCompare={false}
                                    />
                                    <div className="button-container">
                                        <button className="button-reset" disabled={!isCompareFrameValid} onClick={() => {
                                            handleClickAwayTimeFrame()
                                        }}>
                                            Zurücksetzen
                                        </button>
                                        <button className="button-submit" disabled={!isTimeframeValid} onClick={() => {
                                            setTimeFrameStartRemember(startDateTimeFrame);
                                            setTimeFrameEndRemember(endDateTimeFrame);
                                            setCompareFrameStartRemember(startDateCompareFrame);
                                            setCompareFrameEndRemember(endDateCompareFrame);
                                            dispatch(AnalyticsActions.setTrigger())
                                        }}>Anwenden</button>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>
              
                    <div className="timespan-container">
                            <div className="timespan-label">
                            Art der Unterkünft
                            </div>
                            <AccomodationCategoriesDropdown
                                    onDropdownChange={handleDropdownChange}  />
                        </div>
                     </div>
                     <div className="line"></div>
                    <div className='filter-time-filter-container'>
                    <ClickAwayListener onClickAway={handleClickAwayCompare}>            
                        <div className="filter-compare-container">
                            <div className="filter-compare-label">
                                Vergleichsperiode
                            </div>                       
                            <div className="analytics-filter-dropdown">
                              <div
                                    className={`compare-input-container ${showCompareFilter ? 'bordered-active' : 'bordered'}`}
                                    onClick={() => {
                                        setCompareFrameStartRemember(startDateCompareFrame);
                                        setCompareFrameEndRemember(endDateCompareFrame);
                                        setShowCompareFilter(true)
                                    }}
                                >
                                    <div className="compare-input">
                                        {`${formattedStartDateCompareFrame} - ${formattedEndDateCompareFrame}`}
                                    </div>
                                    <CalendarIcon className="calendar-icon"/>
                                </div>
                                <div className={`content content-right ${showCompareFilter ? 'show' : ''}`}>
                                    <DateRangePicker
                                        startDate={startDateCompareFrame}
                                        setStartDate={AnalyticsActions.setStartDateCompareFrame}
                                        endDate={endDateCompareFrame}
                                        setEndDate={AnalyticsActions.setEndDateCompareFrame}
                                        isCompare={true}
                                    />
                                    <div className="button-container">
                                        <button className="button-reset" disabled={!isCompareFrameValid} onClick={() => {
                                            handleClickAwayCompare()
                                        }}>
                                            Zurücksetzen
                                        </button>
                                        <button className="button-submit" disabled={!isCompareFrameValid} onClick={() => {
                                            setTimeFrameStartRemember(startDateTimeFrame);
                                            setTimeFrameEndRemember(endDateTimeFrame);
                                            setCompareFrameStartRemember(startDateCompareFrame);
                                            setCompareFrameEndRemember(endDateCompareFrame);
                                            dispatch(AnalyticsActions.setTrigger())
                                        }}>
                                            Anwenden
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ClickAwayListener>

                        <div className="filter-compare-container">
                            <div className="filter-compare-label">
                             Art der Unterkünft
                            </div>
                            <AccomodationCategoriesDropdown 
                                    onDropdownChange={handleDropdownCompareChange}   />
                        </div>             
                   </div>
                </div>
                </div>
            </div>
        </React.Fragment>
    );
}