import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {Actions} from "./redux";
import {WithBreadcrumb} from "../../breadcrumb/WithBreadcrumb";
import {RegistrationForm} from "./RegistrationFormComponent";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {OnServicePartnerChange} from "components/zterminal/OnServicePartnerChange/OnServicePartnerChange";


export class RegistrationFormReadComponent extends React.Component {

    static propTypes = {
        currentRegistrationForm: PropTypes.any.isRequired,
        fetchCurrentRegistrationForm: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
            loaded: false
        };

        this.gotoRegistrationFormList = this.gotoRegistrationFormList.bind(this);
    }

    get breadcrumbFirstElement() {
        return {
            id: 'bonfire.components.AdministrationSidebar.registrationForm',
            defaultMessage: 'Meldescheine'
        }
    }

    gotoRegistrationFormList() {
        this.props.history.push("/zterminal/registrationform/");
    }

    componentDidMount() {
        const regFormId = this.props.match.params.id;
        if(this.props.currentRegistrationForm || this.props.currentRegistrationForm.id !== regFormId){
            this.props.fetchCurrentRegistrationForm(regFormId).then(() => {
                this.setState({
                    loaded: true
                });
            });
        }
    }

    render() {
        const primaryGuest = this.props.currentRegistrationForm.guests.find(guest => guest.primary === true);
        const rfName = primaryGuest ? `${primaryGuest.guest.firstName}, ${primaryGuest.guest.lastName}` : '';

        const path = [
            this.breadcrumbFirstElement,
            this.props.currentRegistrationForm.servicePartner.companyName,
            rfName
        ];

        if(this.state.loaded) {
            return <div id="registration-form-read">
                <WithBreadcrumb icon={'registrationForm'} path={path}/>
                <OnServicePartnerChange onServicePartnerChange={this.gotoRegistrationFormList} />
                <RegistrationForm read={true}/>
            </div>;
        } else {
            return <LoadingOverlay loading={true} />
        }

    }
}

export const RegistrationFormRead = compose(
    withRouter,
    connect(
        state => ({
            currentRegistrationForm: state.getIn(['zterminal', 'registrationForm', 'currentRegistrationForm']),
        }),
        (dispatch) => ({
            fetchCurrentRegistrationForm: id => dispatch(Actions.fetchCurrentRegistrationForm(id))
        })
    ))(RegistrationFormReadComponent);





