import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import TouristtaxService from "../../../../../services/touristtaxes/TouristtaxService";
import {assoc, equals} from "ramda";
import {AppDispatch, AppGetState, RootState} from "App";
import {RegistrationForm} from "bonfire/ZTerminal/RegistrationForms/RegistrationFormModel";
import {TouristTaxSearchParams} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailModel";

interface TouristTaxRegistrationFormTableQuery extends TouristTaxSearchParams {
    page: number
}

interface SliceState {
    query: TouristTaxRegistrationFormTableQuery | null
    error: string | null
    loading: boolean
    registrationForms: RegistrationForm[]
    totalPages: number
    expandedRows: Record<number, boolean>
}

const initialState: SliceState = {
    query: null,
    error: null,
    loading: true,
    registrationForms: [],
    totalPages: 1,
    expandedRows: {}
}

const fetchRegistrationForms = createAsyncThunk(
    'touristTaxDetail/registrationFormTable/fetchRegistrationFormsIfNecessary',
    (_: void, thunkApi) => {
        const state = thunkApi.getState();
        const query = getQuery(state);
        if (!query) {
            return Promise.resolve({
                totalCount: 0,
                registrationForms: []
            });
        }
        const PAGE_SIZE = 20;
        const offset = query.page ? PAGE_SIZE * (query.page - 1) : 0;
        return TouristtaxService.loadServicePartnerRegistrationForms(query.servicePartnerId, query.startDate, query.endDate, offset, PAGE_SIZE)
            .catch((error: any) => thunkApi.rejectWithValue(error.toString()));
    }
);

const PAGE_SIZE = 20;

const {actions, reducer} = createSlice({
    name: 'touristTaxDetail/registrationFormTable',
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<TouristTaxRegistrationFormTableQuery>) => {
            state.query = action.payload;
        },
        setExpandedRows: (state, action: PayloadAction<Record<number, any>>) => {
            state.expandedRows = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchRegistrationForms.pending, state => {
            state.loading = true;
            state.error = null;
            state.registrationForms = []
        });
        builder.addCase(fetchRegistrationForms.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.toString();
            state.expandedRows = {};
            state.registrationForms = [];
        });
        builder.addCase(fetchRegistrationForms.fulfilled, (state, action) => {
            state.totalPages = Math.ceil(action.payload.totalCount / PAGE_SIZE);
            state.registrationForms = action.payload.data ?? [];
            state.expandedRows = {};
            state.error = null;
            state.loading = false;
        });
    }
});

export {reducer as TouristTaxRegistrationFormTableReducer};

function setRowExpanded(expanded: boolean, index: number) {
    return (dispatch: AppDispatch, getState: AppGetState) => {
        const expandedRows = getExpandedRows(getState());
        // @ts-ignore
        dispatch(actions.setExpandedRows(assoc(index, expanded, expandedRows)));
    };
}

function query(query: any) {
    return (dispatch: AppDispatch, getState: AppGetState) => {
        const currentQuery = getQuery(getState());
        const newQuery: TouristTaxRegistrationFormTableQuery = assoc('page', query.page, query);
        if (equals(newQuery, currentQuery)) {
            return Promise.resolve();
        }

        dispatch(actions.setQuery(newQuery));
        return dispatch(fetchRegistrationForms());
    }
}

export const TouristTaxRegistrationFormTableActions = {
    query,
    setRowExpanded
};

function getRegistrationFormTableState(state: RootState): SliceState {
    return state.getIn(['zterminal', 'touristtax', 'detail'])['registrationFormTable'];
}
const getQuery = (state: RootState): TouristTaxRegistrationFormTableQuery | null => getRegistrationFormTableState(state).query;
const getError = (state: RootState) => getRegistrationFormTableState(state).error;
const getLoading = (state: RootState) => getRegistrationFormTableState(state).loading;
const getRegistrationForms = (state: RootState) => getRegistrationFormTableState(state).registrationForms;
const getTotalPages = (state: RootState) => getRegistrationFormTableState(state).totalPages;
const getExpandedRows = (state: RootState) => getRegistrationFormTableState(state).expandedRows;

export const TouristTaxRegistrationFormTableSelectors = {
    getError,
    getLoading,
    getQuery,
    getRegistrationForms,
    getTotalPages,
    getExpandedRows
};
