import React from 'react';
import ScaleLoader from "react-spinners/ScaleLoader";

const SobrPublicLoadingIndicator = () => (
    <div className="public-loading-indicator height100">
        <div className={'loading-container'}>
            <div className={'outer-panel'}>
                <div className={'inner-panel'}>
                    <div className={'logo'}/>
                    <span className={'title'}/>
                    <div className={'inner-inner-panel'}>
                        <div className={'loading-wrapper'}>
                            <ScaleLoader color={'#003463'}/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default SobrPublicLoadingIndicator;
