/**
 * Gets the repositories of the user from Github
 */

import ApiService from '../api/ApiService';

const getCockpitDataZT = ApiService.createApiEndpoint('admin/dashboard/zt', 'GET', true);

const cockpitDataRequest = ApiService.createApiEndpoint('admin/dashboard', 'GET', true);
function getCockpitData(servicePartnerId) {
    return cockpitDataRequest({servicePartnerId});
}

const regFormsByTimeFrameRequest = ApiService.createApiEndpoint('admin/registration/forms', 'GET', true);
function getRegFormsByTimeFrame(timeFrame, servicePartnerId){
    return regFormsByTimeFrameRequest({
        timeFrame: timeFrame,
        servicePartnerId: servicePartnerId
    });
}

export default {
    getCockpitData,
    getCockpitDataZT,
    getRegFormsByTimeFrame
};
