import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {ReactComponent as BackArrow} from "images/icons/arrow_back_brown.svg";

class SelfServiceStepLayoutComponent extends React.Component {

    static propTypes = {
        id: PropTypes.string,
        backActivated: PropTypes.bool,
        title: PropTypes.any,
        closeActivated: PropTypes.bool,
        history: PropTypes.object
    };

    static defaultProps = {
        backActivated: true,
        closeActivated: true
    };

    render() {

        const url = this.props.history.location.pathname;
        const stepNumber = url.match(/\/step(\d+)/)[1];
        const title = `step${stepNumber}`;
        return <div className="head standard self slr-big-new">
            <div className="row">
                <div className="col-2">
                    <a onClick={() => this.props.history.goBack()}>
                        <button className="b-button-pre-checkin-navigation" id={`self-service-arrow-back-button-${title}`}>
                            <BackArrow />
                        </button>
                    </a>
                </div>
                <div className="col-8">
                    <div className="self-service-title">
                        {this.props.title ? (
                            <FormattedMessage {...this.props.title} />
                        ) : (
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.title'
                                defaultMessage='Pre-Check-In'
                            />
                        )}
                    </div>
                </div>
                <div className="col-2"/>
            </div>
        </div>
    }
}

export const SelfServiceStepLayout = withRouter(SelfServiceStepLayoutComponent);
