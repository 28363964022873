import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import UserForm from "bonfire/ZTerminal/Users/UserForm/UserForm";
import {FormattedMessage} from "react-intl";
import {
    emptyUserFormData,
    USER_DATA_PROPTYPE,
    VALIDATION_DATA_PROPTYPE
} from "bonfire/ZTerminal/Users/UserForm/UserFormLogic";
import {Actions as UserCreatePageActions} from "bonfire/ZTerminal/Users/UserCreate/redux";
import UserService from "services/users/UserService";
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {Actions as UserListPageActions} from "bonfire/ZTerminal/Users/UserList/redux";

class UserCreatePageComponent extends React.Component {

    static propTypes = {
        history: PropTypes.object.isRequired,
        userData: USER_DATA_PROPTYPE,
        validationData: VALIDATION_DATA_PROPTYPE,
        clearValidationData: PropTypes.func.isRequired,
        setUserData: PropTypes.func.isRequired,
        createUser: PropTypes.func.isRequired,
        invalidateUserListData: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.clearValidationData();
        if (!this.props.userData) {
            this.props.setUserData(emptyUserFormData(this.roleAssignmentMode, 'generate'));
        }
    }

    static get breadcrumbPath() {
        return [
            {
                id: 'bonfire.containers.administration.User.header',
                defaultMessage: 'Benutzerverwaltung'
            },
            "Neuer Benutzer"
        ];
    }

    get roleAssignmentMode() {
        switch (this.props.loggedInUserType) {
            case UserService.UserTypeEnum.SERVICE_PARTNER:
                return 'isAdmin';
            case UserService.UserTypeEnum.ZERMATT_TOURISMUS:
                return 'roles';
            default:
                throw new Error(`User Create Page does not support logged in user of type ${this.props.loggedInUserType}`);
        }
    }

    onSubmit() {
        this.props.createUser(this.props.userData, this.roleAssignmentMode)
            .then(() => {
                this.props.invalidateUserListData();
                this.props.history.push('/zterminal/user');
            });
    }

    render() {
        return <div id="user-create-page" className="zterminal-content-box">
            <WithBreadcrumb icon='users' path={UserCreatePageComponent.breadcrumbPath} />
            <div className="zterminal-content-box-body p-3">
                <div className="container-fluid">
                    <LoadingOverlay loading={this.props.loading}>
                        {this.props.userData ? (
                            <UserForm.UserForm
                                roleAssignmentMode={this.roleAssignmentMode}
                                passwordMode='generate'
                                userData={this.props.userData}
                                validationData={this.props.validationData}
                                onDataChange={this.props.setUserData}
                            />
                        ) : (
                            <div>{"Kann User nicht anzeigen"}</div>
                        )}
                    </LoadingOverlay>
                </div>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary btn-160" onClick={this.onSubmit}>
                                <FormattedMessage
                                    id="bonfire.containers.administration.User.create.createButton"
                                    defaultMessage="Erstellen"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}

export default compose(
    withRouter,
    connect(
        state => {
            const userCreateState = state.getIn(['zterminal', 'user', 'create']);
            return {
                userData: userCreateState.get('userData'),
                validationData: userCreateState.get('validationData'),
                loading: userCreateState.get('loading'),
                loggedInUserType: SessionSelectors.userType(state)
            }
        },
        dispatch => ({
            clearValidationData: () => dispatch(UserCreatePageActions.clearValidationData()),
            setUserData: userData => dispatch(UserCreatePageActions.setUserData(userData)),
            createUser: (userData, roleAssignmentMode) => dispatch(UserCreatePageActions.createUser(userData, roleAssignmentMode)),
            invalidateUserListData: () => dispatch(UserListPageActions.invalidateData())
        })
    )
)(UserCreatePageComponent);
