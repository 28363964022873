import React, {useEffect, useState} from 'react';
import {Route, Switch} from "react-router-dom";
import {SelfService} from "bonfire/SelfService/SelfService";
import {connect} from "react-redux";
import {Actions as LanguageActions} from "bonfire/Language/redux";



export function SelfServiceWrapperComponent(props) {
    const [isLanguageSet, setLanguageSet] = useState(false);

    const DEFAULT_LANGUAGE_SELFSERVICE = 'en'

    useEffect(() => {
        if (!isLanguageSet) {
            props.initializeLanguageFromBrowser(DEFAULT_LANGUAGE_SELFSERVICE);
            setLanguageSet(true);
        }
    });

    return <Switch>
        <Route path="/self-service/:id" render={({match}) => <SelfService registrationFormId={match.params.id}/>}/>
    </Switch>
}

export default connect(
    null,
    dispatch => ({
        initializeLanguageFromBrowser: (language) => dispatch(LanguageActions.initializeLanguage(language))
    })
)(SelfServiceWrapperComponent);
