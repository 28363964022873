import {combineReducers} from "redux";
import {AppDispatch, AppGetState} from "App";
import {Moment} from "moment";
import {
    TouristTaxSearchParamActions,
    TouristTaxSearchParamReducer,
    TouristTaxSearchParamSelectors
} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxSearchParamsSlice";
import {
    TouristTaxRegistrationFormTableActions,
    TouristTaxRegistrationFormTableReducer
} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/table/TouristTaxRegistrationFormTableSlice";
import {
    TouristTaxDetailHeaderActions,
    TouristTaxDetailHeaderReducer
} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDetailHeaderSlice";

export const TouristTaxDetailReducer = combineReducers({
    searchParams: TouristTaxSearchParamReducer,
    header: TouristTaxDetailHeaderReducer,
    registrationFormTable: TouristTaxRegistrationFormTableReducer
});

function setServicePartnerId(servicePartnerId: string) {
    return (dispatch: AppDispatch, getState: AppGetState) => {
        dispatch(TouristTaxSearchParamActions.setServicePartnerId(servicePartnerId));
        return doQuery(dispatch, getState);
    }
}

function setStartDate(start: Moment) {
    return (dispatch: AppDispatch, getState: AppGetState) => {
        dispatch(TouristTaxSearchParamActions.setStartDate(start));
        return doQuery(dispatch, getState);
    }
}

function setEndDate(end: Moment) {
    return (dispatch: AppDispatch, getState: AppGetState) => {
        dispatch(TouristTaxSearchParamActions.setEndDate(end));
        return doQuery(dispatch, getState);
    }
}

function doQuery(dispatch: AppDispatch, getState: AppGetState) {
    const query = TouristTaxSearchParamSelectors.searchParams(getState());
    return query ?
        Promise.all([
            dispatch(TouristTaxRegistrationFormTableActions.query(query)),
            dispatch(TouristTaxDetailHeaderActions.query(query))
        ]) :
        Promise.resolve();
}

export const TouristTaxDetailActions = {
    setServicePartnerId,
    setStartDate,
    setEndDate
}
