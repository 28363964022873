import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import {
    SERVICE_PARTNER_DATA_PROPTYPE,
    VALIDATION_DATA_PROPTYPE
} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerFormLogic";
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import * as R from "ramda";
import f from "lib/util";
import {Actions as ServicePartnerEditPageActions} from "bonfire/ZTerminal/ServicePartner/ServicePartnerEdit/redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {ServicePartnerForm} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerForm";
import {FormattedMessage} from "react-intl";
import {Actions as ServicePartnerListPageActions} from "bonfire/ZTerminal/ServicePartner/ServicePartnerList/redux";

class ServicePartnerEditPageComponent extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        servicePartnerData: SERVICE_PARTNER_DATA_PROPTYPE,
        validationData: VALIDATION_DATA_PROPTYPE,
        clearValidationData: PropTypes.func.isRequired,
        fetchServicePartnerData: PropTypes.func.isRequired,
        submitServicePartnerData: PropTypes.func.isRequired,
        invalidateServicePartnerListData: PropTypes.func.isRequired
    };


    constructor(props, context) {
        super(props, context);

        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.clearValidationData();
        this.props.fetchServicePartnerData(this.servicePartnerId);
    }

    get servicePartnerId() {
        return this.props.match.params['id'];
    }

    get breadcrumbPath() {
        const servicePartner = this.props.servicePartner;
        const breadcrumbPath = [{
            id: 'bonfire.components.AdministrationSidebar.basedata',
            defaultMessage: 'Stammdaten',
            url: '/zterminal/servicepartner'
        }];

        f.let(R.path(['partnerGroup', 'name'], servicePartner), breadcrumbPath.push) ;
        f.let(R.prop('name', servicePartner), breadcrumbPath.push);

        return breadcrumbPath;
    }

    onSubmit() {
        this.props.submitServicePartnerData(this.servicePartnerId, this.props.servicePartnerData)
            .then(() => {
                this.props.invalidateServicePartnerListData();
                this.props.history.push('/zterminal/servicepartner')
            });
    }

    render() {
        return <div id="servicepartner-edit-page" className="zterminal-content-box">
            <WithBreadcrumb icon="hotel" path={this.breadcrumbPath} />

            <div className="zterminal-content-box-body p-3 overflow-auto">
                <div className="container-fluid">
                    <LoadingOverlay loading={this.props.loading} >
                        {this.props.servicePartnerData ? (
                            <ServicePartnerForm
                                servicePartnerData={this.props.servicePartnerData}
                                validationData={this.props.validationData}
                                onDataChange={this.props.setServicePartnerData}
                            />
                        ) : (
                            <div>{"Kann Service Partner nicht anzeigen"}</div>
                        )}
                    </LoadingOverlay>
                </div>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary btn-160" onClick={this.onSubmit}>
                                <FormattedMessage
                                    id="bonfire.containers.administration.ServicePartner.edit.createButton"
                                    defaultMessage="Speichern"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default compose(
    withRouter,
    connect(
        state => {
            const spEditState = state.getIn(['zterminal', 'servicePartner', 'edit']);
            return {
                servicePartnerData: spEditState.get('servicePartnerData'),
                validationData: spEditState.get('validationData'),
                loading: spEditState.get('loading')
            };
        },
        dispatch => ({
            clearValidationData: () => dispatch(ServicePartnerEditPageActions.clearValidationData()),
            fetchServicePartnerData: spId => dispatch(ServicePartnerEditPageActions.fetchServicePartnerData(spId)),
            setServicePartnerData: spData => dispatch(ServicePartnerEditPageActions.setServicePartnerData(spData)),
            submitServicePartnerData: (spId, spData) => dispatch(ServicePartnerEditPageActions.submitServicePartner(spId, spData)),
            invalidateServicePartnerListData: () => dispatch(ServicePartnerListPageActions.invalidateData())
        })
    )
)(ServicePartnerEditPageComponent);
