/*
 * HomeConstants
 * Each action has a corresponding type, which the reducer knows and picks up on.
 * To avoid weird typos between the reducer and the actions, we save them as
 * constants here. We prefix them with 'yourproject/YourComponent' so we avoid
 * reducers accidentally picking up actions they shouldn't.
 *
 * Follow this format:
 * export const YOUR_ACTION_CONSTANT = 'yourproject/YourContainer/YOUR_ACTION_CONSTANT';
 */

export const LOGIN_USER = 'bonfire/Administration/Login/LOGIN_USER';
export const LOGIN_USER_SUCCESS = 'bonfire/Administration/Login/LOGIN_USER_SUCCESS';
export const LOGIN_USER_ERROR = 'bonfire/Administration/Login/LOGIN_USER_ERROR';
export const REFRESH_TOKEN = 'bonfire/Administration/Login/REFRESH_TOKEN';
export const REFRESH_TOKEN_SUCCESS = 'bonfire/Administration/Login/REFRESH_TOKEN_SUCCESS';
export const REFRESH_TOKEN_ERROR = 'bonfire/Administration/Login/REFRESH_TOKEN_ERROR';
export const CHECK_USER = 'bonfire/Administration/Login/CHECK_USER';
export const CHECK_USER_SUCCESS = 'bonfire/Administration/Login/CHECK_USER_SUCCESS';
export const CHECK_USER_ERROR = 'bonfire/Administration/Login/CHECK_USER_ERROR';

