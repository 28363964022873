import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import 'moment/locale/de';
import {SobrDatePicker} from "../../SobrDatePicker/SobrDatePicker";

export class SobrDate extends React.Component {

    static propTypes = {
        label: PropTypes.any.isRequired,
        class: PropTypes.string,
        value: PropTypes.any,
        onChange: PropTypes.func.isRequired,
        disabled: PropTypes.bool.isRequired,
        required: PropTypes.bool,
        language: PropTypes.string,
        isError: PropTypes.bool,
        errorMessage: PropTypes.any,
        checkinDateSynthError: PropTypes.bool,
        checkinDateSynthErrorMessage: PropTypes.any,
    };

    static defaultProps = {
        value: Moment.utc(),
        disabled: false,
        required: false,
        checkinDateSynthError: false,
        class: '',
        isError: false
    };

    render() {
        return (
            <div className={"form-group row " + this.props.class}>
                <span className={"col-sm-12 col-form-label"}>
                    {this.props.label}
                    {this.props.required && <span>*</span>}
                </span>
                <div className="col-sm-12">
                    <SobrDatePicker
                        moment={this.props.value ? this.props.value.value : null}
                        showMonthPicker={true}
                        onSetMoment={this.props.onChange}
                        disabled={this.props.disabled}
                        language={this.props.language}
                        todayEnabled={true}
                    />
                    {(this.props.required && this.props.isError && this.props.checkinDateSynthError) && <span className="errorTextStyle">{this.props.checkinDateSynthErrorMessage}</span>}
                    {(this.props.isError) && <span className="errorTextStyle">{this.props.errorMessage}</span>}
                </div>
            </div>
        );
    }
}
