import ApiService from 'services/api/ApiService';
import {ApiEndpoint} from "../api/ApiEndpoints";

const addGuestEndpoint = new ApiEndpoint('admin/registration/forms/:id/guests', 'POST', true);
function addGuest(id, data) {
    return addGuestEndpoint.call(null, data, {id});
}

const deleteExclusionRequest = ApiService.createApiEndpoint('admin/registration/forms/:id/exclusions/:exId', 'DELETE', true);
function deleteExclusion(id, exId) {
    return deleteExclusionRequest({id, exId});
}

const updateExclusionEndpoint = new ApiEndpoint('admin/registration/forms/:id/exclusions/:exId', 'PUT', true);
function updateExclusion(id, exId, data) {
    return updateExclusionEndpoint.call(null, data, {id, exId});
}

const addExclusionEndpoint = new ApiEndpoint('admin/registration/forms/:id/exclusions');
function addExclusion(id, data) {
    return addExclusionEndpoint.call(null, data, {id});
}

const updateGuestDataRegFromEndpoint = new ApiEndpoint('admin/registration/forms/:regFormId/guests/:guestId', 'PUT', true);
function updateGuestDataRegForm(regFormId, guestId, data) {
    return updateGuestDataRegFromEndpoint.call(null, data, {regFormId, guestId});
}

const addGroupEndpoint = new ApiEndpoint('admin/registration/forms/:id/guests/bulk', 'POST', true);
function addGroup(id, data) {
    return addGroupEndpoint.call(null, data, {id});
}

const deleteRegFormRequest = ApiService.createApiEndpoint('admin/registration/forms/:regFormId', 'DELETE', true);
function deleteRegForm(regFormId) {
    return deleteRegFormRequest({regFormId});
}

function duplicateRegistrationForm(regFormId) {
    const duplicateRegistrationFormRequest = ApiService.createApiEndpoint(`admin/registration/forms/${regFormId}/duplicate`, 'POST', true);
    return duplicateRegistrationFormRequest();
}

const deleteGuestRequest = ApiService.createApiEndpoint('admin/registration/forms/:regFormId/guests/:guestId', 'DELETE', true);
function deleteGuest(regFormId, guestId) {
    return deleteGuestRequest({regFormId, guestId});
}

const createRegForm = ApiService.createApiEndpoint('admin/registration/forms', 'POST', true);

const updateRegistrationFormEndpoint = new ApiEndpoint('admin/registration/forms/:id', 'PUT', true);
function updateRegistrationForm(id, data) {
    return updateRegistrationFormEndpoint.call(null, data, {id});
}

const getRegistrationFormRequest = ApiService.createApiEndpoint('registration/forms/:id', 'GET', false);
function getRegistrationForm(id) {
    return getRegistrationFormRequest({id});
}

const request = ApiService.createApiEndpoint('admin/registration/forms/:id', 'GET', true);
function getCompleteRegistrationForm(id) {
    return request({id});
}

const getRegistrationFormsRequest = ApiService.createApiEndpoint('admin/registration/forms', 'GET', true);
function getRegistrationForms(servicePartnerId, pageSize, offset, sortField, sortDirection, searchTerm, timeframe, checkedinOnly, startDate, endDate) {
    return getRegistrationFormsRequest({
        max: pageSize,
        offset,
        sort: sortField,
        order: sortDirection,
        q: searchTerm,
        timeFrame: timeframe,
        startDate,
        endDate,
        checkedinOnly,
        servicePartnerId
    });
}

const getRegistrationCountsPerDayRequest = ApiService.createApiEndpoint('admin/registration/overview/perday', 'GET', true);
function getRegistrationCountsPerDay(servicePartnerId, startDate, endDate, searchTerm, checkedinOnly, timeFrame) {
    return getRegistrationCountsPerDayRequest({
        checkedinOnly,
        timeFrame,
        startDate,
        endDate,
        searchTerm,
        servicePartnerId: servicePartnerId
    });
}

const downloadCsvRequest = ApiService.createDownloadEndpoint('admin/registration/overview/perday/csv', 'text/csv', true);
function downloadRegistrationCountCsv(servicePartnerId, startDate, endDate, searchTerm, checkedinOnly, timeFrame) {
    return downloadCsvRequest('test.csv', {
        checkedinOnly,
        timeFrame,
        startDate,
        endDate,
        searchTerm,
        servicePartnerId: servicePartnerId
    });
}

const uploadExcelFile = new ApiEndpoint('data/import/registrationForms', 'POST', true);
function uploadFile(file, servicePartnerId) {
    return uploadExcelFile.call(null,{file, servicePartnerId} , null, true);
}

const getIdCardData = ApiService.createApiEndpoint('configuration/documenttypes', 'GET', false);
const getTaxCategoryData = ApiService.createApiEndpoint('admin/tax/categories', 'GET', true);
const getTaxExemptReasonData = ApiService.createApiEndpoint('admin/tax/exemptreasons', 'GET', true);


// Für Self-Service erstellt --> Deshalb false bei Auth.
const getNationalityData = ApiService.createApiEndpoint('configuration/countries', 'GET', false);
const getInterestsData = ApiService.createApiEndpoint('configuration/interests', 'GET', false);

const updateGuestEndpoint = new ApiEndpoint('registration/forms/:id/guests', 'POST', false);
function updateGuest(id, data) {
    return updateGuestEndpoint.call(null, data, {id});
}

const getLanguageData = ApiService.createApiEndpoint('configuration/languages', 'GET', false);

export default {
    getRegistrationForm,
    getRegistrationForms,
    getRegistrationCountsPerDay,
    downloadRegistrationCountCsv,
    getNationalityData,
    getInterestsData,
    updateGuest,
    getLanguageData,
    getIdCardData,
    getTaxCategoryData,
    getTaxExemptReasonData,
    getCompleteRegistrationForm,
    createRegForm,
    updateRegistrationForm,
    deleteGuest,
    deleteRegForm,
    duplicateRegistrationForm,
    addGroup,
    updateGuestDataRegForm,
    addExclusion,
    updateExclusion,
    deleteExclusion,
    addGuest,
    uploadFile
};

