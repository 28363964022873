import ApiService from "../api/ApiService";
import {ApiEndpoint} from "../api/ApiEndpoints";

export const UserTypeEnum = Object.freeze({
    UNKNOWN: 'unknown',
    SERVICE_PARTNER: 'service-partner',
    POLICE: 'police',
    ZERMATT_TOURISMUS: 'zermatt-tourismus'
});

const listUsersRequest = ApiService.createApiEndpoint('admin/users', 'GET', true);
export function listUsers(max, offset, sort, order, searchTerm) {
    return listUsersRequest({
        max,
        offset,
        sort,
        order,
        q: searchTerm
    });
}

const getUserRequest = ApiService.createApiEndpoint('admin/users/:userId', 'GET', true);
export function getUser(userId) {
    return getUserRequest({userId});
}

const getCurrentUserRequest = ApiService.createApiEndpoint('admin/user');
export function getCurrentUser() {
    return getCurrentUserRequest()
        .then(data => data.user);
}

export function determineUserType(roles) {
    if (!roles) {
        return UserTypeEnum.UNKNOWN;
    }
    if (roles.find(role => role.startsWith('ROLE_SP'))) {
        return UserTypeEnum.SERVICE_PARTNER;
    }
    if (roles.find(role => role.startsWith('ROLE_POLICE'))) {
        return UserTypeEnum.POLICE;
    }
    return UserTypeEnum.ZERMATT_TOURISMUS;
}

export function isServicePartnerUser(roles) {
    return determineUserType(roles) === UserTypeEnum.SERVICE_PARTNER;
}

export function isZermattTourismusUser(roles) {
    return determineUserType(roles) === UserTypeEnum.ZERMATT_TOURISMUS;
}

export function isPoliceUser(roles) {
    return determineUserType(roles) === UserTypeEnum.POLICE;
}

const resendPasswordEndpoint = new ApiEndpoint('admin/users/:userId/resendpassword', 'POST', true);
export function resendPassword(userId, email) {
    return resendPasswordEndpoint.call(null, {email}, {userId});
}

const createUserRequest = ApiService.createApiEndpoint('admin/users', 'POST', true);
export function createUser(userData) {
    return createUserRequest(userData);
}

const deleteUserRequest = ApiService.createApiEndpoint('admin/users/:userId', 'DELETE', true);
export function deleteUser(userId) {
    return deleteUserRequest({userId});
}

const saveUserDataEndpoint = new ApiEndpoint('admin/users/:userId', 'PUT', true);
export function saveUserData(userId, userData) {
    return saveUserDataEndpoint.call(null, userData, {userId});
}

export default {
    UserTypeEnum,
    listUsers,
    getUser,
    getCurrentUser,
    determineUserType,
    isServicePartnerUser,
    isZermattTourismusUser,
    isPoliceUser,
    resendPassword,
    createUser,
    saveUserData,
    deleteUser
}
