import React from 'react';
import PropTypes from 'prop-types';
import SobrSearchHTML from "../SobrSearchHTML";
import {connect} from 'react-redux';
import f from "lib/util";
import {history} from "utils/history";
import {Actions as FilterActions} from 'bonfire/ZTerminal/RegistrationForms/RegistrationFormSearchFilter/RegistrationFormSearchFilterSlice';
import Moment from 'moment';

export class SobrOverviewSearchComponent extends React.Component {

    constructor(props) {
        super(props);
        this.search = this.search.bind(this);
        this.onSearch = this.onSearch.bind(this);
    }

    static propTypes = {
        class: PropTypes.string,
        onSearch: PropTypes.func
    };

    static defaultProps = {
        class: '',
        onSearch: () => {}
    };

    onSearch(searchValue) {
        f.callIfExists(this.props.onSearch, searchValue);
    }

    search(event, value) {
        if(!event)
            return;

        let searchValue;


        if(event === 'val'){
            searchValue = value;
        }

        if (event.type === 'keyup') {
            if (event.key === 'Enter') {
                this.onSearch(event.target.value);
                history.push('/zterminal/registrationform');
            }
        }

        if (event === 'val') {
            this.onSearch(searchValue);
            history.push('/zterminal/registrationform');
        }
    }

    render() {
        return (
            <div className={'overview-search ' + this.props.class}>

                <SobrSearchHTML
                    placeholder="bonfire.regForm.search"
                    onClick={value => this.search('val', value)}
                    onChange={this.search}
                    onKeyUp={this.search}
                    size={25}
                />

            </div>
        );
    }

}

export const SobrOverviewSearch = connect(
    null,
    (dispatch) => ({
        onSearch: searchTerm => {
            dispatch(FilterActions.reset());
            dispatch(FilterActions.setDateRangeStart(Moment("2017-01-01")));
            dispatch(FilterActions.setDateRangeEnd(Moment().add(2, 'years').endOf("year")));
            dispatch(FilterActions.setSearchTerm(searchTerm));
        }
    })
)(SobrOverviewSearchComponent);
