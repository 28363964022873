import {Map} from 'immutable';
import UserService from "services/users/UserService";
import v from "lib/validation";
import UserFormLogic from "bonfire/ZTerminal/Users/UserForm/UserFormLogic";
import {Accessors as UserFormAccessors} from "bonfire/ZTerminal/Users/UserForm/redux";

export const InitialState = Map({
    userData: null,
    validationData: null,
    loading: false
});

const ACTION_SET_USER_DATA = 'zterminal/user/edit/setUserData';
const ACTION_SET_VALIDATION_DATA = 'zterminal/user/edit/setValidationData';
const ACTION_SET_LOADING = 'zterminal/user/edit/setLoading';

export const Actions = {
    clearValidationData,
    fetchUserData,
    setUserData,
    resendPassword,
    deleteUser,
    submitUserData
};

const userToFormData = userData => UserFormLogic.userToFormData(userData, 'roles', 'expire');
const validateUserFormData = (user, baseData) => UserFormLogic.validateUserFormData(user, baseData, 'roles');

function fetchUserData(userId) {
    return dispatch => {
        dispatch(setLoading(true));
        return UserService.getUser(userId)
            .then(user => {
                dispatch(setUserData(userToFormData(user)));
                dispatch(setLoading(false));
                return user;
            })
            .catch(e => {
                dispatch(setLoading(false));
                return Promise.reject(e);
            });
    }
}

function resendPassword(userId, email) {
    return dispatch => {
        dispatch(setLoading(true));
        return UserService.resendPassword(userId, email)
            .then(() => {
                dispatch(setLoading(false));
            })
            .catch(e => {
                dispatch(setLoading(false));
                return Promise.reject(e);
            });
    };
}

function deleteUser(userId) {
    return dispatch => {
        dispatch(setLoading(true));
        return UserService.deleteUser(userId).then(() => {
            dispatch(setLoading(false));
        });
    };
}

function submitUserData(userId, userData) {
    return (dispatch, getState) => {
        const userFormBaseData = UserFormAccessors.getUserFormBaseData(getState());
        const validationResult = validateUserFormData(userData, userFormBaseData);
        dispatch(setValidationData(validationResult));
        if (!v.isValid(validationResult)) {
            return Promise.reject(validationResult);
        }

        dispatch(setLoading(true));
        return UserService.saveUserData(userId, userData).then(user => {
            const formData = userToFormData(user);
            dispatch(setUserData(formData));
            dispatch(setLoading(false));
            return formData;
        });
    }
}

function setUserData(user) {
    return {
        type: ACTION_SET_USER_DATA,
        user
    }
}

function clearValidationData() {
    return setValidationData(null);
}

function setValidationData(validationData) {
    return {
        type: ACTION_SET_VALIDATION_DATA,
        validationData
    }
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    }
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_USER_DATA:
            return state.set('userData', action.user);
        case ACTION_SET_VALIDATION_DATA:
            return state.set('validationData', action.validationData);
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        default:
            return state;
    }
}

export default {
    InitialState, Actions, Reducer
}
