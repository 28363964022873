import {SessionReducer} from "bonfire/ZTerminal/Session/SessionSlice";
import {combineReducers} from 'redux-immutable';
import RegistrationForms from './RegistrationForms/redux';
import {breadcrumbReducer} from "./breadcrumb/breadcrumbSlice";
import Cockpit from "./Cockpit/redux";
import RegistrationForm from "./RegistrationForms/RegistrationForm/redux";
import ServicePartner from "./ServicePartner/redux";
import Touristtax from "./Touristtax/redux";
import User from "./Users/redux";
import {analyticsSlice} from "bonfire/ZTerminal/Analytics/AnalyticsSlice";
import {analyticsBarsSlice} from "bonfire/ZTerminal/Analytics/AnalyticsBarsSlice";
import {analyticsChartSlice} from "bonfire/ZTerminal/Analytics/AnalyticsChartSlice";

export const Reducer = combineReducers({
    session: SessionReducer,
    registrationForms: RegistrationForms.Reducer,
    breadcrumb: breadcrumbReducer,
    cockpit: Cockpit.Reducer,
    registrationForm: RegistrationForm.Reducer,
    servicePartner: ServicePartner.Reducer,
    touristtax: Touristtax.Reducer,
    user: User.Reducer,
    analytics: analyticsSlice.reducer,
    analyticsChart: analyticsChartSlice.reducer,
    analyticsBars: analyticsBarsSlice.reducer
});

export default {
    Reducer
};
