import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

export class SobrSelect extends React.Component {

    static propTypes = {
        label: PropTypes.any.isRequired,
        class: PropTypes.string,
        inputClass: PropTypes.string.isRequired,
        inputClassPrefix: PropTypes.string,
        id: PropTypes.string.isRequired,
        placeholder: PropTypes.any,
        disabled: PropTypes.bool,
        name: PropTypes.string,
        searchable: PropTypes.bool,
        options: PropTypes.any.isRequired,
        valueKey: PropTypes.string,
        labelKey: PropTypes.string,
        value: PropTypes.any.isRequired,
        onChange: PropTypes.func.isRequired,
        clearable: PropTypes.bool,
        required: PropTypes.bool,
        isMulti: PropTypes.bool,
        errorMessage: PropTypes.any,
        onBlurResetsInput: PropTypes.bool,
        onSelectResetsInput: PropTypes.bool,
        closeMenuOnSelect: PropTypes.bool,
        isError: PropTypes.bool
    };

    static defaultProps = {
        class: '',
        inputClassPrefix: '',
        placeholder: '',
        disabled: false,
        name: '',
        searchable: true,
        valueKey: 'value',
        labelKey: 'name',
        clearable: true,
        required: false,
        isMulti: false,
        onBlurResetsInput: false,
        onSelectResetsInput: false,
        closeMenuOnSelect: true,
        isError: false
    };

    render() {

        const name = this.props.name ? this.props.name : this.props.id;

        return (
            <div className="form-group row">
                <span className={"col-sm-12 col-form-label " + this.props.class}>
                    {this.props.label}
                    {this.props.required && <span>*</span>}
                </span>
                <div className="col-sm-12">
                    <Select
                            className={this.props.inputClass}
                            classNamePrefix={this.props.inputClassPrefix}
                            id={this.props.id}
                            placeholder={this.props.placeholder}
                            ref={(ref) => {
                                this.select = ref;
                            }}
                            isMulti={this.props.isMulti}
                            isDisabled={this.props.disabled}
                            onBlurResetsInput={this.props.onBlurResetsInput}
                            onSelectResetsInput={this.props.onSelectResetsInput}
                            simpleValue
                            clearable={this.props.clearable}
                            onChange={this.props.onChange}
                            name={name}
                            searchable={this.props.searchable}
                            options={this.props.options}
                            valueKey={this.props.valueKey}
                            labelKey={this.props.labelKey}
                            value={this.props.value}
                            closeMenuOnSelect={this.props.closeMenuOnSelect}
                    />
                    {this.props.isError && <span className="errorTextStyle">{this.props.errorMessage}</span>}
                </div>
            </div>
        );
    }
}
