import React from 'react';
import PropTypes from 'prop-types';
import * as R from 'ramda';
import {SobrFormGroup} from "components/SobrForm/SobrFormGroup";
import {SobrMultiSelect, SobrSingleSelect} from "components/SobrForm/Inputs/SobrSelect";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {SobrFormRow} from "components/SobrForm/Forms/SobrFormRow";
import SobrTextInput from "components/SobrForm/Inputs/SobrTextInput";
import SobrPasswordInput from "components/SobrForm/Inputs/SobrPasswordInput";
import {SobrCustomCheckbox} from "components/SobrForm/Inputs/SobrCustomCheckbox";
import {connect} from "react-redux";
import {Accessors as UserFormAccessors, Actions as UserFormActions} from "bonfire/ZTerminal/Users/UserForm/redux";
import {
    BASE_DATA_PROPTYPE,
    USER_DATA_PROPTYPE,
    VALIDATION_DATA_PROPTYPE
} from "bonfire/ZTerminal/Users/UserForm/UserFormLogic";

export class UserFormComponent extends React.Component {

    static propTypes = {
        roleAssignmentMode: PropTypes.oneOf(['roles', 'isAdmin']).isRequired,
        passwordMode: PropTypes.oneOf(['generate', 'expire']).isRequired,
        userData: USER_DATA_PROPTYPE.isRequired,
        validationData: VALIDATION_DATA_PROPTYPE,
        loading: PropTypes.bool,
        baseData: BASE_DATA_PROPTYPE,
        fetchBaseData: PropTypes.func.isRequired,
        onDataChange: PropTypes.func
    };

    static defaultProps = {
        roleAssignmentMode: 'roles',
        passwordMode: 'expire'
    };

    constructor(props) {
        super(props);

        this.updateUserData = this.updateUserData.bind(this);
    }

    get showPasswordFields() {
        return this.props.passwordMode === 'expire' || !this.props.userData.generatePassword;
    }

    componentDidMount() {
        this.fetchBaseDataIfNecessary();
    }

    fetchBaseDataIfNecessary() {
        const baseData = this.props.baseData;
        const baseDataMissing = R.any(R.isEmpty)(R.values(baseData));
        if (baseDataMissing) {
            this.props.fetchBaseData();
        }
    }

    updateUserData(fun) {
        const newUser = fun(this.props.userData);
        this.props.onDataChange(newUser);
    }

    render() {
        const userData = this.props.userData;
        const getError = prop => R.path([prop, 'errorMsg'], this.props.validationData);
        return <div className="user-form">
            <LoadingOverlay loading={this.props.loading}>
                <div className="row">
                    <div className="col-12 col-sm-6 col-md-4">
                        <SobrFormGroup title="bonfire.containers.administration.User.edit.headlineContact">
                            <SobrFormRow label="bonfire.containers.administration.User.edit.firstName"
                                         error={getError('firstName')}>
                                <SobrTextInput value={userData.firstName}
                                               onValueChange={firstName => this.updateUserData(R.assoc('firstName', firstName))}
                                />
                            </SobrFormRow>
                            <SobrFormRow label="bonfire.containers.administration.User.edit.lastName"
                                         error={getError('lastName')}>
                                <SobrTextInput value={userData.lastName}
                                               onValueChange={lastName => this.updateUserData(R.assoc('lastName', lastName))}
                                />
                            </SobrFormRow>
                            <SobrFormRow label="bonfire.containers.administration.User.edit.email"
                                         error={getError('email')}>
                                <SobrTextInput value={userData.email}
                                               onValueChange={email => this.updateUserData(R.assoc('email', email))}
                                />
                            </SobrFormRow>
                        </SobrFormGroup>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SobrFormGroup title="bonfire.containers.administration.User.edit.headlineRights">
                            <SobrFormRow label="bonfire.containers.administration.User.edit.group"
                                         error={getError('partnerGroupId')}>
                                <SobrSingleSelect
                                    isClearable={true}
                                    options={this.props.baseData.partnerGroups}
                                    onChange={partnerGroupId => this.updateUserData(
                                        R.assoc('partnerGroupId', partnerGroupId),
                                    )}
                                    value={userData.partnerGroupId}
                                />
                            </SobrFormRow>
                            <SobrFormRow label="bonfire.containers.administration.User.edit.accommodation"
                                         error={getError('servicePartnerId')}>
                                <SobrSingleSelect
                                    isClearable={true}
                                    options={this.props.baseData.servicePartners}
                                    onChange={servicePartnerId => this.updateUserData(
                                        R.assoc('servicePartnerId', servicePartnerId),
                                    )}
                                    value={userData.servicePartnerId}
                                />
                            </SobrFormRow>
                            <SobrFormRow label="bonfire.containers.administration.User.edit.rights"
                                         error={getError('roleIds')}>
                                {this.props.roleAssignmentMode === "roles" &&
                                    <SobrMultiSelect
                                        isClearable={true}
                                        options={this.props.baseData.roles}
                                        onChange={roles =>
                                            this.updateUserData(R.assoc('roleIds', roles))
                                        }
                                        value={userData.roleIds}
                                    />
                                }

                                {this.props.roleAssignmentMode === 'isAdmin' &&
                                    <SobrCustomCheckbox
                                        checked={userData.admin}
                                        onChange={isAdmin => this.updateUserData(R.assoc('admin', isAdmin))}
                                    />
                                }
                            </SobrFormRow>
                        </SobrFormGroup>
                    </div>

                    <div className="col-12 col-sm-6 col-md-4">
                        <SobrFormGroup title="bonfire.containers.administration.User.edit.password">

                            {this.props.passwordMode === 'generate' &&
                            <SobrFormRow label="bonfire.containers.administration.User.create.passwordManual">
                                <SobrCustomCheckbox
                                    checked={!userData.generatePassword}
                                    onChange={setPasswordManually => this.updateUserData(R.assoc('generatePassword', !setPasswordManually))}
                                />
                            </SobrFormRow>
                            }

                            {this.showPasswordFields &&
                            <div>
                                <SobrFormRow label="bonfire.containers.administration.User.edit.password"
                                             error={getError('password')}>
                                    <SobrPasswordInput
                                        value={userData.password}
                                        onChange={password => this.updateUserData(R.assoc('password', password))}
                                        autoComplete="off"
                                    />
                                </SobrFormRow>
                                <SobrFormRow label="bonfire.containers.administration.User.edit.password2"
                                             error={getError('passwordConfirmation')}>
                                    <SobrPasswordInput
                                        value={userData.passwordConfirmation}
                                        onChange={passwordConfirmation => this.updateUserData(R.assoc('passwordConfirmation', passwordConfirmation))}
                                        autoComplete="off"
                                    />
                                </SobrFormRow>
                            </div>
                            }

                            {this.props.passwordMode === 'expire' &&
                            <SobrFormRow label="bonfire.containers.administration.User.edit.passwordExpired"
                                         error={getError('passwordExpired')}>
                                <SobrCustomCheckbox
                                    checked={userData.passwordExpired}
                                    onChange={passwordExpired => this.updateUserData(R.assoc('passwordExpired', passwordExpired))}
                                />
                            </SobrFormRow>
                            }
                        </SobrFormGroup>
                    </div>
                </div>

            </LoadingOverlay>
        </div>
    }

}

export const UserForm = connect(
    state => ({
        loading: state.getIn(['zterminal', 'user', 'form', 'loading']),
        baseData: UserFormAccessors.getUserFormBaseData(state)
    }),
    dispatch => ({
        fetchBaseData: () => dispatch(UserFormActions.fetchBaseData())
    })
)(UserFormComponent);

export default {
    UserFormComponent,
    UserForm
}
