import React, {ReactElement, useState} from 'react';
import 'react-day-picker/lib/style.css';
import 'moment/locale/de';
import {BonfireIcon} from "components/zterminal/BonfireIcon/BonfireIcon";
import DayPicker from "react-day-picker";
import {MONTHS} from "utils/months";
import SobrOnClickOutside from "../SobrOnClickOutside/SobrOnClickOutside";
import SobrDateInput from "./SobrDateInput";
import {YearMonthForm} from "./YearMonthForm";
import moment, {Moment} from "moment";
import {useIntl} from "react-intl";


interface WeekdaysShort {
    DE: string[];
    EN: string[];
    FR: string[];
    de: string[];
    en: string[];
    fr: string[];
}

const WEEKDAYS_SHORT: WeekdaysShort = {
    DE: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    EN: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    FR: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa'],
    de: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    en: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
    fr: ['Di', 'Lu', 'Ma', 'Me', 'Je', 'Ve', 'Sa']
};

function getWeekdaysShort(language: keyof WeekdaysShort): string[] | undefined {
    return WEEKDAYS_SHORT[language];
}

function getMonths(language: keyof WeekdaysShort): string[] | undefined {
    return MONTHS[language];
}


const WEEKDAYS_LONG = [
    'Sonntag',
    'Montag',
    'Dienstag',
    'Mittwoch',
    'Donnerstag',
    'Freitag',
    'Samstag',
];

const FIRST_DAY_OF_WEEK = 1;

const LABELS = {
    nextMonth: 'next month', previousMonth: 'previousMonth'
};
const DEFAULT_FORMAT = 'DD.MM.YYYY';

export interface SobrDatePickerProps {
    moment: Moment | null | undefined
    onSetMoment: (moment: Moment) => void
    showMonthPicker: boolean
    format?: string
    placeholder?: string
    disabled?: boolean
    todayEnabled?: boolean
    language?: keyof typeof MONTHS;
}

export function SobrDatePicker(props: SobrDatePickerProps): ReactElement {
    const intl = useIntl();
    const [datePickerOpen, setDatePickerOpen] = useState(false);
    const [monthToDisplay, setMonthToDisplay] = useState<Date | null>(null);

    const toggleDatePicker = () => setDatePickerOpen(!datePickerOpen);
    const closeDatePicker = () => setDatePickerOpen(false);
    const setMoment = (moment: Moment) => {
        props.onSetMoment(moment);
        closeDatePicker();
    }
    const setDate = (date: Date) => setMoment(moment.utc(date));

    const dateFormat = props.format ?? DEFAULT_FORMAT;
    const date = props.moment?.toDate();
    const language: keyof typeof MONTHS = props.language ?? 'DE';
    const month = monthToDisplay ?? date;
    const disabled = props.disabled ?? false;
    const todayEnabled = props.todayEnabled ?? false;

    const setDateToday = () => {
        const today = moment().utc(); // Get the current date in the user's local timezone
        setMoment(today);
    }

    return <div className="sobr-date-picker">
        <div className="input-group">
            <SobrDateInput
                moment={props.moment}
                format={dateFormat}
                onSetMoment={setMoment}
                placeholder={props.placeholder}
                disabled={disabled}
            />
            <div className="input-group-append">
                <button className="btn btn-outline-secondary calendar-btn"
                        type="button"
                        onClick={toggleDatePicker}
                        disabled={disabled}
                >
                    <BonfireIcon icon={'calendar-small'}/>
                </button>
            </div>
        </div>
        {datePickerOpen && <SobrOnClickOutside onClickOutside={closeDatePicker}>
            <DayPicker
                locale="de"
                selectedDays={date}
                onDayClick={setDate}
                months={getMonths(intl.locale as keyof WeekdaysShort)}
                month={month}
                weekdaysLong={WEEKDAYS_LONG}
                weekdaysShort={getWeekdaysShort(intl.locale as keyof WeekdaysShort)}
                firstDayOfWeek={FIRST_DAY_OF_WEEK}
                labels={LABELS}
                {...props.showMonthPicker ? {
                    captionElement: ({date}) => (
                        <YearMonthForm
                            date={date}
                            onChange={setMonthToDisplay}
                            locale={language}
                        />
                    )
                } : {}}
                todayButton={todayEnabled ? intl.formatMessage({id: 'bonfire.registrationForm.filter.today'}) : ""}
                onTodayButtonClick={setDateToday}
            />
        </SobrOnClickOutside>}
    </div>


}
