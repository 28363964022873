import {API_ROOT} from "../../api-config";
import Rest from "services/api/RestService";
import DownloadService from "./DownloadService";
import {ApiEndpoint, getAuthHeader} from "./ApiEndpoints";


export function createApiUrl(endpoint, searchParams = {}) {
    const apiUrl = API_ROOT + endpoint;
    return Rest.createGetRequestUrl(apiUrl, searchParams);
}

/**
 * Simplified api for ApiEndpoint class.
 *
 * This automatically decides whether your payload belongs inside the JSON body,
 * path variables or search params, depending on http method and whether the given path
 * contains variables or not.
 *
 * If you need more control than that simple case, use the ApiEndpoint class instead.
 */
export function createApiEndpoint(path, method = 'GET', authorized = true) {
    const apiEndpoint = new ApiEndpoint(path, method, authorized);
    switch (method) {
        case "GET":
        case "DELETE":
            return path.includes(':') ?
                payload => apiEndpoint.call(null, null, payload) :
                payload => apiEndpoint.call(payload, null, null)
        case "PUT":
        case "POST":
            return (payload) => apiEndpoint.call(null, payload, null);
        default:
            return () => { throw new Error(`unknown http method ${method}`) };
    }
}

export function createDownloadEndpoint(path, mimetype, authorized = true) {
    const url = createApiUrl(path);
    return (filename, params = {}) => {
        const headers = authorized ? getAuthHeader() : {};
        return DownloadService.downloadBlob(filename, mimetype, url, params, headers);
    };
}

export default {
    createApiEndpoint, createApiUrl, createDownloadEndpoint
}
