import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import moment, {Moment} from "moment";
import {RootState} from "App";
import {TouristTaxSearchParams} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailModel";

interface SliceState {
    servicePartnerId: string | null
    startDate: Moment
    endDate: Moment
}

const initialState: SliceState = {
    servicePartnerId: null,
    startDate: moment().startOf("month"),
    endDate: moment().endOf("month")
}

const {actions: TouristTaxSearchParamActions, reducer: TouristTaxSearchParamReducer} = createSlice({
    name: 'touristTaxDetail/searchParams',
    initialState,
    reducers: {
        setServicePartnerId: (state, action: PayloadAction<string>) => {
            state.servicePartnerId = action.payload;
        },
        setStartDate: (state, action: PayloadAction<Moment>) => {
            state.startDate = action.payload.clone().startOf("day");
        },
        setEndDate: (state, action: PayloadAction<Moment>) => {
            state.endDate = action.payload.clone().startOf("day");
        }
    }
});

export {TouristTaxSearchParamActions, TouristTaxSearchParamReducer};

function getTouristTaxSearchParamState(state: RootState): SliceState {
    return state.getIn(['zterminal', 'touristtax', 'detail'])['searchParams'] as unknown as SliceState;
}

export const TouristTaxSearchParamSelectors = {
    searchParams: (state: RootState): TouristTaxSearchParams | null => {
        const searchParamState = getTouristTaxSearchParamState(state);
        if (!searchParamState.servicePartnerId) {
            return null;
        }

        return {
            servicePartnerId: searchParamState.servicePartnerId,
            startDate: searchParamState.startDate?.clone(),
            endDate: searchParamState.endDate?.clone()
        }
    },
    startDate: (state: RootState): Moment | null => getTouristTaxSearchParamState(state).startDate,
    endDate: (state: RootState): Moment | null => getTouristTaxSearchParamState(state).endDate
};
