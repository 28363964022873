import moment from "moment";

export const backgroundChartArea = {
    id: 'backgroundChartArea',
    beforeDatasetsDraw(chart: any, args: any, option: any) {
        const {ctx, chartArea: { top, bottom, left, right, width, height }, scales: { x, y } } = chart;
        const { labels } = chart.config._config.data;

        const date = labels.find((element: any) => moment(element).isSameOrAfter(moment(new Date()).format('YYYY-MM-DD')));

        if(date) {
            const index = labels.indexOf(date);
            const dataMetaSet = chart.getDatasetMeta(0);
            const positionX = dataMetaSet?.data[index]?.x;
            const newWidth = x._gridLineItems[x._gridLineItems.length-1].x1 - positionX;
            ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
            ctx.fillRect(positionX, top, newWidth, height);
        }
    }
}