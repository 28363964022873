import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {Link} from "react-router-dom";
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";


export class SelfServiceStep5Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
    };

    render() {
        const totalGuests = this.props.registrationForm.additionalGuestCount ? this.props.registrationForm.additionalGuestCount : 0;
        const id = this.props.registrationForm.id;

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep5Page.title', defaultMessage: 'Pre-Check-in'}} class="step5">

                <SelfServiceStepLayout id={id} title={{id: 'bonfire.containers.SelfServiceStep5Page.stepTitle', defaultMessage: 'Registrierung Mitreisende'}}/>


                <div className="text">
                    <div className="wrapper">
                        <div className="with-bold sar-big">

                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep5Page.countTraveler'
                                defaultMessage='Es sind - Mitreisende angegeben.'
                                values={{
                                    count: totalGuests,
                                    b(fragments) {
                                        return <b>{fragments}</b>
                                    }
                                }}
                            />

                        </div>
                        <div className="sar-big register-text">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep5Page.regCheckText'
                                defaultMessage='Wir bitten Sie, alle Mitreisenden zu registrieren.'
                            ></FormattedMessage>
                        </div>
                    </div>
                </div>
                <div className="bottom">

                    <Link to={`/self-service/${id}/step6`}>
                        <button className="btn slb main">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep5Page.buttonYes'
                                defaultMessage='Ja'
                            ></FormattedMessage>
                        </button>
                    </Link>

                    <Link to={`/self-service/${id}/step8`}>
                        <button className="btn slb">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep5Page.buttonNo'
                                defaultMessage='Nein'
                            ></FormattedMessage>
                        </button>
                    </Link>

                    <div className="card-title sar-small">
                        <FormattedMessage
                            id='bonfire.containers.SelfServiceStep5Page.regTextEnd'
                            defaultMessage='Die Vorabregistrierung aller Mitreisenden vereinfacht das Check-in vor Ort.'
                        ></FormattedMessage>
                    </div>
                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep5 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
    })
)(SelfServiceStep5Component);

