import React from 'react';
import PropTypes from 'prop-types';
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {ServicePartnerForm} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerForm";
import {FormattedMessage} from "react-intl";
import ServicePartnerFormLogic, {emptyServicePartnerFormData} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerFormLogic";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {Actions as ServicePartnerCreatePageActions} from "bonfire/ZTerminal/ServicePartner/ServicePartnerCreate/redux";
import {Actions as ServicePartnerListPageActions} from "bonfire/ZTerminal/ServicePartner/ServicePartnerList/redux";

export class ServicePartnerCreatePageComponent extends React.Component {

    static propTypes = {
        loading: PropTypes.bool.isRequired,
        servicePartnerData: ServicePartnerFormLogic.SERVICE_PARTNER_DATA_PROPTYPE,
        validationData: ServicePartnerFormLogic.VALIDATION_DATA_PROPTYPE,
        setServicePartnerData: PropTypes.func.isRequired,
        clearValidationData: PropTypes.func.isRequired,
        createServicePartner: PropTypes.func.isRequired,
        invalidateServicePartnerListData: PropTypes.func.isRequired
    };

    constructor(props, context) {
        super(props, context);
        this.onSubmit = this.onSubmit.bind(this);
    }

    componentDidMount() {
        this.props.clearValidationData();
        if (!this.props.servicePartnerData) {
            this.props.setServicePartnerData(emptyServicePartnerFormData());
        }
    }

    get breadcrumbPath() {
        return [
            {
                id: 'bonfire.components.AdministrationSidebar.basedata',
                defaultMessage: 'Stammdaten',
                url: '/zterminal/servicepartner'
            },
            "Neuer Leistungs Partner"
        ];
    }

    onSubmit() {
        this.props.createServicePartner(this.props.servicePartnerData)
            .then(() => {
                this.props.invalidateServicePartnerListData();
                this.props.history.push('/zterminal/servicepartner');
            })
    }

    render() {
        return <div id="servicepartner-create-page" className="zterminal-content-box">
            <WithBreadcrumb icon="users" path={this.breadcrumbPath} />
            <div className="zterminal-content-box-body p-3 overflow-auto">
                <div className="container-fluid">
                    <LoadingOverlay loading={this.props.loading}>
                        {this.props.servicePartnerData ? (
                            <ServicePartnerForm
                                servicePartnerData={this.props.servicePartnerData}
                                validationData={this.props.validationData}
                                onDataChange={this.props.setServicePartnerData}
                            />
                        ) : (
                            <div>Kann Service Partner nicht anzeigen</div>
                        )}
                    </LoadingOverlay>
                </div>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-primary btn-160" onClick={this.onSubmit}>
                                <FormattedMessage
                                    id="bonfire.containers.administration.User.create.createButton"
                                    defaultMessage="Erstellen"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }
}

export default compose(
    withRouter,
    connect(
        state => {
            const spCreateState = state.getIn(['zterminal', 'servicePartner', 'create']);
            return {
                servicePartnerData: spCreateState.get('servicePartnerData'),
                validationData: spCreateState.get('validationData'),
                loading: spCreateState.get('loading')
            };
        },
        dispatch => ({
            clearValidationData: () => dispatch(ServicePartnerCreatePageActions.clearValidationData()),
            setServicePartnerData: spData => dispatch(ServicePartnerCreatePageActions.setServicePartnerData(spData)),
            createServicePartner: spData => dispatch(ServicePartnerCreatePageActions.createServicePartner(spData)),
            invalidateServicePartnerListData: () => dispatch(ServicePartnerListPageActions.invalidateData())
        })
    )
)(ServicePartnerCreatePageComponent);
