import React, {ReactElement, useState} from 'react';
import {ViewServicePartnerComponent} from "./ViewServicePartnerComponent";
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {FormattedMessage} from "react-intl";
import SobrPopup from "components/SobrPopup/SobrPopup";
import {ReportChangeForm} from "bonfire/ZTerminal/ServicePartner/ReportChange/ReportChangeForm";
import {BreadcrumbPath} from "bonfire/ZTerminal/breadcrumb/breadcrumbSlice";
import {ServicePartner} from "bonfire/ZTerminal/ServicePartner/ServicePartner";

export interface ViewServicePartnerPageProps {
    servicePartner: ServicePartner
}

export function ViewServicePartnerPage(props: ViewServicePartnerPageProps): ReactElement {
    const [showReportChangeWindow, setShowReportChangeWindow] = useState(false);
    const breadcrumbPath = createBreadcrumbPath(props.servicePartner);


    return <div id="view-servicepartner-page" className="zterminal-content-box">
        <WithBreadcrumb icon={'hotel-small'} path={breadcrumbPath}/>

        <SobrPopup
            show={showReportChangeWindow}
            title={<FormattedMessage id="zterminal.servicePartner.masterdata.reportChange"
                                     defaultMessage="Änderung melden"/>}
            onClose={() => setShowReportChangeWindow(false)}
        >
            <ReportChangeForm onSubmit={() => setShowReportChangeWindow(false)}/>
        </SobrPopup>

        <div className="zterminal-content-box-body p-3 scrollable">
            <div className="container-fluid">
                <ViewServicePartnerComponent servicePartner={props.servicePartner}/>
            </div>
        </div>

        <div className="zterminal-content-box-footer">
            <div className="container-fluid px-4 py-2">
                <div className="row">
                    <div className="col-12">
                        <button className="btn btn-primary"
                                onClick={() => setShowReportChangeWindow(true)}>
                            <FormattedMessage id="zterminal.servicePartner.masterdata.reportChange"
                                              defaultMessage="Änderung melden"/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>;
}

function createBreadcrumbPath(servicePartner: ServicePartner): BreadcrumbPath {
    const breadcrumbPath: BreadcrumbPath = [{
        id: 'bonfire.components.AdministrationSidebar.basedata',
        defaultMessage: 'Stammdaten'
    }];
    if (servicePartner.partnerGroup) {
        breadcrumbPath.push(servicePartner.partnerGroup.name);
    }
    breadcrumbPath.push(servicePartner.name);
    return breadcrumbPath;

}
