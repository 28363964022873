import React, { useState, useEffect } from 'react';
import { createApiEndpoint } from "../../../../../../../services/api/ApiService";
import moment from "moment";
import { AnalyticsBarsSelectors, calculatePercentage, getServicePartnerBarData } from "../../../../AnalyticsBarsSlice";
import {useDispatch, useSelector} from "react-redux";
import { formatNumber } from "../../../../helpers";
import { AnalyticsSelectors, getPartnerTypeAndCategoryId } from 'bonfire/ZTerminal/Analytics/AnalyticsSlice';
import { SessionSelectors } from 'bonfire/ZTerminal/Session/SessionSlice';

export const AnalyticsBetriebeCard = () => {

    const [data, setData] = useState<{servicePartners:number, servicePartnersCompared:number, changePercentage:any}>();
    const trigger = useSelector(AnalyticsSelectors.trigger);
    const dispatch = useDispatch();
    const startTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const startDate = moment(startTimeFrame).format("YYYY-MM-DD");
    const endDate = moment(endTimeFrame).format("YYYY-MM-DD");
    const servicePartners = useSelector(AnalyticsBarsSelectors.servicePartnerBarDataSelection);
    const servicePartnersCompared = useSelector(AnalyticsBarsSelectors.servicePartnerBarDataCompareSelection);
    const startCompareFrame = useSelector(AnalyticsSelectors.startDateCompareFrame);
    const endCompareFrame = useSelector(AnalyticsSelectors.endDateCompareFrame);
    const startDateCompare = moment(startCompareFrame).format("YYYY-MM-DD");
    const endDateCompare = moment(endCompareFrame).format("YYYY-MM-DD");
  
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id || "";
    const dropdownValue = useSelector(AnalyticsSelectors.dropdownValues);
    const dropdownCompareValue = useSelector(AnalyticsSelectors.compareDropdownValues);
    const changePercentage = calculatePercentage(servicePartners, servicePartnersCompared);
          
    useEffect(() => {
        const fetchData = async () => {

            try {
             
            await handleDropdownChange(dropdownValue.selectedKeys, dropdownValue.selectedRadio, startDate, endDate, false);
            await handleDropdownChange(dropdownCompareValue.selectedKeys,dropdownCompareValue.selectedRadio, startDateCompare, endDateCompare, true);
           
        setData({
                    servicePartners: servicePartners,
                    servicePartnersCompared: servicePartnersCompared,
                    changePercentage: changePercentage,
                });
            }
        
            catch (error) {
                console.log(error);
            }
        }
        fetchData();
    }, [trigger, dropdownValue, dropdownCompareValue]);

    const handleDropdownChange = async (selectedKeys: string[], selectedRadio: string, startDate: string, endDate:string, isCompare: boolean) => {
        const { partnerType, categoryId } = getPartnerTypeAndCategoryId(selectedKeys);
   
        if (selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0)) {
           await dispatch(getServicePartnerBarData({ startDate, endDate, isCompare }));
           return
          }

         else if (selectedRadio === "Own") {
           //bars
            await dispatch(getServicePartnerBarData({ startDate, endDate, isCompare, servicePartnerId }));       
       return
          }

        else  if (selectedKeys?.length < 6  && selectedKeys.length > 0 && selectedRadio !== "Own" ) {
           //bars
          await dispatch(getServicePartnerBarData({ startDate, endDate, isCompare, categoryId, partnerType }));
         return
            }
        
       }
    return (
        <React.Fragment>
            <div className="analytics-companies-card">
                   <a className='analytics-info-container-title'> Betriebe </a> 
                <div className="info-container">
                    <div className="info-label">
                        Auswahl 
                    </div>
                    <div className="info-label">
                    /  Vergleich 
                     </div>
                    </div>
     
                    <div className="info-value-container">
                        <div className="compare-value">

                            {formatNumber(servicePartners)}                
                        <div className="info-value-percentage-container">
                            <div className="info-value-percentage">
                                {formatNumber(changePercentage.toFixed(2))} %
                            </div>
                            {(servicePartnersCompared !== undefined && servicePartners !== undefined && servicePartners !== 0) ?
                                (servicePartners > servicePartnersCompared) ? <div className="triangle-positive-big" /> : <div className="triangle-negative-big" />
                                : null
                            }
                         </div>
                        </div>                    
                        <div className="compare-value">
                        <div className='separator-line'> / </div>
                            {formatNumber(servicePartnersCompared)}
                        </div>
                  </div>
            </div>
        </React.Fragment>
    );
}