import  { useState, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getServicePartnerTypes, SessionSelectors } from 'bonfire/ZTerminal/Session/SessionSlice';
import { AnalyticsSelectors, getAccomodationCategories} from '../../AnalyticsSlice';
import { SobrDropdownWithCheckbox } from 'components/zterminal/SobrDropdown/SobrDropdownWithCheckbox/SobrDropdownWithCheckbox';

interface AccomodationCategoriesDropdownProps {
  onDropdownChange: (selectedKeys: string[], selectedRadio: string) => void;
}

export const AccomodationCategoriesDropdown = ({ onDropdownChange }: AccomodationCategoriesDropdownProps) => {
  const dispatch = useDispatch();
  const partnerTypeList = useSelector(SessionSelectors.partnerTypes);
  const accomodationCategoriesList = useSelector(AnalyticsSelectors.accomodationCategoryTypes);
  const [selectedKeys, setSelectedKeys] = useState<string[]>([]); 
  const [listLoaded, setListLoaded] = useState(false);
  const [selectedRadio, setSelectedRadio] = useState("All");
  const radioButtons = useMemo(
    () => [{ value: 'Own', label: 'Mein Betrieb' }, { value: 'All', label: 'Destination' }],
    []
  );

  const filteredPartnerTypeList = useMemo(() => {
    if (!partnerTypeList) return []; // Add a check to handle possible null or undefined values
    return partnerTypeList.filter(pt => pt.label === 'Apartement' || pt.label === 'Berghütte');
  }, [partnerTypeList]);

  const items = useMemo(() => {
    const accommodationItems = (accomodationCategoriesList || []).map(pt => ({ label: pt.label, key: pt.value }));

    const partnerTypeItems = (filteredPartnerTypeList || []).map(pt => ({ label: pt.label, key: pt.value }));

    return [...accommodationItems.sort((a, b) => a.label.localeCompare(b.label)), ...partnerTypeItems];
  }, [accomodationCategoriesList, filteredPartnerTypeList]);



  useEffect(() => {
  
      const loadItemsList = async () => {
        try {
         
          const partnerTypes = await dispatch(getServicePartnerTypes());
          const categoryIds = await dispatch(getAccomodationCategories());
          setListLoaded(true);
          //@ts-ignore
          const filteredPartnerTypes = partnerTypes.data.filter(pt => pt.label === 'Apartement' || pt.label === 'Berghütte')
          //@ts-ignore
          const initialSelectedItems = [...filteredPartnerTypes, ...categoryIds.data]
          const newSelectedKeys = initialSelectedItems.map((item) => item.value);
      
          setSelectedKeys(newSelectedKeys);
        }
    
        catch (error) {
          console.log(error);
        }
      }
    if (!listLoaded) {
      loadItemsList();
    }
}, [items, accomodationCategoriesList, partnerTypeList]);


  const onSelect = (keys: string[]) => {
    const allSelected = keys.length === items.length;
    setSelectedKeys(keys);
    const selectedRadioValue = selectedRadio === 'All' ? allSelected ? 'All' : 'All' : 'Own';
    onDropdownChange(keys, selectedRadioValue);
  };

  const handleRadioSelect = (value: string) => {
    setSelectedRadio(value);
    if (value === 'Own') {
      setSelectedKeys([]);
      setSelectedRadio('Own');
      onDropdownChange([], 'Own');
    } else {
      setSelectedRadio('All');
      setSelectedKeys(items.map((item) => item.key));
      onDropdownChange(items.map((item) => item.key), 'All');
    }
  };


    return (
      <div className="analytics-header-dropdown">
        <div className="analytics-accomodation-dropdown">
          <SobrDropdownWithCheckbox
            radioButtons={radioButtons}
            checkboxes={items}
            onRadioSelect={handleRadioSelect}
            onSelect={onSelect}
            selectedKeys={selectedKeys}
            initialSelectedRadio={selectedRadio}
            onDropdownChange={(selectedKeys:any, selectedRadio:string) => onDropdownChange(selectedKeys, selectedRadio)} 
          />
        </div>
      </div>
    );
    
  }
 

