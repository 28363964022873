/* eslint-disable comma-spacing,no-plusplus,no-unused-vars,react/no-did-mount-set-state */
/*
 * Login
 *
 * List all the features
 */
import React from 'react';
import {history} from '../../../utils/history';
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";

export default class StartPage extends React.PureComponent {

    render() {
        history.push('/');
        return (
            <LoadingOverlay loading={true}/>
        )
    }
}
