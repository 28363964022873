import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import ReactTable, {RowInfo} from "react-table";
import ScaleLoader from "react-spinners/ScaleLoader";
import Moment from "moment";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEye} from "@fortawesome/free-solid-svg-icons";
import SobrPagination from "components/SobrPagination/SobrPagination";

import "./TouristTaxRegistrationFormTable.scss";
import {useHistory} from "react-router-dom";
import {SobrErrorMessage} from "components/SobrErrorMessage/SobrErrorMessage";
import {assoc} from "ramda";
import {
    TouristTaxRegistrationFormTableActions,
    TouristTaxRegistrationFormTableSelectors
} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/table/TouristTaxRegistrationFormTableSlice";
import {RegistrationForm} from "bonfire/ZTerminal/RegistrationForms/RegistrationFormModel";
import {useIntl} from "react-intl";
export interface StatementDetailRegistrationFormListProps {

}

export function TouristTaxRegistrationFormTable(props: StatementDetailRegistrationFormListProps) {
    const intl = useIntl();
    const loading = useSelector(TouristTaxRegistrationFormTableSelectors.getLoading);
    const error = useSelector(TouristTaxRegistrationFormTableSelectors.getError);
    const query = useSelector(TouristTaxRegistrationFormTableSelectors.getQuery);
    const registrationForms = useSelector(TouristTaxRegistrationFormTableSelectors.getRegistrationForms);
    const totalPages = useSelector(TouristTaxRegistrationFormTableSelectors.getTotalPages);
    const expandedRows = useSelector(TouristTaxRegistrationFormTableSelectors.getExpandedRows);
    const history = useHistory();

    const page = query?.page ?? 1;

    const dispatch = useDispatch();
    const toggleRowExpanded = (index: number) => {
        const expanded = expandedRows[index] ?? false;
        dispatch(TouristTaxRegistrationFormTableActions.setRowExpanded(!expanded, index));
    };
    const setPage = (page: number) => {
        if (!query) {
            console.error("cannot set page if not all search params are set");
            return;
        }
        const newQuery = assoc('page', page, query);
        dispatch(TouristTaxRegistrationFormTableActions.query(newQuery));
    }

    const handleSortChange = () => {
        for (const key in expandedRows) {
            dispatch(TouristTaxRegistrationFormTableActions.setRowExpanded(false, Number(key)))
        }
    };

    return <div id="statement-detail-registration-form-list" className="d-flex flex-growing flex-column">
        {error && <SobrErrorMessage title="konnte Meldescheine nicht laden" message={error}/>}
        {!error && (<>
            <div id="statement-detail-registration-form-list-table" className="flex-growing scrollable">
                <div className="sobrdatatable w-100">
                    <ReactTable
                        data={registrationForms}
                        noDataText={intl.formatMessage({id: 'bonfire.components.SobrDataTable.noData'})}
                        loading={loading}
                        loadingText={<ScaleLoader color='#4fa1ff'/>}
                        showPagination={false}
                        className="-striped -highlight h-100 mh-100 w-100 touristtax-table"
                        getTrProps={(state: any, rowInfo: RowInfo | undefined) => ({
                            onClick: () => {
                                if (rowInfo) {
                                    toggleRowExpanded(rowInfo.viewIndex);
                                }
                            }
                        })}
                        SubComponent={row => <RegistrationFormDetails registrationForm={row.original}/>}
                        expanded={expandedRows}
                        onSortedChange={handleSortChange}
                        columns={[
                            {Header: intl.formatMessage({id: 'bonfire.containers.administration.RegistrationForm.detail.name'}), accessor: 'name'},
                            {Header: intl.formatMessage({id: 'bonfire.touristTax.header.anzahl'}), accessor: 'totalGuestCount'},
                            {
                                Header: intl.formatMessage({id: 'bonfire.touristTax.header.date'}),
                                Cell: row => `${Moment.utc(row.original.checkinDate).format('DD.MM.YYYY')} - ${Moment.utc(row.original.checkoutDate).format('DD.MM.YYYY')}`
                            },
                            {Header: intl.formatMessage({id: 'bonfire.touristtax.list.tableheaders.touristtaxBetrag'}), Cell: row => `${row.original.totalTax} CHF`},
                            {
                                Header: '',
                                Cell: row => <div className="cursor-pointer"
                                                  onClick={() => history.push(`/zterminal/registrationform/edit/${row.original.id}`)}>
                                    <FontAwesomeIcon icon={faEye}/>
                                </div>
                            }
                        ]}
                    />
                </div>
            </div>
            <div className="flex-fixed px-4 py-2">
                <SobrPagination
                    numPages={totalPages}
                    currentPage={page}
                    onSelectPage={setPage}
                    className="pagination flex-wrap"
                />
            </div>
        </>)}
    </div>
}

interface RegistrationFromDetailsProps {
    registrationForm: RegistrationForm
}

function RegistrationFormDetails(props: RegistrationFromDetailsProps) {
    const intl = useIntl();
    return <div className="statement-detail-registration-form-list-table-registration-form-details">
        <ReactTable
            data={props.registrationForm.guests}
            showPagination={false}
            minRows={0}
            columns={[
                {Header: intl.formatMessage({id: 'bonfire.containers.administration.RegistrationForm.detail.name'}), accessor: 'displayName'},
                {Header: intl.formatMessage({id: 'bonfire.touristTax.header.guestType'}), Cell: row => row.original.primary ? intl.formatMessage({id: 'bonfire.touristTax.header.mainTraveller'}) : intl.formatMessage({id: 'bonfire.touristTax.header.travelCompanion'})},
                {
                    Header: intl.formatMessage({id: 'bonfire.touristTax.header.date'}),
                    Cell: row => `${Moment.utc(row.original.checkinDate).format('DD.MM.YYYY')} - ${Moment.utc(row.original.checkoutDate).format('DD.MM.YYYY')}`
                },
                {
                    Header: intl.formatMessage({id: 'bonfire.touristtax.list.tableheaders.touristtaxBetrag'}),
                    Cell: row => `CHF ${row.original.totalTax}`
                }
            ]}
        />
    </div>
}





