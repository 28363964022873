import React from 'react';
import PropTypes from 'prop-types';
import "./SobrErrorMessage.scss";

export function SobrErrorMessage(props) {
    return <div className="sobr-error-message alert alert-danger">
        <p className="mb-1"><strong>Fehler: </strong>{props.title}</p>
        {props.message && <p className="mb-0 text-monospace">{props.message}</p>}
    </div>
}

SobrErrorMessage.propTypes = {
    title: PropTypes.string,
    message: PropTypes.string
}
