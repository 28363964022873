import React, {ReactElement, useEffect} from 'react';
import {Redirect, Route, Switch, useHistory} from "react-router-dom";
import RegistrationForms from "./RegistrationForms/RegistrationForms";
import {useDispatch, useSelector} from "react-redux";
import {SessionSelectors} from "./Session/SessionSlice";
import {Session} from "bonfire/ZTerminal/Session/Session";
import {Sidebar} from "./layout/Sidebar";
import {Header} from "./layout/Header";
import ZTerminalPages from "./ZTerminalPages";
import {Breadcrumb} from "./breadcrumb/Breadcrumb";
import {Cockpit} from "./Cockpit/Cockpit";
import ServicePartnerSection from "bonfire/ZTerminal/ServicePartner/ServicePartnerSection";
import TouristtaxSection from "bonfire/ZTerminal/Touristtax/TouristtaxSection";
import UsersSection from "bonfire/ZTerminal/Users/UsersSection";
import Analytics from "./Analytics";

import './zterminal.scss';
import {Actions as LanguageActions} from "bonfire/Language/redux";


export function ZTerminal(): ReactElement {
    const history = useHistory();
    const roles = useSelector(SessionSelectors.roles);
    const user = useSelector(SessionSelectors.user);

    const dispatch = useDispatch();
    const pages = ZTerminalPages.getAllowedPages(roles);
    const defaultPage = pages.length > 0 ? pages[0].link : '/zterminal/cockpit';

    useEffect(() => {
        dispatch(LanguageActions.setLocale('de'));
        if (user?.virgin) {
            history.push('/welcome');
        }
    }, [user, history, dispatch]);

    return <Session>
        <div id="zterminal" className="h-100 d-flex flex-column">
            <Header/>
            <div id="zterminal-body" className="flex-growing d-flex">
                <div className="col-1 zterminal-sidebar-width">
                    {/* @ts-ignore*/}
                    <Sidebar items={pages}/>
                </div>
                <Switch>
                    <Route path="/zterminal/analytics" component={Analytics} />
                    <Route path="/zterminal*" render={() =>
                        <div className="col-11 w-100 mw-100 d-flex flex-column">
                            <div className="flex-fixed">
                                <Breadcrumb/>
                            </div>
                            <div id="zterminal-content" className="flex-growing d-flex">
                                <Switch>
                                    <Route exact path="/zterminal"
                                           render={() => <Redirect to={defaultPage}/>}/>
                                    <Route path="/zterminal/cockpit" component={Cockpit}/>
                                    <Route path="/zterminal/registrationform" component={RegistrationForms}/>
                                    <Route path="/zterminal/servicepartner" component={ServicePartnerSection}/>
                                    <Route path="/zterminal/touristtax" component={TouristtaxSection}/>
                                    <Route path="/zterminal/user" component={UsersSection}/>
                                </Switch>
                            </div>
                        </div>
                    } />
                </Switch>
            </div>
        </div>
    </Session>
}

