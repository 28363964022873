import { createSelector } from 'reselect';

const selectLoginData = (state) => state.get('login');
const selectLoginData2 = (state) => state.get('language');

const makeSelectLoggedId = () => createSelector(
  selectLoginData,
  (loginState) => loginState.getIn(['loggedIn'])
);

const makeSelectLoggedId2 = () => createSelector(
  selectLoginData2,
  (loginState) => loginState.getIn(['loggedIn'])
);

export {
  selectLoginData,
  selectLoginData2,
  makeSelectLoggedId,
  makeSelectLoggedId2,
};

