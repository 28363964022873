import {ReactElement} from 'react';
import SobrDropdown from "components/zterminal/SobrDropdown/SobrDropdown";
import {useDispatch, useSelector} from "react-redux";
import {SessionActions, SessionSelectors} from "../Session/SessionSlice";

export function ServicePartnerChooser(): ReactElement {
    const userType = useSelector(SessionSelectors.userType);
    const currentServicePartner = useSelector(SessionSelectors.currentServicePartner);
    const availableServicePartners = useSelector(SessionSelectors.servicePartners);
    const servicePartnerDropdownItems = availableServicePartners.map(sp => ({
        key: sp.id,
        label: sp.name
    }));

    const dispatch = useDispatch();

    const selectServicePartner = (key: string) => {
        const newServicePartner = availableServicePartners.find(sp => sp.id === key);
        if (newServicePartner) {
            dispatch(SessionActions.setCurrentServicePartner(newServicePartner));
        }
    }

    switch (userType) {
        case "service-partner":
            if (availableServicePartners.length === 1) {
                return <span>{currentServicePartner?.companyName ?? ""}</span>;
            } else {
                return <SobrDropdown
                    label={currentServicePartner?.name ?? ""}
                    items={servicePartnerDropdownItems}
                    onSelect={selectServicePartner}
                />
            }

        case "zermatt-tourismus":
            return <span>Zermatt Tourismus </span>
        case "police":
            return <span>Polizei Zermatt </span>
        default:
            return <></>

    }
}
