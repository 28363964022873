import React from 'react';
import PropTypes from 'prop-types';
import SobrSearchField from "components/SobrSearchField/SobrSearchField";
import {connect} from "react-redux";
import {Actions} from "./RegistrationFormSearchFilterSlice";
import {RegistrationFormListActions} from '../RegistrationFormList/registrationFormListSlice';
import {SobrDatePicker} from "components/SobrDatePicker/SobrDatePicker";
import {injectIntl} from "react-intl";
import Moment from "moment/moment";

function callIfExists(func, arg) {
    if (func && typeof func === 'function') {
        func(arg);
    }
}

export class PoliceRegistrationFormSearchFilterComponent extends React.Component {

    static propTypes = {
        startDate: PropTypes.object,
        endDate: PropTypes.object,
        searchTerm: PropTypes.string,
        disabled: PropTypes.bool,
        onSetSearchTerm: PropTypes.func,
        onSetStartDate: PropTypes.func,
        onSetEndDate: PropTypes.func,
        onSearch: PropTypes.func,
        setCurrentPage: PropTypes.func
    };

    constructor(props) {
        super(props);
        this.setStartDate = this.setStartDate.bind(this);
        this.setEndDate = this.setEndDate.bind(this);
        this.setSearchTerm = this.setSearchTerm.bind(this);

        this.setStartDate(Moment.utc().subtract(6, 'months').startOf('day'))
        this.setEndDate(Moment.utc().startOf('day'))

    }

    setStartDate(startDate) {
        callIfExists(this.props.setCurrentPage, 1);
        callIfExists(this.props.onSetStartDate, startDate);
        callIfExists(this.props.onSearch);
    }

    setEndDate(endDate) {
        callIfExists(this.props.setCurrentPage, 1);
        callIfExists(this.props.onSetEndDate, endDate.clone().endOf('day'));
        callIfExists(this.props.onSearch);
    }

    setSearchTerm(searchTerm) {
        callIfExists(this.props.setCurrentPage, 1);
        callIfExists(this.props.onSetSearchTerm, searchTerm);
        callIfExists(this.props.onSearch);
    }

    get disabled() {
        return this.props.disabled;
    }

    render() {
        const {formatMessage} = this.props.intl;
        return <div id="registration-form-search-filter" className="search-bar">
            <div className="row">
                <div className="col-md-5 col-lg-3 search-bar-col">
                    <SobrDatePicker
                        moment={this.props.startDate}
                        showMonthPicker={true}
                        onSetMoment={this.setStartDate}
                        placeholder={formatMessage({ id: 'bonfire.containers.placeholder.start'})}
                        disabled={this.disabled}
                        todayEnabled={true}
                    />
                </div>
                <div className="col-md-5 col-lg-3 search-bar-col">
                    <SobrDatePicker
                        moment={this.props.endDate}
                        showMonthPicker={true}
                        onSetMoment={this.setEndDate}
                        placeholder={formatMessage({ id: 'bonfire.containers.placeholder.end'})}
                        disabled={this.disabled}
                        todayEnabled={true}
                    />
                </div>
                <div className="col-md-12 col-lg-6 search-bar-col">
                    <SobrSearchField
                        value={this.props.searchTerm}
                        onChangeTerm={this.setSearchTerm}
                        disabled={this.disabled}
                    />
                </div>
            </div>
        </div>
    }

}

export default connect(
    state => {
        const filter = state.getIn(['zterminal', 'registrationForms', 'searchFilter']);
        const disabled = state.getIn(['zterminal', 'registrationForms', 'registrationFormList', 'loading']);

        return {
            startDate: filter.getIn(['dateRange', 'start']),
            endDate: filter.getIn(['dateRange', 'end']),
            searchTerm: filter.get('term'),
            disabled: disabled
        };
    },
    dispatch => ({
        onSetStartDate: startDate => {
            dispatch(Actions.setDateRangeStart(startDate));
            dispatch(RegistrationFormListActions.invalidate());
        },
        onSetEndDate: endDate => {
            dispatch(Actions.setDateRangeEnd(endDate));
            dispatch(RegistrationFormListActions.invalidate());
        },
        onSetSearchTerm: searchTerm => {
            dispatch(Actions.setSearchTerm(searchTerm));
            dispatch(RegistrationFormListActions.invalidate());
        },
        setCurrentPage: currentPage => {
            dispatch(Actions.setCurrentPage(currentPage));
            dispatch(RegistrationFormListActions.invalidate());
        }
    })
)(injectIntl(PoliceRegistrationFormSearchFilterComponent));
