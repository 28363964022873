import React from 'react';
import PropTypes from 'prop-types';
import {debounce} from 'debounce';
import SobrSearchHTML from "../SobrSearchHTML";

export default class SobrSearchField extends React.Component {

    static propTypes = {
        value: PropTypes.string,
        onChangeTerm: PropTypes.func,
        disabled: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            term: props.value
        };

        this._input = React.createRef();

        this.debouncedSearch = this.props.onChangeTerm ? debounce(this.props.onChangeTerm, 500) : null;
        this.changeTerm = this.changeTerm.bind(this);
    }

    moveCaretAtEnd(e) {
        let temp_value = e.target.value
        e.target.value = ''
        e.target.value = temp_value
    }

    changeTerm(term) {
        this.setState({ term: term }, () => {
            if (this.debouncedSearch) {
                this.debouncedSearch(term)
            }
        });
    }

    componentDidUpdate(prevProps, prevState) {
        this._input.current.focus();
    }

    render() {

        return (
            <SobrSearchHTML
                placeholder={"bonfire.regForm.searchField"}
                onChange={term => {this.changeTerm(term)}}
                value={this.state.term}
                disabled={this.props.disabled}
                onFocus={this.moveCaretAtEnd}
                inputRef={c => (this._input.current = c)}
            >
            </SobrSearchHTML>
        )
    }

    get placeholder() {
        switch (this.props.language) {
            case 'de':
                return "Suche";
            case 'en':
                return "Search";
            case 'fr':
                return "Recherche";
            case 'it':
                return "Ricerca";
            default:
                return "Suche";
        }
    }

}

