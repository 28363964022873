import React from 'react';
import { Graph } from "../../../../../components/Graph/Graph";
import 'chartjs-adapter-moment';

export const AuslastungGraphChange = () => {
    return (
        <React.Fragment>
            <Graph
                title={"% Veränderung zu Vergleich"}
                graphDatasetId={"occupancyRateChartCompareData"}
                isCompareGraph={true}
                isPercent={true}
                tooltipTitle={"Auslastung"}
                hasPrediction={false}
            />
        </React.Fragment>
    );
};