import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AnalyticsBarsSelectors, calculatePercentage, getOvernightStaysBarData} from "../../../../AnalyticsBarsSlice";
import moment from "moment";
import {formatNumber} from "../../../../helpers";
import { AnalyticsSelectors, getPartnerTypeAndCategoryId } from 'bonfire/ZTerminal/Analytics/AnalyticsSlice';
import { SessionSelectors } from 'bonfire/ZTerminal/Session/SessionSlice';

export const AnalyticsLogierNaechteCard = () => {
    const [data, setData] = useState<{ overnightStays: number, compared: number, changePercentage:any }>();
    const overnightStays = useSelector(AnalyticsBarsSelectors.overnightStaysBarDataSelection);
    const compared = useSelector(AnalyticsBarsSelectors.overnightStaysBarDataCompareSelection);
    const trigger = useSelector(AnalyticsSelectors.trigger);
    const dispatch = useDispatch();
 
    const startTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const startDate = moment(startTimeFrame).format("YYYY-MM-DD");
    const endDate = moment(endTimeFrame).format("YYYY-MM-DD");
    const startCompareFrame = useSelector(AnalyticsSelectors.startDateCompareFrame);
    const endCompareFrame = useSelector(AnalyticsSelectors.endDateCompareFrame);
    const startDateCompare = moment(startCompareFrame).format("YYYY-MM-DD");
    const endDateCompare = moment(endCompareFrame).format("YYYY-MM-DD");
  
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id || ""; 
    const dropdownValue = useSelector(AnalyticsSelectors.dropdownValues);
    const dropdownCompareValue = useSelector(AnalyticsSelectors.compareDropdownValues);
 
    const changePercentage = calculatePercentage(overnightStays, compared);
       
    useEffect(() => {
   
        const fetchData = async () => {
            try {
                handleDropdownChange(dropdownValue.selectedKeys, dropdownValue.selectedRadio, startDate, endDate, false);
                handleDropdownChange(dropdownCompareValue.selectedKeys,dropdownCompareValue.selectedRadio, startDateCompare, endDateCompare, true);
        
                setData({
                   overnightStays:overnightStays,
                   compared: compared,
                  changePercentage: changePercentage,
                });
            }
           catch(error) {
               console.log(error);
            }
        }
        
           fetchData();
       }, [trigger, dropdownValue, dropdownCompareValue, startDate, endDate]);
   
       const handleDropdownChange = (selectedKeys: string[], selectedRadio: string, startDate: string, endDate:string, isCompare: boolean) => {
        const { partnerType, categoryId } = getPartnerTypeAndCategoryId(selectedKeys);

           if (selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0)) {
               dispatch(getOvernightStaysBarData({ startDate, endDate, isCompare }));
           }
        
           else if (selectedRadio === "Own") {
               //bars
               dispatch(getOvernightStaysBarData({ startDate, endDate, isCompare, servicePartnerId }));
           }
        
           else if  (selectedKeys?.length < 6  && selectedKeys.length > 0 && selectedRadio !== "Own" )  {
               //bars
               dispatch(getOvernightStaysBarData({ startDate, endDate, isCompare, categoryId, partnerType }));
          }
 
       }
    return (
        <React.Fragment>
            <div className="analytics-overnight-card">
          <a className='analytics-info-container-title'>Logiernächte </a>
                <div className="info-container">
                    <div className="info-label">
                       Auswahl 
                    </div>
                    <div className="info-label">
                    /   Vergleich
                    </div>
                   
                </div>
                <div className="info-value-container">
                        <div className="compare-value">
                 {formatNumber(overnightStays)} 
                  <div className="info-value-percentage-container">
                            <div className="info-value-percentage">
                                {formatNumber(changePercentage.toFixed(2))} %
                            </div>
                                 {(compared !== undefined && overnightStays !== undefined && overnightStays !== 0) ?
                                (overnightStays > compared) ? <div className="triangle-positive-big" /> : <div className="triangle-negative-big" />
                                : null
                            }
                              </div>
                    </div>               
                    <div className="compare-value">
                        <div className='separator-line'> / </div>
                        {formatNumber(compared)} 
                    </div>
                </div>
          
            </div>
        </React.Fragment>
    );
}