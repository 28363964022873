import React, {ChangeEvent, useState} from 'react';
import {FormattedMessage} from "react-intl";
import {useDispatch, useSelector} from "react-redux";
import {Actions} from "./redux";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import {RootState} from "App";
import {useIntl} from "react-intl";

export interface ReportChangeFormProps {
    onSubmit?: () => void
}

export function ReportChangeForm(props: ReportChangeFormProps) {
    const intl = useIntl();
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id;
    const loading = useSelector((state: RootState) => state.getIn(['zterminal', 'servicePartner', 'reportChange', 'loading']));
    const error = useSelector((state: RootState) => state.getIn(['zterminal', 'servicePartner', 'reportChange', 'error']));

    const [text, setText] = useState("");
    const buttonSubmitDisabled = loading || !text;
    const dispatch = useDispatch();

    const handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => setText(event.target.value);
    const handleSubmit = () =>
        dispatch(Actions.submit(servicePartnerId, text))
            // @ts-ignore
            .then(() => {
                setText("");
                if (props.onSubmit) {
                    props.onSubmit();
                }
            });

    return <form>
        <div className="form-group row">
            <div className="col-sm-12 col-form-label ">
                <FormattedMessage id="zterminal.servicePartner.masterdata.reportChange.text"
                                  defaultMessage="Nachricht an Zermatt Tourismus"/>
            </div>
            <div className="col-sm-12">
                <textarea value={text} className="form-control" placeholder={intl.formatMessage({id: 'zterminal.servicePartner.masterdata.reportChange.placeholder'})}
                          onChange={handleTextChange} disabled={loading}/>
            </div>
        </div>
        {error && <div className="row">
            <div className="col-sm-12">
                <p className="alert alert-danger">
                    {error}
                </p>
            </div>
        </div>}
        <div className="form-group row">
            <div className="col-sm-12 text-right">
                <button type="button"
                        className="btn btn-primary"
                        onClick={handleSubmit}
                        disabled={buttonSubmitDisabled}>
                    <FormattedMessage id="zterminal.servicePartner.masterdata.reportChange"
                                      defaultMessage="Änderung melden"/>
                </button>
            </div>
        </div>
    </form>

}
