import {useCallback, useState} from "react";

export type tooltipData = {
    opacity: number;
    top: number;
    left: number;
    value: string;
    dataIndex: number;
    compareValue: string;
}

export const useCustomTooltip = (
    chartRef: any
) => {
    const [tooltip, setTooltip] = useState<tooltipData>({
        opacity: 0,
        top: 0,
        left: 0,
        value: '',
        dataIndex: 0,
        compareValue: '',
    });

    const customTooltip =  useCallback((context: any) => {
        const {chart} = context;
        const tooltipModel = context.tooltip
        if (!chart || !chartRef.current) return

        if (tooltipModel.opacity === 0) {
            setTooltip((prev: any) => ({...prev, opacity: 0}))
        }
        else {
            const position = context.chart.canvas.getBoundingClientRect()
            const newTooltipData = {
                opacity: 1,
                left: (tooltipModel.caretX + 255 > chart.width) ? position.left + tooltipModel.caretX - 255 : position.left + tooltipModel.caretX,
                top: (tooltipModel.caretX + 184 > chart.height) ? position.top + tooltipModel.caretY - 184 : position.top + tooltipModel.caretY,
                value: tooltipModel.dataPoints[0].formattedValue,
                compareValue: tooltipModel?.dataPoints[1]?.formattedValue,
                dataIndex: tooltipModel.dataPoints[0].dataIndex,
                show: true
            }

            const isEqual = (curr: any, newData: any): any => {
                return (
                    curr.date === newData.date &&
                    curr.value === newData.value &&
                    curr.opacity === newData.opacity
                );
            };

            if (!isEqual(tooltip, newTooltipData)) {
                setTooltip(newTooltipData);
            }
        }

    }, [tooltip.opacity, tooltip.value]);

    return {
        customTooltip,
        tooltip
    }
}