import {Map} from 'immutable';
import TouristtaxService from "services/touristtaxes/TouristtaxService";

export const InitialState = Map({
    touristtaxList: [],
    sorting: Map({
        column: 'name',
        direction: 'asc'
    }),
    loading: false,
    numPages: 1,
    currentPage: 1,
    searchTerm: '',
    showInactive: false
});

const ACTION_SET_LOADING = 'zterminal/touristtax/list/setLoading';
const ACTION_SET_PAGES = 'zterminal/touristtax/list/setPages';
const ACTION_SET_TOURISTTAX_LIST = 'zterminal/touristtax/list/setTouristtaxList';
const ACTION_SET_SORT_COLUMN = 'zterminal/touristtax/list/setSortColumn';
const ACTION_SET_SORT_ORDER = 'zterminal/touristtax/list/setSortOrder';
const ACTION_SET_CURRENT_PAGE = 'zterminal/touristtax/list/setCurrentPage';
const ACTION_SET_SEARCH_TERM = 'zterminal/touristtax/list/setSearchTerm';
const ACTION_SET_SHOW_INACTIVE = 'zterminal/touristtax/list/setShowInactive';

export const Actions = {
    setSortColumn,
    setSortOrder,
    setCurrentPage,
    setSearchTerm,
    setShowInactive,
    fetchListIfNecessary,
    setPayed
};

function setPayed(touristtaxId) {
    return dispatch => {
        TouristtaxService.setStatus(touristtaxId)
            .then( () => {
                dispatch(fetchListIfNecessary())
            })
            .catch(error => {
                return Promise.reject(error);
            })
    }
}

function fetchListIfNecessary() {

    const PAGE_SIZE = 20;
    return (dispatch, getState) => {
        const state = getState();
        const ttListState = state.getIn(['zterminal', 'touristtax', 'list']);

        const searchTerm = ttListState.get('searchTerm');
        const showInactive = ttListState.get('showInactive');
        const currentPage = ttListState.get('currentPage');
        const offset = PAGE_SIZE * (currentPage - 1);
        const sortCol = ttListState.getIn(['sorting', 'column']);
        const sortOrder = ttListState.getIn(['sorting', 'direction']);

        dispatch(setLoading(true));
        return TouristtaxService.listTouristtaxes(PAGE_SIZE, offset, sortCol, sortOrder, searchTerm, showInactive)
            .then(res => {
                dispatch(setTouristtaxList(res.data));
                dispatch(setPages(Math.ceil(res.totalCount / PAGE_SIZE)));
                dispatch(setLoading(false));
            })
            .catch(error => {
                dispatch(setLoading(false));
                return Promise.reject(error);
            })
    }


}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

function setSortColumn(sortColumn) {
    return {
        type: ACTION_SET_SORT_COLUMN,
        sortColumn
    };
}

function setSortOrder(sortOrder) {
    return {
        type: ACTION_SET_SORT_ORDER,
        sortOrder
    };
}

function setCurrentPage(currentPage) {
    return {
        type: ACTION_SET_CURRENT_PAGE,
        currentPage
    };
}

function setSearchTerm(searchTerm) {
    return {
        type: ACTION_SET_SEARCH_TERM,
        searchTerm
    };
}

function setShowInactive(showInactive) {
    return {
        type: ACTION_SET_SHOW_INACTIVE,
        showInactive
    };
}

function setPages(numPages) {
    return {
        type: ACTION_SET_PAGES,
        numPages
    };
}

function setTouristtaxList(touristtaxList) {
    return {
        type: ACTION_SET_TOURISTTAX_LIST,
        touristtaxList
    };
}

export function Reducer(state = InitialState, action) {

    switch (action.type) {
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_SET_SORT_COLUMN:
            return state.setIn(['sorting', 'column'], action.sortColumn);
        case ACTION_SET_SORT_ORDER:
            return state.setIn(['sorting', 'order'], action.sortOrder);
        case ACTION_SET_TOURISTTAX_LIST:
            return state.set('touristtaxList', action.touristtaxList);
        case ACTION_SET_PAGES:
            return state.set('numPages', action.numPages);
        case ACTION_SET_CURRENT_PAGE:
            return state.set('currentPage', action.currentPage);
        case ACTION_SET_SEARCH_TERM:
            return state.set('searchTerm', action.searchTerm);
        case ACTION_SET_SHOW_INACTIVE:
            return state.set('showInactive', action.showInactive);
        default:
            return state;
    }

}

export default {
    InitialState,
    Actions,
    Reducer
}
