/* eslint-disable no-unused-vars,consistent-return */
/*
 * Home SessionActions
 *
 * SessionActions change things in your application
 * Since this boilerplate uses a uni-directional data flow, specifically redux,
 * we have these actions which are the only way your application interacts with
 * your application state. This guarantees that your state is up to date and nobody
 * messes it up weirdly somewhere.
 *
 * To add a new Action:
 * 1) Import your constant
 * 2) Add a function like this:
 *    export function yourAction(var) {
 *        return { type: YOUR_ACTION_CONSTANT, var: var }
 *    }
 */
import {history} from '../../utils/history';

import {forgotPasswordService} from './service';
import {RESET_PASSWORD, RESET_PASSWORD_ERROR, RESET_PASSWORD_SUCCESS,} from './constants';
import {toast} from 'react-toastify';

/**
 * Changes the input field of the form
 *
 * @param  {name} name The new text of the input field
 *
 * @return {object}    An action object with a type of CHANGE_USERNAME
 */

export const passwordActions = {
    forgotPassword,
};


function forgotPassword(userData) {
    return (dispatch) => {
        dispatch(request({}));
        forgotPasswordService.passwordReset(userData)
            .then(
                (data) => {
                    toast.success("Instruktionen wurden per E-Mail an Sie verschickt.", {
                        bodyClassName: "toast-size",
                        toastClassName: "toast-success",
                    });
                    setTimeout(() => {
                        dispatch(success());
                        history.push('/login');
                    }, 2500);
                },
                (error) => {
                    dispatch(failure(error));
                }
            );
    };

    function request() {
        return {type: RESET_PASSWORD};
    }

    function success() {
        return {type: RESET_PASSWORD_SUCCESS};
    }

    function failure(error) {
        return {type: RESET_PASSWORD_ERROR, error};
    }

}
