import React, {ReactElement} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    TouristTaxDetailHeaderActions,
    TouristTaxDetailsHeaderSelectors
} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDetailHeaderSlice";
import {ExportPopup} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/ExportPopup";
import {FormattedMessage} from "react-intl";

export interface TouristTaxDetailHeaderExportButtonProps {

}

export function TouristTaxDetailHeaderExportButton(props: TouristTaxDetailHeaderExportButtonProps): ReactElement {
    const query = useSelector(TouristTaxDetailsHeaderSelectors.getQuery);
    const showPopup = useSelector(TouristTaxDetailsHeaderSelectors.getShowExportPopup);
    const dispatch = useDispatch();
    const setShowPopup = (b: boolean) => dispatch(TouristTaxDetailHeaderActions.setShowExportPopup(b));

    return <div>
        {query && (
            <ExportPopup
                show={showPopup}
                searchParams={query}
                onClose={() => setShowPopup(false)}
            />
        )}
        <button
            className="btn btn-primary btn-block px-3"
            onClick={() => setShowPopup(true)}
            disabled={!query}
        >
            <FormattedMessage id="bonfire.containers.administration.TouristTaxL.createButton"
                              defaultMessage="Export"/>
        </button>
    </div>
}
