/* eslint-disable no-unused-expressions,react/prop-types */
/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import React from 'react';
import {Redirect, Route, Switch, withRouter} from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min';
import '../../assets/style.css';
import 'react-toastify/dist/ReactToastify.min.css';
import WelcomeChallengePasswordPage from "../Welcome/ChallengePassword/Loadable";
import WelcomeChallengeContactInformationsPage from "../Welcome/ChallengeContactInformations/Loadable";
import StartPage from "../Welcome/StartComponent";
import {Helmet} from "react-helmet";

class App extends React.Component {

    render() {
        return (
            <div className="h-100">
                <Helmet
                    titleTemplate="Bonfire - %s"
                    defaultTitle="Bonfire"
                >
                    <meta name="description" content="A React.js Boilerplate application"/>
                </Helmet>
                <Switch>
                    <Route
                        exact
                        path="/"
                        render={() => (
                            <Redirect to="/zterminal"/>
                        )}
                    />

                    <Route exact path="/welcome" render={() => (<Redirect to="/welcome/challenge_password"/>)}/>
                    <Route exact path="/start" component={StartPage}/>
                    <Route exact path="/welcome/challenge_password" component={WelcomeChallengePasswordPage}/>
                    <Route exact path="/welcome/challenge_contact_informations"
                           component={WelcomeChallengeContactInformationsPage}/>

                    <Route
                        exact
                        path=""
                        render={() => (
                            <Redirect to="/"/>
                        )}
                    />
                </Switch>

            </div>
        );
    }
}

export default withRouter(App);
