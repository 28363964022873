import React, {ChangeEvent, ReactElement} from "react";
import {MONTHS} from '../../utils/months';
import {useIntl} from "react-intl";

const currentYear: number = new Date().getFullYear();
const fromMonth: Date = new Date(new Date(1920, 1, 1).getFullYear(), 0);
const toMonth: Date = new Date(currentYear + 5, 10, 0);
const years: number[] = [];
for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
}

interface WeekdaysShort {
    DE: string[];
    EN: string[];
    FR: string[];
    de: string[];
    en: string[];
    fr: string[];
}

export interface YearMonthFormProps {
    date: Date
    onChange: (date: Date) => void
    locale: keyof typeof MONTHS
}


/**
 * this is strange tobi code -> subject to change
 */
export function YearMonthForm(props: YearMonthFormProps): ReactElement {

    const intl = useIntl();
    const handleChange = function handleChange(e: ChangeEvent<HTMLSelectElement>) {
        if (e.target.form) {
            const year = e.target.form['year'];
            const month = e.target.form["month"];
            props.onChange(new Date(year.value, month.value));
        }
    };


    const months = MONTHS[intl.locale as keyof WeekdaysShort];

    return (
        <form className="DayPicker-Caption">
            <select name="year" onChange={handleChange} value={props.date.getFullYear()}>
                {years.map(year => (
                    <option key={year} value={year}>
                        {year}
                    </option>
                ))}
            </select>
            <select name="month" onChange={handleChange} value={props.date.getMonth()}>
                {months.map((month, i) => (
                    <option key={month} value={i}>
                        {month}
                    </option>
                ))}
            </select>
        </form>
    );
}
