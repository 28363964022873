import {ReactElement, ReactNode} from "react";
import './SobrCockpitButtonBadge.scss';

export interface SobrCockpitButtonBadgeProps {
    children: ReactNode
}

export function SobrCockpitButtonBadge(props: SobrCockpitButtonBadgeProps): ReactElement {
    return <div className="sobr-cockpit-button-badge">
            {props.children}
    </div>
}
