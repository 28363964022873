import React from 'react';
import PropTypes from 'prop-types';
import * as R from "ramda";
import {toInputValue} from "lib/forms";

export default function SobrPasswordInput(props) {
    return <input
        className="form-control"
        type="password"
        value={toInputValue(props.value)}
        onChange={event => props.onChange(event.target.value)}
    />
}

SobrPasswordInput.propTypes = {
    value: PropTypes.string,
    onChange: PropTypes.func,
    autoComplete: PropTypes.oneOf(['on', 'off'])
};

SobrPasswordInput.defaultProps = {
    value: '',
    onChange: R.identity,
    autoComplete: 'on'
};
