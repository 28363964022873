import { createSlice, PayloadAction} from "@reduxjs/toolkit";
import { RootState} from "../../../App";
import { getOccupancyRateChartChangesEndpoint, getOverNightStaysChartAccumulatedEndpoint, getAvgStaysChartChangesEndpoint, getAvgStaysChartEndpoint, getOccupancyRateChartEndpoint, getOverNightStaysChartChangesEndpoint, getOccupancyForecastEndpoint, getOverNightStaysChartAccumulatedForecastEndpoint } from "services/categories/AccomodationCategoriesService";
import { createAsyncThunk } from '@reduxjs/toolkit';

export interface GetAnalyticsChartServiceParams {
    startDate?: string | undefined;
    endDate?: string| undefined;
    servicePartnerId?: any ;
    partnerType?: string[] | [];
    categoryId?: string[] | [];
    isCompare?: boolean | null;
    categoryIdCompare?: string[] | [];
    partnerTypeCompare?: string[] | [];
    compareStartDate?: string | undefined;
    compareEndDate?: string | undefined;
  }
  
export interface SliceState {
    trigger: number,
    overnightStaysChartDataPeriod: any;
    occupancyRateChartDataPeriod: any;
    avgStaysChartDataPeriod : any;
    overnightStaysChartDataPeriodCompare: any;
    occupancyRateChartDataPeriodCompare : any;
    avgStaysChartDataPeriodCompare : any;
    occupancyForecastData: any;
    overNightStaysChartAccumulatedForecastData: any;
    overnightStaysChartCompareData : any;
    occupancyRateChartCompareData : any;
    avgStaysChartCompareData: any;
 
}

const initialState: SliceState = {
    trigger: 0,
   //Period and Period Compare
    overnightStaysChartDataPeriod: [],
    occupancyRateChartDataPeriod: [],
    avgStaysChartDataPeriod: [],
    overnightStaysChartDataPeriodCompare: [],
    occupancyRateChartDataPeriodCompare : [],
    avgStaysChartDataPeriodCompare : [],
    occupancyForecastData: [],
    overNightStaysChartAccumulatedForecastData: [],
    //Change %

    overnightStaysChartCompareData: [],
    occupancyRateChartCompareData: [],
    avgStaysChartCompareData: [],

};

export const analyticsChartSlice = createSlice({
    name: 'analyticsChart',
    initialState,
    reducers: {

        setOvernightStaysChartDataPeriod:  (state, action: PayloadAction<any>) => { 
         state.overnightStaysChartDataPeriod = action.payload.chart;
        },
    
        setOccupancyRateChartDataPeriod: (state,action: PayloadAction<any>) => { 
          state.occupancyRateChartDataPeriod = action.payload.chart;
        },
        setAvgStaysChartDataPeriod: (state, action: PayloadAction<any>) => { 
            state.avgStaysChartDataPeriod = action.payload.chart;
        },
        setOvernightStaysChartDataPeriodCompare: (state, action: PayloadAction<any>) => { 
          state.overnightStaysChartDataPeriodCompare = action.payload.chart;
        },
        setAvgStaysChartDataPeriodCompare: (state, action: PayloadAction<any>) => {
          state.avgStaysChartDataPeriodCompare = action.payload.chart;
        },
        setOccupancyRateChartDataPeriodCompare: (state, action: PayloadAction<any>) => { 
            state.occupancyRateChartDataPeriodCompare = action.payload.chart;
        },
         triggerFetch: (state) => {
            state.trigger = state.trigger + 1;
        },
        setOccupancyForecastDataPeriod: (state, action: PayloadAction<any>) => { 
          state.occupancyForecastData = action.payload.chart;
        },
        setOverNightStaysChartAccumulatedForecastData: (state, action: PayloadAction<any>) => { 
          state.overNightStaysChartAccumulatedForecastData = action.payload.chart;
        },
        ////COMPARE CHART////
        setOvernightStaysChartCompareData: (state, action) => { 
          state.overnightStaysChartCompareData = action.payload;
        },
        setAvgStaysChartCompareData: (state, action) => { 
            state.avgStaysChartCompareData = action.payload;
        },
 
        setOccupancyRateChartCompareData: (state, action) => { 
          state.occupancyRateChartCompareData = action.payload;
        },

   
    }
});
function getAnalyticsChartState(state: RootState): SliceState {
  return state.zterminal.analyticsChart;
}
    // function getAnalyticsChartState(state: RootState): SliceState {
    //     return state.getIn(['zterminal', 'analyticsChart']) as SliceState;
    // }


    //////PERIOD CHART//////
    export const getAvgStaysData = createAsyncThunk(
      'analyticsChart/getAvgStaysChart',
      async ({ startDate, endDate, isCompare , servicePartnerId, partnerType, categoryId, }: GetAnalyticsChartServiceParams, thunkAPI) => {
        try {
          const data = await getAvgStaysChartEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType);
          if (isCompare) {
            thunkAPI.dispatch(analyticsChartSlice.actions.setAvgStaysChartDataPeriodCompare(data));
          } else {
            thunkAPI.dispatch(analyticsChartSlice.actions.setAvgStaysChartDataPeriod(data));
          }
          return data.chart;
        } catch (error:any) {
          return thunkAPI.rejectWithValue(error.message);
        }
      }
    );
    export const getOverNightStaysChartAccumulatedData = createAsyncThunk(
      'analyticsChart/getOverNightStaysChartAccumulatedData',
      async ({ startDate, endDate, isCompare, servicePartnerId, partnerType, categoryId}: GetAnalyticsChartServiceParams, thunkAPI) => {
        try {
              const data = await getOverNightStaysChartAccumulatedEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType);
              if (isCompare) { 
               thunkAPI.dispatch(analyticsChartSlice.actions.setOvernightStaysChartDataPeriodCompare(data));
              } else {
               thunkAPI.dispatch(analyticsChartSlice.actions.setOvernightStaysChartDataPeriod(data));
              }
              
              return data.chart;
            } catch (error:any) {
              return thunkAPI.rejectWithValue(error.message);
            }
          }
    );


      export const getOccupancyRateChartData = createAsyncThunk(
        'analyticsChart/getOccupancyRateChartData',
        async ({ startDate, endDate, isCompare, servicePartnerId, partnerType, categoryId}: GetAnalyticsChartServiceParams, thunkAPI) => {
          try {
        
            const data = await getOccupancyRateChartEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType);
            if (isCompare) {
         
              thunkAPI.dispatch(analyticsChartSlice.actions.setOccupancyRateChartDataPeriodCompare(data));
            } else {
              thunkAPI.dispatch(analyticsChartSlice.actions.setOccupancyRateChartDataPeriod(data));
            }
        
            return data.chart;
          } catch (error:any) {
            return thunkAPI.rejectWithValue(error.message);
          }
        }
      );

          export const getOccupancyForecastData = createAsyncThunk(
            'analyticsChart/getOverNightStaysChartAccumulatedData',
            async ({ servicePartnerId, partnerType, categoryId}: GetAnalyticsChartServiceParams, thunkAPI) => {
                try {
                    const data = await getOccupancyForecastEndpoint( undefined, undefined, servicePartnerId, categoryId, partnerType);
       
                    thunkAPI.dispatch(analyticsChartSlice.actions.setOccupancyForecastDataPeriod(data));
                 
                    return data.chart;
                  } catch (error:any) {
                    return thunkAPI.rejectWithValue(error.message);
                  }
                }
          );
          export const getOverNightStaysChartAccumulatedForecastData = createAsyncThunk(
            'analyticsChart/getOverNightStaysChartAccumulatedForecastData',
            async ({startDate, endDate, servicePartnerId, partnerType, categoryId}: GetAnalyticsChartServiceParams, thunkAPI) => {
                try {
                    const data = await getOverNightStaysChartAccumulatedForecastEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType);
       
                    thunkAPI.dispatch(analyticsChartSlice.actions.setOverNightStaysChartAccumulatedForecastData(data));
                 
                    return data.chart;
                  } catch (error:any) {
                    return thunkAPI.rejectWithValue(error.message);
                  }
                }
          );
              
     //////COMPARE CHART %//////
     export const getOverNightStaysChartChangesData = createAsyncThunk(
      'analyticsChart/getOverNightStaysChartChangesData',
      async ({startDate, endDate,  servicePartnerId, partnerType, categoryId, compareStartDate, compareEndDate, categoryIdCompare, partnerTypeCompare}: GetAnalyticsChartServiceParams, thunkAPI) => {
          try {
              const data = await getOverNightStaysChartChangesEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType, compareStartDate, compareEndDate, categoryIdCompare, partnerTypeCompare);
                thunkAPI.dispatch(analyticsChartSlice.actions.setOvernightStaysChartCompareData(data));
              return data;
            } catch (error:any) {
              return thunkAPI.rejectWithValue(error.message);
            }
          }
        );
    
          export const getAvgStaysCompareData = createAsyncThunk(
            'analyticsChart/getAvgStaysCompareData',
            async ({ startDate, endDate, servicePartnerId, partnerType, categoryId, compareStartDate, compareEndDate,  categoryIdCompare, partnerTypeCompare }: GetAnalyticsChartServiceParams, thunkAPI) => {
              try {
                const data = await getAvgStaysChartChangesEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType, compareStartDate, compareEndDate, categoryIdCompare, partnerTypeCompare);
                
               
                  thunkAPI.dispatch(analyticsChartSlice.actions.setAvgStaysChartCompareData(data));
            
                
                return data;
              } catch (error:any) {
                return thunkAPI.rejectWithValue(error.message);
              }
            }
          );
          
  
            export const getOccupancyRateChartChangesData = createAsyncThunk(
                'analyticsChart/getOccupancyRateChartChangesData',
                async ({ startDate, endDate,  servicePartnerId, partnerType, categoryId, compareStartDate, compareEndDate,  categoryIdCompare, partnerTypeCompare }: GetAnalyticsChartServiceParams, thunkAPI) => {
                    try {
                        const data = await getOccupancyRateChartChangesEndpoint(startDate, endDate, servicePartnerId, categoryId, partnerType, compareStartDate, compareEndDate,categoryIdCompare, partnerTypeCompare);
             
                          thunkAPI.dispatch(analyticsChartSlice.actions.setOccupancyRateChartCompareData(data));
                        return data;
                     
                      } catch (error:any) {
                        return thunkAPI.rejectWithValue(error.message);
                      }
                    }
                  );
         


 

export const AnalyticsChartSelectors = {
   trigger: (state: RootState) => getAnalyticsChartState(state).trigger,
  
    //Period chart data
    overnightStaysChartDataSelectionPeriod: (state: RootState) => getAnalyticsChartState(state).overnightStaysChartDataPeriod,
    avgStaysChartDataSelectionPeriod: (state: RootState) => getAnalyticsChartState(state).avgStaysChartDataPeriod,
    occupancyRateChartDataSelectionPeriod: (state: RootState) => getAnalyticsChartState(state).occupancyRateChartDataPeriod,
    overnightStaysChartDataSelectionPeriodCompare: (state: RootState) => getAnalyticsChartState(state).overnightStaysChartDataPeriodCompare,
    avgStaysChartDataSelectionPeriodCompare: (state: RootState) => getAnalyticsChartState(state).avgStaysChartDataPeriodCompare,
    occupancyRateChartDataSelectionPeriodCompare: (state: RootState) => getAnalyticsChartState(state).occupancyRateChartDataPeriodCompare,
    //Forecast
    occupancyForecastDataSelection: (state: RootState) => getAnalyticsChartState(state).occupancyForecastData,
    overnightForecastDataSelection: (state: RootState) => getAnalyticsChartState(state).overNightStaysChartAccumulatedForecastData,
   
    //Compare chart data 
    overnightStaysChartDataCompareSelection: (state: RootState) => getAnalyticsChartState(state).overnightStaysChartCompareData,
    avgStaysChartDataCompareSelection: (state: RootState) => getAnalyticsChartState(state).avgStaysChartCompareData,
    occupancyRateChartDataCompareSelection: (state: RootState) => getAnalyticsChartState(state).occupancyRateChartCompareData,
   
};

export const AnalyticsChartActions = {
    //Period chart data
    setOvernightStaysChartDataPeriod: analyticsChartSlice.actions.setOvernightStaysChartDataPeriod, 
    setAvgStaysChartDataPeriod: analyticsChartSlice.actions. setAvgStaysChartDataPeriod,
    setOccupancyRateChartDataPeriod: analyticsChartSlice.actions.setOccupancyRateChartDataPeriod, 
    setOvernightStaysChartDataPeriodCompare: analyticsChartSlice.actions.setOvernightStaysChartDataPeriodCompare, 
    setAvgStaysChartDataPeriodCompare: analyticsChartSlice.actions. setAvgStaysChartDataPeriodCompare,
    setOccupancyRateChartDataPeriodCompare: analyticsChartSlice.actions.setOccupancyRateChartDataPeriodCompare, 
    //Forecast
    setOccupancyForecastDataPeriod: analyticsChartSlice.actions.setOccupancyForecastDataPeriod,
    setOverNightStaysChartAccumulatedForecastData: analyticsChartSlice.actions.setOverNightStaysChartAccumulatedForecastData,
    //Compare chart data
    setOvernightStaysChartCompareData: analyticsChartSlice.actions.setOvernightStaysChartCompareData, 
    setAvgStaysChartCompareData: analyticsChartSlice.actions. setAvgStaysChartCompareData,
    setOccupancyRateChartCompareData: analyticsChartSlice.actions.setOccupancyRateChartCompareData, 
  
}