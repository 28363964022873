import TranslationService from "services/TranslationService/TranslationService";
import * as R from 'ramda';
import f from "lib/util";

export const InitialState = {
    currentLanguage: TranslationService.DEFAULT_LANGUAGE,
    currentMessages: null,
    languageMessages: {}
};

// SessionActions
const SET_LANGUAGE_MESSAGES = 'language/setLanguageMessages';
const SET_CURRENT_LANGUAGE = 'language/setCurrentLanguage';
const SET_CURRENT_MESSAGES = 'language/setCurrentMessages';

function initializeLanguageFromBrowser(defaultLanguage = TranslationService.DEFAULT_LANGUAGE) {
    return (dispatch) => {
        const startLanguage = TranslationService.getLanguageKeyFromLocale(navigator.language || defaultLanguage, defaultLanguage);
        dispatch(setLocale(startLanguage));
    }
}

function setLocale(locale) {
    return (dispatch, getState) => {
        const language = TranslationService.getLanguageKeyFromLocale(locale);
        localStorage.setItem("language", language);
        return dispatch(getOrFetchMessages(language, getState().get('language')))
            .then(messages => {
                dispatch(setCurrentMessages(messages));
                dispatch(setCurrentLanguage(language));
            });
    }
}

function getOrFetchMessages(language, languageState) {
    return dispatch => {
        const existingMessages = R.path(['languageMessages', language], languageState);
        if (f.isNotNil(existingMessages)) {
            return Promise.resolve(existingMessages);
        } else {
            return TranslationService.fetchMessagesForLanguage(language)
                .then(messages => {
                    dispatch(setLanguageMessages(language, messages));
                    return messages
                });
        }
    }
}

function setLanguageMessages(language, messages) {
    return {
        type: SET_LANGUAGE_MESSAGES,
        language: language,
        messages: messages
    }
}

function setCurrentLanguage(language) {
    return {
        type: SET_CURRENT_LANGUAGE,
        language: language
    }
}

function setCurrentMessages(messages) {
    return {
        type: SET_CURRENT_MESSAGES,
        messages: messages
    }
}

export const Actions = {
    initializeLanguage: initializeLanguageFromBrowser, setLocale
};

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case SET_LANGUAGE_MESSAGES:
            return R.assocPath(['languageMessages', action.language], action.messages, state);
        case SET_CURRENT_LANGUAGE:
            return R.assoc('currentLanguage', action.language, state);
        case SET_CURRENT_MESSAGES:
            return R.assoc('currentMessages', action.messages, state);
        default:
            return state;
    }
}

export default {
    InitialState, Actions, Reducer
};
