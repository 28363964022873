import React, { useMemo, useEffect } from 'react';
import DatePicker, { CalendarContainer } from "react-datepicker";
import moment from "moment";
import Select, { StylesConfig } from 'react-select';
import { useDispatch, useSelector } from "react-redux";
import {AnalyticsActions, AnalyticsSelectors} from "../../AnalyticsSlice";

interface props {
    startDate: Date | null,
    setStartDate: any,
    endDate: Date | null,
    setEndDate: any,
    isCompare: boolean,
    dayFrame?: Number
}

const presets = [
    { value: 0, label: "Eigener Zeitraum" },
    { value: 1, label: "Anfangs Jahr bis heute" },
    { value: 2, label: "Anfangs Monat bis heute" },
    { value: 3, label: "Letztes Jahr" },
    { value: 4, label: "Gesamter Zeitraum" }
];

const months = [
    'Januar',
    'Februar',
    'März',
    'April',
    'Mai',
    'Juni',
    'Juli',
    'August',
    'September',
    'Oktober',
    'November',
    'Dezember'
];

const selectStyle: StylesConfig<{}> = {
    control: (styles) => ({
        ...styles,
        border: '1px solid #F4F2F0',
        textAlign: 'left',
        fontFamily: 'PFCentroSansPro-Regular, sans-serif',
    })
};

export const DateRangePicker = (props: props) => {
    const dispatch = useDispatch();
    const startDateTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endDateTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const timeFramePreset = useSelector(AnalyticsSelectors.timeFramePreset);

    const onChange = (dates: any) => {
        const [start, end] = dates;
        if(props.isCompare) {
            const differenceInTime = endDateTimeFrame.getTime() - startDateTimeFrame.getTime();
            const differenceInDays = differenceInTime / (1000 * 3600 * 24);

            const endDate = new Date(start);
            endDate.setDate(start.getDate() + differenceInDays);
            dispatch(props.setStartDate(start));
            dispatch(props.setEndDate(endDate));
        }
        else {
            dispatch(props.setStartDate(start));
            dispatch(props.setEndDate(end));
        }
    };

    useEffect(() => {
        if(props.isCompare && endDateTimeFrame !== null) {
            if(props.startDate) {
                const differenceInTime = endDateTimeFrame.getTime() - startDateTimeFrame.getTime();
                const differenceInDays = differenceInTime / (1000 * 3600 * 24);

                const endDate = new Date(props.startDate);
                endDate.setDate(props.startDate.getDate() + differenceInDays);

                dispatch(props.setEndDate(endDate));
            }
        }
    }, [endDateTimeFrame]);

    const changePresetTimeFrame = (value: any) => {
        if (props.isCompare) {

        } else {
            dispatch(AnalyticsActions.setTimeFramePreset(value.value));
        }
    }

    const customHeader = useMemo(() => {
        const startDate = moment(props.startDate).isValid() ? moment(props.startDate).format("DD.MM.yyyy") : "";
        const endDate = moment(props.endDate).isValid() ? moment(props.endDate).format("DD.MM.yyyy") : "";
        return (
            {
                date,
                decreaseMonth,
                increaseMonth,
            }: any) => {
            return (
                <React.Fragment>
                    <Select
                        className="basic-single"
                        classNamePrefix="select"
                        name="presets"
                        value={presets[timeFramePreset]}
                        options={presets}
                        styles={selectStyle}
                        onChange={changePresetTimeFrame}
                    />

                    <div className="react-datepicker-input-container" />
                    <div className="separator"/>

                    <div className="react-datepicker-navigation-container">
                        <div
                            onClick={decreaseMonth}
                            className="react-datepicker-navigation-previous-month"
                        >
                            <div className="arrow"/>
                        </div>
                        <div className="timeframe-select-container">
                            {months[date.getMonth()]} {date.getFullYear()}
                        </div>
                        <div
                            onClick={increaseMonth}
                            className="react-datepicker-navigation-next-month"
                        >
                            <div className="arrow-right"/>
                        </div>
                    </div>
                </React.Fragment>
            )
        }
    }, [props.startDate, props.endDate]);

    return (
        <DatePicker
            calendarClassName="test22"
            selected={props.startDate}
            onChange={onChange}
            startDate={props.startDate}
            endDate={props.endDate}
            selectsRange
            inline
            renderCustomHeader={customHeader}
            dayClassName={(date) => "datepicker-day"}
        />
    );
}