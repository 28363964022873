/* eslint-disable no-console */
/**
 * Gets the repositories of the user from Github
 */

import fetch from 'isomorphic-fetch';
import {API_ROOT} from '../../api-config';

export const forgotPasswordService = {
    passwordReset,
};


function passwordReset(data) {
  const requestURL = `${API_ROOT}admin/user/password/reset`;
  return fetch(requestURL, {
    method: 'POST',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  }).then(handleResponse);
}


function handleResponse(response) {
  if (!response.ok) {
    return Promise.reject(response.statusText);
  }
  return response.json();
}
