import {createSlice} from "@reduxjs/toolkit";
import { RootState} from "../../../App";

import { getServicePartnerBarDataEndpoint, getServiceOverNightStaysBarEndpoint, getServiceOccupancyRateBarEndpoint, getServiceAvgStaysBarEndpoint } from "services/categories/AccomodationCategoriesService";
  
export interface SliceState {
    servicePartnerBarData: number,
    overnightStaysBarData : number,
    occupancyRateBarData :number,
    avgStaysBarData :number,

    servicePartnerBarCompareData: number,
    overnightStaysBarCompareData : number,
    occupancyRateBarCompareData : number,
    avgStaysBarCompareData : number,
   
}

const initialState: SliceState = {
    servicePartnerBarData: 0,
    overnightStaysBarData : 0,
    occupancyRateBarData : 0,
    avgStaysBarData : 0,

    servicePartnerBarCompareData: 0,
    overnightStaysBarCompareData : 0,
    occupancyRateBarCompareData : 0,
    avgStaysBarCompareData : 0,
};

export const analyticsBarsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
                
        setServicePartnerBarData: (state, action) => { 
            state.servicePartnerBarData = action.payload.servicePartners;
        },
        setServicePartnerBarCompareData: (state, action) => { 
            state.servicePartnerBarCompareData = action.payload.servicePartners; 
        },
        setOvernightStaysBarData: (state, action) => { 
            state.overnightStaysBarData = action.payload.overnightStays;
        },
        setOvernightStaysBarCompareData: (state, action) => { 
            state.overnightStaysBarCompareData = action.payload.overnightStays;
        },
        
        setAvgStaysBarData: (state, action) => { 
            state.avgStaysBarData = action.payload.avgStays;
        },
        setAvgStaysBarCompareData: (state, action) => { 
            state.avgStaysBarCompareData = action.payload.avgStays;
        },
        setOccupancyRateBarData: (state, action) => { 
            state.occupancyRateBarData = action.payload.occupancyRate;
        },
        setOccupancyRateBarCompareData: (state, action) => { 
            state.occupancyRateBarCompareData = action.payload.occupancyRate;
        },
        
    }
});

function getAnalyticsState(state: RootState): SliceState {
    return state.getIn(['zterminal', 'analyticsBars']) as SliceState;
}
export function calculatePercentage(firstValue: number, secondValue: number): number {
    const diff = firstValue - secondValue;
    const avg = (firstValue + secondValue) / 2;
    return (diff / avg) * 100 || 0;
}


  const createAction = (endpointFunc:any, actionFunc:any) => {
    return (params:any) => {
        const {
            startDate,
            endDate,
            servicePartnerId,
            partnerType,
            categoryId,
            compareStartDate,
            compareEndDate,
            isCompare
        } = params;

        return (dispatch:any) => {
            return endpointFunc(
                startDate,
                endDate,
                servicePartnerId,
                categoryId,
                partnerType,
                compareStartDate,
                compareEndDate
            )
            .then((data: any) => {
                const action = isCompare ? actionFunc.compare : actionFunc.normal;
                dispatch(action(data));
                return data;
            })
            .catch((error:any) => {
                console.log(error);
                return Promise.reject(error);
            });
        };
    };
};

            export const getServicePartnerBarData = createAction(
                getServicePartnerBarDataEndpoint,
                
                {
                normal: analyticsBarsSlice.actions.setServicePartnerBarData,
                compare: analyticsBarsSlice.actions.setServicePartnerBarCompareData,
                }
            );
   
            export const getOvernightStaysBarData= createAction(
                getServiceOverNightStaysBarEndpoint,
                {
                normal: analyticsBarsSlice.actions.setOvernightStaysBarData,
                compare: analyticsBarsSlice.actions.setOvernightStaysBarCompareData,
                }
            );

            export const getServiceOccupancyRateBarData = createAction(
                getServiceOccupancyRateBarEndpoint,
                {
                  normal: analyticsBarsSlice.actions.setOccupancyRateBarData,
                  compare: analyticsBarsSlice.actions.setOccupancyRateBarCompareData,
                }
              );
   

            export const getServiceAvgStaysBarData = createAction(
                 getServiceAvgStaysBarEndpoint,
                  {
                      normal: analyticsBarsSlice.actions.setAvgStaysBarData ,
                      compare: analyticsBarsSlice.actions.setAvgStaysBarCompareData ,
                  }
                  );
       

export const AnalyticsBarsSelectors = {
    
    overnightStaysBarDataSelection: (state: RootState) => getAnalyticsState(state).overnightStaysBarData,
    servicePartnerBarDataSelection: (state: RootState) => getAnalyticsState(state).servicePartnerBarData,
    avgStaysBarDataSelection: (state: RootState) => getAnalyticsState(state).avgStaysBarData,
    occupancyRateBarDataSelection: (state: RootState) => getAnalyticsState(state).occupancyRateBarData,
   
    overnightStaysBarDataCompareSelection: (state: RootState) => getAnalyticsState(state).overnightStaysBarCompareData,
    servicePartnerBarDataCompareSelection: (state: RootState) => getAnalyticsState(state).servicePartnerBarCompareData,
    avgStaysBarDataCompareSelection: (state: RootState) => getAnalyticsState(state).avgStaysBarCompareData,
    occupancyRateBarDataCompareSelection: (state: RootState) => getAnalyticsState(state).occupancyRateBarCompareData,
   
};