import React, {ReactElement} from 'react';
import {BonfireIcon} from "components/zterminal/BonfireIcon/BonfireIcon";
import {Link, useLocation} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import ZTerminalPages, {ZTerminalPage, ZTerminalPageLabel} from "../ZTerminalPages";
import {useSelector} from "react-redux";
import {ReactComponent as AnalyticsIcon} from 'images/icons/analytics_navigation_icon.svg';

import './zterminalSidebar.scss';
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";

export interface SidebarProps {
    items: ZTerminalPage[]
}

export function Sidebar(props: SidebarProps): ReactElement {
    const location = useLocation();
    const currentServicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id;

    const isActive = (item: ZTerminalPage) => {
        return ZTerminalPages.isSubLocation(location.pathname, item.link);
    }

    // TODO remove this ugly hack (e.g. by making service partner page for single-sp users forward to correct edit page)
    const items = props.items.map(page => {
        if (page.link.indexOf('{SP_ID}') !== -1) {
            page.link = page.link.replace('{SP_ID}', currentServicePartnerId ?? "");
        }
        return page;
    });

    return (
        <div id="zterminal-sidebar">
            <ul className="nav flex-column">
                {items.map((item, index) => (
                    <li key={index} className={`nav-item py-3 ${isActive(item) ? 'active' : ''}`}>
                        <Link to={item.link} className="nav-link">
                            <SidebarIcon icon={item.icon} />
                            <span className="sidebar-label d-none d-xl-block">
                                <SidebarLabel label={item.label} />
                            </span>
                        </Link>
                    </li>
                ))}
            </ul>
        </div>
    );
}


function SidebarLabel(props: { label: ZTerminalPageLabel }): ReactElement {
    if (typeof props.label === 'string') {
        return <>{props.label}</>;
    } else {
        return <FormattedMessage {...props.label} />
    }
}

function SidebarIcon(props: { icon: any }): ReactElement | null {
    if(props.icon === "analytics") {
        return <AnalyticsIcon />
    }
    else {
        return typeof props.icon === 'string' ? <BonfireIcon icon={props.icon} className="sidebar-icon" /> : null;
    }
}