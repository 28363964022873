import React from 'react';
import {Route, Switch} from "react-router-dom";
import { AnalyticsContainer } from "./AnalyticsContainer";

export default function Analytics() {
    return (
        <Switch>
            <Route exact={true} path="/zterminal/analytics" component={AnalyticsContainer} />
        </Switch>
    );
}
