import React, {ReactElement, ReactNode} from 'react';
import ReactTable, {Column, RowInfo} from "react-table";
import SobrLightLoadingIndicator from "components/SobrLightLoadingIndicator";
import {FormattedMessage} from "react-intl";
import * as R from "ramda";
import './SobrDataTable.scss';

export interface SobrDataTableProps<T> {
    data: T[]
    columns: Column[]
    loading: boolean
    additionalClassNames?: string | null
    onSelectRow?: (row: T) => void
    highlight?: (row: T) => boolean
    onChangeSorting?: (field: string, direction: 'asc' | 'desc') => void
    infoPopper?: {
        column: string
        infoPopper: ReactNode
    }
}

export function SobrDataTable<T>(props: SobrDataTableProps<T>): ReactElement {
    const data = props.data || [];
    const infoPopper = props.infoPopper ? props.infoPopper.infoPopper : null;
    const infoPopperColumn = props.infoPopper ? props.infoPopper.column : null;
    const translatedColumns = translateColumnHeaders(props.columns, infoPopperColumn, infoPopper);
    const classNames = `sobrdatatable ${props.additionalClassNames}`;

    return <div className={classNames}>
        <ReactTable
            manual
            className="-striped -highlight h-100 mh-100 w-100"
            data={data} // should default to []
            columns={translatedColumns}
            showPaginationBottom={false}
            defaultPageSize={20}
            loading={props.loading}
            loadingText={<SobrLightLoadingIndicator/>}
            noDataText={<FormattedMessage
                id="bonfire.components.SobrDataTable.noData"
                defaultMessage="Keine Daten gefunden"
            />}
            getTrProps={(state: any, rowInfo: RowInfo | undefined) => ({
                onClick: () => {
                    if (rowInfo && props.onSelectRow) {
                        props.onSelectRow(rowInfo.original);
                    }
                },
                className: (props.highlight && rowInfo && props.highlight(rowInfo.original)) ? 'highlight' : 'non-highlight'
            })
            }
            onSortedChange={(newSorted) => {
                if (props.onChangeSorting) {
                    const field = newSorted[0].id;
                    const direction = newSorted[0].desc ? 'desc' : 'asc';
                    props.onChangeSorting(field, direction);
                }
            }}
        />
    </div>
}

function translateColumnHeaders(columns: Column[], infoPopperColumn: string | null = null, infoPopper: ReactNode = null): Column[] {
    const translateFn = (headerVal: Column['Header']) => typeof headerVal === 'string' ? <FormattedMessage id={headerVal} /> : headerVal;
    const retVal: Column[] =  R.map(R.evolve({'Header': translateFn}))(columns);

    if (infoPopper && infoPopperColumn) {
        const c = retVal.find(tc => tc.accessor === infoPopperColumn);
        if (c) {
            c['Header'] = (
                <div style={{display: 'flex'}}>
                    {c.Header}
                    {infoPopper}
                </div>
            )
        }
    }

    return retVal;
}
