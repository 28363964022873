import React from 'react';
import {Switch, Route} from 'react-router-dom';
import {AnalyticsHeader} from "./components/Header";
import {AnalyticsNavigation} from "./components/Navigation";
import {AuslastungContainer} from "./views/Auslastung/Auslastung";

export const AnalyticsContainer = () => {

    return (
        <React.Fragment>
            <div className="analytics-container">
                <AnalyticsHeader />
                <AnalyticsNavigation/>
                <Switch>
                    <Route path={'/zterminal/analytics'} component={AuslastungContainer}/>
                </Switch>
            </div>
        </React.Fragment>
    );
}