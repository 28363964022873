import React, { useRef } from 'react';
import RegistrationFormService from "../../../../services/registrationforms/RegistrationFormService";
import {FormattedMessage} from "react-intl";
import {useSelector} from "react-redux";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";

export const ExcelUploadButton = (props) => {
    console.log(props)
    const hiddenInput = useRef(null);
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id;

    const handleUploadClick = () => {
        hiddenInput.current.click();
    };

    const uploadFile = async (e) => {
        e.preventDefault()
        const excelFile = e.target.files[0];
        try {
            const result = await RegistrationFormService.uploadFile(excelFile, servicePartnerId);
            if(result.length > 0) {
                props.setErrors(result);
            }
            else {
                props.clearErrors();
            }
        }
        catch(error) {
            props.setGeneralError()
        }
    };

    return (
        <React.Fragment>
            <button onClick={handleUploadClick} className="btn btn-primary btn-block btn-force-margin" >
                <FormattedMessage id="bonfire.registrationForm.filter.import" defaultMessage="Import" />
            </button>
            <input
                type="file"
                ref={hiddenInput}
                onChange={(e) => {
                    uploadFile(e);
                    e.target.value = null;
                }}
                style={{display: 'none'}}
            />
        </React.Fragment>
    );
};