import React from 'react';
import { Graph } from "../../../../../components/Graph/Graph";
import 'chartjs-adapter-moment';

export const LogiernaechteAccumulatedGraph = () => {
    return (
        <React.Fragment>
            <Graph
                title={"Akkumulierte Logiernächte"}
                graphDatasetId={"overnightStaysChartData"} //Veränderung graph
                isCompareGraph={false}
                isPercent={false}
                tooltipTitle={"Logiernächte"}
                hasPrediction={true}
            />
        </React.Fragment>
    );
};