import React, {MouseEventHandler, ReactNode} from 'react';
import './SobrCockpitButton.scss';
import {SobrConditionalLink} from "components/SobrConditionalLink/SobrConditionalLink";
import {SobrCockpitButtonBadge} from "components/SobrCockpitButton/SobrCockpitButtonBadge";

export interface SobrCockpitButtonProps {
    bubbleNumber?: number,
    onClick?: MouseEventHandler<HTMLDivElement>,
    linkTo?: string,
    title: ReactNode,
    children: ReactNode
}

export function SobrCockpitButton(props: SobrCockpitButtonProps) {
    return (
        <div className={`sobr-cockpit-button`}>
            <SobrConditionalLink to={props.linkTo}>
                <div className="sobr-cockpit-button-btn"
                     onClick={props.onClick}
                >
                    <div className="sobr-cockpit-button-content">
                        {props.children}
                    </div>
                    <div className="sobr-cockpit-button-title">
                        {props.title}
                    </div>
                    {props.bubbleNumber != null &&
                    <div className="sobr-cockpit-button-badge-positioning">
                        <SobrCockpitButtonBadge>{props.bubbleNumber}</SobrCockpitButtonBadge>
                    </div>
                    }
                </div>
            </SobrConditionalLink>
        </div>
    );
}
