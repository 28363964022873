import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AnalyticsBarsSelectors, calculatePercentage, getServiceOccupancyRateBarData} from "../../../../AnalyticsBarsSlice";
import moment from "moment";
import {formatNumber} from "../../../../helpers";
import { AnalyticsSelectors, getPartnerTypeAndCategoryId } from 'bonfire/ZTerminal/Analytics/AnalyticsSlice';
import { SessionSelectors } from 'bonfire/ZTerminal/Session/SessionSlice';

export const AnalyticsAuslastungCard = () => {
    const [data, setData] = useState<{ occupancyRate: Number, occupancyRateCompared: Number, changePercentage: Number }>({
        occupancyRate: 0,
        occupancyRateCompared: 0,
        changePercentage: 0,
      });
       const dispatch = useDispatch();
    const occupancyRate = useSelector(AnalyticsBarsSelectors.occupancyRateBarDataSelection);
    const occupancyRateCompared = useSelector(AnalyticsBarsSelectors.occupancyRateBarDataCompareSelection);
  
    const startTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const startDate = moment(startTimeFrame).format("YYYY-MM-DD");
    const endDate = moment(endTimeFrame).format("YYYY-MM-DD");
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id || ""; 
    const trigger = useSelector(AnalyticsSelectors.trigger);
    const startCompareFrame = useSelector(AnalyticsSelectors.startDateCompareFrame);
    const endCompareFrame = useSelector(AnalyticsSelectors.endDateCompareFrame);
    const startDateCompare = moment(startCompareFrame).format("YYYY-MM-DD");
    const endDateCompare = moment(endCompareFrame).format("YYYY-MM-DD");
  
    const dropdownValue = useSelector(AnalyticsSelectors.dropdownValues);
    const dropdownCompareValue = useSelector(AnalyticsSelectors.compareDropdownValues);

    const changePercentage = calculatePercentage(occupancyRate ,occupancyRateCompared);
   
  useEffect(() => {
    handleDropdownChange(dropdownValue.selectedKeys, dropdownValue.selectedRadio, startDate, endDate, false);
    handleDropdownChange(dropdownCompareValue.selectedKeys,dropdownCompareValue.selectedRadio, startDateCompare, endDateCompare, true);
    const fetchData = async () => {
        try {
            setData({
                occupancyRate:  occupancyRate ?? 0,
                occupancyRateCompared: occupancyRateCompared ?? 0,
                changePercentage: changePercentage ?? 0,
              });
            
            }
            catch(error) {
                console.log(error);
            }}
            fetchData();
        }, [trigger, dropdownValue, dropdownCompareValue]);

        const handleDropdownChange = (selectedKeys: string[], selectedRadio: string, startDate: string, endDate:string, isCompare: boolean) => {
            const { partnerType, categoryId } = getPartnerTypeAndCategoryId(selectedKeys);

            if (selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0)) {
                dispatch(getServiceOccupancyRateBarData({ startDate, endDate, isCompare }));
                return
            }
            
            else if (selectedKeys?.length < 6  && selectedKeys.length > 0 && selectedRadio !== "Own" )  {
          
            dispatch(getServiceOccupancyRateBarData({ startDate, endDate, categoryId, partnerType, isCompare }));
            return
            }
            
            else if (selectedRadio === "Own") {
          
            dispatch(getServiceOccupancyRateBarData({ startDate, endDate, servicePartnerId, isCompare }));   
            return
             
            }
        }
    return (
        <React.Fragment>
            <div className="analytics-workload-card">
                <a className='analytics-info-container-title'>  Auslastung </a>
                <div className="info-container">
                    <div className="info-label">
                       Auswahl  
                    </div>
                    <div className="info-label">
                    /
                       Vergleich
                    </div>

                    </div>
                    <div className="info-value-container">
                        <div className="compare-value">
                        {formatNumber(occupancyRate)} %
                        <div className="info-value-percentage-container">
                            <div className="info-value-percentage">
                           
                        
                                {formatNumber(changePercentage.toFixed(2))} %
                            </div>
                          {(occupancyRateCompared !== undefined && occupancyRate !== undefined && occupancyRate !== 0) ?
                                (occupancyRate > occupancyRateCompared) ? <div className="triangle-positive-big" /> : <div className="triangle-negative-big" />
                                : null
                            } 
                               
                            </div>  
                        
                        </div>
                   
                        <div className="compare-value">
                        <div className='separator-line'> / </div>
                       {formatNumber(occupancyRateCompared)} %
                     </div>
                </div>
         
            </div>
        </React.Fragment>
    );
}