import React from 'react';
import { Graph } from "../../../../../components/Graph/Graph";
import 'chartjs-adapter-moment';

export const AufenthaltsdauerGraph = () => {
    return (
        <React.Fragment>
            <Graph
                title={"Tage"}
                graphDatasetId={"avgStaysChartData"}
                isPercent={false}
                isCompareGraph={false}
                tooltipTitle={"Dauer"}
                hasPrediction={false}
            />
        </React.Fragment>
    );
};