/* eslint-disable comma-spacing,no-plusplus */
/*
 * SelfServicePage
 *
 * List all the features
 */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Moment from 'moment';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Actions} from "./redux";
import {getCustomPlaceHolderLanguages} from "../../utils/selectTexts";
import Select from 'react-select';
import $ from "jquery";
import {SobrDatePicker} from "components/SobrDatePicker/SobrDatePicker";
import {SobrDatePickerSelfService} from "components/SobrDatePickerSelfService/SobrDatePicker";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        maxHeight: '200px'
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: '200px'
    })
};

export class SelfServiceStep7Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        setFellowTravelerData: PropTypes.func.isRequired,
        fellowTraveler: PropTypes.any,
        nationalityData: PropTypes.any.isRequired,
        language: PropTypes.string.isRequired,
        match: PropTypes.object,
        languageData: PropTypes.any,
    };

    constructor(props) {
        super(props);
        this.state = {
            birthday: null,
            nationality: '',
            firstName: '',
            lastName: '',
            language: '',
            email: ''
        };

        this.disabled = true;
        this.guestId = this.props.match.params.guestId;

        this.handleBirthdayChange = this.handleBirthdayChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeNationality = this.onChangeNationality.bind(this);
        this.onChangeLanguage = this.onChangeLanguage.bind(this);
        this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
    }

    componentDidMount() {
        if (this.props.registrationForm) {

            let natList = this.props.nationalityData;
            let fellowTraveler = this.props.fellowTraveler;
            let mainDataGuest = this.props.registrationForm.guests.find((guest) => guest.guest.id === this.guestId);
            let currentDataGuest = fellowTraveler && fellowTraveler[this.guestId] ? fellowTraveler[this.guestId] : null;
            let langList = this.props.languageData;

            let lang = currentDataGuest ? currentDataGuest.languageCode : mainDataGuest.guest.languageCode;
            let bDay = currentDataGuest ? currentDataGuest.birthday : mainDataGuest.guest.birthday;
            let nat = currentDataGuest ? currentDataGuest.nationality : mainDataGuest.guest.nationality;

            this.setState({
                birthday: bDay ? Moment.isMoment(bDay) ? bDay : Moment.utc(bDay) : null,
                nationality: nat && nat.length > 0 ? natList.find((nation) => nation.value === nat.toUpperCase()) ? natList.find((nation) => nation.value === nat.toUpperCase()).value : natList.find((nation => nation.label === nat.toUpperCase())).value : '',
                firstName: currentDataGuest ? currentDataGuest.firstName : mainDataGuest.guest.firstName,
                lastName: currentDataGuest ? currentDataGuest.lastName : mainDataGuest.guest.lastName,
                language: lang ? lang.length > 0 && langList.find((language) => language.value === lang) ? langList.find((language) => language.value === lang).value : langList.find((language) => language.label === lang) ? langList.find((language) => language.label === lang).value : '' : '',
                email: currentDataGuest ? currentDataGuest.email : mainDataGuest.guest.email,
            });
        }
    }

    handleDropdownMenu(open) {
        const $contentBoxText = $('.content-box .text');
        let scrollTop = $contentBoxText.scrollTop();
        open ? $contentBoxText.addClass('menu-open').css('margin-top', '-' + scrollTop + 'px') : $contentBoxText.removeClass('menu-open').css('margin-top', '0');
    }

    onChangeNationality(e) {
        this.setState({
            nationality: e.value
        });
    }

    onChangeLanguage(e) {
        this.setState({
            language: e.value
        })
    }

    handleBirthdayChange(e) {
        this.setState({birthday: e});
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit() {
        if (this.disabled)
            return;

        let fellowTravelerAll = this.props.fellowTraveler;
        let fellowTravelerBefore = this.props.registrationForm.guests.filter((guest) => guest.primary === false).find((g) => g.guest.id === this.guestId);

        const fellowTraveler = Object.assign({}, fellowTravelerBefore.guest);

        fellowTraveler.guestId = fellowTravelerBefore.guest.id;
        fellowTraveler.primary = fellowTravelerBefore.primary;
        fellowTraveler.checkinDate = fellowTravelerBefore.checkinDate;
        fellowTraveler.checkoutDate = fellowTravelerBefore.checkoutDate;

        fellowTraveler.firstName = this.state.firstName;
        fellowTraveler.lastName = this.state.lastName;
        fellowTraveler.email = this.state.email;
        fellowTraveler.birthday = Moment.utc(this.state.birthday).format("YYYY-MM-DD")
        fellowTraveler.languageCode = this.state.language;
        fellowTraveler.nationality = this.state.nationality;

        fellowTravelerAll = fellowTravelerAll ? fellowTravelerAll : {};
        fellowTravelerAll[this.guestId] = fellowTraveler;

        this.props.setFellowTravelerData(fellowTravelerAll);

        this.props.history.push(`/self-service/${this.props.registrationForm.id}/step6`);
    }

    render() {
        this.disabled = !(this.state.firstName && this.state.lastName && this.state.language && this.state.birthday && this.state.nationality);

        return (
            <SelfServiceMainLayout
                title={{id: 'bonfire.containers.SelfServiceStep7Page.title', defaultMessage: 'Pre-Check-In'}}
                class="step7">

                <SelfServiceStepLayout id={this.props.registrationForm.id}
                                       title={{id: 'bonfire.containers.SelfServiceStep4Page.title.mitreisende'}} class="step7
          "/>

                <div className="text">
                    <div className="wrapper self-service">

                        <label htmlFor="step3CityInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep7Page.firstName'
                                defaultMessage='Vorname'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3CityInput"
                            onChange={this.handleChange}
                            name="firstName"
                            value={this.state.firstName ? this.state.firstName : ''}
                        />
                        <label htmlFor="step3CityInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep7Page.lastName'
                                defaultMessage='Nachname'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3CityInput"
                            onChange={this.handleChange}
                            name="lastName"
                            value={this.state.lastName ? this.state.lastName : ''}
                        />

                        <label htmlFor="step3CityInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep7Page.birthday'
                                defaultMessage='Geburtsdatum'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <div className="input-group inputGroupCustomRegForm">
                            <SobrDatePickerSelfService
                                moment={this.state.birthday}
                                onSetMoment={this.handleBirthdayChange}
                                showMonthPicker={true}
                                disabled={false}
                                language={this.props.language}
                            />
                        </div>

                        <label htmlFor="step2CountrySelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.country'
                                defaultMessage='Nationalität'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <Select className='b-select-input-service'
                                classNamePrefix="react-select_service"
                                id="nationality"
                                placeholder={getCustomPlaceHolderLanguages(this.props.language)}
                                ref={(ref) => {
                                    this.select = ref;
                                }}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                simpleValue
                                clearable={false}
                                onChange={this.onChangeNationality}
                                name="selected-state"
                                searchable={true}
                                options={this.props.nationalityData}
                                valueKey="code"
                                labelKey="name"
                                onMenuOpen={() => this.handleDropdownMenu(true)}
                                onMenuClose={() => this.handleDropdownMenu(false)}
                                value={this.state.nationality ? this.props.nationalityData.find((nationality) => nationality.value === this.state.nationality) : ''}
                                styles={customStyles}
                        />
                        <label htmlFor="step2LanguageSelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.language'
                                defaultMessage='Sprache'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <Select className='b-select-input-service'
                                classNamePrefix="react-select_service"
                                id="language"
                                placeholder={getCustomPlaceHolderLanguages(this.props.language)}
                                ref={(ref) => {
                                    this.selectLang = ref;
                                }}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                simpleValue
                                clearable={false}
                                onChange={this.onChangeLanguage}
                                name="selected-state"
                                searchable={true}
                                options={this.props.languageData}
                                valueKey="code"
                                labelKey="name"
                                onMenuOpen={() => this.handleDropdownMenu(true)}
                                onMenuClose={() => this.handleDropdownMenu(false)}
                                value={this.state.language ? this.props.languageData.find((language) => language.value === this.state.language) : ''}
                                styles={customStyles}
                        />
                        <label htmlFor="step3CityInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep7Page.email'
                                defaultMessage='E-Mail'
                            />
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3CityInput"
                            onChange={this.handleChange}
                            name="email"
                            value={this.state.email ? this.state.email : ''}
                        />

                    </div>
                </div>
                <div style={{height: '20px'}} />
                <div className="bottom">
                    <div onClick={this.handleSubmit}>
                        <button className="b-button-pre-checkin" id="self-service-step7-confirm-next-button" disabled={this.disabled}>
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep2Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </div>
                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep7 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        fellowTraveler: state.getIn(['selfService', 'data', 'fellowTraveler']),
        language: state.get('language')['currentLanguage'],
        nationalityData: state.getIn(['selfService', 'nationalityData']),
        languageData: state.getIn(['selfService', 'languageData']),
    }),
    (dispatch) => ({
        setFellowTravelerData: fellowTraveler => dispatch(Actions.setFellowTravelerData(fellowTraveler))
    })
)(SelfServiceStep7Component);

