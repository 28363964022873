export function standardRouteNew(roles) {
    let r = roles;

    if(r && r.length > 0) {
        const sp = r.length === 1 ? r[0].startsWith('ROLE_SP') : false;
        const police = r.length === 1 ? r[0].startsWith('ROLE_POLICE_USER') : false;

        if(police) {
            return '/police/';
        } else if(!sp) {
            return '/administration/';
        } else {
            return '/';
        }
    } else {
        return '/';
    }
}

export function isZTUserNew(roles) {
    let r = roles;

    if(r && r.length > 0) {
        const sp = r.length === 1 ? r[0].startsWith('ROLE_SP') : false;
        const police = r.length === 1 ? r[0].startsWith('ROLE_POLICE_USER') : false;
        const noRolesRole = r.length === 1 ? r[0].indexOf('ROLE_NO_ROLES') > -1 : false;

        if(noRolesRole) {
            return false;
        }

        return !sp && !police

    } else {
        return false;
    }
}

export function isPoliceUserNew(roles) {
    let r = roles;

    if(r && r.length > 1) {
        const police = r.length === 1 ? r[0].startsWith('ROLE_POLICE_USER') : r.find((role) => role.startsWith('ROLE_POLICE_USER')) !== undefined;
        return police;
    } else {
        return false;
    }
}

export function arrayRemove(arr, value) {
     arr.splice(value, 1);
    return arr;

}






export function standardRoute(roles) {
    if(roles){
        return standardRouteNew(roles);
    } else {
        if (localStorage.getItem('roles')) {
            const roles = JSON.parse(localStorage.getItem('roles'));
            const roleSP = roles.length > 0 ? roles[0].startsWith('ROLE_SP') : false;
            const policeUser = roles.length > 0 ? roles[0].startsWith('ROLE_POLICE_USER') : false;

            if (policeUser) {
                return '/police/';
            } else if (!roleSP) {
                return '/administration/';
            } else {
                return '/';
            }
        }
    }
}

export function isZTUser(roles) {
    if(roles) {
        return isZTUserNew(roles);
    } else {
        if (localStorage.getItem('roles')) {
            const roles = JSON.parse(localStorage.getItem('roles'));

            if (roles.length > 0) {
                const roleSP = roles[0].startsWith('ROLE_SP');
                const policeUser = roles[0].startsWith('ROLE_POLICE_USER');

                const headerRole = localStorage.getItem('roles');
                if (headerRole === '["ROLE_NO_ROLES"]') {
                    return false;
                }

                return !roleSP && !policeUser;
            }
        } else {
            return false;
        }
    }
}

export function isPoliceUser(roles) {
    if(roles) {
        return isPoliceUserNew(roles);
    } else {
        if (localStorage.getItem('roles')) {
            const roles = JSON.parse(localStorage.getItem('roles'));

            if (roles.length > 0) {
                return roles.find(role => role.startsWith("ROLE_POLICE_USER"));
            }
        } else {
            return false;
        }
    }
}