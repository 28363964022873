import {combineReducers} from "redux-immutable";
import ReportChange from './ReportChange/redux';
import ServicePartnerList from './ServicePartnerList/redux';
import ServicePartnerCreate from './ServicePartnerCreate/redux';
import ServicePartnerEdit from './ServicePartnerEdit/redux';
import ServicePartnerForm from './ServicePartnerForm/redux';

export const Reducer = combineReducers({
    reportChange: ReportChange.Reducer,
    list: ServicePartnerList.Reducer,
    create: ServicePartnerCreate.Reducer,
    edit: ServicePartnerEdit.Reducer,
    form: ServicePartnerForm.Reducer
});

export default {
    Reducer
};
