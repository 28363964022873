import {Actions as LanguageActions} from "bonfire/Language/redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";

import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/fr';
import PropTypes from 'prop-types';
import {isNil} from "ramda";
import React, {useEffect} from 'react';

import {IntlProvider} from 'react-intl';
import {connect} from 'react-redux';

export function LanguageProviderComponent(props) {
    const languagesLoaded = !isNil(props.messages)

    // moment.js locale settings
    useEffect(() => {
        moment.locale(props.language);

        // set to de when unloading LanguageProvider -> main ZTerminal is mono language...
        return function cleanup() {
            moment.locale('de');
        }
    });

    useEffect(() => {
        if (!languagesLoaded) {
            props.onLanguageProviderLoad();
        }
    });

    return <LoadingOverlay loading={!languagesLoaded}>
        <IntlProvider locale={props.language}
                      messages={props.messages}
        >
            {React.Children.only(props.children)}
        </IntlProvider>
    </LoadingOverlay>
}

LanguageProviderComponent.propTypes = {
    language: PropTypes.string,
    messages: PropTypes.object,
    children: PropTypes.node.isRequired,
}

export default connect(
    state => ({
        language: state.get('language')['currentLanguage'],
        messages: state.get('language')['currentMessages']
    }),

    dispatch => ({
        onLanguageProviderLoad: () => dispatch(LanguageActions.initializeLanguage())
    })
)(LanguageProviderComponent);
