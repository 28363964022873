import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {FormattedMessage} from "react-intl";
import {Link, withRouter} from "react-router-dom";
import {compose} from "redux";
import {connect} from "react-redux";
import {SobrDataTable} from "components/SobrDataTable/SobrDataTable";
import SobrPagination from "components/SobrPagination/SobrPagination";
import f from "lib/util";
import {Actions as ServicePartnerListActions} from "bonfire/ZTerminal/ServicePartner/ServicePartnerList/redux";
import SobrSearchField from "components/SobrSearchField/SobrSearchField";

export class ServicePartnerListPageComponent extends Component {

    static propTypes = {
        servicePartners: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        numPages: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        searchTerm: PropTypes.string,
        showInactive: PropTypes.bool,
        onSetCurrentPage: PropTypes.func,
        onSortingChange: PropTypes.func,
        onSetSearchTerm: PropTypes.func,
        onSetShowInactive: PropTypes.func,
        onLoad: PropTypes.func
    };

    static defaultProps = {
        loading: false
    };


    constructor(props, context) {
        super(props, context);

        this.goToServicePartner = this.goToServicePartner.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
    }

    componentDidMount() {
        f.callIfExists(this.props.onLoad);
    }

    get breadcrumbPath() {
        return [{
            id: 'bonfire.containers.administration.ServicePartner.header',
            defaultMessage: 'Stammdaten'
        }];
    }

    get tableColumns() {
        return [
            {
                Header: 'bonfire.servicepartner.list.tableheaders.name',
                accessor: 'name'
            },
            {
                Header: 'bonfire.servicepartner.list.tableheaders.partnerId',
                accessor: 'partnerId'
            },
            {
                Header: 'bonfire.servicepartner.list.tableheaders.partnerGroup',
                accessor: 'partnerGroup.name'
            }
        ]
    }

    goToPage(page) {
        f.callIfExists(this.props.onSetCurrentPage, page);
    }

    goToServicePartner(servicePartner) {
        this.props.history.push(`/zterminal/servicepartner/edit/${servicePartner.id}`);
    }

    changeSorting(field, direction) {
        f.callIfExists(this.props.onSortingChange, field, direction);
    }

    render() {
        return <div id="service-partner-list-page" className="zterminal-content-box">
            <WithBreadcrumb icon="hotel" path={this.breadcrumbPath}/>

            <div className="zterminal-content-box-header">
                <div className="px-4 py-3 border-bottom border-light">
                    <div className="row" id="servicepartner-list-filter">
                        <div className="col-6 text-right">
                            <div className={'inactive-toggler'} id={'filter-show-inactive'}>
                                <button className={'btn show-active' + (this.props.showInactive ? '' : ' active')} onClick={() => this.props.onSetShowInactive(false)}>Nur Aktive zeigen</button>
                                <button className={'btn show-inactive' + (this.props.showInactive ? ' active' : ' ')} onClick={() => this.props.onSetShowInactive(true)}>Nur inaktive</button>
                            </div>
                        </div>
                        <div className="col-6">
                            <SobrSearchField
                                value={this.props.searchTerm}
                                onChangeTerm={this.props.onSetSearchTerm}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="zterminal-content-box-body">
                <div className="flex-growing scrollable px-4 pt-3">
                    <SobrDataTable
                        additionalClassNames="h-100 mh-100 w-100"
                        columns={this.tableColumns}
                        data={this.props.servicePartners}
                        loading={this.props.loading}
                        onSelectRow={this.goToServicePartner}
                        onChangeSorting={this.changeSorting}
                    />
                </div>
                <div className="flex-fixed px-4 py-2">
                    <SobrPagination
                        numPages={this.props.numPages}
                        currentPage={this.props.currentPage}
                        onSelectPage={this.goToPage}
                        className="pagination flex-wrap"
                    />
                </div>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <Link to={'/zterminal/servicepartner/create'} className="btn btn-primary btn-160 mr-3">
                                <FormattedMessage
                                    id="bonfire.containers.administration.ServicePartner.createButton"
                                    defaultMessage="Neu"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    }
}

export default compose(
    withRouter,
    connect(
        state => {
            const spListState = state.getIn(['zterminal', 'servicePartner', 'list']);
            return {
                servicePartners: spListState.get('servicePartnerList'),
                loading: spListState.get('loading'),
                numPages: spListState.get('numPages'),
                currentPage: spListState.get('currentPage'),
                showInactive: spListState.get('showInactive')
            };
        },
        dispatch => ({
            onSortingChange: (field, direction) => {
                dispatch(ServicePartnerListActions.setSortColumn(field));
                dispatch(ServicePartnerListActions.setSortOrder(direction));
                dispatch(ServicePartnerListActions.invalidateData());
                return dispatch(ServicePartnerListActions.fetchListIfNecessary());
            },
            onSetCurrentPage: currentPage => {
                dispatch(ServicePartnerListActions.setCurrentPage(currentPage));
                dispatch(ServicePartnerListActions.invalidateData());
                return dispatch(ServicePartnerListActions.fetchListIfNecessary());
            },
            onSetSearchTerm: searchTerm => {
                dispatch(ServicePartnerListActions.setSearchTerm(searchTerm));
                dispatch(ServicePartnerListActions.invalidateData());
                return dispatch(ServicePartnerListActions.fetchListIfNecessary());
            },
            onSetShowInactive: showInactive => {
                dispatch(ServicePartnerListActions.setShowInactive(showInactive));
                dispatch(ServicePartnerListActions.invalidateData());
                return dispatch(ServicePartnerListActions.fetchListIfNecessary());
            },
            onLoad: () => dispatch(ServicePartnerListActions.fetchListIfNecessary())
        })
    )
)(ServicePartnerListPageComponent);

