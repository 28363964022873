/* eslint-disable comma-spacing,no-plusplus */
/*
 * SelfServicePage
 *
 * List all the features
 */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {getCustomPlaceHolderLanguages} from "../../utils/selectTexts";
import Select from 'react-select';
import {Actions} from "./redux";
import $ from "jquery";

const customStyles = {
    menu: (provided, state) => ({
        ...provided,
        maxHeight: '200px'
    }),
    menuList: (styles) => ({
        ...styles,
        maxHeight: '200px'
    })
};


export class SelfServiceStep3Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        nationalityData: PropTypes.any.isRequired,
        setCustomerData: PropTypes.func.isRequired,
        language: PropTypes.string.isRequired,
        customerData: PropTypes.any
    };

    constructor(props) {
        super(props);
        this.state = {
            street: '',
            zip: '',
            city: '',
            country: '',
            email: '',
            phoneNumber: '',
            mobileNumber: ''
        };

        this.disabled = true;

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.onChangeCountry = this.onChangeCountry.bind(this);
        this.handleDropdownMenu = this.handleDropdownMenu.bind(this);
    }

    componentDidMount() {
        if (this.props.registrationForm) {
            let primaryGuest = this.props.registrationForm.guests.find(guest => guest.primary === true);
            let primGuestAdr = primaryGuest.guest.address;
            let customerData = this.props.customerData;
            let custDataAdr = customerData ? customerData.address : null;
            let countryList = this.props.nationalityData;

            let country = custDataAdr ? custDataAdr.country : primGuestAdr ? primGuestAdr.country : null;

            this.setState({
                street: custDataAdr ? custDataAdr.street : primGuestAdr ? primGuestAdr.street : '',
                zip: custDataAdr ? custDataAdr.zip : primGuestAdr ? primGuestAdr.zip : '',
                city: custDataAdr ? custDataAdr.city : primGuestAdr ? primGuestAdr.city : '',
                email: customerData ? customerData.email : primaryGuest ? primaryGuest.guest.email : '',
                phoneNumber: customerData ? customerData.phoneNumber : primaryGuest ? primaryGuest.guest.phoneNumber : '',
                mobileNumber: customerData ? customerData.mobileNumber : primaryGuest ? primaryGuest.guest.mobileNumber : '',
                country: country ? country.length > 0 && countryList.find((c) => c.value === country.toUpperCase()) ? countryList.find((c) => c.value === country.toUpperCase())?.value : countryList.find((c => c.label === country.toUpperCase()))?.value : null
            });
        }
    }

    handleDropdownMenu(open) {
        let scrollTop = $('.content-box .text').scrollTop();
        open ? $('.content-box .text').addClass('menu-open').css('margin-top', '-' + scrollTop + 'px') : $('.content-box .text').removeClass('menu-open').css('margin-top', '0');
    }

    onChangeCountry(e) {
        this.setState({
            country: e.value
        });
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    is_valid_text(value, isEmail = false) {
        const emailPattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (isEmail)
            return emailPattern.test(String(value).toLowerCase());
        else
            return value && value.length > 0;
    }

    is_valid_mobileNumber(value) {
        const mobileNumberPattern = /^\+?[\d\s]+$/;

        return mobileNumberPattern.test(String(value).toLowerCase());
    }

    handleSubmit() {
        if (this.disabled)
            return;

        let customerBefore = this.props.customerData ? this.props.customerData : this.props.registrationForm.guests.find(guest => guest.primary === true);

        const customer = Object.assign({}, this.props.customerData ? customerBefore : customerBefore.guest);
        customer.guestId = customerBefore.id;
        customer.primary = customerBefore.primary;
        customer.checkinDate = customerBefore.checkinDate;
        customer.checkoutDate = customerBefore.checkoutDate;

        if (!customer.address)
            customer['address'] = {};

        customer.address.street = this.state.street
        customer.address.zip = this.state.zip;
        customer.address.city = this.state.city;
        customer.address.country = this.state.country;
        customer.email = this.state.email;
        customer.phoneNumber = this.state.phoneNumber;
        customer.mobileNumber = this.state.mobileNumber;

        this.props.setCustomerData(customer);

        this.props.history.push(`/self-service/${this.props.registrationForm.id}/step4`);
    }

    render() {
        const countryOptions = this.props.nationalityData;
        this.disabled = this.state.street && this.state.zip && this.state.city && this.state.country && this.state.email && this.is_valid_text(this.state.email, true) && this.is_valid_mobileNumber(this.state.mobileNumber) ? false : true;

        return (
            <SelfServiceMainLayout
                title={{id: 'bonfire.containers.SelfServiceStep3Page.title', defaultMessage: 'Pre-Check-In'}}
                class="step3">

                <SelfServiceStepLayout id={this.props.registrationForm.id}/>


                <div className="text">
                    <div className="wrapper self-service">
                        <label htmlFor="step3StreetInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.street'
                                defaultMessage='Strasse'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3StreetInput"
                            onChange={this.handleChange}
                            name="street"
                            value={this.state.street ? this.state.street : ''}
                        />
                        <label htmlFor="step3PostalcodeInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.postalcode'
                                defaultMessage='PLZ'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3PostalcodeInput"
                            onChange={this.handleChange}
                            name="zip"
                            value={this.state.zip ? this.state.zip : ''}
                        />
                        <label htmlFor="step3CityInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.city'
                                defaultMessage='Ort'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3CityInput"
                            onChange={this.handleChange}
                            name="city"
                            value={this.state.city ? this.state.city : ''}
                        />
                        <label htmlFor="step3CountrySelect" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.country'
                                defaultMessage='Land'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <Select className='b-select-input-service'
                                classNamePrefix="react-select_service"
                                id="country"
                                placeholder={getCustomPlaceHolderLanguages(this.props.language)}
                                ref={(ref) => {
                                    this.select = ref;
                                }}
                                onBlurResetsInput={false}
                                onSelectResetsInput={false}
                                simpleValue
                                clearable={false}
                                onChange={this.onChangeCountry}
                                name="selected-state"
                                searchable={true}
                                options={countryOptions}
                                valueKey="code"
                                labelKey="name"
                                onMenuOpen={() => this.handleDropdownMenu(true)}
                                onMenuClose={() => this.handleDropdownMenu(false)}
                                value={this.state.country ? countryOptions.find((country) => country.value === this.state.country) : ''}
                                styles={customStyles}
                        />
                        <label htmlFor="step3EmailInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.email'
                                defaultMessage='E-Mail'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="mail"
                            className="b-input-self-service"
                            id="step3EmailInput"
                            onChange={this.handleChange}
                            name="email"
                            value={this.state.email ? this.state.email : ''}
                        />
                        <label htmlFor="step3MobilePhoneInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.mobilephone'
                                defaultMessage='Mobiltelefon'
                            />
                            <span className="selfcheckin-primary-star">*</span>
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3MobilePhoneInput"
                            onChange={this.handleChange}
                            name="mobileNumber"
                            value={this.state.mobileNumber ? this.state.mobileNumber : ''}
                        />
                        <label htmlFor="step3MobilePhoneInput" className="b-input-label-self">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.phone'
                                defaultMessage='Festnetz'
                            />
                        </label>
                        <input
                            type="text"
                            className="b-input-self-service"
                            id="step3MobilePhoneInput"
                            onChange={this.handleChange}
                            name="phoneNumber"
                            value={this.state.phoneNumber ? this.state.phoneNumber : ''}
                        />
                    </div>
                </div>

                <div className="bottom">
                    <div onClick={this.handleSubmit}>
                        <button className="b-button-pre-checkin" disabled={this.disabled} id="self-service-step3-confirm-next-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </div>
                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep3 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        nationalityData: state.getIn(['selfService', 'nationalityData']),
        language: state.get('language')['currentLanguage'],
        customerData: state.getIn(['selfService', 'data', 'customerData'])
    }),
    (dispatch) => ({
        setCustomerData: customerData => dispatch(Actions.setCustomerData(customerData))
    })
)(SelfServiceStep3Component);

