import {faCircle} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {SobrInfoButton} from "components/SobrInfoButton/SobrInfoButton";
import Moment from "moment";
import 'moment/locale/de';
import PropTypes from 'prop-types';
import React from 'react';
import {FormattedMessage} from 'react-intl';
import Select from 'react-select';
import {labelStyle} from "../../containers/Login/style";
import {customDropDownStylesLittle} from "../../global-styles";
import icon_minus from '../../images/icons/minus.svg';
import icon_plus from '../../images/icons/plus.svg';

import {
    addDeleteButton,
    buttonInputContainer,
    cancelButtonStyle,
    completeButtonOBStyle,
    completeButtonStyle,
    deleteButtonStyle,
    groupAddHeaderStyle,
    groupElementContainerStyle,
    groupElementInputStyle,
    groupElementTitle,
    iconStyle,
    logo,
    modalBodyStyle,
    modalButtonAreaOnBoardingStyle,
    modalButtonAreaStyle,
    modalContentExcStyle,
    modalContentGroupBookingStyle,
    modalContentOnBoardingStyle,
    modalContentPMSStyle,
    modalContentStyle,
    normalInputStyle,
    onBoardingCircleContainer,
    onBoardingContactText,
    onBoardingDivider,
    onBoardingHeadline,
    onBoardingText,
    titleCompleteStyle,
    titleStyle,
} from './style';

export const inputHeaderContainer = {
    "width": "97%",
    "display": "flex",
};

export default class DialogComponent extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            kids: 0,
            parents: 0,
            smallKids: 0,
            selectedItem: null,
            reason: '',
            taxCategoryId: '',
            onBoardingStep: 1,
            closingDaysData: [{ key: 'startDay', value: new Date() }, { key: 'endDay', value: new Date() }],
            startDay: { key: 'startDay', value: Moment.utc() },
            endDay: { key: 'endDay', value: Moment.utc() }
        };
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.addKid = this.addKid.bind(this);
        this.deleteKid = this.deleteKid.bind(this);
        this.addParents = this.addParents.bind(this);
        this.deleteParents = this.deleteParents.bind(this);
        this.addSmallKids = this.addSmallKids.bind(this);
        this.deleteSmallKids = this.deleteSmallKids.bind(this);
        this.setOnChangeStartDay = this.setOnChangeStartDay.bind(this);
        this.setOnChangeEndDay = this.setOnChangeEndDay.bind(this);
        this.setStartDay = this.setStartDay.bind(this);
        this.handleStartDayChange = this.handleStartDayChange.bind(this);
        this.toggleStartDay = this.toggleStartDay.bind(this);
        this.toggleEndDay = this.toggleEndDay.bind(this);
        this.setEndDay = this.setEndDay.bind(this);
        this.onChangeTaxCategory = this.onChangeTaxCategory.bind(this);
        this.handleCreate = this.handleCreate.bind(this);
        this.handleDelete = this.handleDelete.bind(this);
        this.handleUpdate = this.handleUpdate.bind(this);
        this.nextStepWelcome = this.nextStepWelcome.bind(this);
        this.closeDayPicker = this.closeDayPicker.bind(this);
        this.closeDayPickerMouse = this.closeDayPickerMouse.bind(this);
    };

    onChangeTaxCategory(value) {
        this.setState({touristTaxExemptCategory: value});
        this.setState({closingDaysData: [...this.state.closingDaysData, {key: 'touristTaxExemptCategory', value: value.value}]});
    }

    componentDidMount() {
        document.addEventListener("keydown", this.closeDayPicker, false);
        //document.addEventListener("mousedown", this.closeDayPickerMouse, false);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.closeDayPicker, false);
        //document.removeEventListener("mousedown", this.closeDayPickerMouse, false);
    }


    toggleStartDay (e) {
        e && e.preventDefault();
        this.setState({startdayIsOpen: !this.state.startdayIsOpen});
    }

    handleStartDayChange(startMonth) {
        this.setState({ startMonth });
    }


    setStartDay(event) {
        this.setState({ startDay: { key: 'startDay', value: event } });
        this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'startDay', value: event }] });
        this.toggleStartDay();
    }

    setOnChangeStartDay(event) {
        if (event instanceof Moment) {
            this.setState({startDayOnChange: {key: 'startDayOnChange', value: event} });
            this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'startDay', value: event }] });
        }
        if (event.type === 'change') {
            this.setState({ startDayOnChange: { key: 'startDayOnChange', value: event.target.value }});
        } else if (event.type === 'blur') {
            this.setState({startDayOnChange: '' });
            this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'startDay', value: Moment.utc(event.target.value, 'DD.MM.YYYY').toDate() }] });

            if (event.target.value === 'Invalid date' || event.target.value === '') {
                this.setState({ startMonth: new Date(1920,1,1) });
            } else {
                this.setState({ startMonth: new Date(Moment.utc(event.target.value, 'DD.MM.YYYY').toDate()) });
            }

            if (this.state.startdayIsOpen) {
                this.toggleStartDay();
            }
        }
    }

    toggleEndDay (e) {
        e && e.preventDefault();
        this.setState({enddayIsOpen: !this.state.enddayIsOpen});
    }

    closeDayPicker(event) {
        if (event.keyCode === 27) {
            if (this.state.enddayIsOpen === true) {
                this.setState({enddayIsOpen: false});
            }
            if (this.state.startdayIsOpen === true) {
                this.setState({startdayIsOpen: false});
            }
        }
    }

    closeDayPickerMouse(event) {
        if (event.target.className.indexOf('DayPicker') === -1 && (!event.target.name || event.target.name.indexOf('year') === -1) && (!event.target.name || event.target.name.indexOf('month') === -1)) {
            if (this.state.enddayIsOpen === true) {
                this.setState({enddayIsOpen: false});
            }
            if (this.state.startdayIsOpen === true) {
                this.setState({startdayIsOpen: false});
            }
        }
    }

    setEndDay(event) {
        this.setState({ endDay: { key: 'endDay', value: event } });
        this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'endDay', value: event }] });
        this.toggleEndDay();
    }

    setOnChangeEndDay(event) {
        if (event instanceof Moment) {
            this.setState({ endDayOnChange: { key: 'endDayOnChange', value: event }});
            this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'endDay', value: event }] });
        }
        if (event.type === 'change') {
            this.setState({ endDayOnChange: { key: 'endDayOnChange', value: event.target.value }});
        } else if (event.type === 'blur') {
            this.setState({endDayOnChange: '' });
            this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'endDay', value: Moment.utc(event.target.value, 'DD.MM.YYYY').toDate() }] });

            if (event.target.value === 'Invalid date' || event.target.value === '') {
                this.setState({ startMonth: new Date(1920,1,1) });
            } else {
                this.setState({ startMonth: new Date(Moment.utc(event.target.value, 'DD.MM.YYYY').toDate()) });
            }

            if (this.state.enddayIsOpen) {
                this.toggleEndDay();
            }
        }
    }


    addKid() {
        this.setState({kids: this.state.kids+1});
    }
    deleteKid() {
        if (this.state.kids !== 0) {
            this.setState({kids: this.state.kids-1});
        }
    }
    addParents() {
        this.setState({parents: this.state.parents+1});
    }
    deleteParents() {
        if (this.state.parents !== 0) {
            this.setState({parents: this.state.parents-1});
        }
    }
    addSmallKids() {
        this.setState({smallKids: this.state.smallKids+1});
    }
    deleteSmallKids() {
        if (this.state.smallKids !== 0) {
            this.setState({smallKids: this.state.smallKids-1});
        }
    }
    handleChange(event) {
        if (event.target.name === 'kids') {
            this.setState({kids: event.target.value});
        } else if (event.target.name === 'parents') {
            this.setState({parents: event.target.value});
        } else if (event.target.name === 'smallKids') {
            this.setState({smallKids: event.target.value});
        } else {
            this.setState({ closingDaysData: [...this.state.closingDaysData, { key: event.target.name, value: event.target.value }] });
            this.setState({ [event.target.name]: event.target.value });
        }
    }

    handleSubmit() {
        this.props.function(this.state.parents, this.state.kids, this.state.smallKids);
    }

    handleCreate() {
        const json = {};
        json.reason = this.state.reason;
        json.start = this.state.startDay.value.format('DD.MM.YYYY');
        json.end =  this.state.endDay.value.format('DD.MM.YYYY');
        json.taxCategoryId = this.state.touristTaxExemptCategory.value;

        this.setState({reason: ''});
        this.setState({ taxCategoryId: ''});

        this.props.function(json);

        this.setState({selectedItem: null});
        this.setState({  startDay: { key: 'startDay', value: new Date() } });
        this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'startDay', value: new Date() }, { key: 'endDay', value: new Date() }] });
        this.setState({ endDay: { key: 'endDay', value: new Date() } });
    }

    nextStepWelcome() {
        sessionStorage.setItem('firstLogin', false);
    }

    handleUpdate() {
        const json = {};
        json.reason = this.state.reason;
        json.start = Moment.utc(this.state.startDay.value, 'DD.MM.YYYY');
        json.end =  Moment.utc(this.state.endDay.value, 'DD.MM.YYYY');
        json.taxCategoryId = this.state.touristTaxExemptCategory.value;
        this.setState({reason: ''});
        this.setState({ taxCategoryId: ''});

        this.props.function3(this.state.selectedItem, json);

        this.setState({ selectedItem: null});
        this.setState({ startDay: { key: 'startDay', value: new Date() } });
        this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'startDay', value: new Date() }, { key: 'endDay', value: new Date() }] });
        this.setState({ endDay: { key: 'endDay', value: new Date() } });
    }

    handleDelete(event) {
        this.setState({reason: ''});
        this.setState({ taxCategoryId: ''});
        this.setState({ selectedItem: null});
        this.setState({ startDay: { key: 'startDay', value: new Date() } });
        this.setState({ closingDaysData: [...this.state.closingDaysData, { key: 'startDay', value: new Date() }, { key: 'endDay', value: new Date() }] });
        this.setState({ endDay: { key: 'endDay', value: new Date() } });

        this.props.function2(event.target.id);
    }


    render() {
        const columns = [
            {
                id: "reason",
                Header: 'Grund',
                accessor: 'reason',
                sortable: false,
            },
            {
                id: 'start',
                Header: 'Startdatum',
                accessor: d => Moment.utc(d.start).format("D.MM.YYYY"),
                sortable: false,
            },
            {
                id: 'end',
                Header: 'Enddatum',
                accessor: d => Moment.utc(d.end).format("D.MM.YYYY"),
                sortable: false,
            },
            {
                id: "typ",
                name: "typ",
                Header: 'Typ',
                 accessor: 'taxCategory.label',
                sortable: false,
            },
            {
                Header: 'Löschen',
                accessor: "id",
                Cell: row => (
                    <button type="button" id={row.value} {...cancelButtonStyle.button} name="deleteButton" className="btn" onClick={this.handleDelete}>Löschen</button>
                )
            }
        ];

    return (
        <div className="modal fade" id={this.props.id} data-backdrop="static" data-keyboard="false" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
             aria-hidden="true">

            {(this.props.style === 'deleteNew') &&
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={modalContentStyle}>
                    <div className="modal-body" style={modalBodyStyle}>
                        <span className="icon icon-delete" style={iconStyle}></span>
                        <h5 style={titleStyle}><FormattedMessage {...this.props.title}></FormattedMessage></h5>
                            <FormattedMessage {...this.props.message}></FormattedMessage>
                        <div style={modalButtonAreaStyle}>
                            <button type="button" {...cancelButtonStyle.button} className="button-secondary" data-dismiss="modal">
                                <FormattedMessage {...this.props.cancelButton}></FormattedMessage>
                            </button>
                            <button {...deleteButtonStyle.button} type="button" className="button-primary" onClick={this.props.function} data-dismiss="modal">
                                <FormattedMessage {...this.props.actionButton}></FormattedMessage>
                            </button>
                        </div>
                    </div>
                </div>
            </div>}

            {(this.props.style === 'pmsInfo') &&
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={modalContentPMSStyle}>
                    <div className="modal-body" style={modalBodyStyle}>
                        <h5 style={titleCompleteStyle}>{this.props.title}</h5>
                        {this.props.message}
                        <div style={modalButtonAreaStyle}>
                            <button type="button" {...cancelButtonStyle.button} className="button-secondary" data-dismiss="modal">Schliessen</button>
                        </div>
                    </div>
                </div>
            </div>}


            {(this.props.style === 'onBoardingWelcome') &&
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={modalContentOnBoardingStyle}>
                    <div className="modal-body" style={modalBodyStyle}>
                        <div style={logo}></div>
                        <div style={onBoardingHeadline}>WILLKOMMEN ZU BONFIRE</div>
                        <div style={onBoardingDivider}></div>
                        <div style={onBoardingText}>{this.props.message}</div>
                        <div>
                            <span style={onBoardingContactText}>Telefon:</span> 027 334 44 44
                            <br/>
                            <span style={onBoardingContactText}>E-Mail:</span> zterminal@zermatt.ch
                        </div>
                        <div style={modalButtonAreaOnBoardingStyle}>
                            <button {...completeButtonOBStyle.button} type="button" className="btn" onClick={this.nextStepWelcome} data-dismiss="modal">{this.props.actionButton}</button>
                        </div>
                        <div style={onBoardingCircleContainer}>
                            <FontAwesomeIcon icon={faCircle} size={"sm"} />
                        </div>
                    </div>
                </div>
            </div>}

            {(this.props.style === 'exclusions') &&
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={modalContentExcStyle}>
                    <div className="modal-body" style={modalBodyStyle}>
                        <div style={inputHeaderContainer}>
                            <div style={normalInputStyle}>
                                <label htmlFor="company" style={labelStyle}>Grund</label>
                                <input
                                    type="text"
                                    className="form-control respInputRegForm informationsInputStyle"
                                    id="reason"
                                    name="reason"
                                    value={this.state.reason}
                                    onChange={this.handleChange}
                                />
                            </div>
                            <div style={normalInputStyle}>
                                <label htmlFor="company" style={labelStyle}>Kategorie</label>
                                <Select
                                    className='react-select-container'
                                    classNamePrefix="react-select"
                                    id="touristCategory"
                                    placeholder={<FormattedMessage
                                        id='bonfire.containers.choose'
                                        defaultMessage='Auswählen'
                                    />}
                                    ref={(ref) => {
                                        this.select = ref;
                                    }}
                                    onBlurResetsInput={false}
                                    onSelectResetsInput={false}
                                    onChange={this.onChangeTaxCategory}
                                    simpleValue
                                    clearable={false}
                                    name="selected-state"
                                    searchable={true}
                                    options={this.props.taxCategories}
                                    valueKey="id"
                                    labelKey="name"
                                    styles={Object.assign({}, customDropDownStylesLittle)}
                                    value={this.state.touristTaxExemptCategory}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}

            {(this.props.style === 'addGroup') &&
            <div className="modal-dialog" role="document">
                <div className="modal-content" style={modalContentGroupBookingStyle}>
                    <div className="modal-body" style={modalBodyStyle}>
                        <div style={groupAddHeaderStyle}>
                            <div style={{display: 'flex', marginBottom: '10px', alignItems: 'center'}}>
                                <h5 style={{color: '#524440', margin: '0', fontWeight: 'inherit'}}>
                                    <FormattedMessage {...this.props.title}></FormattedMessage>
                                </h5>
                                <SobrInfoButton>
                                        <div className={'status-info'}>
                                            <span><FormattedMessage id="bonfire.containers.administration.RegistrationForm.popup.erwachsene"
                                                              defaultMessage="bonfire.containers.administration.RegistrationForm.popup.erwachsene"/></span><br />
                                            <span><FormattedMessage id="bonfire.containers.administration.RegistrationForm.popup.kinder"
                                                              defaultMessage="bonfire.containers.administration.RegistrationForm.popup.kinder"/></span><br />
                                            <span><FormattedMessage id="bonfire.containers.administration.RegistrationForm.popup.kleinkinder"
                                                              defaultMessage="bonfire.containers.administration.RegistrationForm.popup.kleinkinder"/></span>
                                        </div>
                                </SobrInfoButton>
                            </div>
                        {this.props.message}
                        </div>
                        <div style={groupElementContainerStyle}>
                            <label style={groupElementTitle}>
                                <FormattedMessage {...this.props.parents}></FormattedMessage>
                            </label>
                            <div style={buttonInputContainer}>
                                <button {...addDeleteButton.button} type="button" className="button-primary auto-h" onClick={this.deleteParents}>
                                    <img src={icon_minus} alt="Logo" className="align-middle" />
                                </button>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="parents"
                                    name="parents"
                                    style={Object.assign({}, groupElementInputStyle)}
                                    value={this.state.parents}
                                    onChange={this.handleChange}
                                />
                                <button {...addDeleteButton.button} type="button" className="button-primary auto-h" onClick={this.addParents}>
                                    <img src={icon_plus} alt="Logo" className="align-middle" />
                                </button>
                            </div>
                        </div>

                        <div style={groupElementContainerStyle}>
                            <label style={groupElementTitle}>
                                <FormattedMessage {...this.props.childrens}></FormattedMessage>
                            </label>
                            <div style={buttonInputContainer}>
                                <button {...addDeleteButton.button} type="button" className="button-primary auto-h" onClick={this.deleteKid}>
                                    <img src={icon_minus} alt="Logo" className="align-middle" />
                                </button>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="kids"
                                    name="kids"
                                    style={Object.assign({}, groupElementInputStyle)}
                                    value={this.state.kids}
                                    onChange={this.handleChange}
                                />
                                <button {...addDeleteButton.button} type="button" className="button-primary auto-h" onClick={this.addKid}>
                                    <img src={icon_plus} alt="Logo" className="align-middle" />
                                </button>
                            </div>
                        </div>

                        <div style={groupElementContainerStyle}>
                            <label style={groupElementTitle}>
                                <FormattedMessage {...this.props.subchildrens}></FormattedMessage>
                            </label>
                            <div style={buttonInputContainer}>
                                <button {...addDeleteButton.button} type="button" className="button-primary auto-h" onClick={this.deleteSmallKids}>
                                    <img src={icon_minus} alt="Logo" className="align-middle" />
                                </button>
                                <input
                                    type="text"
                                    className="form-control"
                                    id="smallKids"
                                    name="smallKids"
                                    style={Object.assign({}, groupElementInputStyle)}
                                    value={this.state.smallKids}
                                    onChange={this.handleChange}
                                />
                                <button {...addDeleteButton.button} type="button" className="button-primary auto-h" onClick={this.addSmallKids}>
                                    <img src={icon_plus} alt="Logo" className="align-middle" />
                                </button>
                            </div>
                        </div>

                        <div style={modalButtonAreaStyle}>
                            <button type="button" {...cancelButtonStyle.button} className="button-secondary" data-dismiss="modal">
                                <FormattedMessage {...this.props.cancelButton}/>
                            </button>
                            <button {...completeButtonStyle.button} type="button" className="button-primary" onClick={this.handleSubmit} data-dismiss="modal">
                                <FormattedMessage {...this.props.actionButton}/>
                            </button>
                        </div>
                    </div>
                </div>
            </div>}

        </div>

    );
  }
}



DialogComponent.propTypes = {
    id: PropTypes.any,
    title: PropTypes.any,
    message: PropTypes.any,
    actionButton: PropTypes.any,
    cancelButton: PropTypes.any,
    onClick1: PropTypes.func,
    style: PropTypes.string,
    data: PropTypes.any,
    taxCategories: PropTypes.any,
    parents: PropTypes.any,
    childrens: PropTypes.any,
    subchildrens: PropTypes.any,
    object: PropTypes.any,
    onSave: PropTypes.func,
};
