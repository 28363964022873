import { AsyncThunk } from "@reduxjs/toolkit";
import { AnalyticsChartSelectors, GetAnalyticsChartServiceParams, getAvgStaysCompareData, getAvgStaysData, getOccupancyForecastData, getOccupancyRateChartChangesData, getOccupancyRateChartData, getOverNightStaysChartAccumulatedData, getOverNightStaysChartAccumulatedForecastData, getOverNightStaysChartChangesData } from "../../AnalyticsChartSlice";

type GraphDataSelectors = {
    [key: string]: {
      period: (state: any) => any;
      periodCompare: (state: any) => any;
      actionCreator: AsyncThunk<any, GetAnalyticsChartServiceParams, {}>;
    };
  };

  export const graphDataSelectors: GraphDataSelectors = {
  //PERIOD
    overnightStaysChartData: {
      period: AnalyticsChartSelectors.overnightStaysChartDataSelectionPeriod,
      periodCompare: AnalyticsChartSelectors.overnightStaysChartDataSelectionPeriodCompare,
      actionCreator: getOverNightStaysChartAccumulatedData,
    },
    avgStaysChartData: {
      period: AnalyticsChartSelectors.avgStaysChartDataSelectionPeriod,
      periodCompare: AnalyticsChartSelectors.avgStaysChartDataSelectionPeriodCompare,
      actionCreator: getAvgStaysData,
    },
    occupancyRateChartData: {
      period: AnalyticsChartSelectors.occupancyRateChartDataSelectionPeriod,
      periodCompare: AnalyticsChartSelectors.occupancyRateChartDataSelectionPeriodCompare,
      actionCreator: getOccupancyRateChartData,
    },
    forecastChartData: {
      period: AnalyticsChartSelectors.occupancyForecastDataSelection,
      periodCompare: () => null,
      actionCreator: getOccupancyForecastData,
    },
    overnightForecastChartData: {
      period: AnalyticsChartSelectors.overnightForecastDataSelection,
      periodCompare: () => null,
      actionCreator: getOverNightStaysChartAccumulatedForecastData,
    },
    //COMPARE
    overnightStaysChartCompareData: {
      period: AnalyticsChartSelectors.overnightStaysChartDataCompareSelection,
      periodCompare: AnalyticsChartSelectors.overnightStaysChartDataCompareSelection,
      actionCreator: getOverNightStaysChartChangesData,
    },

    occupancyRateChartCompareData: {
      period: AnalyticsChartSelectors.occupancyRateChartDataCompareSelection,
      periodCompare:AnalyticsChartSelectors.occupancyRateChartDataCompareSelection,
      actionCreator: getOccupancyRateChartChangesData,
    },

    avgStaysChartCompareData: {
      period: AnalyticsChartSelectors.avgStaysChartDataCompareSelection,
      periodCompare:  AnalyticsChartSelectors.avgStaysChartDataCompareSelection,
      actionCreator: getAvgStaysCompareData,
    },

  };
  