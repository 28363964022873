export function bodylessRequest(url, params, method = 'GET', headers = {}) {
    const finalUrl = createGetRequestUrl(url, params);
    const finalHeaders = Object.assign({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }, headers);
    return fetch(finalUrl, {
        method: method,
        headers: finalHeaders
    }).then(handleJsonResponse);
}

/**
 * Create get request url with given search params.
 * Search param keys with value === undefined are not appended.
 */
export function createGetRequestUrl(url, searchParams = {}) {
    searchParams = searchParams || {};
    const result = new URL(url);
    Object.keys(searchParams)
        .map(key => ({key: key, val: searchParams[key]}))
        .filter(({val}) => val !== undefined)
        .forEach(({key, val}) => result.searchParams.append(key, val));
    return result;
}


export function getJson(url, params, headers = {}) {
    return bodylessRequest(url, params, 'GET', headers);
}

export function deleteJson(url, params, headers = {}) {
    return bodylessRequest(url, params, 'DELETE', headers);
}

export function bodyRequest(url, body, method = 'POST', headers = {}) {
    const finalHeaders = Object.assign({
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    }, headers);

    return fetch(url, {
        method: method,
        headers: finalHeaders,
        body: JSON.stringify(body)
    }).then(handleJsonResponse);
}

export function postJson(url, body, headers = {}) {
    return bodyRequest(url, body, 'POST', headers);
}

export function putJson(url, body, headers = {}) {
    return bodyRequest(url, body, 'PUT', headers);
}

export function handleJsonResponse(response) {
    if (response.ok) {
        if (response.status === 204) {
            return true
        } else {
            return response.json();
        }
    } else {
        return Promise.reject(response);
    }
}

export default {
    getJson, postJson, deleteJson, putJson, createGetRequestUrl, handleJsonResponse
}
