import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Link, withRouter} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import SobrSearchField from "components/SobrSearchField/SobrSearchField";
import {Actions as UserListActions} from './redux';
import f from "lib/util";
import {SobrDataTable} from "components/SobrDataTable/SobrDataTable";
import SobrPagination from "components/SobrPagination/SobrPagination";
import {compose} from "redux";
import { injectIntl } from "react-intl";

class UserListPageComponent extends React.Component {

    static propTypes = {
        searchTerm: PropTypes.string,
        users: PropTypes.array,
        onSetSearchTerm: PropTypes.func,
        onSetCurrentPage: PropTypes.func,
        onLoad: PropTypes.func,
        language: PropTypes.string.isRequired,
        loading: PropTypes.bool,
        numPages: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        history: PropTypes.object.isRequired
    };

    static defaultProps = {
        language: 'de',
        loading: false
    };

    constructor(props) {
        super(props);

        this.goToUser = this.goToUser.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
    }

    componentDidMount() {
        f.callIfExists(this.props.onLoad);
    }

    get breadcrumbPath() {
        return [
            {
                id: 'bonfire.containers.administration.User.header',
                defaultMessage: 'Benutzerverwaltung'
            }
        ];
    }

    get tableColumns() {
        return [
            {
                id: 'name',
                Header: 'bonfire.user.list.tableheaders.name',
                accessor: d => `${d.firstName} ${d.lastName}`,
            },
            {
                Header: 'bonfire.user.list.tableheaders.email',
                accessor: 'email'
            },
            {
                Header: 'bonfire.user.list.tableheaders.servicePartner',
                accessor: 'servicePartner.companyName'
            }
        ];
    }

    goToUser(user) {
        this.props.history.push(`/zterminal/user/edit/${user.id}`)
    }

    goToPage(page) {
        f.callIfExists(this.props.onSetCurrentPage, page);
    }

    changeSorting(field, direction) {
        f.callIfExists(this.props.onSortingChange, field, direction);
    }

    render() {
        return <div id="user-list-page" className="zterminal-content-box">
            <WithBreadcrumb icon="users" path={this.breadcrumbPath}/>
            <div className="zterminal-content-box-header">
                <div className="px-4 py-3 border-bottom border-light">
                    <div className="row" id="user-list-filter">
                        <div className="col-12 search-bar-col">
                            <SobrSearchField
                                value={this.props.searchTerm}
                                onChangeTerm={this.props.onSetSearchTerm}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <div className="zterminal-content-box-body">
                <div className="flex-growing scrollable px-4 pt-3">
                    <SobrDataTable
                        additionalClassNames="h-100 mh-100 w-100"
                        columns={this.tableColumns}
                        data={this.props.users}
                        loading={this.props.loading}
                        locale={this.props.language}
                        onSelectRow={this.goToUser}
                        onChangeSorting={this.changeSorting}
                    />
                </div>
                <div className="flex-fixed px-4 py-2">
                    <SobrPagination
                        numPages={this.props.numPages}
                        currentPage={this.props.currentPage}
                        onSelectPage={this.goToPage}
                        className="pagination flex-wrap"
                    />
                </div>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <Link to={'/zterminal/user/create'} className="btn btn-primary btn-160 mr-3">
                                <FormattedMessage
                                    id="bonfire.containers.administration.User.createButton"
                                    defaultMessage="Neuer Benutzer"
                                />
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    }
}

export default compose(
    withRouter,
    connect(
        state => {
            const userListState = state.getIn(['zterminal', 'user', 'list']);
            return {
                searchTerm: userListState.get('term'),
                users: userListState.get('userList'),
                loading: userListState.get('loading'),
                numPages: userListState.get('pages'),
                currentPage: userListState.get('currentPage')
            };
        },
        dispatch => ({
            onSetSearchTerm: searchTerm => {
                dispatch(UserListActions.setSearchTerm(searchTerm));
                dispatch(UserListActions.invalidateData());
                return dispatch(UserListActions.fetchListIfNecessary());
            },
            onSetCurrentPage: currentPage => {
                dispatch(UserListActions.setCurrentPage(currentPage));
                dispatch(UserListActions.invalidateData());
                return dispatch(UserListActions.fetchListIfNecessary());
            },
            onSortingChange: (field, direction) => {
                dispatch(UserListActions.setSortColumn(field));
                dispatch(UserListActions.setSortOrder(direction));
                dispatch(UserListActions.invalidateData());
                return dispatch(UserListActions.fetchListIfNecessary());
            },
            onLoad: () => dispatch(UserListActions.fetchListIfNecessary())
        })
    )
)(UserListPageComponent);


