/*
 * HomeReducer
 *
 * The reducer takes care of our data. Using actions, we can change our
 * application state.
 * To add a new action, add it to the switch statement in the reducer function
 *
 * Example:
 * case YOUR_ACTION_CONSTANT:
 *   return state.set('yourStateVariable', true);
 */
import { fromJS } from 'immutable';

import {
  LOGIN_USER,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_ERROR,
  REFRESH_TOKEN,
  CHECK_USER,
  REFRESH_TOKEN_SUCCESS, CHECK_USER_SUCCESS, REFRESH_TOKEN_ERROR, CHECK_USER_ERROR
} from './constants';

// The initial state of the App
const initialState = fromJS({
  loading: false,
  error: false,
  userToken: false,
  loggedIn: false,
});

function rootReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN_USER:
    case REFRESH_TOKEN:
    case CHECK_USER:
      return state
        .set('loading', true)
        .set('error', false)
        .set('loggedIn', false)
        .setIn(['userToken'], false);
    case LOGIN_USER_SUCCESS:
    case REFRESH_TOKEN_SUCCESS:
    case CHECK_USER_SUCCESS:
      return state
        .setIn(['userToken'], action.data)
        .set('loggedIn', true)
        .set('loading', false);
    case LOGIN_USER_ERROR:
    case REFRESH_TOKEN_ERROR:
    case CHECK_USER_ERROR:
      return state
        .set('loading', false)
        .set('loggedIn', false)
        .set('error', action.error);
    default:
      return state;
  }
}

export default rootReducer;
