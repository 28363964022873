import './SobrDropdownWithCheckbox.scss'
import  { useState } from 'react';
import { IconCheckbox } from '../../../SobrCheckbox/IconCheckbox';
import { SobrRadioButton} from'../../../SobrRadioButton/SobrRadioButton';

interface DropdownItem {
  key?: string;
  label: string;
  disabled?: boolean;
  selected?: boolean;
  onClick?: () => void; // Added this line
  onChange?: (checked: boolean) => void;
}

interface CheckboxItem extends DropdownItem {
  value?: string;
  key?: string;
}

interface RadioButtonItem extends DropdownItem {
  value: string;
  key?: string;
}

interface CustomDropdownProps {
  onDropdownChange: (dropdownId: string, values: any) => void;
  onItemClick?: () => void; 
  initialSelectedRadio: string;
  selectedKeys: string[] ;
  radioButtons: RadioButtonItem[];
  checkboxes: CheckboxItem[];
  onSelect: (keys: string[], allCheckboxSelected:boolean) => void;
  onRadioSelect: (key: string) => void;
  onClick?: () => void;
  allCheckboxDisabled?: boolean;
}

export const SobrDropdownWithCheckbox = ({ radioButtons, checkboxes, selectedKeys, initialSelectedRadio, onSelect, onRadioSelect }: CustomDropdownProps) => {
  const [selectedRadio, setSelectedRadio] = useState(initialSelectedRadio);

  const [selectedCheckbox, setSelectedCheckbox] = useState<string[]>(selectedKeys);


 // <string[]>( checkboxes.map((checkbox) => checkbox.key || checkbox.label));
  const [checkboxesDisabled, setCheckboxesDisabled] = useState(false);
  const [selectedLabel, setSelectedLabel] = useState(radioButtons[1].label);

  const handleRadioSelect = (value: string) => {
    setSelectedRadio(value);
    if (value === radioButtons[0].value) {
      setCheckboxesDisabled(true);
      setSelectedCheckbox([]);
      if (onSelect) {
        onSelect([], false);
      }
    } else {
      setCheckboxesDisabled(false);
  
      const allCheckboxKeys = checkboxes.map((checkbox) => checkbox.key || checkbox.label);
      setSelectedCheckbox(allCheckboxKeys);
      if (onSelect) {
        onSelect(allCheckboxKeys, true);
      }
    }
    if (onRadioSelect) {
      onRadioSelect(value);
    }
    setSelectedRadio(value);
    const selectedRadioItem = radioButtons.find((radio) => radio.value === value);
    if (selectedRadioItem) {
      setSelectedLabel(selectedRadioItem.label);
    }
    if (value === radioButtons[1].value) {
      setCheckboxesDisabled(false);
    }
  };

  const handleCheckboxSelect = (key: string) => {

    const checkbox = checkboxes.find((item) => item.key === key);
    if (checkbox && checkbox.disabled) {
      return;
    } else {
      const isSelected = selectedKeys.includes(key);
      const newSelectedKeys = isSelected
        ? selectedKeys.filter((k) => k !== key)
        : [...selectedKeys, key];
        setSelectedCheckbox(newSelectedKeys);
      if (onSelect && selectedRadio !== radioButtons[0].value) {
        onSelect(newSelectedKeys, false);
      } else {
        setCheckboxesDisabled(true);
      }
    }
  };

  return (
    <div className="custom-dropdown">
      <div className="dropdown">
          <button
           className="dropdown-toggle"
           type="button"
           id="dropdownMenuButton"
           data-toggle="dropdown"
           aria-haspopup="true"
           aria-expanded="false"
          
         > <span className='button-label'>  {selectedLabel} </span></button>
        <div className="dropdown-menu"  onClick={(e) => e.stopPropagation()}>
    
            {radioButtons.map((radio) => (
               <div onClick={() => handleRadioSelect(radio.value)} >
              <SobrRadioButton
                key={radio.key || radio.value}
                label={radio.label}
                value={radio.value}
                name="radio"
                selected={selectedRadio.includes(radio.key || radio.value)}
                onClick={() => handleRadioSelect(radio.value)}
              />
                </div>
            ))}
        {checkboxes.map((checkbox) => (
      <div

    onClick={() => handleCheckboxSelect(checkbox.key || checkbox.label)}
    
    className={`custom-checkbox-item${checkbox.disabled ? ' pointer-events-none' : ''}`}
  >
    <IconCheckbox
      key={checkbox.key}
      label={checkbox.label}
      value={checkbox.value  || ''}
      name="checkbox"
      checked={selectedKeys.includes(checkbox.key || checkbox.label)}
      disabled={checkboxesDisabled || checkbox.disabled === true}
      onChange={() => handleCheckboxSelect(checkbox.key || checkbox.label)}
    />
  </div>
))}
      

    
        </div>
      </div>
    </div>
  );
};
