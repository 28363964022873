import React, { useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";
import {AnalyticsBarsSelectors, calculatePercentage, getServiceAvgStaysBarData} from "../../../../AnalyticsBarsSlice";
import moment from "moment";
import {formatNumber} from "../../../../helpers";
import { AnalyticsSelectors, getPartnerTypeAndCategoryId } from 'bonfire/ZTerminal/Analytics/AnalyticsSlice';
import { SessionSelectors } from 'bonfire/ZTerminal/Session/SessionSlice';

export const AnalyticsAufenthaltsdauerCard = () => {
    const [data, setData] = useState<{ avgStays: number, avgStaysCompared: number, changePercentage: any}>();
    const trigger = useSelector(AnalyticsSelectors.trigger);
    const dispatch = useDispatch();
    const avgStays = useSelector(AnalyticsBarsSelectors.avgStaysBarDataSelection);
    const avgStaysCompared = useSelector(AnalyticsBarsSelectors.avgStaysBarDataCompareSelection);
    const startTimeFrame = useSelector(AnalyticsSelectors.startDateTimeFrame);
    const endTimeFrame = useSelector(AnalyticsSelectors.endDateTimeFrame);
    const startDate = moment(startTimeFrame).format("YYYY-MM-DD");
    const endDate = moment(endTimeFrame).format("YYYY-MM-DD");
    const startCompareFrame = useSelector(AnalyticsSelectors.startDateCompareFrame);
    const endCompareFrame = useSelector(AnalyticsSelectors.endDateCompareFrame);
    const startDateCompare = moment(startCompareFrame).format("YYYY-MM-DD");
    const endDateCompare = moment(endCompareFrame).format("YYYY-MM-DD");
  
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id || ""; 

    const dropdownValue = useSelector(AnalyticsSelectors.dropdownValues);
    const dropdownCompareValue = useSelector(AnalyticsSelectors.compareDropdownValues);
    const changePercentage = calculatePercentage(avgStays, avgStaysCompared);
   
    useEffect(() => {
   
        handleDropdownChange(dropdownValue.selectedKeys, dropdownValue.selectedRadio, startDate, endDate, false);
        handleDropdownChange(dropdownCompareValue.selectedKeys,dropdownCompareValue.selectedRadio, startDateCompare, endDateCompare, true);

        const fetchData = async () => {
             try {
              
                setData({
                    avgStays: avgStays,
                    avgStaysCompared: avgStaysCompared,
                    changePercentage: changePercentage,
                });
         }
        catch(error) {
            console.log(error);
        }}
        fetchData();
    }, [dropdownValue, dropdownCompareValue, startDate, endDate, trigger]);

    const handleDropdownChange = (selectedKeys: string[], selectedRadio: string, startDate: string, endDate:string, isCompare: boolean) => {
        const { partnerType, categoryId } = getPartnerTypeAndCategoryId(selectedKeys);

        if (selectedRadio === "All" && (selectedKeys?.length === 6 || selectedKeys?.length === 0)) {
            dispatch(getServiceAvgStaysBarData({ startDate, endDate, isCompare }));
            return;
          }
        
         else if (selectedRadio === "Own") {
           //bars
            dispatch(getServiceAvgStaysBarData({ startDate, endDate, isCompare, servicePartnerId }));   
            return;
          }
        
        else  if (selectedKeys?.length < 6  && selectedKeys.length > 0 && selectedRadio !== "Own" )  {
           //bars
            dispatch(getServiceAvgStaysBarData({ startDate, endDate, isCompare, categoryId, partnerType }));
            return;
          }
 
       }
 
    return (
        <React.Fragment>
            <div className="analytics-length-card">
                <a className='analytics-info-container-title'>Aufenthaltsdauer</a>
                <div className="info-container">
                    <div className="info-label">
                    Auswahl 
                </div>
                <div className="info-label">
                    /  Vergleich
                    </div>
                    </div> 
              
                    <div className="info-value-container">
                        <div className="compare-value">
                        {formatNumber(avgStays)} Tage
                        <div className="info-value-percentage-container">
                            <div className="info-value-percentage">
                                {formatNumber(changePercentage.toFixed(2))} %
                            </div>
                            {(avgStaysCompared !== undefined && avgStays !== undefined && avgStays !== 0) ?
                                (avgStays > avgStaysCompared) ? <div className="triangle-positive-big" /> : <div className="triangle-negative-big" />
                                : null
                            }
                              </div>
                       
                    </div>
    
                    <div className="compare-value">
                    <div className='separator-line'> / </div>
                        {formatNumber(avgStaysCompared)} Tage
                    </div>                
                </div>
            </div>
        </React.Fragment>
    );
}