import {TouristTaxDetailActions} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailSlice";
import moment from "moment";
import React, {Component} from 'react';
import {compose} from "redux";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import f from "lib/util";
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {SobrCustomCheckbox} from "components/SobrForm/Inputs/SobrCustomCheckbox";
import SobrSearchField from "components/SobrSearchField/SobrSearchField";
import {Actions as TouristtaxListActions} from "bonfire/ZTerminal/Touristtax/TouristtaxList/redux";
import {SobrDataTable} from "components/SobrDataTable/SobrDataTable";
import SobrPagination from "components/SobrPagination/SobrPagination";

export class TouristtaxListPageComponent extends Component {

    static propTypes = {
        touristtaxes: PropTypes.array.isRequired,
        loading: PropTypes.bool,
        numPages: PropTypes.number.isRequired,
        currentPage: PropTypes.number.isRequired,
        onLoad: PropTypes.func,
        setDetailsDateRange: PropTypes.func
    };

    static defaultProps = {
        loading: false
    };

    constructor(props, context) {
        super(props, context);

        this.goToTouristtax = this.goToTouristtax.bind(this);
        this.goToPage = this.goToPage.bind(this);
        this.changeSorting = this.changeSorting.bind(this);
        this.setPayed = this.setPayed.bind(this);
    }

    componentDidMount() {
        f.callIfExists(this.props.onLoad);
    }

    goToPage(page) {
        f.callIfExists(this.props.onSetCurrentPage, page);
    }

    get breadcrumbPath() {
        return [{
            id: 'bonfire.touristtax.detail.Header',
            defaultMessage: 'Kurtaxe'
        }];
    }

    goToTouristtax(touristtax) {
        const startDate = moment(touristtax.touristTaxStatement[0].periodStartDate);
        const endDate = moment(touristtax.touristTaxStatement[0].periodEndDate);
        this.props.setDetailsDateRange(startDate, endDate);
        this.props.history.push(`/zterminal/touristtax/detail/${touristtax.id}`);
    }

    changeSorting(field, direction) {
        f.callIfExists(this.props.onSortingChange, field, direction);
    }

    setPayed(touristTaxStatement) {
        if(touristTaxStatement.status === "INITIAL") {
            f.callIfExists(this.props.onSetPayed, touristTaxStatement.id);
        }
    }

    get tableColumns() {
        return [
            {
                Header: 'bonfire.servicepartner.list.tableheaders.name',
                accessor: 'name'
            },
            {
                Header: 'bonfire.touristtax.list.tableheaders.touristtaxName',
                accessor: 'touristTaxStatement[0].name'
            },
            {
                Header: 'bonfire.touristtax.list.tableheaders.touristtaxBetrag',
                accessor: 'touristTaxStatement[0].total'
            },
            {
                Header: 'bonfire.touristtax.list.tableheaders.touristtaxStatus',
                accessor: 'touristTaxStatement[0].status'
            },
            {
                Header: 'bonfire.touristtax.list.tableheaders.touristtaxPayed',
                Cell: row => (
                    <button type="button" className="btn btn-secondary" onClick={ () => this.setPayed(row.original.touristTaxStatement[0]) }>Bezahlt</button>
                )
            }
        ]
    }

    render() {

        return(
            <div id="service-partner-list-page" className="zterminal-content-box">
                <WithBreadcrumb icon="taxes" path={this.breadcrumbPath}/>

                <div className="zterminal-content-box-header">
                    <div className="px-4 py-3 border-bottom border-light">
                        <div className="row" id="servicepartner-list-filter">
                            <div className="col-6 text-right">
                                <label htmlFor="filter-show-inactive" className="mr-2">
                                    Inaktive zeigen
                                </label>
                                <SobrCustomCheckbox
                                    checked={this.props.showInactive}
                                    onChange={this.props.onSetShowInactive}
                                    id="filter-show-inactive"
                                />
                            </div>
                            <div className="col-6">
                                <SobrSearchField
                                    value={this.props.searchTerm}
                                    onChangeTerm={this.props.onSetSearchTerm}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="zterminal-content-box-body">
                    <div className="flex-growing scrollable px-4 pt-3">
                        <SobrDataTable
                            additionalClassNames="h-100 mh-100 w-100"
                            columns={this.tableColumns}
                            data={this.props.touristtaxes}
                            loading={this.props.loading}
                            onSelectRow={this.goToTouristtax}
                            onChangeSorting={this.changeSorting}
                        />
                    </div>
                    <div className="flex-fixed px-4 py-2">
                        <SobrPagination
                            numPages={this.props.numPages}
                            currentPage={this.props.currentPage}
                            onSelectPage={this.goToPage}
                            className="pagination flex-wrap"
                        />
                    </div>
                </div>

            </div>
        )
    }

}

export default compose(
    withRouter,
    connect(
        state => {
            const ttListState = state.getIn(['zterminal', 'touristtax', 'list']);
            return {
                touristtaxes: ttListState.get('touristtaxList'),
                loading: ttListState.get('loading'),
                numPages: ttListState.get('numPages'),
                currentPage: ttListState.get('currentPage'),
                showInactive: ttListState.get('showInactive')
            };
        },
        dispatch => ({
            onSortingChange: (field, direction) => {
                dispatch(TouristtaxListActions.setSortColumn(field));
                dispatch(TouristtaxListActions.setSortOrder(direction));
                return dispatch(TouristtaxListActions.fetchListIfNecessary());
            },
            onSetCurrentPage: currentPage => {
                dispatch(TouristtaxListActions.setCurrentPage(currentPage));
                return dispatch(TouristtaxListActions.fetchListIfNecessary());
            },
            onSetSearchTerm: searchTerm => {
                dispatch(TouristtaxListActions.setSearchTerm(searchTerm));
                return dispatch(TouristtaxListActions.fetchListIfNecessary());
            },
            onSetShowInactive: showInactive => {
                dispatch(TouristtaxListActions.setShowInactive(showInactive));
                return dispatch(TouristtaxListActions.fetchListIfNecessary());
            },
            onLoad: () => dispatch(TouristtaxListActions.fetchListIfNecessary()),
            onSetPayed: touristtaxId => dispatch(TouristtaxListActions.setPayed(touristtaxId)),
            setDetailsDateRange: (startDate, endDate) => {
                dispatch(TouristTaxDetailActions.setStartDate(startDate));
                dispatch(TouristTaxDetailActions.setEndDate(endDate));
            }
        })
    )
)(TouristtaxListPageComponent);
