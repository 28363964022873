import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import React from 'react';
import PropTypes from 'prop-types';
import {Link} from "react-router-dom";
import {FormattedMessage} from "react-intl";
import {TaxOverview} from "./TaxOverview";
import {Actions} from "./redux";
import {connect} from "react-redux";
import RegistrationFormSearchFilter from "../RegistrationFormSearchFilter/RegistrationFormSearchFilter";
import {WithBreadcrumb} from "../../breadcrumb/WithBreadcrumb";
import {OnServicePartnerChange} from "components/zterminal/OnServicePartnerChange/OnServicePartnerChange";
import RegistrationFormService from "services/registrationforms/RegistrationFormService";

import './taxOverview.scss';

const OverviewRegistrationFormSearchFilter = connect(
    null,
    dispatch => ({
        onSearch: () => {
            dispatch(Actions.fetchOverviewIfNecessary())
        }
    })
)(RegistrationFormSearchFilter);

export class TaxOverviewPageComponent extends React.Component {

    constructor(props) {
        super(props);
        this.downloadCsv = this.downloadCsv.bind(this);
    }

    get breadcrumbPath() {
        return [
            {
                id: 'bonfire.components.AdministrationSidebar.registrationForm',
                defaultMessage: 'Meldescheine'
            },
            {
                id: 'bonfire.components.RegistrationForm.Overview',
                defaultMessage: 'Übersicht'
            }
        ]
    }

    downloadCsv() {
        const makeDateStr = moment => {
            return moment ? moment.format("DD.MM.YYYY HH:mm:ss.000") : undefined;
        };

        const sf = this.props.searchFilter;
        const startDate = makeDateStr(sf.getIn(['dateRange', 'start']));
        const endDate = makeDateStr(sf.getIn(['dateRange', 'end']));
        const searchTerm = sf.get('term');
        const timeframe = sf.get('timeFrame');
        const checkedinOnly = sf.get('checkedinOnly');

        RegistrationFormService.downloadRegistrationCountCsv(this.props.servicePartnerId, startDate, endDate, searchTerm, checkedinOnly, timeframe);
    }

    render() {
        return <div id="registration-form-list-tax" className="zterminal-content-box">
            <WithBreadcrumb icon="registrationForm" path={this.breadcrumbPath}/>
            <OnServicePartnerChange onServicePartnerChange={this.props.onServicePartnerChange}/>

            <div className="zterminal-content-box-header">
                <div className="px-4 py-3 border-bottom border-light">
                    <OverviewRegistrationFormSearchFilter/>
                </div>
            </div>

            <div className="zterminal-content-box-body scrollable">
                <TaxOverview/>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-secondary btn-160 mr-3" onClick={this.downloadCsv}>CSV</button>
                            <Link to="/zterminal/registrationform/" className="btn btn-primary btn-160">
                                <FormattedMessage
                                    id="bonfire.containers.administration.RegistrationForm.TaxOverview.RegistrationFormButton"
                                    defaultMessage="Meldeschein-Liste"/>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

    static propTypes = {
        servicePartnerId: PropTypes.string.isRequired,
        searchFilter: PropTypes.object.isRequired,
        onServicePartnerChange: PropTypes.func
    }

}

export const TaxOverviewPage = connect(
    state => ({
        servicePartnerId: SessionSelectors.currentServicePartner(state)?.id,
        searchFilter: state.getIn(['zterminal', 'registrationForms', 'searchFilter'])
    }),
    dispatch => ({
        onServicePartnerChange: () => {
            dispatch(Actions.invalidateOverview());
            dispatch(Actions.fetchOverviewIfNecessary());
        }
    })
)(TaxOverviewPageComponent);
