import {Map} from 'immutable';
import ServicePartnerService from "services/servicepartners/ServicePartnerService";
import ServicePartnerFormLogic from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/ServicePartnerFormLogic";
import {Accessors as ServicePartnerFormAccessors} from "bonfire/ZTerminal/ServicePartner/ServicePartnerForm/redux";
import v from "lib/validation";
import * as R from "ramda";

export const InitialState = Map({
    servicePartnerData: null,
    validationData: null,
    loading: false
});

const ACTION_SET_SERVICE_PARTNER_DATA = 'zterminal/servicepartner/edit/setServicePartnerData';
const ACTION_SET_VALIDATION_DATA = 'zterminal/servicepartner/edit/setValidationData';
const ACTION_SET_LOADING = 'zterminal/servicepartner/edit/setLoading';

export const Actions = {
    clearValidationData,
    fetchServicePartnerData,
    setServicePartnerData,
    submitServicePartner
};

const spToFormData = ServicePartnerFormLogic.servicePartnerToFormData;
const validateServicePartnerFormData = ServicePartnerFormLogic.validateServicePartnerFormData;

function fetchServicePartnerData(spId) {
    return dispatch => {
        dispatch(setLoading(true));
        return ServicePartnerService.getServicePartner(spId)
            .then(sp => {
                dispatch(setServicePartnerData(spToFormData(sp)));
                dispatch(setLoading(false));
                return sp;
            })
            .catch(e => {
                dispatch(setLoading(false));
                return Promise.reject(e);
            });
    };
}

function submitServicePartner(spId, spFormData) {
    return (dispatch, getState) => {
        const spBaseData = ServicePartnerFormAccessors.getServicePartnerFormBaseData(getState());
        const validationResult = validateServicePartnerFormData(spFormData, spBaseData);
        dispatch(setValidationData(validationResult));
        console.log(validationResult);
        if (!v.isValid(validationResult)) {
            return Promise.reject(validationResult);
        }

        dispatch(setLoading(true));
        const pmsData = ServicePartnerFormLogic.formDataToPmsIntegrationRequestData(spFormData);
        const spData = ServicePartnerFormLogic.formDataToServicePartnerRequestData(spFormData);
        return Promise.all([
            ServicePartnerService.updateServicePartner(spId, spData),
            ServicePartnerService.updatePmsIntegration(spId, pmsData.id, pmsData)
        ]).then(([sp, pmsIntegration]) => {
            const newSpFormData = R.assocPath(['pmsIntegrations', 0], pmsIntegration)(sp);
            dispatch(setServicePartnerData(newSpFormData));
            dispatch(setLoading(false));
            return newSpFormData;
        });
    }
}

function setServicePartnerData(servicePartner) {
    return {
        type: ACTION_SET_SERVICE_PARTNER_DATA,
        servicePartner
    };
}

function clearValidationData() {
    return setValidationData(null);
}

function setValidationData(validationData) {
    return {
        type: ACTION_SET_VALIDATION_DATA,
        validationData
    };
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_SERVICE_PARTNER_DATA:
            return state.set('servicePartnerData', action.servicePartner);
        case ACTION_SET_VALIDATION_DATA:
            return state.set('validationData', action.validationData);
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        default:
            return state;
    }
}

export default {
    InitialState, Actions, Reducer
}
