import React, { useState } from 'react';

import {GraphNavigation} from "../../components/GraphNavigation";
import {AuslastungGraph} from "./components/graphs/auslastung/AuslastungGraph";
import {AuslastungGraphChange} from "./components/graphs/auslastung/AuslastungGraphChange";
import {AufenthaltsdauerGraph} from "./components/graphs/aufenthaltsdauer/AufenthaltsdauerGraph";
import { LogiernaechteGraph } from "./components/graphs/logiernaechte/LogiernaechteGraph";
import { AufenthaltsdauerGraphChange } from "./components/graphs/aufenthaltsdauer/AufenthaltsdauerGraphChange";


import {AnalyticsBetriebeCard} from "./components/cards/Betriebe";
import {AnalyticsAuslastungCard} from "./components/cards/Auslastung";
import {AnalyticsLogierNaechteCard} from "./components/cards/Logiernaechte";
import {AnalyticsAufenthaltsdauerCard} from "./components/cards/Aufenthaltsdauer";
import {LogiernaechteAccumulatedGraph} from "./components/graphs/logiernaechte/LogiernaechteAccumulated";

export const AuslastungContainer = () => {
    const [currentView, setCurrentView] = useState(0);
    const [currentSubView, setCurrentSubView] = useState(0);

    return (
        <React.Fragment>
            <div className="analytics-row">
                <div className="analytics-col-left">
                    <AnalyticsBetriebeCard />
                    <AnalyticsAuslastungCard />
                    <AnalyticsLogierNaechteCard />
                    <AnalyticsAufenthaltsdauerCard />
                </div>
                <div className="analytics-col-right">
                    <div className="analytics-graph-card">
                        <GraphNavigation
                            currentView={currentView}
                            setCurrentView={setCurrentView}
                            currentSubView={currentSubView}
                            setCurrentSubView={setCurrentSubView}
                        />

                        {currentView === 0 ? <React.Fragment>
                            {currentSubView === 0 && <AuslastungGraph />}
                            {currentSubView === 1 && <AuslastungGraphChange /> }
                        </React.Fragment>: null
                        }

                        {currentView === 1 ? <React.Fragment>
                            {currentSubView === 0 && <LogiernaechteGraph />}
                            {currentSubView === 1 && <LogiernaechteAccumulatedGraph /> }
                        </React.Fragment>: null
                        }

                        {currentView === 2 ? <React.Fragment>
                            {currentSubView === 0 && <AufenthaltsdauerGraph />}
                            {currentSubView === 1 && <AufenthaltsdauerGraphChange /> }
                        </React.Fragment>: null
                        }

                    </div>
                </div>
            </div>
        </React.Fragment>
    );
}