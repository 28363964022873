import ApiService from "../api/ApiService";
import * as R from "ramda";

const getServicePartnersRequest = ApiService.createApiEndpoint('admin/servicepartners', 'GET', true);
function getServicePartners() {
    return getServicePartnersRequest({
        offset: 0
    })
        .then(R.pipe(
            R.prop('data'),
            R.filter(R.propSatisfies(R.isNil, 'partnerGroup')),
            R.map(servicePartner => ({
                value: servicePartner.id,
                label: servicePartner.companyName || servicePartner.name
            })),
            R.sortBy(R.prop('label'))
        ));
}

const getRoles = ApiService.createApiEndpoint('admin/roles', 'GET', true);

export default {
    getServicePartners,
    getRoles
};
