import {combineReducers} from "redux-immutable";
import UserList from './UserList/redux';
import UserCreate from './UserCreate/redux';
import UserEdit from './UserEdit/redux';
import UserForm from "bonfire/ZTerminal/Users/UserForm/redux";

export const Reducer = combineReducers({
    list: UserList.Reducer,
    create: UserCreate.Reducer,
    edit: UserEdit.Reducer,
    form: UserForm.Reducer
});

export default {
    Reducer
}
