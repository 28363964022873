import {combineReducers} from "redux-immutable";
import TouristtaxList from "bonfire/ZTerminal/Touristtax/TouristtaxList/redux";
import {TouristTaxDetailReducer} from './TouristtaxDetail/TouristTaxDetailSlice'

export const Reducer = combineReducers({
    detail: TouristTaxDetailReducer,
    list: TouristtaxList.Reducer
});

export default {
    Reducer
};
