const protocol = window && window.location && window.location.protocol;
const hostname = window && window.location && window.location.hostname;
const port = window && window.location && window.location.port;

// 3000 is dev react server, in this case the backend is on 8080. Otherwise assume api is on same port.
const apiPort = +port === 3000 ? ':8080' : `:${port}`;

// window.location.protocol contains the ':'

//to use test server instead of local server use:
// export const API_ROOT = `https://zterminal-test.zermatt.ch/api/`;

export const API_ROOT = `${protocol}//${hostname}${apiPort}/api/`;
