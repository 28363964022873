import React, {ReactElement, ReactNode} from 'react';
import {FormattedMessage} from "react-intl";

export interface SobrFormGroupProps {
    title: string | ReactNode
    children: ReactNode
}

export function SobrFormGroup(props: SobrFormGroupProps): ReactElement {
    return <div className="sobr-form-widget">
        <div className="sobr-form-widget-form">
            <div className="heading">
                {typeof props.title === 'string' ?
                    <FormattedMessage id={props.title} /> :
                    props.title
                }
            </div>
            {props.children}
        </div>
    </div>;
}
