import React, {ReactElement, ReactNode} from 'react';
import {FormattedMessage} from "react-intl";

export interface SobrFormRowProps {
    label: string
    children: ReactNode
    required?: boolean
    error?: string | null
}

export function SobrFormRow(props: SobrFormRowProps): ReactElement {
    const required = props.required ?? false;
    const error = props.error ?? null;
    return <div className="form-group row">
        <span className="col-12 col-form-label">
            <FormattedMessage id={props.label} />{required && "*"}
        </span>
        <div className="col-sm-12">
            {props.children}
            {!!error && <span className="errorTextStyle">{error}</span> }
        </div>
    </div>
}
