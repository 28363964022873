import * as R from 'ramda';
import {UserRole} from "bonfire/ZTerminal/Users/UserModel";

export interface ZTerminalPage {
    link: string
    icon: string
    label: ZTerminalPageLabel
    roleRequired: UserRole[]
}

export type ZTerminalPageLabel = string | { id: string, defaultMessage?: string }


const ALL_PAGES: ZTerminalPage[] = [

    // Dashboard
    {
        link: '/zterminal/cockpit/',
        icon: 'overview',
        roleRequired: [
            'ROLE_BACK_OFFICE_USER',
            'ROLE_REGISTRATION_FORM_ADMIN',
            'ROLE_SERVICE_PARTNER_ADMIN',
            'ROLE_SYSTEM_ADMIN',
            'ROLE_SYSTEM_SETTINGS_ADMIN',
            'ROLE_TOURIST_TAX_ADMIN',
            'ROLE_USER_ADMIN',
            'ROLE_SP_REGISTRATION_FORM_ADMIN',
            'ROLE_SP_SERVICE_PARTNER_ADMIN',
            'ROLE_SP_TOURIST_TAX_ADMIN',
            'ROLE_SP_USER',
            'ROLE_SP_USER_ADMIN'
        ],
        label: {
            id: 'bonfire.components.AdministrationSidebar.overview',
            defaultMessage: 'Cockpit'
        }
    },

    // Police directly to registrationforms
    {
        link: '/zterminal/registrationform/',
        icon: 'registrationForm',
        roleRequired: [
            'ROLE_POLICE_USER'
        ],
        label: {
            id: 'bonfire.components.AdministrationSidebar.registrationForm',
            defaultMessage: 'Meldescheine'
        }
    },


    {
        link: '/zterminal/registrationform/',
        icon: 'registrationForm',
        roleRequired: ['ROLE_REGISTRATION_FORM_ADMIN', 'ROLE_SP_REGISTRATION_FORM_ADMIN'],
        label: {
            id: 'bonfire.components.AdministrationSidebar.registrationForm',
            defaultMessage: 'Meldescheine'
        }
    },
    {
        link: '/zterminal/touristtax',
        icon: 'taxes',
        roleRequired: ['ROLE_SP_TOURIST_TAX_ADMIN'],
        label: {
            id: 'bonfire.components.AdministrationSidebar.touristTax',
            defaultMessage: 'Kurtaxe',
        }
    },
    {
        link: '/zterminal/touristtax',
        icon: 'taxes',
        roleRequired: ['ROLE_TOURIST_TAX_ADMIN'],
        label: {
            id: 'bonfire.components.AdministrationSidebar.touristTax',
            defaultMessage: 'Kurtaxe'
        }
    },
    {
        link: `/zterminal/servicepartner/`,
        icon: 'hotel',
        roleRequired: ['ROLE_SP_SERVICE_PARTNER_ADMIN', 'ROLE_SERVICE_PARTNER_ADMIN'],
        label: {
            id: 'bonfire.components.AdministrationSidebar.basedata',
            defaultMessage: 'Stammdaten'
        }
    },
    {
        link: '/zterminal/analytics',
        icon: 'analytics',
         roleRequired: ['ROLE_BACK_OFFICE_USER',
         'ROLE_REGISTRATION_FORM_ADMIN',
         'ROLE_SERVICE_PARTNER_ADMIN',
         'ROLE_SYSTEM_ADMIN',
         'ROLE_SYSTEM_SETTINGS_ADMIN',
         'ROLE_TOURIST_TAX_ADMIN',
         'ROLE_USER_ADMIN',
         'ROLE_SP_REGISTRATION_FORM_ADMIN',
         'ROLE_SP_SERVICE_PARTNER_ADMIN',
         'ROLE_SP_TOURIST_TAX_ADMIN',
         'ROLE_SP_USER',
         'ROLE_SP_USER_ADMIN'],
        label: {
            id: 'bonfire.components.AdministrationSidebar.analytics',
            defaultMessage: 'Analytics',
        }
    },
    {
        link: '/zterminal/user',
        icon: 'users',
        roleRequired: ['ROLE_USER_ADMIN', 'ROLE_SP_USER_ADMIN'],
        label: {
            id: 'bonfire.components.AdministrationSidebar.user',
            defaultMessage: 'Benutzer',
        }
    }
];

export function getZterminalPages(): ZTerminalPage[] {
    return R.clone(ALL_PAGES);
}

export function getAllowedPages(roles: UserRole[]): ZTerminalPage[] {
    return getZterminalPages().filter(page => {
        if (!R.has('roleRequired', page)) {
            return true;
        } else if (!roles) {
            return false;
        } else {
            return page.roleRequired.some(role => roles.indexOf(role) !== -1);
        }
    });
}

export function normalized(location: string): string {
    return R.replace(RegExp("^/*|/*$"), '', location);
}

export function locationsMatch(l1: string, l2: string) {
    return normalized(l1) === normalized(l2);
}

export function isSubLocation(location: string, superLocation: string) {
    return normalized(location).startsWith(normalized(superLocation));
}

const exports = {
    getZterminalPages, getAllowedPages, normalized, locationsMatch, isSubLocation
}

export default exports;
