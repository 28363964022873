import {combineReducers} from 'redux-immutable';
import SearchFilter from './RegistrationFormSearchFilter/RegistrationFormSearchFilterSlice';
import {registrationFormListReducer} from './RegistrationFormList/registrationFormListSlice';
import RegistrationForm from './RegistrationForm/redux';
import TaxOverview from './TaxOverview/redux';

/*
 * Reducers
 */

export const Reducer = combineReducers({
    registrationFormList: registrationFormListReducer,
    taxOverview: TaxOverview.Reducer,
    searchFilter: SearchFilter.Reducer,
    registrationForm: RegistrationForm.Reducer
});

export default {
    Reducer
}
