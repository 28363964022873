import React from 'react';
import PropTypes from 'prop-types';
import f from "lib/util";
import {toInputValue} from "lib/forms";

export class SobrZipCity extends React.Component {

    static propTypes = {
        label: PropTypes.any.isRequired,
        class: PropTypes.string,
        wrapperClass: PropTypes.string,
        inputClassZip: PropTypes.string,
        inputClassCity: PropTypes.string,
        idZip: PropTypes.string.isRequired,
        idCity: PropTypes.string.isRequired,
        nameZip: PropTypes.string,
        nameCity: PropTypes.string,
        valueZip: PropTypes.any,
        valueCity: PropTypes.any,
        onChangeZip: PropTypes.func,
        onChangeCity: PropTypes.func,
        readOnly: PropTypes.bool,
        required: PropTypes.bool,
        errorMessage: PropTypes.any,
        isError: PropTypes.bool
    };

    static defaultProps = {
        valueZip: '',
        valueCity: '',
        required: false,
        class: '',
        wrapperClass: '',
        inputClassZip: '',
        inputClassCity: '',
        nameZip: '',
        nameCity: '',
        isError: false,
        readOnly: false
    };

    constructor(props) {
        super(props);

        this.handleChangeZip = this.handleChangeZip.bind(this);
        this.handleChangeCity = this.handleChangeCity.bind(this);
    }

    handleChangeZip(e) {
        f.callIfExists(this.props.onChangeZip, e);
    }

    handleChangeCity(e) {
        f.callIfExists(this.props.onChangeCity, e);
    }

    render() {

        const nameZip = this.props.nameZip ? this.props.nameZip : this.props.idZip;
        const nameCity = this.props.nameCity ? this.props.nameCity : this.props.idCity;

        return (
            <div className="form-group row">
                <span className={"col-sm-12 col-form-label " + this.props.class}>
                    {this.props.label}
                    {this.props.required && <span>*</span>}
                </span>
                <div className={"col-sm-12 " + this.props.wrapperClass}>
                    <div className="row">
                        <div className="col-sm-5 zip-control">
                            <input
                                type="text"
                                className={"form-control " + this.props.inputClassZip}
                                id={this.props.idZip}
                                name={nameZip}
                                value={toInputValue(this.props.valueZip)}
                                onChange={this.handleChangeZip}
                                readOnly={this.props.readOnly}
                            />
                        </div>
                        <div className="col-sm-7 city-control">
                            <input
                                type="text"
                                className={"form-control " + this.props.inputClassCity}
                                id={this.props.idCity}
                                name={nameCity}
                                value={toInputValue(this.props.valueCity)}
                                onChange={this.handleChangeCity}
                                readOnly={this.props.readOnly}
                            />
                        </div>
                    </div>
                </div>
                <div className="col-sm-12">
                    {this.props.isError &&
                    <div className="errorTextStyleRegForm">{this.props.errorMessage}</div>}
                </div>
            </div>
        );
    }
}
