import React, {ReactElement, useEffect} from 'react';
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {useDispatch, useSelector} from "react-redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {TouristTaxDateRange} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDateRange";
import {TouristTaxDetailHeader} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDetailHeader";
import {TouristTaxRegistrationFormTable} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/table/TouristTaxRegistrationFormTable";
import {TouristTaxRegistrationFormTableSelectors} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/table/TouristTaxRegistrationFormTableSlice";
import {TouristTaxDetailActions} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailSlice";
import {TouristTaxDetailHeaderExportButton} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDetailHeaderExportButton";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import {UserType} from "bonfire/ZTerminal/Users/UserModel";
import {TouristTaxDetailsHeaderSelectors} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/header/TouristTaxDetailHeaderSlice";

export interface TouristTaxDetailPageProps {
    servicePartnerId: string
}

export function TouristTaxDetailPage(props: TouristTaxDetailPageProps): ReactElement {
    const servicePartnerId = props.servicePartnerId;
    const servicePartnerName = useSelector(TouristTaxDetailsHeaderSelectors.getData).servicePartnerName;
    const userType = useSelector(SessionSelectors.userType);
    const headerLoading = useSelector(TouristTaxDetailsHeaderSelectors.getLoading);
    const tableLoading = useSelector(TouristTaxRegistrationFormTableSelectors.getLoading);
    const loading = headerLoading && tableLoading;
    const showExportButton = userType === "service-partner";
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(TouristTaxDetailActions.setServicePartnerId(servicePartnerId));
    }, [servicePartnerId, dispatch]);

    const breadcrumbPath = getBreadCrumbPath(userType, servicePartnerName);
    // const breadcrumbPath = getBreadCrumbPath(userType, servicePartnerName);
    return <div id="touristtax-detail-page" className="zterminal-content-box">
        <WithBreadcrumb icon="taxes" path={breadcrumbPath}/>
        <div className="zterminal-content-box-body flex-row p-3">
            <div className="flex-growing">
                <div className="statement-details d-flex flex-column w-100">
                    <LoadingOverlay loading={loading}>
                        <div className="flex-fixed">
                            <div className="row">
                                <div className="col-8 mt-2 mb-3 col-xl-4">
                                    <TouristTaxDateRange/>
                                </div>
                                {showExportButton && (
                                    <div className="col-4 mt-2 mb-3 text-right col-xl-2 order-xl-last">
                                        <TouristTaxDetailHeaderExportButton/>
                                    </div>
                                )}
                                <div className="col-12 col-xl-6 order-xl-2 text-xl-center pr-3">
                                    <TouristTaxDetailHeader/>
                                </div>
                            </div>
                        </div>
                        <TouristTaxRegistrationFormTable/>
                    </LoadingOverlay>
                </div>
            </div>
        </div>
    </div>
}

function getBreadCrumbPath(userType: UserType | null, servicePartnerName: string | null) {
    const path = ["Kurtaxe"];
    if (userType === "zermatt-tourismus" && servicePartnerName) {
        path.push(servicePartnerName);
    }
    return path;
}
