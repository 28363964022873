import React from 'react';
import './style.scss';

export interface BonfireIconProps extends React.HTMLProps<HTMLSpanElement> {
    icon: string
}

export function BonfireIcon(props: BonfireIconProps) {
    const {icon, className, ...rest} = props;

        return <span
            className={`bonfire-font-icon bonfire-font-icon-${icon} ${className || ''}`}
            {...rest}
        />
}
