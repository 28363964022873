import {Map} from 'immutable';
import UserService from "../../../../services/users/UserService";
import v from "lib/validation";
import UserFormLogic, {emptyUserFormData} from "bonfire/ZTerminal/Users/UserForm/UserFormLogic";
import {Accessors as UserFormAccessors} from "bonfire/ZTerminal/Users/UserForm/redux";

export const InitialState = Map({
    userData: null,
    validationData: null,
    loading: false
});

const ACTION_SET_USER_DATA = 'zterminal/user/create/setUserData';
const ACTION_SET_VALIDATION_DATA = 'zterminal/user/create/setValidationData';
const ACTION_SET_LOADING = 'zterminal/user/create/setLoading';

export const Actions = {
    clearValidationData,
    setUserData,
    createUser
};

function createUser(userData, roleAssignmentMode) {
    return (dispatch, getState) => {
        const userFormBaseData = UserFormAccessors.getUserFormBaseData(getState());
        const validationResult = UserFormLogic.validateUserFormData(userData, userFormBaseData, roleAssignmentMode);
        dispatch(setValidationData(validationResult));
        if (!v.isValid(validationResult)) {
            return Promise.reject(validationResult);
        }

        dispatch(setLoading(true));
        return UserService.createUser(userData).then(user => {
            dispatch(setUserData(emptyUserFormData(roleAssignmentMode, 'generate')));
            dispatch(setLoading(false));
            return user;
        });
    }
}

function setUserData(user) {
    return {
        type: ACTION_SET_USER_DATA,
        user
    }
}

function clearValidationData() {
    return setValidationData(null);
}

function setValidationData(validationData) {
    return {
        type: ACTION_SET_VALIDATION_DATA,
        validationData
    }
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    }
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_USER_DATA:
            return state.set('userData', action.user);
        case ACTION_SET_VALIDATION_DATA:
            return state.set('validationData', action.validationData);
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        default:
            return state;
    }
}

export default {
    InitialState, Actions, Reducer
}
