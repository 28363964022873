import {
    Actions as RegistrationFormFilterActions
} from "bonfire/ZTerminal/RegistrationForms/RegistrationFormSearchFilter/RegistrationFormSearchFilterSlice";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import {SobrCockpitButton} from "components/SobrCockpitButton/SobrCockpitButton";
import SobrDashboardCardGuest from "components/SobrDashboardCardGuest";
import {SobrOverviewSearch} from "components/SobrOverviewSearch/SobrOverviewSearch";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {ReactComponent as IconAddRegForm} from 'images/icons/icon_dashboard_add_regform.svg';
import {ReactComponent as IconCheckinTomorrow} from 'images/icons/icon_dashboard_checkin_tomorrow.svg';
import {ReactComponent as IconCheckinYesterday} from 'images/icons/icon_dashboard_checkin_yesterday.svg';
import iconNoCheckins from 'images/icons/icon_dashboard_no_checkins.svg';
import {ReactComponent as IconCheckinToday} from "images/icons/icon_dashoard_checkin_today.svg";
import {ReactComponent as IconNewSP} from "images/icons/icon_new_sp.svg";
import {ReactComponent as IconSP} from "images/icons/icon_sp.svg";
import {ReactComponent as IconTT} from "images/icons/icon_tt.svg";
import {ReactComponent as IconUser} from "images/icons/icon_user.svg";
import $ from 'jquery';
import Moment from 'moment/moment';
import {ifElse} from "ramda";
import {useEffect} from 'react';
import {FormattedMessage, useIntl} from "react-intl";
import {useDispatch, useSelector} from 'react-redux';
import {useHistory} from "react-router-dom";
import {UserTypeEnum} from "services/users/UserService";
import {standardRoute} from "utils/standardRoute";
import {WithBreadcrumb} from "../breadcrumb/WithBreadcrumb";

import './cockpit.scss';
import {Actions} from "./redux";

export function Cockpit() {
    const intl = useIntl();
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id;
    const isAdministration = useSelector(SessionSelectors.userType) === UserTypeEnum.ZERMATT_TOURISMUS;

    const languageData = useSelector(state => state.getIn(['selfService', 'languageData']));
    const isLoading = useSelector(state => state.getIn(['zterminal', 'cockpit', 'isLoading']));
    const cockpitData = useSelector(state => state.getIn(['zterminal', 'cockpit', 'cockpitData']));
    const registrationForms = useSelector(state => state.getIn(['zterminal', 'cockpit', 'registrationForms']));
    const roles = useSelector(SessionSelectors.roles);

    const dispatch = useDispatch();
    const setFilterTimeFrame = timeFrame => dispatch(RegistrationFormFilterActions.setTimeFrame(timeFrame));
    const setCheckedinOnly = checkedinOnly => dispatch(RegistrationFormFilterActions.setCheckedinOnly(checkedinOnly));
    const resetSearchFilters = () => dispatch(RegistrationFormFilterActions.reset());

    const cookiePolicies = {
        de: "https://www.zermatt.ch/Media/Cookie-Policy-von-www.zermatt.ch",
        en: "https://www.zermatt.ch/en/Media/Cookie-policy-of-www.zermatt.ch",
        fr: "https://www.zermatt.ch/fr/Media/Charte-sur-les-cookies-de-www.zermatt.ch",
    };

    console.log(languageData)

    const currentPolicy = cookiePolicies[intl.locale];

    const breadcrumbPath = [{
        id: 'bonfire.components.AdministrationSidebar.overview',
        defaultMessage: 'Cockpit'
    }];

    const history = useHistory();

    useEffect(() => {
        const fetchData = (servicePartnerId, timeframe) => {
            dispatch(Actions.clearCockpitData());
            return dispatch(Actions.fetchData(servicePartnerId, timeframe));
        };
        fetchData(servicePartnerId, 'today').then(() => {
            let content = $('#cockpit-content');
            let height = content.height();
            let offsetTop = content.offset() ? content.offset().top : 0;
            let windowHeight = $(window).height();
            let sizeTo100Percent = windowHeight - offsetTop;

            if (sizeTo100Percent < height) {
                content.css({
                    'height': sizeTo100Percent + 'px',
                    'overflow-y': 'scroll'
                });
            }

            if (!isAdministration) {
                /* Ticket Bilder einmitten / strecken  --> In diesem File weil hier wird es nur einmal ausgeführt, in der Komponente selbst, wird es für jede existierende Komponente jeweils einmal aufgerufen. */
                let images = $('.ticket-service img');
                let parentHeight = images.parent().height();
                let parentWidth = images.parent().width();

                $.each(images, (index, image) => {
                    let img = $(image);
                    let height = img.prop('naturalHeight');
                    let width = img.prop('naturalWidth');
                    let ratio = width / height;

                    if (ratio >= 1) {
                        img.css({
                            'height': '100%',
                            'width': 'auto',
                            'margin-left': (parentWidth - (width + ((parentHeight - height) * ratio))) / 2 + 'px'
                        });
                    } else {
                        img.css({
                            'width': '100%',
                            'height': 'auto',
                            'margin-top': (parentHeight - (height + ((parentWidth - width) * ratio))) / 2 + 'px'
                        });
                    }
                });
            }
        }).catch((error) => {
            console.log('Data loading Error', error);
        });
    }, [servicePartnerId, isAdministration, dispatch]);

    function getRegistrationForms() {
        return registrationForms?.data ?? [];
    }

    function setTimeFrame(timeFrame) {
        resetSearchFilters();
        setFilterTimeFrame(timeFrame);
        history.push('/zterminal/registrationform');
    }

    function setCheckedInOnly(checkedinOnly) {
        resetSearchFilters();
        setCheckedinOnly(checkedinOnly);
        history.push('/zterminal/registrationform');
    }


    return (
        <LoadingOverlay loading={isLoading}>
            <div>
                <WithBreadcrumb icon={'overview'} path={breadcrumbPath}/>
                <div id="cockpit-content" className="row no-gutters">
                    <div className="col-12">

                        {/* Suche  */}
                        <div className="content search-row">
                            <div className="container">
                                <div className="col-12">
                                    <div className="row">
                                        <SobrOverviewSearch class="col-12"/>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {/* Meldeschein Section */}
                        {
                            !isAdministration &&
                            <div className="section meldescheine-row">
                                <div className="big-title">
                                    <h4>
                                        <FormattedMessage
                                            id='bonfire.components.AdministrationSidebar.dataProtection'
                                            defaultMessage='Datenschutz'
                                        />
                                    </h4>
                                    <a className="sobr-cockpit-dataprotect"
                                       href="https://ztnet.ch/images/content/2023.12.12DatenschutzerklaerungzTerminal.pdf"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <FormattedMessage
                                            id='bonfire.components.AdministrationSidebar.readDataProtectionRulles'
                                            defaultMessage='Missing Translation'
                                        />
                                    </a>
                                    <a className="sobr-cockpit-dataprotect"
                                       href={currentPolicy}
                                       target="_blank"
                                       rel="noopener noreferrer"> <FormattedMessage
                                        id='bonfire.components.AdministrationSidebar.cookiePolicy'
                                        defaultMessage='Missing Translation'
                                    /></a>


                                </div>

                                <div className="big-title">
                                    <h4>
                                        <FormattedMessage
                                            id='bonfire.components.AdministrationSidebar.registrationForm'
                                            defaultMessage='Meldescheine'
                                        />
                                    </h4>
                                </div>

                                <div className="container-fluid">
                                    {cockpitData &&
                                        <div className="row mt-2 mb-3">
                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.counts.today}
                                                    title={<FormattedMessage id="bonfire.regForm.arrivalToday"
                                                                             defaultMessage="Anreise heute"/>}
                                                    onClick={() => setTimeFrame('today')}>
                                                    <IconCheckinToday/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.counts.tomorrow}
                                                    title={<FormattedMessage id="bonfire.regForm.arrivalTomorrow"
                                                                             defaultMessage="Anreise morgen"/>}
                                                    onClick={() => setTimeFrame('tomorrow')}>
                                                    <IconCheckinTomorrow/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.counts.yesterday}
                                                    title={<FormattedMessage id="bonfire.regForm.arrivalYesterday"
                                                                             defaultMessage="Anreise gestern"/>}
                                                    onClick={() => setTimeFrame('yesterday')}>
                                                    <IconCheckinYesterday/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.counts.checkedIn}
                                                    title={<FormattedMessage id="bonfire.regForm.guestsInHouse"
                                                                             defaultMessage="Gäste im Haus"/>}
                                                    onClick={() => setCheckedInOnly(true)}>
                                                    <IconUser/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    title={<FormattedMessage id="bonfire.regForm.new"
                                                                             defaultMessage="Neu"/>}
                                                    linkTo="/zterminal/registrationform/create">
                                                    <IconAddRegForm/>
                                                </SobrCockpitButton>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        }

                        {/* Checkins heute */}
                        {
                            !isAdministration &&
                            <div className="section checkins-row">
                                <div className="big-title">
                                    <h4>
                                        <FormattedMessage
                                            id='bonfire.containers.administration.Overview.guestsToday'
                                            defaultMessage='Gäste heute'
                                        />
                                    </h4>
                                </div>

                                <div className="container-fluid">
                                    <div className="row">
                                        {ifElse(
                                            rfs => rfs.length,
                                            rfs => rfs.map((object, key) => (
                                                <div
                                                    className="checkins-column-wrap col-md-12 col-lg-6 col-xl-4 col-xxl-3"
                                                    key={key}>
                                                    <SobrDashboardCardGuest
                                                        count={object.totalGuestCount}
                                                        guestId={object.id}
                                                        guestName={object.guests[0].guest.lastName + ' ' + object.guests[0].guest.firstName}
                                                        guestCountry={object.guests[0].guest.address ? (object.guests[0].guest.address.country) : ''}
                                                        guestAddress={object.guests[0].guest.address ? (object.guests[0].guest.address.street + ', ' + object.guests[0].guest.address.zip + ' ' + object.guests[0].guest.address.city) : ''}
                                                        guestCheckedDate={Moment.utc(object.checkinDate).format('DD.MM.YYYY') + ' - ' + Moment.utc(object.checkoutDate).format('DD.MM.YYYY')}
                                                    />
                                                </div>
                                            )),
                                            () => <div
                                                className="checkins-column-wrap col-md-12 col-lg-6 col-xl-4 col-xxl-3">
                                                <SobrDashboardCardGuest
                                                    noGuest={<FormattedMessage id="bonfire.regForm.checkinsToday"
                                                                               defaultMessage="Keine Checkins für heute geplant"/>}
                                                    icon={iconNoCheckins}
                                                />
                                            </div>
                                        )(getRegistrationForms())}
                                    </div>
                                </div>
                            </div>
                        }


                        {/* Administration Section */}
                        {
                            isAdministration &&
                            <div className="section admin-row">
                                <div className="big-title">
                                    <h4>
                                        <FormattedMessage
                                            id='bonfire.components.AdministrationSidebar.administration'
                                            defaultMessage='Administration'
                                        />
                                    </h4>
                                </div>

                                <div className="container-fluid">

                                    {cockpitData &&
                                        <div className="row">
                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    title={<FormattedMessage
                                                        id="bonfire.containers.administration.Overview.newSP"
                                                        defaultMessage="Neue Unterkunft"/>}
                                                    linkTo={`${standardRoute(roles)}servicepartner/create`}>
                                                    <IconNewSP/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.numServicePartners}
                                                    title={<FormattedMessage
                                                        id="bonfire.containers.administration.Overview.sps"
                                                        defaultMessage="Unterkünfte"/>}
                                                    linkTo={`${standardRoute(roles)}servicepartner`}>
                                                    <IconSP/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.numOpenTaxStatements}
                                                    title={<FormattedMessage
                                                        id="bonfire.containers.administration.Overview.openTax"
                                                        defaultMessage="Offene Abrechnungen"/>}
                                                    linkTo={`${standardRoute(roles)}touristtax`}>
                                                    <IconTT/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    title={<FormattedMessage
                                                        id="bonfire.containers.administration.Overview.totalOpen"
                                                        defaultMessage="Total offen"/>}
                                                    linkTo={`${standardRoute(roles)}touristtax`}>
                                                    {`${cockpitData.totalOpenTaxAmount.toFixed(2)} CHF`}
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    title={<FormattedMessage
                                                        id="bonfire.containers.administration.Overview.newUser"
                                                        defaultMessage="Neuer Benutzer"/>}
                                                    linkTo="/zterminal/user/create">
                                                    <IconNewSP/>
                                                </SobrCockpitButton>
                                            </div>

                                            <div className="col-xs-12 col-sm-6 col-md-6 col-lg-4 col-xl">
                                                <SobrCockpitButton
                                                    bubbleNumber={cockpitData.numUsers}
                                                    title={<FormattedMessage
                                                        id="bonfire.containers.administration.Overview.users"
                                                        defaultMessage="Benutzer"/>}
                                                    linkTo="/zterminal/user">
                                                    <IconUser/>
                                                </SobrCockpitButton>
                                            </div>

                                        </div>
                                    }
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </LoadingOverlay>
    );
}
