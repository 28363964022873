import {Map} from 'immutable';
import Moment from 'moment';
import 'moment/locale/de';
import {AnyAction} from "redux";
import {ThunkAction} from "redux-thunk";

const DATE_RANGE_START = Moment.utc().startOf('day');
const DATE_RANGE_END = Moment.utc().add(7, 'day').startOf('day');

export const InitialState = Map({
    term: '',
    currentPage: 1,
    sorting: Map({
        field: 'checkinDate',
        direction: 'asc'
    }),
    timeFrame: null,
    checkedinOnly: false,
    dateRange: Map({
        start: DATE_RANGE_START,
        end: DATE_RANGE_END
    })
});

const ACTION_FILTER_SET_SEARCH_TERM = 'zterminal/registrationForms/filter/setSearchTerm';
const ACTION_FILTER_SET_DATE_RANGE_START = 'zterminal/registrationForms/filter/dateRange/start';
const ACTION_FILTER_SET_TIMEFRAME = 'zterminal/registrationForms/filter/timeframe';
const ACTION_FILTER_SET_CHECKINONLY = 'zterminal/registrationForms/filter/checkinOnly';
const ACTION_FILTER_SET_DATE_RANGE_END = 'zterminal/registrationForms/filter/dateRange/end';
const ACTION_FILTER_SET_CURRENT_PAGE = 'zterminal/registrationForms/filter/setCurrentPage';
const ACTION_FILTER_SET_SORTING = 'zterminal/registrationForms/filter/setSorting';

export const Actions = {
    reset,
    setSearchTerm,
    setDateRangeStart,
    setDateRangeEnd,
    setTimeFrame,
    setCheckedinOnly,
    setCurrentPage,
    setSorting,
};

function reset(): ThunkAction<void, any, void, any> {
    return dispatch => {
        dispatch(setSearchTerm(''));
        dispatch(setDateRangeStart(DATE_RANGE_START));
        dispatch(setDateRangeEnd(DATE_RANGE_END));
        dispatch(setCurrentPage(0));
    }
}

function setSearchTerm(searchTerm: string): AnyAction {
    return {
        type: ACTION_FILTER_SET_SEARCH_TERM,
        term: searchTerm
    };
}

function setDateRangeStart(date: Moment.Moment): AnyAction {
    return {
        type: ACTION_FILTER_SET_DATE_RANGE_START,
        date: date
    };
}

function setDateRangeEnd(date: Moment.Moment): AnyAction {
    return {
        type: ACTION_FILTER_SET_DATE_RANGE_END,
        date: date
    };
}

type NamedTimeFrame = 'yesterday' | 'today' | 'tomorrow' | 'lastmonth' | 'now'

function setTimeFrame(timeFrame: NamedTimeFrame): AnyAction {
    return {
        type: ACTION_FILTER_SET_TIMEFRAME,
        timeFrame: timeFrame
    };
}

function setCheckedinOnly(checkedinOnly: boolean): AnyAction {
    return {
        type: ACTION_FILTER_SET_CHECKINONLY,
        checkedinOnly: checkedinOnly
    };
}

function setCurrentPage(currentPage: number): AnyAction {
    return {
        type: ACTION_FILTER_SET_CURRENT_PAGE,
        currentPage: currentPage
    };
}

type SortDirection = 'asc' | 'desc';

function setSorting(sortField: string, sortDirection: SortDirection): AnyAction {
    return {
        type: ACTION_FILTER_SET_SORTING,
        field: sortField,
        direction: sortDirection
    };
}

type StateType = typeof InitialState;

export function Reducer(state: StateType = InitialState, action: AnyAction): StateType {
    switch (action.type) {
        case ACTION_FILTER_SET_SEARCH_TERM:
            return state
                .set('term', action.term)
                .set('valid', false)
                .set('currentPage', 1);
        case ACTION_FILTER_SET_DATE_RANGE_START:
            return state
                .setIn(['dateRange', 'start'], action.date)
                .set('timeFrame', null)
                .set('checkedinOnly', false)
                .set('valid', false);
        case ACTION_FILTER_SET_DATE_RANGE_END:
            return state
                .setIn(['dateRange', 'end'], action.date)
                .set('checkedinOnly', false)
                .set('timeFrame', null)
                .set('valid', false);
        case ACTION_FILTER_SET_TIMEFRAME:
            return state
                .set('timeFrame', action.timeFrame)
                .set('checkedinOnly', false)
                .setIn(['dateRange', 'start'], null)
                .setIn(['dateRange', 'end'], null)
                .set('valid', false);
        case ACTION_FILTER_SET_CHECKINONLY:
            return state
                .set('checkedinOnly', action.checkedinOnly)
                .setIn(['dateRange', 'start'], null)
                .setIn(['dateRange', 'end'], null)
                .set('timeFrame', null)
                .set('valid', false);
        case ACTION_FILTER_SET_CURRENT_PAGE:
            return state
                .set('currentPage', action.currentPage)
                .set('valid', false);
        case ACTION_FILTER_SET_SORTING:
            return state
                .set('sorting', Map({
                    field: action.field,
                    direction: action.direction
                }))
                .set('valid', false);
        default:
            return state;
    }
}

export default {
    InitialState, Actions, Reducer
}
