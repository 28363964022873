/* eslint-disable comma-spacing,no-plusplus */
/*
 * SelfServicePage
 *
 * List all the features
 */
import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from "react-intl";
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";


export class SelfServiceErrorComponent extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired
    };

    render() {
        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceError.title', defaultMessage: 'Error'}} class="error">
                <SelfServiceStepLayout backActivated={false} closeActivated={false} title={{id: 'bonfire.containers.SelfServiceError.title', defaultMessage: 'Error'}}/>

                <div className="text">
                    <FormattedMessage
                        id="bonfire.containers.SelfServiceError"
                        defaultMessage='Der aufgerufene Link ist leider ungültig oder abgelaufen.'
                    />
                </div>


            </SelfServiceMainLayout>
        );
    }
}
