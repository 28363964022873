import * as R from 'ramda';
import {handleJsonResponse} from "../api/RestService";

const LANGUAGES = [
    {
        key: 'de',
        translation: {
            key: 'bonfire.languages.name.de',
            defaultMessage: 'Deutsch'
        }
    },
    {
        key: 'en',
        translation: {
            key: 'bonfire.languages.name.en',
            defaultMessage: 'Englisch'
        },
    },
    {
        key: 'fr',
        translation: {
            key: 'bonfire.languages.name.fr',
            defaultMessage: 'Französisch'
        }
    }
];

const DEFAULT_LANGUAGE = 'en';

export function getLanguageKeyFromLocale(locale, defaultLanguage = DEFAULT_LANGUAGE) {
    const language = locale.replace(/[_-](.*)$/, '');
    return R.any(R.propEq('key', language), LANGUAGES) ? language : defaultLanguage;
}

export function fetchMessagesForLanguage(language) {
    if (!R.any(R.propEq('key', language), LANGUAGES)) {
        return Promise.reject(`unknown language ${language}`);
    }
    return fetch(`${process.env.PUBLIC_URL}/react-translations/${language}.json`).then(handleJsonResponse);
}

export function getLanguageKeys() {
    return LANGUAGES.map(entry => entry.key);
}

export function getLanguages() {
    return R.clone(LANGUAGES);
}

export default {
    DEFAULT_LANGUAGE,
    getLanguageKeyFromLocale,
    fetchMessagesForLanguage,
    getLanguageKeys,
    getLanguages,
}
