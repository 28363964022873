export function getCustomPlaceHolderLanguages(lang) {
    let placeholder = '';
    switch (lang) {
        case 'de':
            placeholder = 'Auswählen';
            break;
        case 'en':
            placeholder = 'select';
            break;
        case 'fr':
            placeholder = 'sélectionner';
            break;
        default:
            placeholder = 'Auswählen';
            break;
    }

    return placeholder;
}