/**
 * converts v to valid input value (aka converted to string or empty string in case of falsy value)
 */
function toInputValue(v) {
    if (v) {
        return '' + v;
    } else {
        return '';
    }
}

export {
    toInputValue
}
