import {createAsyncThunk, createSlice, PayloadAction} from "@reduxjs/toolkit";
import TouristtaxService from "services/touristtaxes/TouristtaxService";
import {AppDispatch, AppGetState, RootState} from "App";
import {TouristTaxSearchParams} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailModel";
import {equals} from "ramda";

export interface TouristTaxSummary {
    servicePartnerName: string
    totalTax: number
    totalNightCount: number
    adultNightCount: number
    childrenNightCount: number
    smallChildrenNightCount: number
    taxFreeNightCount: number
}

function emptySummary(): TouristTaxSummary {
    return {
        servicePartnerName: "",
        totalTax: 0,
        totalNightCount: 0,
        adultNightCount: 0,
        childrenNightCount: 0,
        smallChildrenNightCount: 0,
        taxFreeNightCount: 0
    };
}

interface SliceState {
    query: TouristTaxSearchParams | null
    data: TouristTaxSummary
    loading: boolean
    error: string | null
    showExportPopup: boolean
}

const initialState: SliceState = {
    query: null,
    data: emptySummary(),
    loading: true,
    error: null,
    showExportPopup: false
}

function getStatementDetailsState(state: RootState): SliceState {
    return state.getIn(['zterminal', 'touristtax', 'detail'])['header'] as SliceState;
}

const fetchStatementDetails = createAsyncThunk(
    'touristTaxDetail/header/fetchStatementDetailsIfNecessary',
    (_: void, thunkApi) => {
        const state = thunkApi.getState();
        const query = getQuery(state);
        if (!query) {
            return Promise.resolve(emptySummary());
        }
        return TouristtaxService.loadServicePartnerSummary(query.servicePartnerId, query.startDate, query.endDate)
            .catch((error: any) => thunkApi.rejectWithValue(error.toString()));
    }
);

const slice = createSlice({
    name: 'touristTaxDetail/header',
    initialState,
    reducers: {
        setQuery: (state, action: PayloadAction<TouristTaxSearchParams>) => {
            state.query = action.payload;
        },
        setShowExportPopup: (state, action: PayloadAction<boolean>) => {
            state.showExportPopup = action.payload;
        }
    },
    extraReducers: builder => {
        builder.addCase(fetchStatementDetails.pending, state => {
            state.loading = true;
            state.error = null;
        });
        builder.addCase(fetchStatementDetails.rejected, (state, action) => {
            state.loading = false;
            state.error = action.error.toString();
            state.data = emptySummary();
        });
        builder.addCase(fetchStatementDetails.fulfilled, (state, action) => {
            state.loading = false;
            state.error = null;
            state.data = action.payload ?? emptySummary();
        });
    }
});

export const TouristTaxDetailHeaderReducer = slice.reducer;

function query(query: TouristTaxSearchParams) {
    return (dispatch: AppDispatch, getState: AppGetState) => {
        const currentQuery = getQuery(getState());
        if (equals(query, currentQuery)) {
            return Promise.resolve();
        }

        dispatch(slice.actions.setQuery(query));
        return dispatch(fetchStatementDetails());
    }
}

export const TouristTaxDetailHeaderActions = {
    query,
    setShowExportPopup: slice.actions.setShowExportPopup
};

const getQuery = (state: RootState) => getStatementDetailsState(state).query;
const getData = (state: RootState) => getStatementDetailsState(state).data;
const getLoading = (state: RootState) => getStatementDetailsState(state).loading;
const getError = (state: RootState) => getStatementDetailsState(state).error;
const getShowExportPopup = (state: RootState) => getStatementDetailsState(state).showExportPopup;

export const TouristTaxDetailsHeaderSelectors = {
    getQuery,
    getData,
    getLoading,
    getError,
    getShowExportPopup
};
