import ApiService from "../api/ApiService";

export const getAccomodationCategories = ApiService.createApiEndpoint('admin/accommodationCategories', 'GET', true);

export const getBarsServiceEndpoint = (endpoint) => {
  return  (startDate, endDate, servicePartnerId, categoryId, partnerType, compareStartDate, compareEndDate, categoryIdCompare, partnerTypeCompare) => {
   

    const formattedParams = {
      startDate: startDate || '',
      endDate: endDate || '',
      servicePartnerId: servicePartnerId || '',
      ...formatIds(categoryId, 'categoryId'),
      ...formatIds(partnerType, 'partnerType'),
      compareStartDate: compareStartDate || '',
      compareEndDate: compareEndDate || '',
      ...formatIds(categoryIdCompare, 'categoryIdCompare'),
      ...formatIds(partnerTypeCompare, 'partnerTypeCompare')
    };

    const urlParams = new URLSearchParams(
      Object.fromEntries(
        Object.entries(formattedParams).filter(([_, value]) => value !== undefined && value !== '' && value != 0)
      )
    );
    const url = `forecast/${endpoint}?${decodeURIComponent(urlParams.toString())}`;
    return ApiService.createApiEndpoint(url, 'POST', true)();
  };
};

function formatIds(ids, key) {
  const formatted = {};
  if (Array.isArray(ids)) {
    formatted[key] = ids.join('&' + key + '=');
  } else if (ids) {
    formatted[key] = ids;
  }
  return formatted;
}

//bars
 export const getServicePartnerBarDataEndpoint = getBarsServiceEndpoint('servicePartnerBar');
 export const getServiceOccupancyRateBarEndpoint = getBarsServiceEndpoint('occupancyRateBar');
 export const getServiceOverNightStaysBarEndpoint = getBarsServiceEndpoint('overNightStaysBar');
 export const getServiceAvgStaysBarEndpoint = getBarsServiceEndpoint('avgStaysBar');

 //graphs period
 export const getAvgStaysChartEndpoint = getBarsServiceEndpoint('avgStaysChart');
 export const getOccupancyRateChartEndpoint = getBarsServiceEndpoint('occupancyRateChart');
 export const getOverNightStaysChartAccumulatedEndpoint = getBarsServiceEndpoint('overNightStaysChartAccumulated');
 export const getOccupancyForecastEndpoint = getBarsServiceEndpoint('occupancyForecast');
 export const getOverNightStaysChartAccumulatedForecastEndpoint = getBarsServiceEndpoint('overNightStaysChartAccumulatedForecast');

//graphs compare
 export const getAvgStaysChartChangesEndpoint = getBarsServiceEndpoint('avgStaysChartChanges');
 export const getOccupancyRateChartChangesEndpoint = getBarsServiceEndpoint('occupancyRateChartChanges');
 export const getOverNightStaysChartChangesEndpoint = getBarsServiceEndpoint('overNightStaysChartChanges');
 
 export default {
    getAccomodationCategories,
  }
