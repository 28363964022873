import React, {ReactElement, useEffect, useRef} from 'react';
import {useSelector} from "react-redux";
import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";

export type ServicePartnerChangeHandler = (servicePartnerId: string | null | undefined) => void

export interface OnServicePartnerChangeProps {
    onServicePartnerChange: ServicePartnerChangeHandler
}

export function OnServicePartnerChange(props: OnServicePartnerChangeProps): ReactElement {
    const isInitialMount = useRef(true);
    const servicePartnerId = useSelector(SessionSelectors.currentServicePartner)?.id;
    const {onServicePartnerChange} = props;
    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            onServicePartnerChange(servicePartnerId);
        }
    }, [servicePartnerId, onServicePartnerChange]);

    return <></>
}
