import React, {ReactElement, useState} from "react";
import {SobrCustomCheckbox} from "components/SobrForm/Inputs/SobrCustomCheckbox";
import {downloadBilligPeriodRegistrationFormsCsv} from "services/touristtaxes/TouristtaxService";
import SobrPopup from "components/SobrPopup/SobrPopup";
import ScaleLoader from "react-spinners/ScaleLoader";
import {TouristTaxSearchParams} from "bonfire/ZTerminal/Touristtax/TouristtaxDetail/TouristTaxDetailModel";

export interface ExportPopupProps {
    show: boolean
    searchParams: TouristTaxSearchParams
    onClose: () => void
}

export function ExportPopup(props: ExportPopupProps): ReactElement {
    const [agreed, setAgreed] = useState(false);
    const [loading, setLoading] = useState(false);
    const close = () => {
        props.onClose();
        setAgreed(false);
        setLoading(false);
    };
    const downloadCsv = () => {
        setLoading(true)
        downloadBilligPeriodRegistrationFormsCsv(props.searchParams.servicePartnerId, props.searchParams.startDate, props.searchParams.endDate)
            .then(() => {
                close();
            })
            .catch(() => {
                close();
            });
    };

    return <SobrPopup
        show={props.show}
        title="DSV Hinweis"
        onClose={() => close()}>
        <form>
            <label className="d-flex">
                <span className="align-self-center">
                    <SobrCustomCheckbox
                        checked={agreed}
                        disabled={false}
                        onChange={setAgreed}
                    />
                </span>
                <span className="flex-grow-1 pl-2 text-left">
                    Ich bestätige, dass ich die <a href="https://ztnet.ch/images/content/2023.12.12DatenschutzerklaerungzTerminal.pdf" target="_blank">DSV</a> zur Kenntnis genommen und verstanden habe.
                </span>
            </label>
            <div className="form-group row">
                <div className="col-sm-12 py-3">
                    {loading ?
                        (
                            <div className="text-center">
                                <ScaleLoader color='#003463'/>
                            </div>
                        ) : (
                            <div className="text-right">
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={downloadCsv}
                                    disabled={!agreed}>
                                    Exportieren
                                </button>
                            </div>
                        )
                    }
                </div>
            </div>
        </form>
    </SobrPopup>
}
