import React from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';
import 'moment/locale/de';
import f from "lib/util";

export default class SobrDateInput extends React.Component {

    static propTypes = {
        moment: PropTypes.instanceOf(Moment),
        onSetMoment: PropTypes.func,
        format: PropTypes.string.isRequired,
        placeholder: PropTypes.string,
        disabled: PropTypes.bool,
    };

    static defaultProps = {
        selfService: false
    };

    constructor(props) {
        super(props);
        this.state = {
            inputString: ''
        };

        this.handleTextChange = this.handleTextChange.bind(this);
    }

    handleTextChange(str) {
        this.setState({inputString: str});
        const newMoment = this.tryParseMoment(str);
        if (newMoment) {
            this.setMoment(newMoment);
        }
    }

    tryParseMoment(str) {
        const moment = Moment.utc(str, this.props.format, true);
        if (moment.isValid()) {
            return moment.clone().startOf('day');
        } else {
            return undefined;
        }
    }

    setMoment(moment) {
        f.callIfExists(this.props.onSetMoment, moment);
    }

    get dateString() {
        if (this.state.inputString) {
            return this.state.inputString;
        } else if (this.props.moment) {
            return this.props.moment.format(this.props.format);
        } else {
            return '';
        }
    }

    render() {
        return <input
            type="text"
            className={this.props.selfService ? 'b-input-self-service-date' : "form-control"}
            placeholder={this.props.placeholder}
            value={this.dateString}
            onChange={event => this.handleTextChange(event.target.value)}
            disabled={this.props.disabled}
        />
    }

}
