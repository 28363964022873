import {Map} from 'immutable';
import UserService from "services/users/UserService";

export const InitialState = Map({
    term: '',
    dataValid: false,
    currentPage: 1,
    loading: false,
    userList: [],
    pages: 1,
    sorting: Map({
        column: 'firstName',
        direction: 'asc'
    })
});

const ACTION_SET_SEARCH_TERM = 'zterminal/user/list/setSearchTerm';
const ACTION_SET_DATA_VALID = 'zterminal/user/list/setDataValid';
const ACTION_SET_LOADING = 'zterminal/user/list/setLoading';
const ACTION_SET_USER_LIST = 'zterminal/user/list/setUserList';
const ACTION_SET_PAGES = 'zterminal/user/list/setPages';
const ACTION_SET_CURRENT_PAGE = 'zterminal/user/list/setCurrentPage';
const ACTION_SET_SORT_COLUMN = 'zterminal/user/list/setSortColumn';
const ACTION_SET_SORT_ORDER = 'zterminal/user/list/setSortOrder';

export const Actions = {
    invalidateData,
    fetchListIfNecessary,
    setSearchTerm,
    setCurrentPage,
    setSortColumn,
    setSortOrder
};

function fetchListIfNecessary() {
    const PAGE_SIZE = 20;
    return (dispatch, getState) => {
        const state = getState();
        const userListState = state.getIn(['zterminal', 'user', 'list']);
        const dataValid = userListState.get('dataValid');
        if (dataValid) {
            return Promise.resolve();
        }

        const searchTerm = userListState.get('term');
        const page = userListState.get('currentPage');
        const offset = PAGE_SIZE * (page - 1);
        const sortCol = userListState.getIn(['sorting', 'column']);
        const sortOrder = userListState.getIn(['sorting', 'direction']);

        dispatch(setLoading(true));
        return UserService.listUsers(PAGE_SIZE, offset, sortCol, sortOrder, searchTerm)
            .then(res => {
                dispatch(setUserList(res.data));
                dispatch(setPages(Math.ceil(res.totalCount / PAGE_SIZE)));
                dispatch(setLoading(false));
                dispatch(setDataValid(true));
            })
            .catch(error => {
                dispatch(setLoading(false));
                return Promise.reject(error);
            });
    };
}

function setSearchTerm(searchTerm) {
    return {
        type: ACTION_SET_SEARCH_TERM,
        term: searchTerm
    };
}

function invalidateData() {
    return setDataValid(false);
}

function setDataValid(valid) {
    return {
        type: ACTION_SET_DATA_VALID,
        valid: valid
    };
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading: loading
    };
}

function setUserList(userList) {
    return {
        type: ACTION_SET_USER_LIST,
        userList: userList
    };
}

function setPages(pages) {
    return {
        type: ACTION_SET_PAGES,
        pages: pages
    };
}

function setCurrentPage(page) {
    return {
        type: ACTION_SET_CURRENT_PAGE,
        page: page
    };
}

function setSortColumn(col) {
    return {
        type: ACTION_SET_SORT_COLUMN,
        column: col
    };
}

function setSortOrder(order) {
    return {
        type: ACTION_SET_SORT_ORDER,
        order: order
    };
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_SEARCH_TERM:
            return state.set('term', action.term);
        case ACTION_SET_DATA_VALID:
            return state.set('dataValid', action.valid);
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_SET_USER_LIST:
            return state.set('userList', action.userList);
        case ACTION_SET_PAGES:
            return state.set('pages', action.pages);
        case ACTION_SET_CURRENT_PAGE:
            return state.set('currentPage', action.page);
        case ACTION_SET_SORT_COLUMN:
            return state.setIn(['sorting', 'column'], action.column);
        case ACTION_SET_SORT_ORDER:
            return state.setIn(['sorting', 'order', action.order]);
        default:
            return state;
    }
}

export default {
    InitialState,
    Actions,
    Reducer
}
