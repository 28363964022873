import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Moment from 'moment';
import {Link} from "react-router-dom";
import {SelfServiceMainLayout} from "./layout/mainLayout";
import zermattLogo from 'images/logos/zermattLogoBig.png';


export class SelfServiceHomeComponent extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired
    };

    render() {
        const servicePartner = this.props.registrationForm.servicePartner;
        const primaryGuest = this.props.registrationForm.guests.find(guest => guest.primary === true);
        const id = this.props.registrationForm.id;
        const img = servicePartner.logoUrl ?? zermattLogo;

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceHomePage.title', defaultMessage: 'Pre-Check-in'}} class="login" wrapperClass="test">

                <div className="head">
                    <img className="logo" src={img} alt=""/>
                </div>
                <div className="text">
                    <h2 className="card-title slr-big with-bold">
                        <FormattedMessage
                        id="bonfire.containers.SelfServiceHomePage.card.title1"
                        defaultMessage='Herzlichen willkommen!'
                    />
                    </h2>
                    <p className="sar-big card-content1">
                        <FormattedMessage
                            id="bonfire.containers.SelfServiceHomePage.card.content1"
                            defaultMessage="Wir freuen uns Sie bald im Hotel willkommen heissen zu dürfen!"
                        />
                    </p>
                    <h5 className="sab-big">{servicePartner.selfCheckinName || servicePartner.name || servicePartner.companyName}</h5>
                    <div className="date-wrapper">
                        <div className="check-in">
                            <label className="sar-small">
                                <FormattedMessage
                                    id='bonfire.containers.SelfServiceHomePage.card.content2'
                                    defaultMessage='Anreise'
                                />
                            </label>
                            <span
                                className="sab-big">{Moment.utc(primaryGuest.checkinDate).format('DD.MM.YYYY')}</span>
                        </div>
                        <div className="check-out">
                            <label className="sar-small">
                                <FormattedMessage
                                    id="bonfire.containers.SelfServiceHomePage.card.content3"
                                    defaultMessage="Abreise"
                                />
                            </label>
                            <span
                                className="sab-big">{Moment.utc(primaryGuest.checkoutDate).format('DD.MM.YYYY')}</span>
                        </div>
                    </div>
                </div>
                <div className="bottom" onClick={() => window.scrollTo(0, 0)}>
                    <Link to={`/self-service/${id}/step2`}>
                        <button className="b-button-pre-checkin" id="self-service-step0-start-checkin-button" >
                   
                            <FormattedMessage
                                id="bonfire.containers.SelfServiceHomePage.card.button"
                                defaultMessage="Check-in beginnen"
                            />
                        </button>
                    </Link>
                </div>
            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceHome = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm'])
    })
)(SelfServiceHomeComponent);

