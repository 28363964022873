import React from 'react';
import PropTypes from 'prop-types';
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import {compose} from "redux";
import {WithBreadcrumb} from "bonfire/ZTerminal/breadcrumb/WithBreadcrumb";
import {Actions as UserEditPageActions} from "bonfire/ZTerminal/Users/UserEdit/redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import UserForm from "bonfire/ZTerminal/Users/UserForm/UserForm";
import {FormattedMessage} from "react-intl";
import {USER_DATA_PROPTYPE, VALIDATION_DATA_PROPTYPE} from "bonfire/ZTerminal/Users/UserForm/UserFormLogic";
import {Actions as UserListPageActions} from "bonfire/ZTerminal/Users/UserList/redux";
import SobrPopup from "components/SobrPopup/SobrPopup";

class UserEditPageComponent extends React.Component {

    static propTypes = {
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired,
        userData: USER_DATA_PROPTYPE,
        validationData: VALIDATION_DATA_PROPTYPE,
        clearValidationData: PropTypes.func.isRequired,
        fetchUserData: PropTypes.func.isRequired,
        deleteUser: PropTypes.func.isRequired,
        submitUserData: PropTypes.func.isRequired,
        resendPassword: PropTypes.func.isRequired,
        invalidateUserListData: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            userDeleteConfirmationVisible: false
        };

        this.showDeleteUserConfirmation = this.showDeleteUserConfirmation.bind(this);
        this.hideDeleteUserConfirmation = this.hideDeleteUserConfirmation.bind(this);
        this.resendPassword = this.resendPassword.bind(this);
        this.onDelete = this.onDelete.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
    }

    get userId() {
        return this.props.match.params['id'];
    }

    componentDidMount() {
        this.props.clearValidationData();
        this.props.fetchUserData(this.userId);
    }

    showDeleteUserConfirmation() {
        this.setState({userDeleteConfirmationVisible: true});
    }

    hideDeleteUserConfirmation() {
        this.setState({userDeleteConfirmationVisible: false});
    }

    onDelete() {
        this.hideDeleteUserConfirmation();
        this.props.deleteUser(this.userId)
            .then(() => {
                this.props.invalidateUserListData();
                this.props.history.push('/zterminal/user');
            });
    }

    onSubmit() {
        this.props.submitUserData(this.userId, this.props.userData)
            .then(() => {
                this.props.history.push('/zterminal/user');
            });
    }

    get breadcrumbPath() {
        const path = [
            {
                id: 'bonfire.containers.administration.User.header',
                defaultMessage: 'Benutzerverwaltung',
                url: '/zterminal/user'
            }
        ];
        if (this.props.userData) {
            const user = this.props.userData;
            const username = `${user.firstName} ${user.lastName}`;
            // breadcrumb takes id / defaultMessage objects or strings
            // noinspection JSCheckFunctionSignatures
            path.push(username);
        }

        return path;
    }

    resendPassword() {
        this.props.resendPassword(this.userId, this.props.userData.email);
    }

    render() {
        return <div id="user-edit-page" className="zterminal-content-box">
            <WithBreadcrumb icon={'users'} path={this.breadcrumbPath} />

            {/* Delete Confirmation Popup */}
            <SobrPopup
                show={this.state.userDeleteConfirmationVisible}
                title={"User löschen?"}
                onClose={this.hideDeleteUserConfirmation}
            >
                <div className="row">
                    <div className="col-6 text-left">
                        <button className="btn btn-secondary btn-160"
                                onClick={this.hideDeleteUserConfirmation}
                        >
                            Abbrechen
                        </button>
                    </div>
                    <div className="col-6 text-right">
                        <button className="btn btn-primary btn-160"
                                onClick={this.onDelete}
                        >
                            Löschen
                        </button>
                    </div>
                </div>
            </SobrPopup>

            <div className="zterminal-content-box-body p-3">
                <div className="container-fluid">
                    <LoadingOverlay loading={this.props.loading}>
                        {this.props.userData ? (
                            <UserForm.UserForm
                                userData={this.props.userData}
                                validationData={this.props.validationData}
                                onDataChange={this.props.setUserData}
                            />
                        ) : (
                            <div>{"Kann User nicht anzeigen"}</div>
                        )}
                    </LoadingOverlay>
                </div>
            </div>

            <div className="zterminal-content-box-footer">
                <div className="container-fluid px-4 py-2">
                    <div className="row">
                        <div className="col-12">
                            <button className="btn btn-secondary btn-160 mr-3" onClick={this.resendPassword}>
                                <FormattedMessage
                                    id="bonfire.containers.administration.User.edit.resendPW"
                                    defaultMessage="Passwort neu senden"
                                />
                            </button>

                            <button className="btn btn-secondary btn-160 mr-3" onClick={this.showDeleteUserConfirmation}>
                                <FormattedMessage
                                    id="bonfire.containers.administration.User.edit.deleteButton"
                                    defaultMessage="Löschen"
                                />
                            </button>

                            <button className="btn btn-primary btn-160" onClick={this.onSubmit}>
                                <FormattedMessage
                                    id="bonfire.containers.administration.User.edit.createButton"
                                    defaultMessage="Speichern"
                                />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    }

}

export default compose(
    withRouter,
    connect(
        state => {
            const userEditState = state.getIn(['zterminal', 'user', 'edit']);
            return {
                userData: userEditState.get('userData'),
                validationData: userEditState.get('validationData'),
                loading: userEditState.get('loading')
            }
        },
        dispatch => ({
            clearValidationData: () => dispatch(UserEditPageActions.clearValidationData()),
            fetchUserData: userId => dispatch(UserEditPageActions.fetchUserData(userId)),
            setUserData: userData => dispatch(UserEditPageActions.setUserData(userData)),
            resendPassword: (userId, email) => dispatch(UserEditPageActions.resendPassword(userId, email)),
            deleteUser: userId => dispatch(UserEditPageActions.deleteUser(userId)),
            submitUserData: (userId, userData) => dispatch(UserEditPageActions.submitUserData(userId, userData)),
            invalidateUserListData: () => dispatch(UserListPageActions.invalidateData())
        })
    )
)(UserEditPageComponent);
