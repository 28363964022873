import {Map} from 'immutable';
import UserFormBasedataService from "services/users/UserFormBasedataService";
import PartnerGroupService from "services/servicepartners/PartnerGroupService";

const InitialState = Map({
    loading: false,
    baseData: Map({
        partnerGroups: [],
        servicePartners: [],
        roles: []
    })
});

export const Accessors = {
    getUserForm,
    getUserFormBaseData
};

function getUserForm(state) {
    return state.getIn(['zterminal', 'user', 'form']).toJS();
}

function getUserFormBaseData(state) {
    return state.getIn(['zterminal', 'user', 'form', 'baseData']).toJS();
}


const ACTION_SET_LOADING = 'zterminal/user/form/setLoading';
const ACTION_BASEDATA_SET_PARTNERGROUPS = 'zterminal/user/form/basedata/setPartnerGroups';
const ACTION_BASEDATA_SET_SERVICE_PARTNERS = 'zterminal/user/form/basedata/setServicePartners';
const ACTION_BASEDATA_SET_ROLES = 'zterminal/user/form/basedata/setRoles';

export const Actions = {
    fetchBaseData,
    fetchRoleData
};

function fetchRoleData(){
    return dispatch => {
        dispatch(setLoading(true));
        return Promise.all([
        UserFormBasedataService.getRoles()
            .then(roles => {
                dispatch(setBaseDataRoles(roles));
            })
        ]).then(() => {
            dispatch(setLoading(false));
        });
    }
}

function fetchBaseData() {
    return dispatch => {
        dispatch(setLoading(true));
        return Promise.all([
            PartnerGroupService.getPartnerGroups()
                .then(response => {
                    dispatch(setBaseDataPartnerGroups(response.data));
                }),
            UserFormBasedataService.getServicePartners()
                .then(servicePartnerOptions => {
                    dispatch(setBaseDataServicePartners(servicePartnerOptions));
                }),
            UserFormBasedataService.getRoles()
                .then(roles => {
                    dispatch(setBaseDataRoles(roles));
                })
        ]).then(() => {
            dispatch(setLoading(false));
        });
    };
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

function setBaseDataPartnerGroups(partnerGroups) {
    return {
        type: ACTION_BASEDATA_SET_PARTNERGROUPS,
        partnerGroups
    };
}

function setBaseDataServicePartners(servicePartners) {
    return {
        type: ACTION_BASEDATA_SET_SERVICE_PARTNERS,
        servicePartners
    };
}

function setBaseDataRoles(roles) {
    return {
        type: ACTION_BASEDATA_SET_ROLES,
        roles
    };
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_BASEDATA_SET_PARTNERGROUPS:
            return state.setIn(['baseData', 'partnerGroups'], action.partnerGroups);
        case ACTION_BASEDATA_SET_SERVICE_PARTNERS:
            return state.setIn(['baseData', 'servicePartners'], action.servicePartners);
        case ACTION_BASEDATA_SET_ROLES:
            return state.setIn(['baseData', 'roles'], action.roles);
        default:
            return state;
    }
}

export default {
    InitialState,
    Accessors,
    Actions,
    Reducer
}
