import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import {List, Map} from 'immutable';
import Moment from 'moment';
import 'moment/locale/de';
import FilteredRegistrationSearchService from "services/registrationforms/FilteredRegistrationSearchService";

export const InitialState = Map({
    totals: {},
    perDay: List([]),
    valid: false,
    loading: false,
    error: false
});

// SessionActions

const ACTION_OVERVIEW_DATA_SET_TOTALS = 'zterminal/registrationForms/overviewData/setTotals';
const ACTION_OVERVIEW_DATA_SET_PER_DAY = 'zterminal/registrationForms/overviewData/setPerDay';
const ACTION_OVERVIEW_DATA_SET_LOADING = 'zterminal/registrationForms/overviewData/setLoading';
const ACTION_OVERVIEW_DATA_SET_ERROR = 'zterminal/registrationForms/overviewData/setError';
const ACTION_OVERVIEW_DATA_INVALIDATE = 'zterminal/registrationForms/overviewData/invalidate';
const ACTION_OVERVIEW_DATA_SET_VALID = 'zterminal/registrationForms/overviewData/setValid';

export const Actions = {
    fetchOverviewIfNecessary,
    setTotals,
    setPerDay,
    setOverviewError,
    setOverviewLoading,
    invalidateOverview
};

function fetchOverviewIfNecessary() {
    return (dispatch, getState) => {
        const servicePartner = SessionSelectors.currentServicePartner(getState());
        const searchFilter = getState().getIn(['zterminal', 'registrationForms', 'searchFilter']);
        const valid = searchFilter.get('valid');
        if (valid) {
            return Promise.resolve();
        }

        dispatch(setOverviewLoading(true));
        return FilteredRegistrationSearchService.getGuestCountsPerDay(servicePartner.id, searchFilter)
            .then(data => {
                const mapTotalsObjectToRow = totalsObject => {
                    const perCategory = totalsObject['perCategory'];
                    return {
                        date: Moment.utc(totalsObject['periodStartDate']).format("DD.MM.YYYY"),
                        adult: perCategory['tax-adult'],
                        kids: perCategory['tax-kids'],
                        smallKids: perCategory['tax-small-kids'],
                        taxFree: totalsObject['totalTaxFreeNights'],
                        total: totalsObject['totalNights']
                    };
                };
                const perDay = List(data.perDay.map(mapTotalsObjectToRow));
                const totals = mapTotalsObjectToRow(data.total);
                dispatch(setTotals(totals));
                dispatch(setPerDay(perDay));

                dispatch(setOverviewLoading(false));
                dispatch(setOverviewError(false));
                dispatch(setOverviewValid(true));
            });
    };
}

function invalidateOverview() {
    return { type: ACTION_OVERVIEW_DATA_INVALIDATE };
}

function setOverviewValid(valid) {
    return {
        type: ACTION_OVERVIEW_DATA_SET_VALID,
        valid: valid
    }
}

function setTotals(totals) {
    return {
        type: ACTION_OVERVIEW_DATA_SET_TOTALS,
        totals: totals
    }
}

function setPerDay(perDay) {
    return {
        type: ACTION_OVERVIEW_DATA_SET_PER_DAY,
        perDay: perDay
    }
}

function setOverviewLoading(loading) {
    return {
        type: ACTION_OVERVIEW_DATA_SET_LOADING,
        loading: loading
    }
}

function setOverviewError(error) {
    return {
        type: ACTION_OVERVIEW_DATA_SET_ERROR,
        error: error
    }
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_OVERVIEW_DATA_SET_TOTALS:
            return state.set('totals', action.totals);
        case ACTION_OVERVIEW_DATA_SET_PER_DAY:
            return state.set('perDay', action.perDay);
        case ACTION_OVERVIEW_DATA_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_OVERVIEW_DATA_SET_ERROR:
            return state.set('error', action.error);
        case ACTION_OVERVIEW_DATA_INVALIDATE:
            return state.set('valid', false);
        case ACTION_OVERVIEW_DATA_SET_VALID:
            return state.set('valid', action.valid);
        default:
            return state;
    }
}

export default {
    InitialState,
    Actions,
    Reducer
}
