import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";
import LanguagePicker from "bonfire/Language/LanguagePicker";
import SobrModal from "components/SobrModal/SobrModal";
import zermattLogo from 'images/logos/zermattLogoBig.png';

export class SelfServiceMainLayout extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            showModal: props.showModal
        }
    }

    static propTypes = {
        tabTitle: PropTypes.string,
        title: PropTypes.any.isRequired,
        wrapperClass: PropTypes.string,
        class: PropTypes.string.isRequired,
        showModal: PropTypes.bool,
        onCloseModal: PropTypes.func
    };

    static defaultProps = {
        tabTitle: 'Self-Check-In',
        wrapperClass: '',
        showModal: false,
        onCloseModal: null
    };


    UNSAFE_componentWillUpdate(nextProps, nextState, nextContext) {
        if (this.state.showModal !== nextProps.showModal) {
            this.setState({showModal: nextProps.showModal}, () => {
                if (this.state.showModal)
                    document.getElementById('self-service-error-modal-toggle').click();
            });
        }
    }

    render() {
        const errorModal = (
            <SobrModal
                withFooter={false}
                withHeader={true}
                modalTitle={'Fehler'}
                id={'self-service-error-modal'}
                onClose={() => {
                    this.props.onCloseModal();
                }}
            >
                <span>Es ist ein Fehler aufgetreten, bitte probieren Sie es später erneut oder kontaktieren Sie uns.</span>
            </SobrModal>
        );

        const img = zermattLogo;

        return (
            <div id="self-service">

                {this.state.showModal && errorModal}

                <Helmet>
                    <title>{this.props.tabTitle}</title>
                    <meta name="description" content="Feature page of React.js Boilerplate application"/>
                </Helmet>
                <div className="wrapper-main">
                    <header className="header-self-service">
                        <div className="container p-0">
                            <div className="row no-gutters">
                                <div className="col-6">
                                    <img className="logo-self-service" src={img} alt=""/>
                                </div>
                                <div className="col-6 text-right">
                                    <LanguagePicker
                                        isSelfService={true}
                                    />
                                </div>
                            </div>
                        </div>
                    </header>
                    <div className={'wrapper-content ' + this.props.wrapperClass}>
                        <div className="content-box">
                            <div className={this.props.class}>

                                {this.props.children}

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
