import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Route, Switch, withRouter} from "react-router-dom";
import {Actions} from "./redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {compose} from "redux";
import {RegistrationFormRead} from "./RegistrationFormReadComponent";
import {RegistrationFormEdit} from "./RegistrationFormEditComponent";
import {RegistrationFormCreate} from "./RegistrationFormCreateComponent";
import {UserTypeEnum} from "services/users/UserService";


export class RegistrationFormLoaderComponent extends React.Component {

    static propTypes = {
        read: PropTypes.bool,
        edit: PropTypes.bool,
        create: PropTypes.bool,
        registrationFormId: PropTypes.any,
        fetchCurrentRegistrationForm: PropTypes.func.isRequired,
        currentRegistrationForm: PropTypes.any,
        fetchNationalityData: PropTypes.func.isRequired,
        nationalityData: PropTypes.any,
        fetchInterestsData: PropTypes.func.isRequired,
        interestsData: PropTypes.any,
        fetchLanguageData: PropTypes.func.isRequired,
        languageData: PropTypes.any,
        fetchIdCardData: PropTypes.func.isRequired,
        idCardData: PropTypes.any,
        fetchTaxCategorydData: PropTypes.func.isRequired,
        taxCategoryData: PropTypes.any,
        fetchTaxExemptReasonData: PropTypes.func.isRequired,
        taxExemptReasonData: PropTypes.any,
        userType: PropTypes.any.isRequired
    };

    static defaultProps = {
        read: false,
        edit: false,
        create: false
    };

    constructor(props) {
        super(props);
        this.state = {
          loaded: false
        };
    }

    componentDidMount() {
        let promises = [];
        if (!this.props.create && !this.props.currentRegistrationForm) {
            promises.push(this.props.fetchCurrentRegistrationForm(this.props.registrationFormId));
        }

        if(!this.props.nationalityData) {
            promises.push(this.props.fetchNationalityData());
        }

        if(!this.props.interestsData) {
            promises.push(this.props.fetchInterestsData());
        }

        if(!this.props.languageData) {
            promises.push(this.props.fetchLanguageData());
        }

        if(!this.props.idCardData) {
            promises.push(this.props.fetchIdCardData());
        }

        if(!this.props.taxCategoryData) {
            promises.push(this.props.fetchTaxCategorydData());
        }

        if(!this.props.taxExemptReasonData) {
            promises.push(this.props.fetchTaxExemptReasonData());
        }

        Promise.all(promises).then(() => {
            this.setState({
                loaded: true
            });
        }).catch((error) => {
            console.log('Data loading Error', error);
        });

    }

    render() {
        if (this.state.loaded) {
            return (
                <Switch>
                    <Route exact path="/zterminal/registrationform/read/:id/" component={RegistrationFormRead}/>
                    <Route exact path="/zterminal/registrationform/edit/:id/" component={RegistrationFormEdit}/>
                    <Route exact path="/zterminal/registrationform/create/" component={RegistrationFormCreate}/>
                </Switch>
            )
        } else {
            return <LoadingOverlay loading={true} />
        }
    }
}

export const RegistrationFormLoader = compose(
    withRouter,
    connect(
        state => ({
            currentRegistrationForm: state.getIn(['zterminal', 'registrationForm', 'currentRegistrationForm']),
            userType: SessionSelectors.userType(state),
            nationalityData: state.getIn(['zterminal', 'registrationForm', 'nationalityData']),
            interestsData: state.getIn(['zterminal', 'registrationForm', 'interestsData']),
            languageData: state.getIn(['zterminal', 'registrationForm', 'languageData']),
            idCardData: state.getIn(['zterminal', 'registrationForm', 'idCardData']),
            taxCategoryData: state.getIn(['zterminal', 'registrationForm', 'taxCategoryData']),
            taxExemptReasonData: state.getIn(['zterminal', 'registrationForm', 'taxExemptReasonData'])
        }),

        (dispatch) => ({
            fetchCurrentRegistrationForm: id => dispatch(Actions.fetchCurrentRegistrationForm(id)),
            fetchNationalityData: () => dispatch(Actions.fetchNationalityData()),
            fetchInterestsData: () => dispatch(Actions.fetchInterestsData()),
            fetchLanguageData: () => dispatch(Actions.fetchLanguageData()),
            fetchIdCardData: () => dispatch(Actions.fetchIdCardData()),
            fetchTaxCategorydData: () => dispatch(Actions.fetchTaxCategorydData()),
            fetchTaxExemptReasonData: () => dispatch(Actions.fetchTaxExemptReasonData())
        })
    ))(RegistrationFormLoaderComponent);





