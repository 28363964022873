import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import {compose} from "redux";
import {WithBreadcrumb} from "../../breadcrumb/WithBreadcrumb";
import {RegistrationForm} from "./RegistrationFormComponent";
import {Actions} from "./redux";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {OnServicePartnerChange} from "components/zterminal/OnServicePartnerChange/OnServicePartnerChange";
import {UserTypeEnum} from "services/users/UserService";


export class RegistrationFormEditComponent extends React.Component {

    static propTypes = {
        loggedInUserType: PropTypes.string.isRequired,
        currentRegistrationForm: PropTypes.any.isRequired,
        fetchCurrentRegistrationForm: PropTypes.func.isRequired,
        match: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {
          loaded: false
        };

        this.gotoCreate = this.gotoCreate.bind(this);
    }

    get breadcrumbFirstElement() {
        return {
            id: 'bonfire.components.AdministrationSidebar.registrationForm',
            defaultMessage: 'Meldescheine',
            url: '/zterminal/registrationform'
        }
    }

    gotoCreate() {
        this.props.history.push("/zterminal/registrationform/create");
    }

    componentDidMount() {
        const regFormId = this.props.match.params.id;
        if(this.props.currentRegistrationForm || this.props.currentRegistrationForm.id !== regFormId){
            this.props.fetchCurrentRegistrationForm(regFormId).then(() => {
                this.setState({
                   loaded: true
                });
            });
        }
    }

    render() {
        const servicePartner = this.props.currentRegistrationForm.servicePartner;
        const primaryGuest = this.props.currentRegistrationForm.guests.find(guest => guest.primary === true);
        const rfName = primaryGuest ? primaryGuest.guest.firstName + ', ' + primaryGuest.guest.lastName : '';

        const path = [
            this.breadcrumbFirstElement,
            servicePartner.companyName || servicePartner.name,
            rfName
        ];

        return <LoadingOverlay loading={!this.state.loaded}>
            <div id="registration-form-edit">
                <WithBreadcrumb icon={'registrationForm'} path={path} />
                <OnServicePartnerChange onServicePartnerChange={this.gotoCreate} />
                <RegistrationForm edit={this.props.loggedInUserType === UserTypeEnum.SERVICE_PARTNER}/>
            </div>
        </LoadingOverlay>
    }
}

export const RegistrationFormEdit = compose(
    withRouter,
    connect(
        state => ({
            currentRegistrationForm: state.getIn(['zterminal', 'registrationForm', 'currentRegistrationForm']),
            loggedInUserType: SessionSelectors.userType(state)
        }),
        (dispatch) => ({
            fetchCurrentRegistrationForm: id => dispatch(Actions.fetchCurrentRegistrationForm(id))
        })
    ))(RegistrationFormEditComponent);





