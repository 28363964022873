const LOGIN_DATA_STORAGE_KEY = 'auth-info';

const CredentialStore = {
    getData: () => {
        const rawData = localStorage.getItem(LOGIN_DATA_STORAGE_KEY);
        return rawData !== null ?
            JSON.parse(rawData) :
            null;
    },
    setData: (loginData) => localStorage.setItem(LOGIN_DATA_STORAGE_KEY, JSON.stringify(loginData)),
    remove: () => localStorage.removeItem(LOGIN_DATA_STORAGE_KEY)
};

export default CredentialStore;
