import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import Moment from 'moment';
import {Link} from "react-router-dom";
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {ReactComponent as BackArrow} from 'images/icons/arrow_back_brown.svg'

export class SelfServiceStep1Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        customerData: PropTypes.any,
        nationalityData: PropTypes.any.isRequired
    };

    render() {
        const customerData = this.props.customerData;
        const primaryGuest = this.props.registrationForm.guests.find(guest => guest.primary === true);
        const id = this.props.registrationForm.id;
        const nationalityData = this.props.nationalityData;
        let nat = customerData && customerData.nationality ? customerData.nationality : primaryGuest.guest.nationality ? primaryGuest.guest.nationality : '';
        const nationality = nat.length > 0 && nationalityData.find((nation) => nation.value === nat) ? nationalityData.find((nation) => nation.value === nat).label : nationalityData.find((nation) => nation.label === nat) ? nationalityData.find((nation) => nation.label === nat).label : '';
        const guestAddress = customerData  ? customerData.address : primaryGuest.guest.address;

        const guestData = customerData ? customerData : primaryGuest.guest;

        const allPrimaryFieldsFilled = !!(guestData && guestAddress && guestData.firstName && guestData.lastName && guestData.birthday && guestData.nationality && guestData.email && guestAddress.street && guestAddress.zip && guestAddress.city && guestAddress.country && guestData.languageCode && guestData.salutation);

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep1Page.title', defaultMessage: 'Pre-Check-In'}} class="step1">
                <SelfServiceStepLayout id={id}/>

                <div className="text">
                    <div className="table-responsive">
                        <table className="table table-striped">
                            <tbody>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep1Page.fullname'
                                    defaultMessage='Name:'
                                    />
                                </td>
                                <td className="self-service-value">{guestData.salutation + ' ' + guestData.firstName + ' ' + guestData.lastName}</td>
                            </tr>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep1Page.birthdate'
                                    defaultMessage='Geburtsdatum:'
                                    />
                                </td>
                                <td className="self-service-value">{guestData.birthday ? Moment.utc(guestData.birthday).format('DD.MM.YYYY') : ''}</td>
                            </tr>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep1Page.nationality'
                                    defaultMessage='Nationalität:'
                                    />
                                </td>
                                <td className="self-service-value">{nationality}</td>
                            </tr>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                        id='bonfire.containers.SelfServiceStep1Page.street'
                                        defaultMessage='Strasse und Nummer:'
                                    />
                                </td>
                                <td className="self-service-value">{guestAddress && guestAddress.street ? guestAddress.street : ''}</td>
                            </tr>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep1Page.resident'
                                    defaultMessage='Wohnhaft in:'
                                    />
                                </td>
                                <td className="self-service-value"><span>{guestAddress && guestAddress.zip ? guestAddress.zip : ''}</span>
                                    &nbsp;<span>{guestAddress && guestAddress.city ? guestAddress.city : ''}</span>
                                </td>
                            </tr>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                        id='bonfire.containers.SelfServiceStep1Page.email'
                                        defaultMessage='E-Mail:'
                                    />
                                </td>
                                <td className="self-service-value">{guestData.email}</td>
                            </tr>
                            <tr>
                                <td className="self-service-label">
                                    <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep1Page.mobilephone'
                                    defaultMessage='Mobiltelefon:'
                                    />
                                </td>
                                <td className="self-service-value">{guestData.mobileNumber}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </div>

                <div className="container">
                    <div className="row" style={{textAlign: 'center'}}>
                        <div className="col-3">
                            <a onClick={() => this.props.history.goBack()}>
                                <button className="b-button-pre-checkin-navigation" id="self-service-step1-back-button">
                                    <BackArrow />
                                </button>
                            </a>
                        </div>
                        <div className="col-6" style={{margin: 0, padding: 0}}>
                            <Link to={`/self-service/${id}/step2`}>
                                <button className={"b-button-pre-checkin"} id="self-service-step1-confirm-next-to-screen-2-button">
                                    {allPrimaryFieldsFilled ? (
                                        <FormattedMessage
                                            id='bonfire.containers.SelfServiceStep1Page.buttonNo'
                                            defaultMessage='Nein, jetzt editieren'
                                        />
                                    ) : (
                                        <FormattedMessage
                                            id='bonfire.containers.SelfServiceStep1Page.buttonNeed'
                                            defaultMessage='Meine Angaben jetzt ergänzen'
                                        />
                                    )}
                                </button>
                            </Link>
                        </div>
                        <div className="col-3">
                            {allPrimaryFieldsFilled &&
                                <Link to={`/self-service/${id}/step4`}>
                                    <button className="b-button-pre-checkin-navigation" id="self-service-step1-confirm-next-to-screen-4-button">
                                        <BackArrow/>
                                    </button>
                                </Link>
                            }

                        </div>
                    </div>
                </div>
            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep1 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        customerData: state.getIn(['selfService', 'data', 'customerData']),
        nationalityData: state.getIn(['selfService', 'nationalityData'])
    })
)(SelfServiceStep1Component);

