import {Map} from 'immutable';
import RegistrationFormService from "services/registrationforms/RegistrationFormService"

export const InitialState = Map({
    currentRegistrationForm: null,
    nationalityData: null,
    interestsData: null,
    languageData: null,
    idCardData: null,
    taxCategoryData: null,
    taxExemptReasonData: null,
    deleteGuestPopupVisible: false
});

const ACTION_SET_CURRENT_REGISTRATION_FORM = 'zterminal/registrationForm/setCurrentRegistrationForm';
const ACTION_SET_NATIONALITY_DATA = 'zterminal/registrationForm/setNationalityData';
const ACTION_SET_INTERESTS_DATA = 'zterminal/registrationForm/setInterestsData';
const ACTION_SET_LANGUAGE_DATA = 'zterminal/registrationForm/setLanguageData';
const ACTION_SET_ID_CARD_DATA = 'zterminal/registrationForm/setIdCardData';
const ACTION_SET_TAX_CATEGORY_DATA = 'zterminal/registrationForm/setTaxCategoryData';
const ACTION_SET_TAX_EXEMPT_REASON_DATA = 'zterminal/registrationForm/setTaxExemptReasonData';
const ACTION_SET_DELETE_GUEST_POPUP_VISIBLE = 'zterminal/registrationForm/setDeleteGuestPopupVisible';

function fetchCurrentRegistrationForm(id) {
    return dispatch => {
        return RegistrationFormService.getCompleteRegistrationForm(id)
            .then(registrationForm => {
                return dispatch(setCurrentRegistrationForm(registrationForm));
            });
    }
}

function fetchNationalityData() {
    return dispatch => {
        return RegistrationFormService.getNationalityData()
            .then(nationalityData => {
                return dispatch(setNationalityData(nationalityData));
            });
    }
}

function fetchInterestsData() {
    return dispatch => {
        return RegistrationFormService.getInterestsData()
            .then(interestsData => {
                return dispatch(setInterestsData(interestsData));
            });
    }
}

function fetchLanguageData() {
    return dispatch => {
        return RegistrationFormService.getLanguageData()
            .then(languageData => {
                return dispatch(setLanguageData(languageData));
            });
    }
}

function fetchIdCardData() {
    return dispatch => {
        return RegistrationFormService.getIdCardData()
            .then(idCardData => {
                return dispatch(setIdCardData(idCardData));
            });
    }
}

function fetchTaxCategorydData() {
    return dispatch => {
        return RegistrationFormService.getTaxCategoryData()
            .then(taxCategoryData => {
                return dispatch(setTaxCategoryData(taxCategoryData));
            });
    }
}

function fetchTaxExemptReasonData() {
    return dispatch => {
        return RegistrationFormService.getTaxExemptReasonData()
            .then(taxExemptReasonData => {
                return dispatch(setTaxExemptReasonData(taxExemptReasonData));
            });
    }
}

function createRegForm(data) {
    return dispatch => {
        return RegistrationFormService.createRegForm(data)
            .then(createdRegForm => {
                return dispatch(setCurrentRegistrationForm(createdRegForm));
            });
    }
}

function updateRegistrationForm(id, data) {
    return dispatch => {
        return RegistrationFormService.updateRegistrationForm(id, data);
    }
}

function deleteGuest(regForm, guestId) {
    return dispatch => {
        return RegistrationFormService.deleteGuest(regForm, guestId)
            .then(updatedRegForm => {
                return dispatch(setCurrentRegistrationForm(updatedRegForm));
        });
    }
}

function deleteRegForm(regFormId) {
    return () => {
        return RegistrationFormService.deleteRegForm(regFormId);
    }
}

function addGroup(id, data) {
    return dispatch => {
        return RegistrationFormService.addGroup(id, data)
            .then(updatedRegForm => {
                return dispatch(setCurrentRegistrationForm(updatedRegForm));
            });
    }
}

function updateGuestDataRegForm(regFormId, guestId, data) {
    return dispatch => {
        return RegistrationFormService.updateGuestDataRegForm(regFormId, guestId, data)
            .then(updatedRegForm => {
                return dispatch(setCurrentRegistrationForm(updatedRegForm));
            });
    }
}

function addExclusion(id, data) {
    return () => {
        return RegistrationFormService.addExclusion(id, data)
            .then(response => {
                console.log('addExclusion response: ', response);
            });
    }
}

function addGuest(id, data) {
    return dispatch => {
        return RegistrationFormService.addGuest(id, data)
            .then(updatedRegForm => {
                return dispatch(setCurrentRegistrationForm(updatedRegForm));
            });
    }
}

function updateExclusion(id, exId ,data) {
    return () => {
        return RegistrationFormService.updateExclusion(id, exId, data)
            .then(response => {
                console.log('updateExclusion response: ', response);
            });
    }
}

function deleteExclusion(id, exId) {
    return () => {
        return RegistrationFormService.deleteExclusion(id, exId)
            .then(response => {
                console.log('deleteExclusion response: ', response);
            });
    }
}

function setCurrentRegistrationForm(currentRegistrationForm) {
    return {
        type: ACTION_SET_CURRENT_REGISTRATION_FORM,
        currentRegistrationForm: currentRegistrationForm
    }
}

function setNationalityData(nationalityData) {
    return {
        type: ACTION_SET_NATIONALITY_DATA,
        nationalityData: nationalityData
    }
}

function setInterestsData(interestsData) {
    return {
        type: ACTION_SET_INTERESTS_DATA,
        interestsData: interestsData
    }
}

function setLanguageData(languageData) {
    return {
        type: ACTION_SET_LANGUAGE_DATA,
        languageData: languageData
    }
}

function setIdCardData(idCardData) {
    return {
        type: ACTION_SET_ID_CARD_DATA,
        idCardData: idCardData
    }
}

function setTaxCategoryData(taxCategoryData) {
    return {
        type: ACTION_SET_TAX_CATEGORY_DATA,
        taxCategoryData: taxCategoryData
    }
}

function setTaxExemptReasonData(taxExcmptReasonData) {
    return {
        type: ACTION_SET_TAX_EXEMPT_REASON_DATA,
        taxExemptReasonData: taxExcmptReasonData
    }
}

function setDeleteGuestPopupVisible(visible) {
    return {
        type: ACTION_SET_DELETE_GUEST_POPUP_VISIBLE,
        deleteGuestPopupVisible: visible
    }
}

export const Actions = {
    fetchCurrentRegistrationForm,
    fetchNationalityData,
    fetchInterestsData,
    fetchLanguageData,
    fetchIdCardData,
    fetchTaxCategorydData,
    fetchTaxExemptReasonData,
    createRegForm,
    deleteGuest,
    deleteRegForm,
    addGroup,
    updateRegistrationForm,
    updateGuestDataRegForm,
    addExclusion,
    updateExclusion,
    deleteExclusion,
    addGuest,
    setDeleteGuestPopupVisible
};


export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_CURRENT_REGISTRATION_FORM:
            return state.set('currentRegistrationForm', action.currentRegistrationForm);
        case ACTION_SET_NATIONALITY_DATA:
            return state.set('nationalityData', action.nationalityData);
        case ACTION_SET_INTERESTS_DATA:
            return state.set('interestsData', action.interestsData);
        case ACTION_SET_LANGUAGE_DATA:
            return state.set('languageData', action.languageData);
        case ACTION_SET_ID_CARD_DATA:
            return state.set('idCardData', action.idCardData);
        case ACTION_SET_TAX_CATEGORY_DATA:
            return state.set('taxCategoryData', action.taxCategoryData);
        case ACTION_SET_TAX_EXEMPT_REASON_DATA:
            return state.set('taxExemptReasonData', action.taxExemptReasonData);
        case ACTION_SET_DELETE_GUEST_POPUP_VISIBLE:
            return state.set('deleteGuestPopupVisible', action.deleteGuestPopupVisible);
        default:
            return state;
    }
}


export default {
    Actions, InitialState, Reducer
}
