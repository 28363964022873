import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {Actions} from "./redux";
import {List} from "immutable";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import { injectIntl } from "react-intl";

class TaxOverviewComponent extends React.Component {

    static propTypes = {
        totals: PropTypes.object.isRequired,
        perDay: PropTypes.instanceOf(List).isRequired,
        onLoad: PropTypes.func
    };

    componentDidMount() {
        if (this.props.onLoad) {
            this.props.onLoad();
        }
    }

    render() {

        return <div className="row no-gutters" id="tax-overview-body">
            <div className="col-md-12">
                <div id="tax-overview-table" className="h-100">
                    <LoadingOverlay loading={this.props.loading}>
                        {this.props.perDay && this.props.totals && this.table}
                    </LoadingOverlay>
                </div>
            </div>
        </div>
    }

    get table() {
        const {formatMessage} = this.props.intl;

        return <div className="table-responsive">
            <table className="table table-striped table-hover">
                <thead className="thead">
                <tr>
                    <th scope="col">{formatMessage({ id: 'bonfire.touristTax.header.date'})}</th>
                    <th scope="col" className="text-center">{formatMessage({ id: 'bonfire.containers.administration.TouristTax.parentsHeader'})}</th>
                    <th scope="col" className="text-center">{formatMessage({ id: 'bonfire.containers.administration.TouristTax.childrenHeader'})}</th>
                    <th scope="col" className="text-center">{formatMessage({ id: 'bonfire.touristTax.header.infants'})}</th>
                    <th scope="col" className="text-center">{formatMessage({ id: 'bonfire.touristTax.header.exempt'})}</th>
                    <th scope="col" className="text-center">{formatMessage({ id: 'bonfire.containers.administration.TouristTax.total'})}</th>
                </tr>
                </thead>
                <tbody>
                {this.props.perDay.map(row => {
                    return <tr key={row.date}>
                        <th scope="row">{row.date}</th>
                        <td className="text-center">{row.adult}</td>
                        <td className="text-center">{row.kids}</td>
                        <td className="text-center">{row.smallKids}</td>
                        <td className="text-center">{row.taxFree}</td>
                        <td className="text-center">{row.total}</td>
                    </tr>
                })}
                </tbody>
                <tfoot>
                <tr>
                    <th scope="col">Total</th>
                    <th scope="col" className="text-center">{this.props.totals.adult}</th>
                    <th scope="col" className="text-center">{this.props.totals.kids}</th>
                    <th scope="col" className="text-center">{this.props.totals.smallKids}</th>
                    <th scope="col" className="text-center">{this.props.totals.taxFree}</th>
                    <th scope="col" className="text-center">{this.props.totals.total}</th>
                </tr>
                </tfoot>
            </table>
        </div>

    }

}

export const TaxOverview = connect(
    state => {
        const overviewData = state.getIn(['zterminal', 'registrationForms', 'taxOverview']);
        return {
            loading: overviewData.get('loading'),
            totals: overviewData.get('totals'),
            perDay: overviewData.get('perDay')
        };
    },
    dispatch => ({
        onLoad: () => { dispatch(Actions.fetchOverviewIfNecessary()) }
    })
)(injectIntl(TaxOverviewComponent));

