import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import React, {ReactElement} from 'react';
import {useSelector} from "react-redux";
import {ZTerminal} from "bonfire/ZTerminal/ZTerminal";
import App from "./containers/App";
import CredentialStore from "./services/authorization/CredentialStore";

/**
 * does the glue between tobi code and clean code.
 * We want to use the "new" login, so we set all the localStorage and sessionStorage according to our session data,
 * which is appropriately stored in redux.
 */
export function TobiCodeLoader(): ReactElement {
    const loggedIn = useSelector(SessionSelectors.loggedIn);
    const roles = useSelector(SessionSelectors.roles);
    const servicePatners = useSelector(SessionSelectors.servicePartners);
    const currentServicePartner = useSelector(SessionSelectors.currentServicePartner);

    if (loggedIn) {
        sessionStorage.setItem('roles', JSON.stringify(roles));
        sessionStorage.setItem('loggedIn', 'true');

        const loginData = CredentialStore.getData();
        localStorage.setItem('access_token', loginData['access_token']);
        localStorage.setItem('refresh_token', loginData['refresh_token']);

        sessionStorage.setItem('servicePartners', JSON.stringify(servicePatners));
        if (currentServicePartner) {
            sessionStorage.setItem('currentServicePartner', currentServicePartner?.id);
        }
    }

    // if we're not logged id, render the clean Bonfire App, it will handle the login
    if (!loggedIn) {
        return <ZTerminal/>
    }

    // if we're logged in, componentDidMount() will have set all the session data. We can then render Tobi Code
    else {
        return <App/>
    }
}
