import React from 'react';
import {Route, Switch} from "react-router-dom";
import TouristtaxPage from "bonfire/ZTerminal/Touristtax/TouristtaxPage";
import {TouristTaxDetailPage} from "./TouristtaxDetail/TouristTaxDetailPage";

export default function TouristtaxSection() {
    return <Switch>
        <Route exact={true} path="/zterminal/touristtax" component={TouristtaxPage} />
        <Route exact={true} path="/zterminal/touristtax/detail/:id" render={({match}) => <TouristTaxDetailPage servicePartnerId={match.params.id} />} />
    </Switch>
}
