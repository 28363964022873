import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {RootState} from "App";


export interface TranslatableBreadcrumbPathElement {
    id: string
    defaultMessage?: string
    url?: string
}

export type BreadcrumbPathElement = string | TranslatableBreadcrumbPathElement
export type BreadcrumbPath = BreadcrumbPathElement[]

export function isTranslatableBreadcrumbPathElement(pathElement: BreadcrumbPathElement): pathElement is TranslatableBreadcrumbPathElement {
    return !(typeof pathElement === 'string');
}

export interface BreacrumbSliceState {
    icon: string | null
    path: BreadcrumbPath
}

export const initialState: BreacrumbSliceState = {
    icon: null,
    path: []
};

const slice = createSlice({
    name: 'zterminal/breadcrumb',
    initialState,
    reducers: {
        setIcon: (state, action: PayloadAction<string | null>) => {
            state.icon = action.payload;
        },
        setPath: (state, action: PayloadAction<BreadcrumbPath>) => {
            state.path = action.payload;
        }
    }
});

function getBreadcrumbState(state: RootState): BreacrumbSliceState {
    return state.getIn(['zterminal', 'breadcrumb']);
}

export const breadcrumbReducer = slice.reducer;
export const breadcrumbActions = {
    setIcon: slice.actions.setIcon,
    setPath: slice.actions.setPath
}
export const breadcrumbSelectors = {
    icon: (state: RootState) => getBreadcrumbState(state).icon,
    path: (state: RootState) => getBreadcrumbState(state).path
}
