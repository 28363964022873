import f from "lib/util";
import * as R from "ramda";
import {API_ROOT} from "api-config";
import CredentialStore from "../authorization/CredentialStore";
import {createGetRequestUrl, handleJsonResponse} from "./RestService";

/**
 * Helper class for calling API Endpoints.
 *
 * Handles authorization (including token refreshes and retries in case of token expiration),
 * JSON payload + headers, path variable replacing and attach search params (?var=foo).
 *
 * Prefer this over ApiService.createApiEndpoint in cases that need to handle more than the simple payload case.
 */
export class ApiEndpoint {

    constructor(path, method = 'GET', authorized = true) {
        this.url = API_ROOT + path;
        this.method = method;
        this.authorized = authorized;
    }

    call(searchParams, body, pathVariables, hasFormData = false) {
        const actualUrl = getActualUrl(pathVariables, searchParams, this.url);
        const headers = getHeaders(this.authorized,hasFormData);
        const formData = new FormData();

        if(hasFormData) {
            formData.append('title', body.file.name);
            formData.append('file', body.file);
            formData.append('filename', body.file.filename);

            formData.append('servicePartnerId', body.servicePartnerId)

        }

        const request = () => fetch(actualUrl, {
            method: this.method,
            headers: headers,
            ...(f.isNilOrEmpty(body) ? {} : hasFormData ? {body: formData} : {body: JSON.stringify(body)})
        }).then(handleJsonResponse);

        const promise = request();
        return this.authorized ? promise.catch(retryAuthOn401(request)) : promise;
    }

    callWithSearchParams(searchParams) {
        return this.call(searchParams, null, null);
    }

    callWithBody(body) {
        return this.call(null, body, null);
    }

    callWithPathVariables(pathVariables) {
        return this.call(null, null, pathVariables);
    }

}

function getActualUrl(pathVariables, searchParameters, url) {
    return R.pipe(
        R.partial(processUrlPathVariables, [pathVariables]),
        R.partialRight(createGetRequestUrl, [searchParameters])
    )(url);
}

function getHeaders(authorized, hasFormData = false) {
    if(hasFormData) {
        return getAuthHeader();
    }
    else {
        return Object.assign({
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Accept-Language': localStorage.getItem("language") !== null ? localStorage.getItem("language") : "en"
        }, (authorized ? getAuthHeader() : {}));
    }
}

export function getAuthHeader() {
    const loginData = CredentialStore.getData();
    return loginData ?
        { 'Authorization': `${loginData.token_type} ${loginData.access_token}` } :
        {};
}

function processUrlPathVariables(pathVariables, url) {
    if (!R.includes('/:', url)) {
        return url;
    }
    const reduceFn = (url, [key, value]) => R.replace(RegExp(`/:${key}(/|$)`, 'g'), `/${value}$1`, url);
    return R.pipe(
        R.toPairs,
        R.reduce(reduceFn, url)
    )(pathVariables);
}

const retryFnRef = {
    retryFn: null
};

export function setRetryFn(retryFn) {
    retryFnRef.retryFn = retryFn;
}

function retryAuthOn401(request) {
    return error => {
        if (!retryFnRef.retryFn || error.status !== 401) {
            return Promise.reject(error);
        }

        return retryFnRef.retryFn(request, error);
    };
}

