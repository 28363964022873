import * as R from "ramda";
import {ApiEndpoint} from "../api/ApiEndpoints";
import ApiService from "../api/ApiService";

const getTouristtaxesRequest = ApiService.createApiEndpoint('admin/servicepartners/taxes');
export function getAllTouristtaxes() {
    return getTouristtaxesRequest({
        sort: 'name',
        order: 'asc'
    }).then(R.prop('data'));
}

export function listTouristtaxes(max, offset, sort, order, q=undefined, showInactive = false) {
    return getTouristtaxesRequest({
        max,
        offset,
        sort,
        order,
        q,
        ...showInactive ? {} : { inactive: false }
    });
}

const getServicePartnerRequest = new ApiEndpoint('admin/tax/statements/:id', 'GET', true);
export function loadBillingPeriodDetails(id) {
    return getServicePartnerRequest.callWithPathVariables({id});
}

export function loadBillingPeriods(servicePartnerId, startDate, endDate) {
    const loadBillingPeriodsRequest = new ApiEndpoint('admin/tax/statements', 'GET', true);
    return loadBillingPeriodsRequest.call({servicePartnerId, startDate, endDate});
}

const loadBillingPeriodRegistrationFormsRequest = new ApiEndpoint('admin/tax/statements/:id/registrations', 'GET', true);
export function loadBillingPeriodRegistrationForms(id, offset, limit) {
    return loadBillingPeriodRegistrationFormsRequest.call({offset, limit}, {}, {id})
}


export function loadServicePartnerSummary(servicePartnerId, startDateMoment, endDateMoment) {
    const loadServicePartnerDetailsRequest = new ApiEndpoint(`admin/tax/servicepartner/${servicePartnerId}/summary`, 'GET', true);
    const searchParams = {
        start: startDateMoment.format("YYYY-MM-DD"),
        end: endDateMoment.format("YYYY-MM-DD")
    };
    return loadServicePartnerDetailsRequest.call(searchParams, {}, {})
}

export function loadServicePartnerRegistrationForms(servicePartnerId, startDateMoment, endDateMoment, offset, limit) {
    const loadServicePartnerRegistrationFormsRequest = new ApiEndpoint(`admin/tax/servicepartner/${servicePartnerId}/registrations`, 'GET', true);
    const searchParams = {
        start: startDateMoment.format("YYYY-MM-DD"),
        end: endDateMoment.format("YYYY-MM-DD"),
        offset,
        max: limit
    };
    return loadServicePartnerRegistrationFormsRequest.call(searchParams, {}, {})
}

export function downloadBilligPeriodRegistrationFormsCsv(servicePartnerId, startDateMoment, endDateMoment) {
    const downloadBilligPeriodRegistrationFormsCsvRequest = ApiService.createDownloadEndpoint(`admin/tax/servicepartner/${servicePartnerId}/registrations/csv`, 'text/csv', true);
    const searchParams = {
        start: startDateMoment.format("YYYY-MM-DD"),
        end: endDateMoment.format("YYYY-MM-DD")
    };
    return downloadBilligPeriodRegistrationFormsCsvRequest('test.csv', searchParams)
}

export function loadYearlyTaxSummary(servicePartnerId) {
    const loadYearlyTaxSummaryRequest = new ApiEndpoint('admin/tax/statements/yearly');
    return loadYearlyTaxSummaryRequest.call({servicePartnerId});
}

export function setStatus(id) {
    const setStatusRequest = new ApiEndpoint('admin/tax/statements/:id/status', 'POST');
    return setStatusRequest.call( {}, {status: 'SETTLED'}, {id})
}

export default {
    loadBillingPeriods,
    loadBillingPeriodDetails,
    loadBillingPeriodRegistrationForms,
    loadServicePartnerSummary,
    loadServicePartnerRegistrationForms,
    loadYearlyTaxSummary,
    setStatus,

    getAllTouristtaxes,
    listTouristtaxes
}
