import React, {ReactNode, useEffect} from 'react';
import {Login} from "./Login";
import {SessionActions, SessionSelectors} from "./SessionSlice";
import {useDispatch, useSelector} from "react-redux";
import {BonfireStore} from "App";
import {LoadingOverlay} from "components/zterminal/LoadingOverlay/LoadingOverlay";
import {setRetryFn} from "services/api/ApiEndpoints";

export interface SessionProps {
    children: ReactNode
}

export function Session(props: SessionProps) {
    const loggedIn = useSelector(SessionSelectors.loggedIn);
    const triedRefresh = useSelector(SessionSelectors.triedRefresh);
    const sessionLoading = useSelector(SessionSelectors.sessionLoading);

    const dispatch = useDispatch();
    useEffect(() => {
        const tryRefreshSessionToken = () => dispatch(SessionActions.tryRefreshSavedToken())
        if (!loggedIn && !triedRefresh) {
            tryRefreshSessionToken();
        }
    }, [loggedIn, triedRefresh, dispatch]);

    return <LoadingOverlay loading={sessionLoading}>
        {loggedIn ? props.children : <Login />}
    </LoadingOverlay>
}


setRetryFn((request: () => Promise<any>, error: any) => {
    return BonfireStore.dispatch(SessionActions.tryRefreshSavedToken())
        .then((retryResponse: {success: boolean}) => {
            if (retryResponse && retryResponse.success) {
                return request();
            } else {
                return Promise.reject(error);
            }
        })
});
