import {Map} from 'immutable';
import ServicePartnerService from "services/servicepartners/ServicePartnerService";

export const InitialState = Map({
    servicePartnerList: [],
    sorting: Map({
        column: 'name',
        direction: 'asc'
    }),
    loading: false,
    numPages: 1,
    currentPage: 1,
    searchTerm: '',
    showInactive: false
});

const ACTION_SET_LOADING = 'zterminal/servicepartner/list/setLoading';
const ACTION_SET_SORT_COLUMN = 'zterminal/servicepartner/list/setSortColumn';
const ACTION_SET_SORT_ORDER = 'zterminal/servicepartner/list/setSortOrder';
const ACTION_SET_DATA_VALID = 'zterminal/servicepartner/list/setDataValid';
const ACTION_SET_SERVICE_PARTNER_LIST = 'zterminal/servicepartner/list/setServicePartnerList';
const ACTION_SET_PAGES = 'zterminal/servicepartner/list/setPages';
const ACTION_SET_CURRENT_PAGE = 'zterminal/servicepartner/list/setCurrentPage';
const ACTION_SET_SEARCH_TERM = 'zterminal/servicepartner/list/setSearchTerm';
const ACTION_SET_SHOW_INACTIVE = 'zterminal/servicepartner/list/setShowInactive';

export const Actions = {
    setSortColumn,
    setSortOrder,
    setCurrentPage,
    setSearchTerm,
    setShowInactive,
    invalidateData,
    fetchListIfNecessary
};

function fetchListIfNecessary() {
    const PAGE_SIZE = 20;
    return (dispatch, getState) => {
        const state = getState();
        const spListState = state.getIn(['zterminal', 'servicePartner', 'list']);
        const dataValid = spListState.get('dataValid');
        if (dataValid) {
            return Promise.resolve();
        }

        const searchTerm = spListState.get('searchTerm');
        const showInactive = spListState.get('showInactive');
        const currentPage = spListState.get('currentPage');
        const offset = PAGE_SIZE * (currentPage - 1);
        const sortCol = spListState.getIn(['sorting', 'column']);
        const sortOrder = spListState.getIn(['sorting', 'direction']);

        dispatch(setLoading(true));
        return ServicePartnerService.listServicePartners(PAGE_SIZE, offset, sortCol, sortOrder, searchTerm, showInactive)
            .then(res => {
                dispatch(setServicePartnerList(res.data));
                dispatch(setPages(Math.ceil(res.totalCount / PAGE_SIZE)));
                dispatch(setLoading(false));
                dispatch(setDataValid(true));
            })
            .catch(error => {
                dispatch(setLoading(false));
                return Promise.reject(error);
            })
    }
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        loading
    };
}

function setSortColumn(sortColumn) {
    return {
        type: ACTION_SET_SORT_COLUMN,
        sortColumn
    };
}

function setSortOrder(sortOrder) {
    return {
        type: ACTION_SET_SORT_ORDER,
        sortOrder
    };
}

function setDataValid(valid) {
    return {
        type: ACTION_SET_DATA_VALID,
        valid
    };
}

function invalidateData() {
    return setDataValid(false);
}

function setServicePartnerList(servicePartnerList) {
    return {
        type: ACTION_SET_SERVICE_PARTNER_LIST,
        servicePartnerList
    };
}

function setPages(numPages) {
    return {
        type: ACTION_SET_PAGES,
        numPages
    };
}

function setCurrentPage(currentPage) {
    return {
        type: ACTION_SET_CURRENT_PAGE,
        currentPage
    };
}

function setSearchTerm(searchTerm) {
    return {
        type: ACTION_SET_SEARCH_TERM,
        searchTerm
    };
}

function setShowInactive(showInactive) {
    return {
        type: ACTION_SET_SHOW_INACTIVE,
        showInactive
    };
}

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_LOADING:
            return state.set('loading', action.loading);
        case ACTION_SET_SORT_COLUMN:
            return state.setIn(['sorting', 'column'], action.sortColumn);
        case ACTION_SET_SORT_ORDER:
            return state.setIn(['sorting', 'order'], action.sortOrder);
        case ACTION_SET_DATA_VALID:
            return state.set('dataValid', action.valid);
        case ACTION_SET_SERVICE_PARTNER_LIST:
            return state.set('servicePartnerList', action.servicePartnerList);
        case ACTION_SET_PAGES:
            return state.set('numPages', action.numPages);
        case ACTION_SET_CURRENT_PAGE:
            return state.set('currentPage', action.currentPage);
        case ACTION_SET_SEARCH_TERM:
            return state.set('searchTerm', action.searchTerm);
        case ACTION_SET_SHOW_INACTIVE:
            return state.set('showInactive', action.showInactive);
        default:
            return state;
    }
}

export default {
    InitialState,
    Actions,
    Reducer
}
