import React from 'react';
import PropTypes from 'prop-types';
import {FormattedMessage} from 'react-intl';
import {connect} from 'react-redux';
import {SelfServiceMainLayout} from "./layout/mainLayout";
import {SelfServiceStepLayout} from "./layout/stepLayout";
import {Actions} from "./redux";


export class SelfServiceStep4Component extends React.Component {

    static propTypes = {
        registrationForm: PropTypes.object.isRequired,
        setDataComment: PropTypes.func.isRequired,
        comment: PropTypes.string
    };

    constructor(props) {
        super(props);
        this.state = {
            comment: ''
        };

        this.handleSubmit = this.handleSubmit.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    componentDidMount() {
        if(this.props.registrationForm) {

            let comment = this.props.comment ? this.props.comment : this.props.registrationForm.comment;

            this.setState({
                comment: comment ? comment : ''
            });
        }
    }

    handleChange(event) {
        this.setState({
            [event.target.name]: event.target.value
        });
    }

    handleSubmit() {
        this.props.setDataComment(this.state.comment);

        if (this.props.registrationForm.totalGuestCount > 1) {
            this.props.history.push(`/self-service/${this.props.registrationForm.id}/step6`);
        } else {
            this.props.history.push(`/self-service/${this.props.registrationForm.id}/step8`);
        }


    }

    render() {
        const id = this.props.registrationForm.id;

        return (
            <SelfServiceMainLayout title={{id: 'bonfire.containers.SelfServiceStep4Page.title', defaultMessage: 'Pre-Check-In'}} class="step4">

                <SelfServiceStepLayout id={id} title={{id: 'bonfire.containers.SelfServiceStep4Page.title.furtherinfos'}} class="step4"/>


                <div className="text">
                    <div className="wrapper">
                        <div className="sar-big thanks">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep4Page.textthankyou'
                                defaultMessage='Vielen Dank! Sie sind nun eingecheckt!'
                            />
                        </div>
                        <div className="important">
                            <label htmlFor="step4DescriptionTextarea" className="b-input-label-self">
                                <FormattedMessage
                                    id='bonfire.containers.SelfServiceStep4Page.description'
                                    defaultMessage='Gibt es noch etwas Wichtiges, was wir wissen müssen?'
                                />
                            </label>
                            <textarea id="step4DescriptionTextarea" rows="5"
                                      className="b-textarea-self" name="comment"
                                      onChange={this.handleChange}
                                      value={this.state.comment}>
                            </textarea>
                        </div>
                    </div>
                </div>
                <div className="bottom">
                    <div onClick={this.handleSubmit}>
                        <button className="b-button-pre-checkin" id="self-service-step4-confirm-next-button">
                            <FormattedMessage
                                id='bonfire.containers.SelfServiceStep3Page.buttonfinish'
                                defaultMessage='Bestätigen und weiter'
                            />
                        </button>
                    </div>
                </div>


            </SelfServiceMainLayout>
        );
    }
}

export const SelfServiceStep4 = connect(
    state => ({
        registrationForm: state.getIn(['selfService', 'registrationForm']),
        comment: state.getIn(['selfService', 'data', 'comment'])
    }),
    (dispatch) => ({
        setDataComment: comment => dispatch(Actions.setDataComment(comment))
    })
)(SelfServiceStep4Component);

