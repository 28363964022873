import {SessionSelectors} from "bonfire/ZTerminal/Session/SessionSlice";
import {Map} from 'immutable';
import CockpitService from "services/cockpit/CockpitService";
import {UserTypeEnum} from "services/users/UserService";

export const InitialState = Map({
    cockpitData: null,
    registrationForms: null,
    isLoading: false
});

const ACTION_SET_LOADING = 'zterminal/cockpit/setLoading';
const ACTION_LOAD_COCKPIT_DATA = 'zterminal/cockpit/loadData';
const ACTION_LOAD_REGFORMS = 'zterminal/cockpit/loadRegForms';

function fetchData(servicePartnerId, timeframe) {
    return (dispatch, getState) => {
        const hasData = !!getState().getIn(['zterminal', 'cockpit', 'cockpitData']);
        if (hasData) {
            return Promise.resolve();
        }

        dispatch(setLoading(true));
        const userType = SessionSelectors.userType(getState());
        const promises = [dispatch(fetchCockpitData(servicePartnerId))];
        if (userType === UserTypeEnum.SERVICE_PARTNER) {
            promises.push(dispatch(fetchRegForms(servicePartnerId, timeframe)));
        }

        return Promise.all(promises).then(result => {
            dispatch(setLoading(false));
        });
    };
}

function fetchCockpitData(servicePartnerId) {
    return (dispatch, getState) => {
        const userType = SessionSelectors.userType(getState());
        const fetchDataPromise = userType === UserTypeEnum.SERVICE_PARTNER ?
            CockpitService.getCockpitData(servicePartnerId) :
            CockpitService.getCockpitDataZT();
        return fetchDataPromise
            .then(cockpitData => dispatch(setCockpitData(cockpitData)))
            .catch(error => Promise.reject(error));
    };
}

function fetchRegForms(servicePartnerId, timeFrame) {
    return dispatch => CockpitService.getRegFormsByTimeFrame(timeFrame, servicePartnerId)
        .then(regForms => {
            regForms.timeFrame = timeFrame;
            return dispatch(setRegFormsData(regForms));
        })
        .catch(error => Promise.reject(error))
}

function setLoading(loading) {
    return {
        type: ACTION_SET_LOADING,
        isLoading: loading
    };
}

function clearCockpitData() {
    return {
        type: ACTION_LOAD_COCKPIT_DATA,
        cockpitData: null
    };
}

function setCockpitData(cockpitData) {
    return {
        type: ACTION_LOAD_COCKPIT_DATA,
        cockpitData: cockpitData
    };
}

function setRegFormsData(regForms) {
    return {
        type: ACTION_LOAD_REGFORMS,
        registrationForms: regForms
    };
}

export const Actions = {
    clearCockpitData,
    fetchData
};

export function Reducer(state = InitialState, action) {
    switch (action.type) {
        case ACTION_SET_LOADING:
            return state.set('isLoading', action.isLoading);
        case ACTION_LOAD_COCKPIT_DATA:
            return state.set('cockpitData', action.cockpitData);
        case ACTION_LOAD_REGFORMS:
            return state.set('registrationForms', action.registrationForms);
        default:
            return state;
    }
}


export default {
    Actions, InitialState, Reducer
}
