import React from 'react';

interface props {
    title: string
    showDataLabelSwitch: boolean
    showDataLabels: boolean,
    setShowDataLabels: React.Dispatch<React.SetStateAction<boolean>>
}

export const GraphHeader = ({title, showDataLabelSwitch, showDataLabels, setShowDataLabels}: props) => {
    return (
        <React.Fragment>
            <div className="graph-header-container">
                <div className="graph-title">
                    {title}
                </div>
                {showDataLabelSwitch &&
                    <div className="graph-toggle-datalabels-container">
                        <div className="graph-toggle-datalabels-label">
                            Graph beschriften
                        </div>
                        <label className="switch">
                            <input
                                type="checkbox"
                                checked={showDataLabels}
                                onChange={() => setShowDataLabels(prev => !prev)}
                            />
                            <span className="slider round"/>
                        </label>
                    </div>
                }
            </div>
        </React.Fragment>
    );
}