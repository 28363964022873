import React, {ReactElement} from 'react';
import './SobrCustomCheckbox.scss';

export interface SobrCustomCheckboxProps {
    id?: string | undefined
    name?: string | undefined
    checked: boolean
    disabled: boolean
    onChange: (checked: boolean) => void
}

export function SobrCustomCheckbox(props: SobrCustomCheckboxProps): ReactElement {
    return <div className="sobr-custom-checkbox-input">
        <label>
            <input
                id={props.id}
                type="checkbox"
                checked={props.checked}
                name={props.name}
                onChange={e => props.onChange(e.target.checked)}
                disabled={props.disabled}
            />
            <div className="control_indicator"/>
        </label>
    </div>
}
