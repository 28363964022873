const dot = (color = '#ccc') => ({
    alignItems: 'center',
    display: 'flex',
    color: "#7e7979",

    ':before': {
        backgroundColor: color,
        borderRadius: 10,
        content: '" "',
        display: 'block',
        marginRight: 8,
        height: "32px",
        width: "14vw",
    },
});

export const customDropDownStylesLittle = {
    control: (styles, { data, isDisabled, isFocused, isSelected }) => (
        { ...styles,
            backgroundColor: isDisabled
                ? '#eef4f9'
                :  '#ddeeff',
            width: "7vw",
            boxShadow: "none",
            borderColor: "#d0e5f8",
            minHeight: "32px",
            fontSize: "0.9em",
            fontFamily: "'PFCentroSansPro-Regular', sans-serif",
            color: isDisabled
                ? '#b1b0b0 !important'
                :  '#7e7979',
            fontWeight: "400",
            height: "32px",
        }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
        return {
            ...styles,
            backgroundColor: isDisabled
                ? 'pink'
                : isSelected ? '#ddeeff' : isFocused ? '#ddeeff' : 'white',
            color: isDisabled
                ? '#7e7979'
                : isSelected
                    ? 'pink' > 2 ? 'white' : '#7e7979'
                    :  "#7e7979",
            width: "7vw",
            cursor: isDisabled ? 'not-allowed' : 'default',
            height: "32px",
            minHeight: "32px",
            fontSize: "0.9em",
            fontFamily: "'PFCentroSansPro-Regular', sans-serif",
            fontWeight: "400",
        };
    },
    input: styles => ({ ...styles, ...dot() }),
    placeholder: styles => ({ ...styles, ...dot() }),
    singleValue: (styles, { data }) => ({ ...styles, ...dot('green') }),
};
